import React from 'react';
import { number, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Form } from 'antd';
import DetailRow from '@components/DetailRow';
import LanguageIcon from '@components/LanguageIcon';
import { Card } from '@cards/Card';
import './style.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

function IndexEventDetailsCard({ indexEvent, loading, title, ...props }) {
  const { t } = useTranslation();

  return (
    <Card.Half loading={loading} title={t(title)} className="index-event-details-card" {...props}>
      {indexEvent === null ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <DetailRow
            label={
              <>
                <LanguageIcon language="en" />
                {t('indexEvents:Details.names.en')}
              </>
            }
            value={indexEvent.name}
          />

          {Object.entries(indexEvent.translatedNames).map(([language, translatedName]) => (
            <DetailRow
              key={`${language}-name`}
              label={
                <>
                  <LanguageIcon language={language} />
                  {t(`indexEvents:Details.names.${language}`)}
                </>
              }
              value={translatedName}
            />
          ))}
        </Form>
      )}
    </Card.Half>
  );
}

IndexEventDetailsCard.propTypes = {
  indexEvent: shape({
    id: number,
    name: string,
    slug: string,
    translatedNames: shape({}),
  }),
  title: string,
};

IndexEventDetailsCard.defaultProps = {
  indexEvent: null,
  title: 'indexEvents:Details.title',
};

export default IndexEventDetailsCard;
