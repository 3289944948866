import { IReduxAction } from '@redux/types';
import {
  ICreateHospitalData,
  IHospital,
  IHospitalInvites,
  EditHospitalData,
  FETCH_HOSPITALS,
  LOAD_HOSPITALS,
  LOAD_HOSPITALS_SUCCESS,
  LOAD_HOSPITALS_FAILED,
  CREATE_HOSPITAL,
  CREATE_HOSPITAL_FAILED,
  CREATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL,
  UPDATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL_FAILED,
  EDIT_HOSPITAL,
  EDIT_HOSPITAL_FAILED,
  EDIT_HOSPITAL_SUCCESS,
} from './types';

export type IFetchHospitals = IReduxAction<typeof FETCH_HOSPITALS>;
export const fetchHospitals = (): IFetchHospitals => ({
  type: FETCH_HOSPITALS,
  payload: {},
});

export type ILoadHospitals = IReduxAction<typeof LOAD_HOSPITALS>;
export const loadHospitals = (): ILoadHospitals => ({
  type: LOAD_HOSPITALS,
  payload: {},
});

export type ILoadHospitalsSuccess = IReduxAction<
  typeof LOAD_HOSPITALS_SUCCESS,
  { hospitals: IHospital[]; invites: IHospitalInvites }
>;
export const loadHospitalsSuccess = (
  hospitals: IHospital[],
  invites: IHospitalInvites,
): ILoadHospitalsSuccess => ({
  type: LOAD_HOSPITALS_SUCCESS,
  payload: { hospitals, invites },
});

export type ILoadHospitalsFailed = IReduxAction<typeof LOAD_HOSPITALS_FAILED>;
export const loadHospitalsFailed = (): ILoadHospitalsFailed => ({
  type: LOAD_HOSPITALS_FAILED,
  payload: {},
});

export type CreateHospital = IReduxAction<
  typeof CREATE_HOSPITAL,
  { hospital: ICreateHospitalData }
>;
export const createHospital = (hospital: ICreateHospitalData) => ({
  type: CREATE_HOSPITAL,
  payload: { hospital },
});

export type CreateHospitalFailed = IReduxAction<typeof CREATE_HOSPITAL_FAILED>;
export const createHospitalFailed = () => ({
  type: CREATE_HOSPITAL_FAILED,
  payload: {},
});

export type CreateHospitalSuccess = IReduxAction<
  typeof CREATE_HOSPITAL_SUCCESS,
  { hospital: IHospital }
>;
export const createHospitalSuccess = (hospital: IHospital) => ({
  type: CREATE_HOSPITAL_SUCCESS,
  payload: { hospital },
});

export type IUpdateHospital = IReduxAction<typeof UPDATE_HOSPITAL, { hospital: IHospital }>;
export const updateHospital = (hospital: IHospital): IUpdateHospital => ({
  type: UPDATE_HOSPITAL,
  payload: { hospital },
});

export type IUpdateHospitalSuccess = IReduxAction<
  typeof UPDATE_HOSPITAL_SUCCESS,
  { hospitals: IHospital[] }
>;
export const updateHospitalSuccess = (hospitals: IHospital[]): IUpdateHospitalSuccess => ({
  type: UPDATE_HOSPITAL_SUCCESS,
  payload: { hospitals },
});

export type IUpdateHospitalFailed = IReduxAction<typeof UPDATE_HOSPITAL_FAILED>;
export const updateHospitalFailed = (): IUpdateHospitalFailed => ({
  type: UPDATE_HOSPITAL_FAILED,
  payload: {},
});

export type EditHospital = IReduxAction<
  typeof EDIT_HOSPITAL,
  { id: string; hospital: EditHospitalData; editOnly?: boolean }
>;
export const editHospital = (
  id: string,
  hospital: EditHospitalData,
  editOnly?: boolean,
): EditHospital => ({
  type: EDIT_HOSPITAL,
  payload: { id, hospital, editOnly },
});

export type EditHospitalSuccess = IReduxAction<
  typeof EDIT_HOSPITAL_SUCCESS,
  { hospitals: IHospital[] }
>;
export const editHospitalSuccess = (hospitals: IHospital[]): EditHospitalSuccess => ({
  type: EDIT_HOSPITAL_SUCCESS,
  payload: { hospitals },
});

export type EditHospitalFailed = IReduxAction<typeof EDIT_HOSPITAL_FAILED, {}>;
export const editHospitalFailed = (): EditHospitalFailed => ({
  type: EDIT_HOSPITAL_FAILED,
  payload: {},
});
