import { call, getContext, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import { IPathwaysAdminClient } from '@liquid-state/pathways-client';
import { doFetchRules } from '@pathways/redux/rules/sagas/fetch';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import {
  duplicatePathwaySuccess,
  duplicatePathwaysSuccess,
  IDuplicatePathway,
  IDuplicatePathways,
} from '../actions';
import { selectPathwaysById, selectPathway } from '../reducers';
import { updatePathwayMetadata } from '../utils';
import i18n from '../../../../i18n';
import { doDetermineOwnerForPathway } from './utils';
import { doFetchPathways } from './fetch';
import { IPathway } from '../types';

function* doDuplicationOfPathways(
  pathwayIds: number[],
  updatedMetadataByPathwayId: { [key: string]: { [key: string]: any } },
  ownerId: string,
): any {
  const pathwaysClient: IPathwaysAdminClient = yield call(doCreatePathwaysClient);
  const newPathways = [];

  for (const pathwayId of pathwayIds) {
    const duplicateResponse = yield call(
      pathwaysClient.duplicatePathway,
      pathwayId,
      updatedMetadataByPathwayId[`${pathwayId}`],
      ownerId,
    );
    const duplicatedPathway = yield call(duplicateResponse.json.bind(duplicateResponse));
    newPathways.push(duplicatedPathway);
  }

  yield call(doFetchPathways);
  yield call(doFetchRules);

  return newPathways;
}

type IDuplicationData = { id: string; updatedMetadata: { [key: string]: any } };
export function* doDuplicatePathways({ payload: { pathwayIds } }: IDuplicatePathways) {
  try {
    const pathways = yield select(selectPathwaysById(pathwayIds));
    const ownerId = yield call(doDetermineOwnerForPathway);
    const updatedMetdataByPathwayId = pathways
      .map((pathway: IPathway) => ({
        id: pathway.id,
        updatedMetadata: updatePathwayMetadata(pathway.metadata, ownerId),
      }))
      .reduce(
        (toDuplicate: { [key: string]: IDuplicationData }, data: IDuplicationData) => ({
          ...toDuplicate,
          [data.id]: data.updatedMetadata,
        }),
        {},
      );

    yield call(doDuplicationOfPathways, pathwayIds, updatedMetdataByPathwayId, ownerId);

    yield put(duplicatePathwaysSuccess());
    yield call(message.success, i18n.t('cards:ProcedurePathwayDetails.duplicate.success'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('cards:ProcedurePathwayDetails.duplicate.error'));
  }
}

export function* doDuplicatePathway({ payload: { pathwayId } }: IDuplicatePathway): any {
  const history = yield getContext('history');
  const pathway = yield select(selectPathway(pathwayId));
  const ownerId = yield call(doDetermineOwnerForPathway);

  try {
    const [duplicatedPathway] = yield call(
      doDuplicationOfPathways,
      [pathwayId],
      { [pathwayId]: updatePathwayMetadata(pathway.metadata, ownerId) },
      ownerId,
    );

    yield call(history.push, `/procedure/pathways/${duplicatedPathway.id}`);
    yield call(message.success, i18n.t('cards:ProcedurePathwayDetails.duplicate.success'));
    yield put(duplicatePathwaySuccess());
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('cards:ProcedurePathwayDetails.duplicate.error'));
  }
}
