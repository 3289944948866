import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Empty, Button, Tooltip, Form } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { fetchForms, fetchVersionFormData } from '@redux/forms/actions';
import { selectVersionFormData } from '@redux/forms/reducers';
import { fetchAppUsersForms } from '@redux/appUsers/actions';
import { selectAppUserFormSubmissions } from '@redux/appUsers/reducers';
import TranslatedForm from '@components/TranslatedForm';
import DetailRow from '@components/DetailRow';
import { useFormSubmissionData } from '@pages/AppUsers/AppUserFormDetail';
import { Card } from '../../Card';
import { appToken } from 'settings';
import './style.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const FormPreviewCard = ({ appUserId, formId, submissionId, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(0);
  const {
    setDisplayNames,
    setImprovements,
    setObjectVersion,
    setOrdering,
  } = useFormSubmissionData();
  const [submission, setSubmission] = useState();
  const [dataVersion, setDataVersion] = useState();

  const [loadingFormData, formVersionData] = useSelector(
    selectVersionFormData(formId, dataVersion),
  );

  useEffect(() => {
    if (dataVersion) {
      setObjectVersion(dataVersion);
    }
  }, [dataVersion, setObjectVersion]);

  useEffect(() => {
    if (formVersionData && formVersionData.computations) {
      const formData = Object.keys(formVersionData.computations).reduce(
        (data, key) => ({
          names: { ...data.names, [key]: formVersionData.computations[key].display_name },
          improvements: {
            ...data.improvements,
            [key]: formVersionData.computations[key].improvement,
          },
          ordering: [
            ...(data.ordering || []),
            { key, order: formVersionData.computations[key].display_order },
          ],
        }),
        {},
      );
      setDisplayNames(formData.names);
      setImprovements(formData.improvements);
      setOrdering(formData.ordering.sort((a, b) => a.order - b.order));
    }
  }, [formVersionData, setDisplayNames, setImprovements, setOrdering]);

  const [submissionLoading, userSubmissions] = useSelector(
    selectAppUserFormSubmissions(appUserId, formId),
  );

  const pipObjectType = `${appToken}-form-${formId}`;

  const formSubmissions = useMemo(() => {
    if (userSubmissions) {
      return userSubmissions.filter(sub => sub.formObjectType === pipObjectType);
    }
    return [];
  }, [pipObjectType, userSubmissions]);

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchAppUsersForms(appUserId));
  }, [dispatch, appUserId, formId]);

  useEffect(() => {
    if (submissionLoading === false && formSubmissions?.length) {
      const selectedSubmission = formSubmissions[index];
      setSubmission(selectedSubmission);
    }
  }, [formSubmissions, index, submissionLoading]);

  useEffect(() => {
    if (submissionLoading === false && formSubmissions?.length) {
      const defaultIndex = formSubmissions.findIndex(sub => sub.uuid === submissionId);
      setIndex(defaultIndex);
    }
  }, [formSubmissions, submissionId, setIndex, submissionLoading]);

  useEffect(() => {
    const dataKey = `${pipObjectType}-data`;

    if (submission) {
      const schemaVersion = submission.versions[pipObjectType];
      const dataVersion = submission.versions[dataKey];

      dispatch(fetchVersionFormData(formId, dataVersion, schemaVersion, appUserId));
      setDataVersion(dataVersion);
    }
  }, [appUserId, dispatch, formId, pipObjectType, submission]);

  const loading = loadingFormData || submissionLoading || dataVersion === undefined;

  // sort by version, descending (newest to oldest)
  const submissions = useMemo(
    () =>
      formSubmissions &&
      formSubmissions.sort((a, b) => {
        if (a.created > b.created) return -1;
        if (a.created < b.created) return 1;
        return 0;
      }),
    [formSubmissions],
  );

  const currentSubmission = submissions ? submissions[index] : undefined;

  const readOnlyUISchema = {
    'ui:readonly': true,
    ...(formVersionData?.uiSchema ?? {}),
  };

  return (
    <Card.Half
      className="form-preview-card"
      title={t('cards:FormPreview.title')}
      loading={loading}
      extra={
        <Button.Group>
          <Tooltip title={t('cards:FormPreview.older')} placement="bottomRight">
            <Button
              onClick={() => setIndex(index + 1)}
              disabled={
                submissions === undefined ||
                submissions.length === 0 ||
                index === submissions.length - 1
              }
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
          </Tooltip>
          <Tooltip title={t('cards:FormPreview.newer')} placement="bottomRight">
            <Button onClick={() => setIndex(index - 1)} disabled={index === 0}>
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </Tooltip>
        </Button.Group>
      }
      {...props}
    >
      <div className="date-submitted">
        <Form {...formItemLayout}>
          <DetailRow
            label={t('cards:FormPreview.dateSubmitted')}
            value={currentSubmission && moment(currentSubmission.created).format('LLL')}
          />
        </Form>
      </div>
      {formVersionData === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <TranslatedForm
          namespace="forms"
          schema={formVersionData.schema}
          uiSchema={readOnlyUISchema}
          formData={formVersionData?.formData}
        >
          <div></div>
        </TranslatedForm>
      )}
    </Card.Half>
  );
};

export default FormPreviewCard;
