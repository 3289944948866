import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { contentTypes, getContentTypeValues } from '@utils';

import './style.less';

const createMenu = (history, t) => {
  const onMenuClick = ({ key }) => `${history.push(getContentTypeValues(key).path)}/new`;

  return (
    <Menu onClick={onMenuClick}>
      {Object.keys(contentTypes).map(key => {
        return (
          <Menu.Item key={key} className="new-content-menu-item">
            <div className="menu-item-icon">
              <FontAwesomeIcon icon={contentTypes[key].icon} />
            </div>
            <span className="menu-item-label">{t(contentTypes[key].label)}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const NewContentDropdown = ({ primaryKey, ...props }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const menu = useMemo(() => createMenu(history, t), [history, t]);
  const { path } = useMemo(() => getContentTypeValues(primaryKey), [primaryKey]);

  return (
    <Dropdown.Button
      onClick={() => history.push(`${path}/new`)}
      overlay={menu}
      icon={<FontAwesomeIcon icon={faAngleDown} />}
      type="primary"
      className="new-content-dropdown primary-action-button"
      {...props}
    >
      {t('common:buttons.new')}
    </Dropdown.Button>
  );
};

NewContentDropdown.propTypes = {
  primaryKey: PropTypes.string.isRequired,
};

export default NewContentDropdown;
