import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import LanguageIcon from '@components/LanguageIcon';
import SourceIcon from '@components/SourceIcon';
import None from '@components/None';
import { extractSourceFromOwnerId } from '@utils';
import { Card } from '../../../../cards/Card';
import './style.less';

function PathwayDetailsCard({ loading, pathway, externalId, ...props }) {
  const { t } = useTranslation();

  if (loading) {
    return <Card.Half loading title={t('cards:ProcedurePathwayDetails.title')} {...props} />;
  }

  if (!pathway) return null;

  return (
    <Card.Half
      className="procedure-pathway-details-card"
      title={t('cards:ProcedurePathwayDetails.title')}
      {...props}
    >
      <DetailForm>
        <DetailRow label={t('cards:ProcedurePathwayDetails.labels.title')} value={pathway.name} />
        {externalId ? <DetailRow label="UCID" value={externalId} /> : null}
        <DetailRow
          label={t('cards:ProcedurePathwayDetails.labels.status')}
          value={<PublishedStatusIcon published={pathway.isActive} showText />}
        />
        {/* <DetailRow
          label={t('cards:ProcedurePathwayDetails.labels.created')}
          value={pathway.created}
        /> */}
        <DetailRow
          label={t('cards:ProcedurePathwayDetails.labels.description')}
          value={pathway.description || <None />}
        />
        {pathway.ownerId ? (
          <DetailRow
            label={t('cards:ProcedurePathwayDetails.labels.source')}
            value={<SourceIcon source={extractSourceFromOwnerId(pathway.ownerId)} showText />}
          />
        ) : null}
        <DetailRow
          label={t('cards:ProcedurePathwayDetails.labels.language')}
          value={<LanguageIcon language={pathway.language || pathway.metadata.language} showText />}
        />
      </DetailForm>
    </Card.Half>
  );
}

PathwayDetailsCard.propTypes = {
  loading: bool,
};

PathwayDetailsCard.defaultProps = {
  loading: false,
};

export default PathwayDetailsCard;
