import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearch } from '@hooks';
import { useHistory } from 'react-router-dom';

import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospital } from '@redux/hospitals/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathwaysById } from '@pathways/redux/pathways/reducers';
import { Card } from '@cards/Card';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

const HospitalProcedurePathwaysCard = ({ showTitle, hospitalId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchHospitals());
    dispatch(fetchPathways());
  }, [dispatch]);

  const [hospitalLoading, hospital] = useSelector(selectHospital(hospitalId));
  const pathwaysSelector = useCallback(selectPathwaysById(hospital?.pathways), [hospital]);
  const [pathwaysLoading, hospitalPathways] = useSelector(pathwaysSelector);
  const [searchResults, searchProps] = useSearch(hospitalPathways, filterFunc);

  return (
    <>
      <Card.Full className="pathway-list-card" noPadding>
        <div className="search-container">
          {showTitle ? <h3>{t('cards:ProcedurePathwayList.title')}</h3> : null}
          <Input.Search placeholder={t('common:search')} {...searchProps} />
        </div>
        <Table
          columns={columns}
          dataSource={searchResults}
          loading={pathwaysLoading || hospitalLoading}
          pagination={{ position: 'bottom' }}
          rowKey="id"
          scroll={{ x: 1000 }}
          onRow={({ id }) => ({
            onClick: () => history.push(`/procedure/pathways/${id}`),
          })}
        />
      </Card.Full>
    </>
  );
};

export default HospitalProcedurePathwaysCard;
