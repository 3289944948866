import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Card } from '@cards/Card';
import './style.less';

const LinkCard = ({
  onContentClick,
  showButton,
  buttonIcon,
  buttonLabel,
  buttonClick,
  ...props
}) => {
  return (
    <Card.Half className="link-card" {...props} title={undefined}>
      <div
        className={cn('content', {
          clickable: onContentClick !== undefined,
        })}
        onClick={onContentClick}
      >
        <h1>{props.title}</h1>
        {props.description}
      </div>
      {showButton && (
        <div className="new-button" onClick={() => buttonClick()}>
          <span className="btn-icon">{buttonIcon}</span>
          <span className="btn-label">{buttonLabel}</span>
        </div>
      )}
    </Card.Half>
  );
};

LinkCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onContentClick: PropTypes.func,
  showButton: PropTypes.bool,
  buttonClick: PropTypes.func.isRequired,
  buttonIcon: PropTypes.object.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

LinkCard.defaultProps = {
  showButton: true,
};

export default LinkCard;
