import React, { useEffect, useState, useMemo, useContext } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Modal, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import MessageButton from '@components/MessageButton';
import { fetchForms, deleteForms } from '@redux/forms/actions';
import { selectFormDeleting } from '@redux/forms/reducers';
import { useTranslation } from 'react-i18next';
import SearchableTable from '@components/SearchableTable';
import IconButton from '@components/IconButton';
import { Permissions } from '@authorisation/constants';
import { useHasPermissions } from '@authorisation/hooks';
import { FormSelectorContext } from '../../../pages/Forms';
import createColumns from './createColumns';

const confirmDelete = (onOk, t, formTranslationKey) => {
  Modal.confirm({
    title: t(`${formTranslationKey}:FormList.confirmDelete`),
    onOk,
    onCancel: () => {},
  });
};

const filterFunc = (value, comparator) => {
  return comparator(value.name) || comparator(value.description);
};

const FormListCard = ({ showTitle, match, formTranslationKey }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useStore();
  const selector = useContext(FormSelectorContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = useMemo(() => createColumns(t, formTranslationKey), [t, formTranslationKey]);

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch]);

  const [loading, forms] = useSelector(selector);
  const { [Permissions.ViewPatients]: canViewPatients } = useHasPermissions(
    Permissions.ViewPatients,
  );

  return (
    <SearchableTable
      columns={columns}
      dataSource={forms}
      extra={
        <>
          {canViewPatients ? (
            <>
              <MessageButton
                disabled={selectedRows.length !== 1}
                onClick={() => history.push(`/messages/new/${selectedRows[0]}`)}
              />
              <Divider key="divider" type="vertical" />
            </>
          ) : null}
          <IconButton
            disabled={!selectedRows.length}
            icon={faTrashAlt}
            tooltip={t('common:buttons.delete')}
            onClick={() =>
              confirmDelete(
                () => {
                  dispatch(deleteForms(selectedRows, formTranslationKey));
                  setSelectedRows([]);
                  return waitForStoreUpdate(store, selectFormDeleting); // must return for modal to be dismissed
                },
                t,
                formTranslationKey,
              )
            }
          />
        </>
      }
      filterFunc={filterFunc}
      hasRoutedPagination
      loading={loading}
      rowKey="uuid"
      title={showTitle ? t('cards:FormList.title') : null}
      onRowClick={row => history.push(`${match.path}/${row.uuid}`)}
      onRowSelectionChange={(selectedRowKeys, selectedRows) => setSelectedRows(selectedRowKeys)}
    />
  );
};

export default FormListCard;
