import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HospitalList from './List';
import HospitalDetails from './Details';

function Hospital({ match }) {
  return (
    <Switch>
      <Route path={`${match.path}/:hospitalId`} component={HospitalDetails} />
      <Route path={match.path} component={HospitalList} />
    </Switch>
  );
}

export default Hospital;
