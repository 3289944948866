import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnGroup, Column } from '@cards/Card';
import AttachedContent from '@cards/Messages/AttachedContent';
import useWizard from '@components/Wizard/useWizard';
import DetailsCard from './DetailsCard';
import PreviewCard from '@cards/Messages/Preview';
import { OPEN_CONTENT } from '@constants';
import { fetchDocuments } from '@redux/documents/actions';
import { fetchForms } from '@redux/forms/actions';
import { selectDocument } from '@redux/documents/reducers';
import { selectForm } from '@redux/forms/reducers';
import './style.less';

function Details({ initialContentId }) {
  const dispatch = useDispatch();

  const { currentStepData, form: wizardForm, setCurrentStepData } = useWizard();
  const { action, content, title, websiteLink } = wizardForm.getFieldsValue();
  const { contentTitle } = currentStepData;

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchForms());
  }, [dispatch]);

  const [, form] = useSelector(selectForm(initialContentId));
  const [, document] = useSelector(selectDocument(initialContentId));

  useEffect(() => {
    let content = document || form;

    if (content) {
      if (document) {
        content = {
          ...content,
          metadata: {
            type: content.metadata?.tags?.find(t => t.term === 'VIDEO') ? 'VIDEO' : 'DOCUMENT',
            ...(content.metadata || {}),
          },
        };
      }

      wizardForm.setFieldsValue({ action: OPEN_CONTENT });
      setCurrentStepData({
        ...currentStepData,
        contentTitle: content.title || content.name,
        contentType: content.metadata.type,
        contentData: { id: content.uuid, ...content },
      });
    }
  }, [document, form]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ColumnGroup>
      <Column>
        <DetailsCard />
        {action === OPEN_CONTENT ? <AttachedContent content={currentStepData.contentData} /> : null}
      </Column>
      <Column>
        <PreviewCard
          action={action}
          content={content}
          title={title}
          websiteLink={websiteLink}
          contentTitle={contentTitle}
        />
      </Column>
    </ColumnGroup>
  );
}

export default Details;
