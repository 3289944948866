import React from 'react';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import SourceIcon from '@components/SourceIcon';
import { createLanguageFilters } from '@utils/supportedLanguages';
import { extractSourceFromOwnerId } from '@utils';
import './style.less';

export default t => [
  {
    className: 'language-column',
    title: t('cards:ProcedurePathwayList.columns.language.title'),
    dataIndex: 'metadata',
    key: 'language',
    render: ({ language }) => (language ? <LanguageIcon language={language} /> : null),
    sorter: (a, b) =>
      a.metadata.language && b.metadata.language
        ? a.metadata.language.localeCompare(b.metadata.language)
        : -1,
    filters: createLanguageFilters(t),
    onFilter: (value, record) => {
      return record.metadata.language === value;
    },
  },
  {
    className: 'source-column',
    title: t('cards:ProcedurePathwayList.columns.source.title'),
    dataIndex: 'ownerId',
    key: 'source',
    render: ownerId => <SourceIcon source={extractSourceFromOwnerId(ownerId)} />,
    // width: 130,
    sorter: (a, b) => {
      if (a.source > b.source) return -1;
      if (a.source < b.source) return 1;
      return 0;
    },
    filters: [
      {
        text: t('common:source.global'),
        value: 'global',
      },
      // {
      //   text: t('common:source.country'),
      //   value: 'country',
      // },
      {
        text: t('common:source.hospital'),
        value: 'hospital',
      },
    ],
    onFilter: (value, record) => (record.ownerId || '').includes(value),
  },
  {
    title: t('cards:ProcedurePathwayList.columns.title'),
    dataIndex: 'name',
    key: 'title',
    // width: 250,
    ellipsis: true,
    sorter: (a, b) => {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    },
  },
  {
    title: t('cards:ProcedurePathwayList.columns.description'),
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    sorter: (a, b) => {
      if (a.description > b.description) return -1;
      if (a.description < b.description) return 1;
      return 0;
    },
  },
  {
    className: 'published-column',
    title: t('cards:ProcedurePathwayList.columns.published.title'),
    dataIndex: 'isActive',
    fixed: 'right',
    key: 'isActive',
    render: isActive => <PublishedStatusIcon published={isActive} />,
    sorter: ({ isActive: a }, { isActive: b }) => {
      if (a === b) return 0;
      if (a < b) return 1;
      if (a > b) return -1;
    },
    filters: [
      {
        text: t('cards:ProcedurePathwayList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:ProcedurePathwayList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.isActive === value,
  },
];
