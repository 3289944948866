import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form } from 'antd';
import { contentTypes } from '@utils';

import './styles.less';

function ContentInformation({ title, type, onChangeContent }) {
  const [contentInfo, setContentInfo] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (type) {
      setContentInfo(contentTypes[type]);
    }
  }, [type]);

  if (!contentInfo) return null;

  return (
    <>
      <Form.Item label={t('messages:Wizard.details.contentType')}>
        <span>
          <FontAwesomeIcon icon={contentInfo.icon} /> {t(contentInfo.label)}
        </span>
      </Form.Item>
      <Form.Item
        className="content-information-form-item-with-button"
        label={t('messages:Wizard.details.contentTitle')}
      >
        <span>{title}</span>
        <Button onClick={onChangeContent}>{t('messages:Wizard.details.changeContent')}</Button>
      </Form.Item>
    </>
  );
}

ContentInformation.propTypes = {
  title: string,
  type: string,
  onChangeContent: func.isRequired,
};

export default ContentInformation;
