import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Empty, Form } from 'antd';
import moment from 'moment';
import { Card } from '../../Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown /* , faInfoCircle */ } from '@fortawesome/pro-regular-svg-icons';
import { useFormSubmissionData } from '@pages/AppUsers/AppUserFormDetail';
// import IconButton from '@components/IconButton';
import { fetchForms } from '@redux/forms/actions';
import { fetchAppUsersForms } from '@redux/appUsers/actions';
import { selectAppUserFormComputations } from '@redux/appUsers/reducers';
import './style.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
    md: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
    md: { span: 16 },
  },
};

function formatComputedValue(value) {
  if (typeof value === 'number') {
    const result = value.toFixed(1);
    return Number(result) === -0 ? 0 : result;
  }

  return value;
}

const INCREASE = 'increase';
const DECREASE = 'decrease';

function Arrow({ improvement, previous, value }) {
  const [className, setClassName] = useState('');
  const [icon, setIcon] = useState();

  useEffect(() => {
    if (previous !== undefined) {
      if (
        (value < previous && improvement === DECREASE) ||
        (value > previous && improvement === INCREASE)
      ) {
        setClassName('green-arrow');
      } else if (
        (value > previous && improvement === DECREASE) ||
        (value < previous && improvement === INCREASE)
      ) {
        setClassName('red-arrow');
      }

      if (value < previous) setIcon(faArrowDown);
      if (value > previous) setIcon(faArrowUp);
    }
  }, [improvement, previous, value]);

  return (
    <div className={className}>
      {icon ? <FontAwesomeIcon icon={icon} size="1.5x" /> : null}
      {formatComputedValue(value)}
    </div>
  );
}

const FormScoreCard = ({
  displayNames,
  improvements,
  scoreData,
  loading,
  canDownload,
  canPreview,
  ordering,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Card.Half
      className="form-scores-card"
      noPadding
      title={t('cards:FormScores.title')}
      loading={loading}
      // extra={
      //   <IconButton
      //     icon={faInfoCircle}
      //     tooltip={t('common:buttons.delete')}
      //     // onClick={onDelete}
      //   />
      // }
      {...props}
    >
      {!scoreData ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <div>
          <div className="scores-container">
            <div>
              {ordering.map(({ key }) =>
                key === '_created' ? null : (
                  <span key={key}>
                    {displayNames[key]}
                    <Arrow
                      improvement={improvements[key]}
                      previous={scoreData.previous[key] || undefined}
                      value={scoreData.latest[key] || undefined}
                    />
                  </span>
                ),
              )}
            </div>
          </div>
          <Form {...formItemLayout} className="details-form">
            <Form.Item label={t('cards:FormScores.labels.submitted')}>
              <span className="ant-form-text">
                {moment(scoreData.latest._created).format('L LT')}
              </span>
            </Form.Item>
            {scoreData.previous._created ? (
              <Form.Item label={t('cards:FormScores.labels.previousSubmission')}>
                <span className="ant-form-text">
                  {moment(scoreData.previous._created).format('L LT')}
                </span>
              </Form.Item>
            ) : null}
          </Form>
        </div>
      )}
    </Card.Half>
  );
};

const FormScoreCardbyId = ({ appUserId, formId, ...props }) => {
  const dispatch = useDispatch();
  const { displayNames, improvements, objectVersion, ordering } = useFormSubmissionData();

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchAppUsersForms(appUserId));
  }, [dispatch, appUserId]);

  const [loading, computations] = useSelector(
    selectAppUserFormComputations(appUserId, formId, objectVersion),
  );

  const scoreData = useMemo(() => {
    if (computations) {
      return computations.find(computation => computation.dataObjectVersion === objectVersion);
    }
  }, [computations, objectVersion]);

  if (!scoreData) return null;

  return (
    <FormScoreCard
      scoreData={scoreData}
      displayNames={displayNames}
      improvements={improvements}
      loading={loading}
      ordering={ordering}
      {...props}
    />
  );
};

export { FormScoreCard };

export default FormScoreCardbyId;
