import { takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ACTIVATE_APP_USER,
  DEACTIVATE_APP_USER,
  DELETE_APP_USERS,
  FETCH_APP_USERS_FORMS,
  FETCH_APP_USERS,
  REFRESH_APP_USERS,
  INVITE_APP_USER,
  RESEND_APP_USER_INVITE,
  APP_USER_SUBMIT_FORM,
  UPDATE_APP_USER,
} from '../types';
import takeFirst from '../../takeFirst';

import { doActivateAppUser, doDeactivateAppUser, doResendAppUserInvite } from './appUserAccess';
import { doFetchAppUserForms, doFetchAppUsers, doFetchAppUserFormsForResults } from './fetch';
import { doDeleteAppUsers, doInviteAppUser, doSubmitAppUserForm, doUpdateAppUser } from './manage';

export default function* root() {
  yield takeLatest('appUsers/fetch-app-user-forms-for-results', doFetchAppUserFormsForResults);
  yield takeLatest(ACTIVATE_APP_USER, doActivateAppUser);
  yield takeLatest(DEACTIVATE_APP_USER, doDeactivateAppUser);
  yield takeLatest(DELETE_APP_USERS, doDeleteAppUsers);
  yield takeFirst(FETCH_APP_USERS, doFetchAppUsers);
  yield takeLatest(REFRESH_APP_USERS, doFetchAppUsers);
  yield takeLatest(FETCH_APP_USERS_FORMS, doFetchAppUserForms);
  yield takeEvery(INVITE_APP_USER, doInviteAppUser);
  yield takeLatest(RESEND_APP_USER_INVITE, doResendAppUserInvite);
  yield takeLatest(APP_USER_SUBMIT_FORM, doSubmitAppUserForm);
  yield takeLatest(UPDATE_APP_USER, doUpdateAppUser);
}
