import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createLanguageFilters } from '@utils/supportedLanguages';
import { contentTypes, documentTypes } from '@utils/contentTypes';
import DocumentPublishedStatusColumn from '@cards/Documents/List/PublishedStatusColumn';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import LanguageIcon from '@components/LanguageIcon';

const createColumns = (t, dataSourceContentTypes) => [
  {
    className: 'language-column',
    dataIndex: 'language',
    filters: createLanguageFilters(t),
    key: 'language',
    onFilter: (value, record) => {
      const lang = record.language || '';
      return lang.toLowerCase() === value;
    },
    render: language => <LanguageIcon language={language} />,
    sorter: (a, b) => (a.language || '').localeCompare(b.language || ''),
    title: t('cards:DocumentList.columns.language.title'),
  },
  {
    title: t('common:modals.SelectContent.columns.contentType'),
    dataIndex: 'type',
    key: 'type',
    render: type => (
      <span className="fa-icon-with-text">
        <FontAwesomeIcon icon={contentTypes[type]?.icon} />
        {t(contentTypes[type]?.label)}
      </span>
    ),
    width: 160,
    sorter: (a, b) => (a.type || '').localeCompare(b.type || ''),
    filters: dataSourceContentTypes.map(type => ({
      text: t(contentTypes[type]?.label),
      value: type,
    })),
    onFilter: (value, record) => record.type === value,
  },
  {
    title: t('common:modals.SelectContent.columns.title'),
    dataIndex: 'name',
    key: 'title',
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    className: 'published-column',
    key: 'published',
    title: t('common:modals.SelectContent.columns.published'),
    render: ({ id, published, type }) => {
      if (documentTypes.includes(type)) {
        return <DocumentPublishedStatusColumn documentId={id} />;
      }
      return <PublishedStatusIcon published={published} />;
    },
    sorter: (a, b) => {
      return a.published > b.published;
    },
  },
];

export default createColumns;
