import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, oneOf } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Pagination, Skeleton } from 'antd';
import {
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  OPEN_CONTENT,
  OPEN_LINK,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
} from '@constants';
import { clearDocumentPreview, getDocumentPreview } from '@redux/documents/actions';
import { selectDocumentPreview } from '@redux/documents/reducers';
import { fetchForms, fetchFormData, removeFormData } from '@redux/forms/actions';
import { selectForm, selectFormData } from '@redux/forms/reducers';
import FormPreviewModal from '@components/FormPreviewModal';
import { pathwayRuleType } from '../../../../propTypes';

const documentRuleTypes = [FEATURE_DOCUMENT, FEATURE_VIDEO];
const formRuleTypes = [
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
];
const messageRuleTypes = [MESSAGE, MESSAGE_ALERT, MESSAGE_REMINDER];

function DocumentPreview({ document, visible, onCancel }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const preview = useSelector(selectDocumentPreview);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(getDocumentPreview(document.id));

    return () => dispatch(clearDocumentPreview());
  }, [dispatch, document]);

  return (
    <Modal
      className="rule-document-preview-modal"
      title={document.name}
      visible={visible}
      onCancel={onCancel}
      footer={
        <Button type="primary" onClick={onCancel}>
          {t('common:buttons.close')}
        </Button>
      }
    >
      {preview.loading ? (
        <Skeleton />
      ) : (
        <div className="iframe-container">
          <iframe src={preview.pages[currentPage - 1]} title={document.name} />
        </div>
      )}
      {preview.pages.length > 1 ? (
        <Pagination
          current={currentPage}
          defaultPageSize={1}
          total={preview.pages.length}
          onChange={page => {
            setCurrentPage(page);
          }}
        />
      ) : null}
    </Modal>
  );
}

function FormPreview({ formId, visible, onCancel }) {
  const dispatch = useDispatch();
  const [formLoading, form] = useSelector(selectForm(formId));
  const [formDataLoading, formData] = useSelector(selectFormData(formId));

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchFormData(formId));

    return () => dispatch(removeFormData(formId));
  }, [dispatch, formId]);

  return (
    <FormPreviewModal
      readOnly
      name={form?.name}
      visible={visible}
      handleCloseModal={onCancel}
      loading={formLoading || formDataLoading}
      schema={formData?.schema ?? {}}
      uiSchema={formData?.uiSchema ?? {}}
    />
  );
}

function MessagePreview({ message, visible, onCancel }) {
  const { t } = useTranslation();

  if (!message) return null;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={
        <Button type="primary" onClick={onCancel}>
          {t('common:buttons.close')}
        </Button>
      }
    >
      <div className="notification-preview">
        <div className="notification-message-preview">
          <div className="notification-message-preview-header">
            <span>{t('messages:Wizard.details.preview.forPatientApp')}</span>
            <span>{t('messages:Wizard.details.preview.now')}</span>
          </div>
          <p>
            <b>{message.title}</b>
          </p>
          <p>{message.body}</p>
          {message.payload.action === OPEN_LINK && message.payload.data ? (
            <a href={message.payload.data} rel="noopener noreferrer" target="_blank">
              {message.payload.data}
            </a>
          ) : null}
          {message.payload.action === OPEN_CONTENT && message.payload.data.name ? (
            <u>{message.payload.data.name}</u>
          ) : null}
        </div>
      </div>
      <div className="notification-preview-footer">
        {t('messages:Wizard.details.preview.footer')}
      </div>
    </Modal>
  );
}

function ContentPreview({ previewType, rule, visible, onCancel }) {
  if (!rule) return null;

  if (documentRuleTypes.includes(rule[previewType])) {
    return (
      <DocumentPreview
        document={rule[`${previewType}Detail`]}
        visible={visible}
        onCancel={onCancel}
      />
    );
  }

  if (formRuleTypes.includes(rule[previewType])) {
    return (
      <FormPreview
        formId={rule[`${previewType}Detail`].uuid}
        visible={visible}
        onCancel={onCancel}
      />
    );
  }

  if (messageRuleTypes.includes(rule[previewType])) {
    return (
      <MessagePreview
        message={rule[`${previewType}Detail`]}
        visible={visible}
        onCancel={onCancel}
      />
    );
  }

  return (
    <Modal visible={visible} onCancel={onCancel}>
      {rule.name}
    </Modal>
  );
}

ContentPreview.propTypes = {
  previewType: oneOf(['what', 'when']),
  rule: pathwayRuleType,
  visible: bool,
  onCancel: func.isRequired,
};

ContentPreview.defaultProps = {
  previewType: 'what',
  rule: undefined,
  visible: false,
};

export default ContentPreview;
