import React from 'react';
import { Icon, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pathway } from '@assets/icons';
import LanguageIcon from '@components/LanguageIcon';
import {
  DELAY,
  whatTypeIcons,
  whatLabelTypes,
  whenTypes,
  whoTypes,
  TRANSITION_NEXT,
} from '@constants';
import { createLanguageFilters } from '@utils/supportedLanguages';
import { extractSourceFromOwnerId } from '@utils';
import SourceIcon from '@components/SourceIcon';

export default (t, defaultFilteredValue) => [
  {
    title: t('cards:ProcedureRuleList.columns.language.title'),
    dataIndex: 'metadata',
    defaultFilteredValue: defaultFilteredValue.language || [],
    key: 'language',
    render: ({ language }) => <LanguageIcon language={language} />,
    width: 150,
    sorter: (a, b) => a.metadata.language.localeCompare(b.metadata.language),
    filters: createLanguageFilters(t),
    onFilter: (value, record) => record.metadata.language === value,
  },
  {
    className: 'source-column',
    dataIndex: 'ownerId',
    title: t('cards:ProcedureRuleList.columns.source.title'),
    key: 'source',
    render: ownerId => <SourceIcon source={extractSourceFromOwnerId(ownerId)} />,
    width: 130,
    sorter: (a, b) => {
      if (a.source > b.source) return -1;
      if (a.source < b.source) return 1;
      return 0;
    },
    filters: [
      {
        text: t('common:source.global'),
        value: 'global',
      },
      {
        text: t('common:source.country'),
        value: 'country',
      },
      {
        text: t('common:source.hospital'),
        value: 'hospital',
      },
    ],
    onFilter: (value, record) => extractSourceFromOwnerId(record.ownerId) === value,
  },
  {
    title: t('cards:ProcedureRuleList.columns.name'),
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a, b) => {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    },
  },
  {
    title: t('cards:ProcedureRuleList.columns.what'),
    dataIndex: 'what',
    key: 'what',
    ellipsis: true,
    width: 100,
    render: what =>
      what === TRANSITION_NEXT ? (
        <Tooltip title={t(whatLabelTypes[what])}>
          <Icon component={pathway} />
        </Tooltip>
      ) : (
        <Tooltip title={t(whatLabelTypes[what])}>
          <FontAwesomeIcon icon={whatTypeIcons[what]} />
        </Tooltip>
      ),
  },
  {
    title: t('cards:ProcedureRuleList.columns.who'),
    dataIndex: 'who',
    key: 'who',
    ellipsis: true,
    render: who => t(whoTypes[who]),
  },
  {
    title: t('cards:ProcedureRuleList.columns.when'),
    dataIndex: 'when',
    key: 'when',
    ellipsis: true,
    render: (when, record) => {
      if (when === DELAY) return `${record.whenDetail.days} ${t(whenTypes[when])}`;
      return t(whenTypes[when]);
    },
  },
];
