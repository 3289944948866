import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { Modal, Button, Skeleton, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import TranslatedForm from '@components/TranslatedForm';
import { fetchFormData } from '@redux/forms/actions';
import { selectFormData } from '@redux/forms/reducers';
import './style.less';

const FormPreviewModal = ({
  readOnly,
  schema,
  uiSchema,
  visible,
  handleCloseModal,
  name,
  loading,
  submitting,
  children,
  hideCloseFooter,
  ...props
}) => {
  const { t } = useTranslation();

  const finalUiSchema = {
    'ui:readonly': readOnly ? true : false,
    ...uiSchema,
  };

  return (
    <Modal
      className="form-preview-modal"
      title={name}
      visible={visible}
      onCancel={handleCloseModal}
      centered
      footer={
        hideCloseFooter
          ? null
          : [
              <Button type="primary" onClick={handleCloseModal}>
                {t('cards:FormContent.modalButton')}
              </Button>,
            ]
      }
    >
      {loading ? (
        <Skeleton />
      ) : (
        <Spin spinning={submitting}>
          <TranslatedForm schema={schema} uiSchema={finalUiSchema} namespace="forms" {...props}>
            {readOnly ? <div></div> : children}
          </TranslatedForm>
        </Spin>
      )}
    </Modal>
  );
};

FormPreviewModal.propTypes = {
  submitting: bool,
};

FormPreviewModal.defaultProps = {
  submitting: false,
};

export const FormPreviewModalById = ({ formId, children, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFormData(formId));
  }, [dispatch, formId]);

  const [loading, formData] = useSelector(selectFormData(formId));

  return (
    <FormPreviewModal
      loading={loading}
      schema={formData?.schema ?? {}}
      uiSchema={formData?.uiSchema ?? {}}
      {...props}
    >
      {children}
    </FormPreviewModal>
  );
};

export default FormPreviewModal;
