import React, { useMemo } from 'react';
import ReactDOM from 'react-dom';
import App from './pages';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { useTranslation } from 'react-i18next';
import { Router } from 'react-router';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import deDE from 'antd/es/locale/de_DE';
import esES from 'antd/es/locale/es_ES';
import csCZ from 'antd/es/locale/cs_CZ';
import jaJP from 'antd/es/locale/ja_JP';
import skSK from 'antd/es/locale/sk_SK';

import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/cs';
import 'moment/locale/ja';
import 'moment/locale/sk';

import { initialise as initialiseAnalytics, trackApplicationEvent } from './analytics';

import createStore from './store';
import * as serviceWorker from './serviceWorker';
import './i18n';

import './style.less';

const history = createBrowserHistory();
const store = createStore({ history });

initialiseAnalytics();
trackApplicationEvent('Loading');

// maps i18next ISO language codes to antd locale codes
const getLocale = lang => {
  switch (true) {
    case lang.startsWith('en'):
      return enGB;
    case lang.startsWith('de'):
      return deDE;
    case lang.startsWith('es'):
      return esES;
    case lang.startsWith('cs'):
      return csCZ;
    case lang.startsWith('ja'):
      return jaJP;
    case lang.startsWith('sk'):
      return skSK;
    default:
      return enGB;
  }
};

const Application = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const locale = useMemo(() => getLocale(language), [language]);

  moment.locale(language);

  return (
    <Provider store={store}>
      <ConfigProvider locale={locale}>
        <Router history={history}>
          <App />
        </Router>
      </ConfigProvider>
    </Provider>
  );
};

ReactDOM.render(<Application />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
