import React from 'react';
import LanguageIcon from '@components/LanguageIcon';
import ReorderButton from '@components/ReorderButton';
import supportedLanguages from '@utils/supportedLanguages';

export default (t, language, editIndexEvent, disabledIds = {}) => [
  {
    title: t('indexEvents:List.columns.languages'),
    dataIndex: 'translatedNames',
    key: 'languages',
    render: translatedNames => (
      <div>
        <LanguageIcon language="en" />
        {Object.keys(translatedNames).map(language => (
          <LanguageIcon key={language} language={language} />
        ))}
      </div>
    ),
    width: 200,
    filters: Object.values(supportedLanguages).map(lang => ({
      text: t(lang.name),
      value: lang.key,
    })),
    onFilter: (value, record) => (value === 'en' ? true : !!record.translatedNames[value]),
  },
  {
    title: t('indexEvents:List.columns.name'),
    key: 'name',
    render: record => record.translatedNames[language] || record.name,
  },
  {
    title: 'ID',
    key: 'slug',
    render: record => record.slug,
  },
  {
    title: t('indexEvents:List.columns.order'),
    width: 200,
    render: (text, record, index) => (
      <ReorderButton
        downDisabled={record.id === disabledIds.down}
        upDisabled={record.id === disabledIds.up}
        onClickDown={event => {
          event.stopPropagation();
          return editIndexEvent({ ...record, order: record.order + 1 });
        }}
        onClickUp={event => {
          event.stopPropagation();
          return editIndexEvent({ ...record, order: record.order - 1 });
        }}
      />
    ),
  },
];
