import {
  whatTypeIcons,
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE,
  MESSAGE_REMINDER,
  MESSAGE_ALERT,
  TRANSITION_NEXT,
} from '@constants';

const tableRowOrder = [
  FEATURE_DOCUMENT,
  FEATURE_VIDEO,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE,
  MESSAGE_REMINDER,
  MESSAGE_ALERT,
  TRANSITION_NEXT,
];

function stageRulesByWhat(stages) {
  if (!stages) return [];

  const contentTypesByStage = Object.values(stages).reduce((prev, stage) => {
    return {
      ...prev,
      [stage.number]: {
        ...stage.rules.reduce(
          (rulesByWhat, rule) => ({
            ...rulesByWhat,
            [rule.what]: [...(rulesByWhat[rule.what] ? rulesByWhat[rule.what] : []), rule],
          }),
          {},
        ),
      },
    };
  }, {});

  const stageRulesByWhat = Object.entries(contentTypesByStage).reduce(
    (prev, [stageNumber, rulesByWhat]) => {
      return {
        ...prev,
        ...Object.entries(rulesByWhat).reduce(
          (stageByWhat, [whatType, rules]) => ({
            ...stageByWhat,
            [whatType]: {
              ...prev[whatType],
              icon: whatTypeIcons[whatType],
              what: whatType,
              key: `${whatType}-row`,
              [stageNumber]: rules,
            },
          }),
          {},
        ),
      };
    },
    {},
  );

  const sortedDataSource = [];

  tableRowOrder.forEach(whatType => {
    if (stageRulesByWhat[whatType]) {
      sortedDataSource.push(stageRulesByWhat[whatType]);
    }
  });

  return sortedDataSource;
}

export default stageRulesByWhat;
