import { put, call, getContext, race, take, select } from 'redux-saga/effects';
import { message } from 'antd';
import createPipClient, { PIPObject } from '@api/pipClient';
import { appToken } from 'settings';
import {
  INVITE_APP_USER_WITH_PATHWAYS_FAILED,
  INVITE_APP_USER_WITH_PATHWAYS_SUCCESS,
} from '@pathways/redux/appUserPathways/types';
import { FORM, QUESTIONNAIRE, TASK } from '@utils/contentTypes';
import { selectForm } from '@redux/forms/reducers';
import { IForm } from '@redux/forms/types';
import { selectAndLoadHospital } from '@redux/hospitals/utils';
import { IHospital } from '@redux/hospitals/types';
import { IFormSubmissionRaw, IFormSubmission } from './../types';
import doCreateUISAdminClient from '../../doCreateUISAdminClient';
import i18n from '../../../i18n';
import { createRegistrationCode, mapUISUserToAppUser } from '../utils';
import {
  inviteAppUserSuccess,
  IInviteAppUser,
  ISubmitFormAsAppUser,
  fetchAppUsers,
  submitFormAsAppUserSuccess,
  IUpdateAppUser,
  updateAppUserSuccess,
  updateAppUserFailed,
} from '../actions';
import { selectAppUser } from '../reducers';

export function* doInviteAppUser({ payload: { appUser, journeys } }: IInviteAppUser) {
  const history = yield getContext('history');
  const dashboardUserProfile: {
    hospitalId: string;
  } = yield select(state => state.login.user.profile);

  const hospital: IHospital = yield call(selectAndLoadHospital, dashboardUserProfile.hospitalId!);

  try {
    const appUserProfile = {
      ...appUser,
      hospitalId: dashboardUserProfile.hospitalId,
      yearOfBirth: appUser.dateOfBirth ? parseInt(appUser.dateOfBirth.split('-')[0]) : undefined,
    };

    const uisClient = yield call(doCreateUISAdminClient);

    // create App User in UIS
    const newUisUser = yield call(uisClient.createAppUser, appUserProfile);
    const newAppUser = mapUISUserToAppUser(newUisUser);

    yield call(uisClient.createUserRegistrationCode, newAppUser.url, createRegistrationCode(), {
      hospitalName: hospital.name,
    });

    yield put(inviteAppUserSuccess(newAppUser.uuid, newAppUser, journeys));

    // this will need to be moved to keeps pathways stuff seperate
    const {
      failure,
      // success
    } = yield race({
      succes: take(INVITE_APP_USER_WITH_PATHWAYS_SUCCESS),
      failure: take(INVITE_APP_USER_WITH_PATHWAYS_FAILED),
    });

    if (failure) {
      throw new Error('Failed to add pathways to app user');
    }

    yield call(history.push, '/patients/individuals');
  } catch (err) {
    console.error(err);
  }
}

export function* doDeleteAppUsers() {
  yield call(message.success, i18n.t('patients:deleteConfirmation'));
}

export function* doSubmitAppUserForm({
  payload: { formId, formData, appUserUISId, type },
}: ISubmitFormAsAppUser): any {
  try {
    yield put(fetchAppUsers());

    const [, appUser] = yield select(selectAppUser(appUserUISId));

    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const [, form]: [boolean, IForm] = yield select(selectForm(formId));

    const appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);
    const pipAppuser = appUserResponse.results[0];

    // submit new form data as app user
    const formSubmission = yield call(
      pipClient.createObject,
      `${appToken}-form-${formId}-data`,
      formData,
      pipAppuser.uuid,
    );

    // fetch describe_versions for form
    const formSchemaObjectType = `${appToken}-form-${formId}`;
    const formDataObjectType = `${formSchemaObjectType}-data`;
    const versions = yield call(
      pipClient.describeVersionsForType,
      `${appToken}-form-${formId}`,
      pipAppuser.uuid,
      [formDataObjectType],
    );

    const newVersion = {
      versions,
      created: new Date().toISOString(),
      uuid: formSubmission.uuid,
      formObjectType: `${appToken}-form-${formId}`,
      formName: form.name,
    };

    // submit new form submission as app user
    const [latest]: [
      PIPObject<{
        submissions: IFormSubmissionRaw[];
      }>,
    ] = yield call(
      pipClient.getObjectsForType,
      `${appToken}-form-submissions`,
      'latest',
      pipAppuser.uuid,
    );
    const latestSubmissions =
      latest && latest.json && latest.json.submissions ? latest.json.submissions : [];
    const newSubmissions = [...latestSubmissions, newVersion] as IFormSubmissionRaw[];

    yield call(
      pipClient.createObject,
      `${appToken}-form-submissions`,
      { submissions: newSubmissions },
      pipAppuser.uuid,
    );

    switch (type) {
      case FORM:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitFormSuccess'));
        break;
      case QUESTIONNAIRE:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitQuestionnaireSuccess'));
        break;
      case TASK:
        yield call(message.success, i18n.t('patients:IndividualDetail.submitTaskSuccess'));
        break;
    }

    yield put(
      submitFormAsAppUserSuccess(
        appUserUISId,
        newSubmissions.map(sub => ({
          // At least one of these will always be defined. This should be temporary to account for historical data.
          formObjectType: (sub.formObjectType ?? sub['form-object-type'])!,
          created: sub.created,
          versions: sub.versions,
          uuid: sub.uuid,
        })) as IFormSubmission[],
      ),
    );
  } catch (err) {
    console.error(err);

    switch (type) {
      case FORM:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitFormFailed'));
        break;
      case QUESTIONNAIRE:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitQuestionnaireFailed'));
        break;
      case TASK:
        yield call(message.warning, i18n.t('patients:IndividualDetail.submitTaskFailed'));
        break;
    }
  }
}

export function* doUpdateAppUser({ payload: { appUserId, profile } }: IUpdateAppUser) {
  try {
    const uisClient = yield call(doCreateUISAdminClient);

    profile.yearOfBirth = profile.dateOfBirth
      ? parseInt(profile.dateOfBirth.split('-')[0])
      : undefined;

    const updatedUisUser = yield call(uisClient.updateAppUser, appUserId, profile);

    const updatedAppUser = mapUISUserToAppUser(updatedUisUser);

    yield put(updateAppUserSuccess(appUserId, updatedAppUser));

    const history = yield getContext('history');
    yield call(history.goBack);
    yield call(message.success, i18n.t('patients:EditPatient.successMessage'));
  } catch (err) {
    console.error(err);
    yield call(message.warning, i18n.t('patients:EditPatient.failedMessage'));
    yield put(updateAppUserFailed());
  }
}
