import { takeEvery } from 'redux-saga/effects';
import {
  CREATE_PATHWAY,
  DELETE_PATHWAYS,
  DELETE_PATHWAY,
  DUPLICATE_PATHWAY,
  DUPLICATE_PATHWAYS,
  EDIT_PATHWAY,
  FETCH_PATHWAYS,
  PUBLISH_PATHWAYS,
  UNPUBLISH_PATHWAYS,
} from '../types';
import takeFirst from '../../../../redux/takeFirst';
import { doDeletePathway, doDeletePathways } from './delete';
import { doDuplicatePathway, doDuplicatePathways } from './duplicate';
import { doFetchPathways } from './fetch';
import { doPublishPathways, doUnpublishPathways } from './publish';
import { doCreatePathway, doEditPathway } from './save';

export default function* root() {
  yield takeEvery(CREATE_PATHWAY, doCreatePathway);
  yield takeEvery(DELETE_PATHWAYS, doDeletePathways);
  yield takeEvery(DELETE_PATHWAY, doDeletePathway);
  yield takeEvery(DUPLICATE_PATHWAY, doDuplicatePathway);
  yield takeEvery(DUPLICATE_PATHWAYS, doDuplicatePathways);
  yield takeEvery(EDIT_PATHWAY, doEditPathway);
  yield takeFirst(FETCH_PATHWAYS, doFetchPathways);
  yield takeEvery(PUBLISH_PATHWAYS, doPublishPathways);
  yield takeEvery(UNPUBLISH_PATHWAYS, doUnpublishPathways);
}
