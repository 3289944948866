import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarStar } from '@fortawesome/pro-regular-svg-icons';

import RuleTag from '@components/RuleTag';

import { fetchDocuments } from '@redux/documents/actions';
import { selectDocuments } from '@redux/documents/reducers';
import { fetchForms } from '@redux/forms/actions';
import { selectForms } from '@redux/forms/reducers';
import { fetchCannedMessages } from '@redux/messages/actions';
import { selectCannedMessages } from '@redux/messages/reducers';

import './style.less';

const { Column, ColumnGroup } = Table;

function getColumnHeading(time, isDate, t) {
  if (isDate) return moment(time).format('L');

  const days = Number(time);

  if (days > 0)
    return t('cards:AppUserIndexEventTriggeredRules.timing.daysAfter', { numberOfDays: days });
  if (days < 0)
    return t('cards:AppUserIndexEventTriggeredRules.timing.daysBefore', {
      numberOfDays: Math.abs(days),
    });

  return t('cards:AppUserIndexEventTriggeredRules.timing.onDay');
}

function IndexEventTriggeredRules({ rulesByDate, todaysDate, indexEventsByDate }) {
  const { i18n, t } = useTranslation();
  const orderedDates = useMemo(() => {
    const allDates = Array.from(
      new Set([...Object.keys(rulesByDate), ...Object.keys(indexEventsByDate)]),
    );
    return allDates.sort((a, b) => {
      // if Number is NaN, a date has not been defined for this Index Event
      if (Number.isNaN(Number(a))) {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      }

      const dayDiffA = Number(a);
      const dayDiffB = Number(b);

      if (dayDiffA > dayDiffB) return 1;
      if (dayDiffA < dayDiffB) return -1;
      return 0;
    });
  }, [rulesByDate, indexEventsByDate]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchForms());
    dispatch(fetchCannedMessages());
  }, [dispatch]);

  const [documentsLoading] = useSelector(selectDocuments);
  const [formsLoading] = useSelector(selectForms);
  const [cannedMessagesLoading] = useSelector(selectCannedMessages);

  const loading = documentsLoading || formsLoading || cannedMessagesLoading;

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  return (
    <Table
      loading={loading}
      dataSource={[{ ...rulesByDate, key: 'data' }]}
      pagination={false}
      scroll={{ x: true }}
    >
      {orderedDates.map(date => (
        <ColumnGroup key={date} title={getColumnHeading(date, !!todaysDate, t)}>
          <Column
            className={date === todaysDate ? 'today-column' : ''}
            key={date}
            title={
              indexEventsByDate[date]
                ? indexEventsByDate[date].map(indexEvent => (
                    <div key={indexEvent.id}>
                      <FontAwesomeIcon icon={faCalendarStar} />{' '}
                      {t('cards:AppUserIndexEventTriggeredRules.indexEventAbbreviation')}{' '}
                      {indexEvent.translatedNames[languageCode] || indexEvent.name}
                    </div>
                  ))
                : ''
            }
            render={() =>
              rulesByDate[date]?.length ? (
                <div key={`rule-${date}`} className="rule-container">
                  {rulesByDate[date].map(rule => (
                    <div key={rule.id}>
                      <RuleTag rule={rule} key={rule.id} isIndexEventRule />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="empty-field">
                  {t('cards:AppUserIndexEventTriggeredRules.nothing')}
                </div>
              )
            }
          />
        </ColumnGroup>
      ))}
    </Table>
  );
}

export default IndexEventTriggeredRules;
