import { select } from 'redux-saga/effects';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { appToken } from 'settings';
import { IFormComputation, IRawFormComputation } from '@redux/appUsers/types';
import { PIPObject } from '@api/pipClient';

// previously all forms stored under the base object type
export const BASE_FORMS_OBJECT_TYPE = `${appToken}-forms`;
export const BASE_FORM_OBJECT_TYPE = `${appToken}-form`;
export const GLOBAL_FORMS_OBJECT_TYPE = `${BASE_FORMS_OBJECT_TYPE}-global`;
export const GLOBAL_FORM_OBJECT_TYPE = `${BASE_FORM_OBJECT_TYPE}`;

export function* getBaseObjectTypeForForms() {
  const currentDashboardUser = yield select(selectCurrentDashboardUser);

  if (currentDashboardUser.profile.hospitalId) {
    return `${BASE_FORMS_OBJECT_TYPE}-${currentDashboardUser.profile.hospitalId}`;
  }

  // TODO: add handling for country and pathway admins when needed

  return GLOBAL_FORMS_OBJECT_TYPE;
}

export function getBaseObjectTypeForForm(formId: string) {
  return `${GLOBAL_FORM_OBJECT_TYPE}-${formId}`;
}

export const parseFormComputations = (
  rawComputations: PIPObject<IRawFormComputation>[],
): IFormComputation[] =>
  rawComputations.map(rawComputation => ({
    dataObjectUUID: rawComputation.json.data_object_uuid,
    dataObjectVersion: rawComputation.json.data_object_version,
    latest: rawComputation.json.latest,
    previous: rawComputation.json.previous,
  }));
