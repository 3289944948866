import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Permissions } from '@authorisation/constants';
import { useHasPermissions } from '@authorisation/hooks';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { matchType } from '../../propTypes';
import Global from './Global';
// import Country from './Country';
import Hospital from './Hospital';
import Pathways from './Pathways';

function ReviewHub({ match }) {
  const permissions = useHasPermissions();
  const currentDashboardUser = useSelector(selectCurrentDashboardUser);

  if (permissions[Permissions.ViewHospitalMetrics]) {
    return (
      <Switch>
        <Route path={`${match.path}/hospital`} component={Hospital} />
        <Route path={`${match.path}/pathway-data`} component={Pathways} />
        <Redirect to={`${match.path}/hospital/${currentDashboardUser.profile.hospitalId}`} />
      </Switch>
    );
  }

  return (
    <Switch>
      <Route path={`${match.path}/global`} component={Global} />
      {/* <Route path={`${match.path}/country`} component={Country} /> */}
      <Route path={`${match.path}/hospital`} component={Hospital} />
      <Route path={`${match.path}/pathway-data`} component={Pathways} />
      <Redirect to={`${match.path}/global`} />
    </Switch>
  );
}

ReviewHub.propTypes = {
  match: matchType.isRequired,
};

export default ReviewHub;
