import { put, call, takeEvery, takeLatest, getContext, select } from 'redux-saga/effects';
import { message } from 'antd';
import { v4 as uuid } from 'uuid';
import i18next from '../../i18n';
import createPipClient, { PIPObject } from '@api/pipClient';
import { findLatest } from '@utils';
import { selectForm, selectForms } from '@redux/forms/reducers';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { determineSourceTypeForNewContent } from '@utils';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { Permissions } from '@authorisation/constants';
import { FORM } from '@utils/contentTypes';
import {
  loadForms,
  formsLoaded,
  fetchFormDataSuccess,
  fetchFormDataFailed,
  IDownloadForm,
  IFetchFormData,
  ICreateForm,
  formCreated,
  formUpdated,
  IUpdateForm,
  IDeleteForms,
  formsDeleted,
  IPublishForm,
  publishFormSuccess,
  publishFormFailed,
  IUnpublishForm,
  unpublishFormSuccess,
  unpublishFormFailed,
  fetchVersionFormDataSuccess,
  IFetchVersionFormData,
  IFetchForm,
  formLoaded,
} from './actions';
import { doFetchAppUsers } from '@redux/appUsers/sagas/fetch';
import { selectAppUser } from '@redux/appUsers/reducers';
import {
  FETCH_FORMS,
  LOAD_FORMS_FAILED,
  DOWNLOAD_FORMS,
  FETCH_FORM_DATA,
  GRANT_INDIVIDUAL_FORMS_ACCESS,
  GRANT_GROUP_FORMS_ACCESS,
  IForm,
  IFormData,
  CREATE_FORM,
  UPDATE_FORM,
  DELETE_FORMS,
  PUBLISH_FORM,
  UNPUBLISH_FORM,
  FETCH_VERSION_FORM_DATA,
  IVersionFormData,
  FETCH_FORM,
} from './types';
import takeFirst from '../takeFirst';
import { appToken, pipAppUrl } from 'settings';
import {
  getBaseObjectTypeForForms,
  getBaseObjectTypeForForm,
  BASE_FORMS_OBJECT_TYPE,
  GLOBAL_FORM_OBJECT_TYPE,
  parseFormComputations,
  GLOBAL_FORMS_OBJECT_TYPE,
} from './utils';
import { appUserFormComputationsLoaded } from '@redux/appUsers/actions';
import { IRawFormComputation } from '@redux/appUsers/types';
import { trackDataEvent } from '../../analytics';

export default function* root() {
  yield takeFirst(FETCH_FORMS, doFetchForms);
  yield takeLatest(FETCH_FORM, doFetchForm);
  yield takeLatest(FETCH_FORM_DATA, doFetchFormData);
  yield takeLatest(FETCH_VERSION_FORM_DATA, doFetchVersionFormData);
  yield takeLatest(LOAD_FORMS_FAILED, doLoadFailed);
  yield takeLatest(DOWNLOAD_FORMS, doDownloadFile);
  yield takeEvery(GRANT_INDIVIDUAL_FORMS_ACCESS, doGrantIndividualFormsAccess);
  yield takeEvery(GRANT_GROUP_FORMS_ACCESS, doGrantGroupFormsAccess);
  yield takeLatest(CREATE_FORM, doCreateForm);
  yield takeLatest(UPDATE_FORM, doUpdateForm);
  yield takeLatest(DELETE_FORMS, doDeleteForms);

  yield takeLatest(PUBLISH_FORM, doPublishForm);
  yield takeLatest(UNPUBLISH_FORM, doUnpublishForm);
}

function* doFetchForms(): any {
  yield put(loadForms());
  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);
  const objectType = yield call(getBaseObjectTypeForForms);

  const [usersLatestForms] = yield call(pipClient.getObjectsForType, objectType, 'latest');

  // migrate old master forms list to list based on users role (e.g. hospital or global for super admins)
  if (usersLatestForms === undefined) {
    const currentDashboardUser = yield select(selectCurrentDashboardUser);
    const [globalLatestForms] = yield call(
      pipClient.getObjectsForType,
      BASE_FORMS_OBJECT_TYPE,
      'latest',
    );

    const usersForms = globalLatestForms.json.filter((form: IForm) =>
      currentDashboardUser.profile.hospitalId
        ? form.metadata.hospitalId === currentDashboardUser.profile.hospitalId
        : form.owner === 'global',
    );

    yield call(pipClient.createObjectType, objectType, appToken);
    yield call(pipClient.createObject, objectType, usersForms);
    trackDataEvent('Forms Loaded');
    yield put(formsLoaded(usersForms));
    return;
  }

  if (usersLatestForms && usersLatestForms.json && Array.isArray(usersLatestForms.json)) {
    const forms: IForm[] = usersLatestForms.json.map((form: IForm) => ({
      ...form,
      type: FORM,
    }));
    trackDataEvent('Forms Loaded');
    yield put(formsLoaded(forms));
    return;
  }

  yield put(formsLoaded([]));
}

function* doFetchForm({ payload: { formId } }: IFetchForm): any {
  try {
    let [, form] = yield select(selectForm(formId));
    const [, forms] = yield select(selectForms);

    if (!form && forms.length === 0) {
      yield call(doFetchForms);
      [, form] = yield select(selectForm(formId));
    }

    if (!!form) return;

    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);

    const [globalForms] = yield call(
      pipClient.getObjectsForType,
      GLOBAL_FORMS_OBJECT_TYPE,
      'latest',
    );

    form = globalForms.json.find((globalForm: IForm) => globalForm.uuid === formId);

    if (form) {
      yield put(formLoaded(form));
      return;
    }

    throw new Error('Unable to find Form');
  } catch (err) {
    console.error(err);
  }
}

function* doFetchFormData({ payload: { formId } }: IFetchFormData) {
  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);
  let objectType = yield call(getBaseObjectTypeForForm, formId);
  const formList = yield select(state => state.forms.list);
  if (!formList.includes(formId)) {
    objectType = `${GLOBAL_FORM_OBJECT_TYPE}-${formId}`;
  }

  try {
    const { results }: { results: PIPObject<IFormData>[] } = yield call(
      pipClient.getObjectsForType,
      objectType,
    );
    const latest = findLatest(results);
    if (latest && latest.json) {
      const {
        results: translations,
      }: { results: PIPObject<{ [key: string]: any }[]>[] } = yield call(
        pipClient.getObjectsForType,
        `${objectType}-i18n`,
      );
      const translationsLatest = findLatest(translations) || { json: {} };
      for (const [key, value] of Object.entries(translationsLatest.json)) {
        i18next.addResourceBundle(key, 'forms', value, true, false);
      }
      yield put(fetchFormDataSuccess(formId, latest.json));
    }
  } catch (err) {
    console.error(err);
    yield put(fetchFormDataFailed(formId, 'failed'));
  }
}

function* doFetchVersionFormData({
  payload: { formId, dataVersion, schemaVersion, appUserUISId },
}: IFetchVersionFormData): any {
  try {
    let [, appUser] = yield select(selectAppUser(appUserUISId));
    if (!appUser) {
      yield call(doFetchAppUsers);
      [, appUser] = yield select(selectAppUser(appUserUISId));
    }

    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);

    const appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);

    if (appUserResponse.results.length === 0) {
      yield call(message.warning, 'User could not be found');
    }

    const pipAppUser = appUserResponse.results[0];
    const objectType = yield call(getBaseObjectTypeForForm, formId);

    // fetch form schema version
    const { results: schemaResult }: { results: PIPObject<any>[] } = yield call(
      pipClient.getObjectsForType,
      objectType,
      schemaVersion.toString(),
    );

    // fetch form data
    const { results: dataResult }: { results: PIPObject<any>[] } = yield call(
      pipClient.getObjectsForType,
      `${objectType}-data`,
      dataVersion.toString(),
      pipAppUser.uuid,
    );

    // store successfully
    const versionFormData = {
      schema: schemaResult[0].json.schema,
      uiSchema: schemaResult[0].json.uiSchema,
      computations: schemaResult[0].json.computations,
      formData: dataResult[0].json,
    } as IVersionFormData;

    let {
      next,
      results: computations,
    }: { next: string; results: PIPObject<IRawFormComputation>[] } = yield call(
      pipClient.getObjectsForType,
      `${objectType}-computations`,
      null,
      pipAppUser.uuid,
    );

    while (next) {
      let response = yield call(fetch, next, {
        headers: pipClient.headers(),
      });
      const { next: nextUrl, results } = yield call(response.json.bind(response));

      next = nextUrl;
      computations = [...computations, ...results];
    }

    yield put(appUserFormComputationsLoaded(appUserUISId, parseFormComputations(computations)));

    yield put(fetchVersionFormDataSuccess(formId, dataVersion, versionFormData));
  } catch (err) {
    console.error(err);
  }
}

function* doLoadFailed() {
  yield call(message.error, i18next.t('forms:failedLoad'));
}

function* doDownloadFile({ payload: { fileName, formData } }: IDownloadForm) {
  const fileData = JSON.stringify(formData);
  const blob = new Blob([fileData], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = fileName;
  link.href = url;
  link.click();

  yield call(message.success, i18next.t('forms:fileDownloaded'));
}

function* doGrantIndividualFormsAccess() {
  yield call(message.success, i18next.t('patients:addFormSuccess'));
}

function* doGrantGroupFormsAccess() {
  yield call(message.success, i18next.t('groups:addFormSuccess'));
}

function* doCreateForm({
  payload: {
    formTranslationKey,
    name,
    description,
    language,
    published,
    formData,
    type,
    path,
    fileName,
  },
}: ICreateForm) {
  const permissions = yield select(selectPermissionsForUser);
  const currentDashboardUser = yield select(selectCurrentDashboardUser);

  const newForm: IForm = {
    type: FORM,
    name,
    description: description || '',
    language,
    published,
    version: 1,
    metadata: {
      type,
      fileName,
      ...(permissions.includes(Permissions.ManagePatients)
        ? { hospitalId: currentDashboardUser.profile.hospitalId }
        : {}),
    },
    uuid: uuid(),
    created: new Date().toISOString(),
    owner: determineSourceTypeForNewContent(permissions),
  };

  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);
  const objectType = yield call(getBaseObjectTypeForForms);

  let [latest]: PIPObject<IForm[]>[] = yield call(
    pipClient.getObjectsForType,
    objectType,
    'latest',
  );

  if (latest === undefined) {
    yield call(pipClient.createObjectType, objectType, appToken);
  }

  const newForms = [newForm, ...(latest?.json || [])];

  const response = yield call(pipClient.createObject, objectType, newForms);

  const createdForm = response.json[0];

  if (createdForm.name === newForm.name) {
    const formTypeName = yield call(getBaseObjectTypeForForm, createdForm.uuid);

    const objectTypeResponse = yield call(pipClient.createObjectType, formTypeName, pipAppUrl);

    const formSlug = objectTypeResponse.slug;

    const formSchema = {
      schema: formData.schema,
      uiSchema: formData.uiSchema,
      computations: formData.computations,
    };

    yield call(pipClient.createObject, formSlug, formSchema);

    yield call(pipClient.createObjectType, `${formTypeName}-data`, pipAppUrl, [
      objectTypeResponse.url,
    ]);
    yield call(pipClient.createObjectType, `${formTypeName}-i18n`, pipAppUrl, [
      objectTypeResponse.url,
    ]);

    if (formSchema.computations) {
      yield call(pipClient.createObjectType, `${formTypeName}-computations`, pipAppUrl, [
        objectTypeResponse.url,
      ]);
    }

    yield put(formsLoaded(newForms));
    yield put(formCreated());

    const history = yield getContext('history');
    const newPath = path.replace('new', createdForm.uuid);

    yield call(history.replace, newPath);

    trackDataEvent('Form Created', {
      formId: createdForm.uuid,
      formName: createdForm.name,
      formType: createdForm.metadata?.type,
    });
    yield call(message.success, i18next.t(`${formTranslationKey}:NewForm.successMessage`));
  }
}

function* doUpdateForm({
  payload: {
    formTranslationKey,
    uuid,
    name,
    description,
    language,
    published,
    formData,
    type,
    fileName,
  },
}: IUpdateForm): any {
  const [, form] = yield select(selectForm(uuid));

  const objectType = yield call(getBaseObjectTypeForForms);

  const updatedForm: IForm = {
    ...form,
    name,
    description: description || '',
    language,
    published,
    version: form.version + 1,
    metadata: {
      ...form.metadata,
      type,
      fileName,
    },
    uuid,
  };

  const tokens = yield getContext('tokens');

  const pipClient = yield call(createPipClient, tokens);

  const [latest]: PIPObject<IForm[]>[] = yield call(
    pipClient.getObjectsForType,
    objectType,
    'latest',
  );

  const newForms: IForm[] = latest.json.map(form => (form.uuid === uuid ? updatedForm : form));

  yield call(pipClient.createObject, objectType, newForms);

  const formSchema = {
    schema: formData.schema,
    uiSchema: formData.uiSchema,
    computations: formData.computations,
  };

  const formObjectType = yield call(getBaseObjectTypeForForm, uuid);

  yield call(pipClient.createObject, formObjectType, formSchema);

  trackDataEvent('Form Updated', {
    formId: updatedForm.uuid,
    formName: updatedForm.name,
    formType: updatedForm.metadata?.type,
  });

  yield put(formsLoaded(newForms));

  yield put(formUpdated());

  const history = yield getContext('history');

  yield call(history.goBack);

  yield call(message.success, i18next.t(`${formTranslationKey}:EditForm.successMessage`));
}

function* doDeleteForms({ payload: { ids, formTranslationKey } }: IDeleteForms): any {
  const tokens = yield getContext('tokens');
  const objectType = yield call(getBaseObjectTypeForForms);
  const pipClient = yield call(createPipClient, tokens);
  const [latest] = yield call(pipClient.getObjectsForType, objectType, 'latest');

  const newForms = latest.json.filter((form: IForm) => !ids.includes(form.uuid));

  yield call(pipClient.createObject, objectType, newForms);

  yield put(formsDeleted(newForms));

  yield call(
    message.success,
    i18next.t(`${formTranslationKey}:DeleteForm.deleteSuccess`, { count: ids.length }),
  );
}

function* doPublishForm({ payload: { formId, formTranslationKey } }: IPublishForm): any {
  const [, form] = yield select(selectForm(formId));

  const newForm = {
    ...form,
    published: true,
  };

  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForForms);

    const [latest]: PIPObject<IForm[]>[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );

    const newForms: IForm[] = latest.json.map(form => (form.uuid === formId ? newForm : form));

    yield call(pipClient.createObject, objectType, newForms);
    trackDataEvent('Form Published', {
      formId: newForm.uuid,
      formName: newForm.name,
      formType: newForm.metadata?.type,
    });
    yield put(formsLoaded(newForms));
    yield put(publishFormSuccess());

    yield call(message.success, i18next.t(`${formTranslationKey}:FormDetail.publishSuccess`));
  } catch (err) {
    console.error(err);
    yield put(publishFormFailed());
    yield call(message.warning, i18next.t(`${formTranslationKey}:FormDetail.publishFailed`));
  }
}

function* doUnpublishForm({ payload: { formId, formTranslationKey } }: IUnpublishForm): any {
  const [, form] = yield select(selectForm(formId));

  const newForm = {
    ...form,
    published: false,
  };

  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForForms);

    const [latest]: PIPObject<IForm[]>[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );

    const newForms: IForm[] = latest.json.map(form => (form.uuid === formId ? newForm : form));

    yield call(pipClient.createObject, objectType, newForms);
    trackDataEvent('Form Unpublished', {
      formId: newForm.uuid,
      formName: newForm.name,
      formType: newForm.metadata?.type,
    });

    yield put(formsLoaded(newForms));
    yield put(unpublishFormSuccess());

    yield call(message.success, i18next.t(`${formTranslationKey}:FormDetail.unpublishSuccess`));
  } catch (err) {
    console.error(err);
    yield put(unpublishFormFailed());
    yield call(message.warning, i18next.t(`${formTranslationKey}:FormDetail.unpublishFailed`));
  }
}
