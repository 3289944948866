import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { Card } from '@cards/Card';
import DetailRow from '@components/DetailRow';
import IconButton from '@components/IconButton';
import DocumentContentCard from '@cards/Documents/Content';
import PagePreviewModal from '@cards/Documents/Content/PagePreviewModal';
// import './styles.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 19 },
  },
};

function ReviewContent({ content }) {
  const { t } = useTranslation();
  const [modalState, setModalState] = useState({ pageUrl: null, shown: false, title: null });
  const match = useRouteMatch();
  useEffect(() => {
    setModalState({
      ...modalState,
      pageUrl: content.originFileObj ? URL.createObjectURL(content.originFileObj) : null,
      title: content.name,
    });
  }, [content]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!content.originFileObj && match.params.id) {
    return <DocumentContentCard documentId={match.params.id} />;
  }

  return (
    <>
      <Card.Half
        extra={
          <IconButton
            icon={faExternalLink}
            tooltip={t('common:buttons.open')}
            onClick={() => setModalState({ ...modalState, shown: true })}
          />
        }
        title={t('cards:DocumentContent.title')}
      >
        <Form {...formItemLayout}>
          <DetailRow label={t('common:contentTypes.singular.document')} value={content.name} />
        </Form>
      </Card.Half>
      <PagePreviewModal
        url={modalState.pageUrl}
        visible={modalState.shown}
        title={modalState.title}
        handleCloseModal={() => setModalState({ ...modalState, shown: false })}
      />
    </>
  );
}

ReviewContent.propTypes = {
  content: shape({
    name: string,
  }).isRequired,
};

export default ReviewContent;
