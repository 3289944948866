import { call, getContext, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import i18n from '../../../../i18n';
import {
  createRuleFailure,
  editRuleSuccess,
  editRuleFailed,
  ruleCreated,
  ICreateRule,
  IEditRule,
} from '../actions';
import doCreatePathwaysClient from '../../../../redux/doCreatePathwaysClient';
import { fixWhatContentType, fixWhenType, mapRawRule } from '../utils';
import { FORM_SUBMITTED, QUESTIONNAIRE_SUBMITTED, TASK_SUBMITTED } from '@constants';
import { doDetermineOwnerForPathway } from '../../pathways/sagas/utils';
import { selectRule } from '../reducers';

export function* doCreateRule({ payload: { rule } }: ICreateRule) {
  try {
    const history = yield getContext('history');
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const ownerId = yield call(doDetermineOwnerForPathway);

    let { description, metadata, name, what, whatDetail, when, whenDetail, who, whoDetail } = rule;
    [what, whatDetail] = fixWhatContentType(what, whatDetail);
    [when, whenDetail] = fixWhenType(when, whenDetail);

    if (
      [FORM_SUBMITTED, QUESTIONNAIRE_SUBMITTED, TASK_SUBMITTED].includes(when) &&
      whenDetail.uuid
    ) {
      whenDetail.form_id = whenDetail.uuid;
    }

    const newRule = yield call(pathwaysClient.createRule, {
      description: description || '',
      metadata,
      name,
      what,
      whatDetail: whatDetail || {},
      when,
      whenDetail: whenDetail || {},
      who,
      whoDetail: whoDetail || {},
      ownerId,
    });

    yield put(ruleCreated(mapRawRule(newRule)));
    yield call(history.replace, '/procedure/rules');
    yield call(message.success, i18n.t('pathways:ProcedureRules.createSuccess'));
  } catch (err) {
    yield call(message.error, i18n.t('pathways:ProcedureRules.createError'));
    yield put(createRuleFailure());
  }
}

export function* doEditRule({ payload: { id, editedRule } }: IEditRule): any {
  try {
    const history = yield getContext('history');
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const [, existingRule] = yield select(selectRule(id));

    let {
      description,
      metadata,
      name,
      what,
      whatDetail,
      when,
      whenDetail,
      who,
      whoDetail,
    } = editedRule;
    [what, whatDetail] = fixWhatContentType(what, whatDetail);
    [when, whenDetail] = fixWhenType(when, whenDetail);

    if (when === FORM_SUBMITTED && whenDetail.uuid) {
      whenDetail.form_id = whenDetail.uuid;
    }

    const response = yield call(pathwaysClient.patchRule, id, {
      description,
      metadata: { ...existingRule.metadata, ...metadata },
      name,
      what,
      whatDetail,
      when,
      whenDetail,
      who,
      whoDetail,
    });

    yield put(editRuleSuccess(mapRawRule(response)));
    yield call(history.push, `/procedure/rules/${response.id}`);
    yield call(message.success, i18n.t('pathways:ProcedureRules.editSuccess'));
  } catch (err) {
    console.error(err);
    yield put(editRuleFailed());
    yield call(message.error, i18n.t('pathways:ProcedureRules.editError'));
  }
}
