import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty, Form } from 'antd';
import DetailRow from '@components/DetailRow';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { Card } from '../../Card';
import moment from 'moment';

import './styles.less';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';

const formItemLayout = {
  layout: 'vertical',
  labelCol: {
    xs: { span: 24 },
    sm: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 14 },
  },
};

const AppUserDetailsCard = ({ appUser, title, loading, ...props }) => {
  const { t } = useTranslation();
  const { [Permissions.ViewPatients]: canViewPII } = useHasPermissions(Permissions.ViewPatients);
  const obfuscatePIValue = '******';

  return (
    <Card.Half
      className="app-user-details-card"
      noPadding
      loading={loading}
      title={t(title)}
      {...props}
    >
      {appUser === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <DetailRow
            label={t('cards:PatientDetails.labels.fullName')}
            value={canViewPII ? `${appUser.firstName} ${appUser.lastName}` : obfuscatePIValue}
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.mobile')}
            value={canViewPII ? appUser.phone : obfuscatePIValue}
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.email')}
            value={canViewPII ? appUser.email : obfuscatePIValue}
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.address')}
            value={
              canViewPII
                ? `${appUser.address ? `${appUser.address}, ` : ''}${
                    appUser.city ? `${appUser.city} ` : ''
                  }${appUser.postcode || ''}`
                : obfuscatePIValue
            }
          />

          {/* <DetailRow
            label={t('cards:PatientDetails.labels.age')}
            value={appUser.profile.age}
          /> */}

          <DetailRow
            label={t('cards:PatientDetails.labels.dateOfBirth')}
            value={
              canViewPII
                ? appUser.dateOfBirth
                  ? moment(appUser.dateOfBirth).format('L')
                  : ''
                : obfuscatePIValue
            }
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.sex')}
            value={
              appUser.sex && canViewPII
                ? t(`cards:PatientDetails.labels.gender.${appUser.sex}`)
                : obfuscatePIValue
            }
          />

          <DetailRow
            label={t('cards:PatientDetails.labels.title')}
            value={canViewPII ? appUser.title : obfuscatePIValue}
          />

          <DetailRow label={t('cards:PatientDetails.labels.userId')} value={appUser.id} />
        </Form>
      )}
    </Card.Half>
  );
};

AppUserDetailsCard.propTypes = {
  title: string,
  appUser: object,
};

AppUserDetailsCard.defaultProps = {
  title: 'cards:PatientDetails.title',
};

const AppUserDetailByIdCard = ({ appUserId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const [loading, appUser] = useSelector(selectAppUser(appUserId));

  return <AppUserDetailsCard appUser={appUser} loading={loading} {...props} />;
};

export { AppUserDetailsCard };

export default AppUserDetailByIdCard;
