import React, { useMemo } from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Table } from 'antd';
import { Card } from '@cards/Card';
import './style.less';

function EditButton({ link }) {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Button block size="large" onClick={() => history.push(link)}>
      {t('common:buttons.edit')}
    </Button>
  );
}

EditButton.propTypes = {
  link: string.isRequired,
};

function AdminOnboaring() {
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      { dataIndex: 'name', title: t('cards:HospitalAdminOnboarding.columns.checklist') },
      {
        dataIndex: 'link',
        title: t('cards:HospitalAdminOnboarding.columns.tasks'),
        render: link => <EditButton link={link} />,
      },
    ],
    [t],
  );

  const tasks = useMemo(
    () => [
      // TODO: add links to relevant editing page when complete
      {
        key: 1,
        name: t('cards:HospitalAdminOnboarding.tasks.details'),
        link: '/administration/hospital/edit',
      },
      // {
      //   key: 2,
      //   name: t('cards:HospitalAdminOnboarding.tasks.content'),
      //   link: '/administration/hospital',
      // },
      // {
      //   key: 3,
      //   name: t('cards:HospitalAdminOnboarding.tasks.terms'),
      //   link: '/administration/hospital',
      // },
      // {
      //   key: 4,
      //   name: t('cards:HospitalAdminOnboarding.tasks.privacy'),
      //   link: '/administration/hospital',
      // },
    ],
    [t],
  );

  return (
    <Card className="admin-onboarding" title={undefined}>
      <h1>{t('cards:HospitalAdminOnboarding.title')}</h1>
      <p>{t('cards:HospitalAdminOnboarding.explain1')}</p>
      <p>{t('cards:HospitalAdminOnboarding.explain2')}</p>
      <Table columns={columns} dataSource={tasks} pagination={false} />
    </Card>
  );
}

export default AdminOnboaring;
