import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { fetchAppUsers, refreshAppUsers } from '../redux/appUsers/actions';
import { fetchAppUsersPathways } from '@pathways/redux/appUserPathways/actions';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectAppUsers } from '@redux/appUsers/reducers';
import SelectionModal from './SelectionModal';

const IndividualAccessModal = ({
  registeredUsersOnly,
  submitting,
  visible,
  onCancel,
  onSubmit: onSubmitProp,
  selectedRowKeys,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, appUsers] = useSelector(selectAppUsers);

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchPathways());
    dispatch(fetchAppUsersPathways());
  }, [dispatch]);

  const onSubmit = useCallback(
    selectedRowKeys => {
      const selectedAppUsers = appUsers.filter(user => selectedRowKeys.includes(user.id));
      onSubmitProp(selectedRowKeys, selectedAppUsers);
    },
    [appUsers, onSubmitProp],
  );

  const dataSource = useMemo(
    () =>
      registeredUsersOnly
        ? appUsers.filter(appUser => appUser.invitationCodes.some(({ consumed }) => consumed))
        : appUsers,
    [appUsers, registeredUsersOnly],
  );

  return (
    <SelectionModal
      columns={[
        {
          title: t('common:firstName'),
          dataIndex: 'firstName',
          key: 'firstName',
          render: firstName => <div>{firstName}</div>,
          sorter: (a, b) => a.firstName > b.firstName,
        },
        {
          title: t('common:lastName'),
          dataIndex: 'lastName',
          key: 'lastName',
          render: lastName => <div>{lastName}</div>,
          sorter: (a, b) => a.lastName > b.lastName,
        },
      ]}
      dataSource={dataSource}
      displayKeys={['firstName', 'lastName']}
      loading={loading}
      primaryKey="id"
      submitting={submitting}
      title={t('cards:DocumentList.individualAccessTitle')}
      width={700}
      visible={visible}
      onCancel={onCancel}
      onSubmit={onSubmit}
      refreshList={() => dispatch(refreshAppUsers())}
      selectedRowKeys={selectedRowKeys}
    />
  );
};

IndividualAccessModal.propTypes = {
  registeredUsersOnly: bool,
  submitting: bool,
};

IndividualAccessModal.defaultProps = {
  registeredUsersOnly: false,
  submitting: false,
};

export default IndividualAccessModal;
