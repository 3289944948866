import moment from 'moment';
import DateFilter from '@components/DateFilter';

export default t => [
  {
    title: t('cards:PatientFormList.columns.date'),
    key: 'date',
    render: data => moment(data.submission.created).format('lll'),
    width: 200,
    ellipsis: true,
    sorter: (a, b) => a.submission.created.localeCompare(b.submission.created),
    filterDropdown: DateFilter.filterDropdown,
    onFilter: (value, record) => DateFilter.onFilter(value, record.submission.created),
  },
  {
    title: t('cards:PatientFormList.columns.title'),
    key: 'title',
    render: data => data.name,
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
];
