import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Empty } from 'antd';
import FormPreviewModal from '@components/FormPreviewModal';
import { Card } from '../../Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkSquare, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { fetchForms, downloadForm, fetchFormData } from '../../../redux/forms/actions';
import { selectForm, selectFormData } from '../../../redux/forms/reducers';

import './style.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  layout: 'vertical',
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const FormContentCard = ({ form, formData, loading, canDownload, canPreview, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);

  const handleDownload = useCallback(
    () =>
      dispatch(
        downloadForm((form && form.metadata && form.metadata.fileName) || 'form.json', formData),
      ),
    [dispatch, form, formData],
  );

  const handleOpenModal = () => setVisible(true);

  const closeModal = () => setVisible(false);

  return (
    <Card.Half
      className="form-content"
      noPadding
      title={t('cards:FormContent.title')}
      loading={loading}
      extra={
        <>
          {canPreview ? (
            <FontAwesomeIcon
              icon={faExternalLinkSquare}
              className="modal-btn clickable-icon"
              onClick={handleOpenModal}
            />
          ) : null}
          {canPreview ? (
            <FontAwesomeIcon
              icon={faDownload}
              className="clickable-icon"
              onClick={() => handleDownload()}
            />
          ) : null}
        </>
      }
      {...props}
    >
      {form === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form {...formItemLayout} className="detail-form">
          <Form.Item label={t('cards:FormContent.fileLabel')}>
            <span className="ant-form-text">{form.metadata.fileName || 'form.json'}</span>
          </Form.Item>
        </Form>
      )}
      <FormPreviewModal
        readOnly
        name={form?.name}
        visible={visible}
        handleCloseModal={closeModal}
        loading={loading}
        schema={formData?.schema ?? {}}
        uiSchema={formData?.uiSchema ?? {}}
      />
    </Card.Half>
  );
};

const FormContentCardbyId = ({ formId, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchFormData(formId));
  }, [dispatch, formId]);

  const [formLoading, form] = useSelector(selectForm(formId));
  const [formDataLoading, formData] = useSelector(selectFormData(formId));

  const loading = useMemo(() => formLoading || formDataLoading, [formLoading, formDataLoading]);

  return <FormContentCard form={form} formData={formData} loading={loading} {...props} />;
};

export { FormContentCard };

export default FormContentCardbyId;
