import { spawn, put, select } from 'redux-saga/effects';
import { AccessDenied } from './index';
import { selectPermissionsForUser } from './selectors';
import { setPermissionsMap } from './actions';
import { permissionsMap } from './constants';

export default function*() {
  yield spawn(doLoadPermissionsMap);
}

export function* requiresPermissions(...permissions: string[]) {
  const userPermissions = yield select(selectPermissionsForUser);
  const ok = permissions.every(p => userPermissions.includes(p));
  if (!ok) {
    throw new AccessDenied();
  }
}

function* doLoadPermissionsMap() {
  yield put(setPermissionsMap(permissionsMap));
}
