import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, Menu } from 'antd';
import { /* faDatabase, */ faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { procedurePathway } from '@assets/icons';
import { usePageActions } from '@hooks';
import { selectHospital } from '@redux/hospitals/reducers';
import { fetchHospitals } from '@redux/hospitals/actions';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import Content from './Content';
// import DataRequests from './DataRequests';
import Pathways from './Pathways';
import Users from './Users';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';

const routes = {
  content: 'content',
  dataRequests: 'data-requests',
  pathways: 'pathways',
  users: 'users',
};

function HospitalDetails({ match }) {
  const permissions = useHasPermissions();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, hospital] = useSelector(selectHospital(match.params.hospitalId));

  const { setTitle } = usePageActions({
    showBack: !permissions[Permissions.ViewHospitalMetrics],
    title: hospital ? hospital.name : t('reviewHub:Hospital.title'),
  });

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  useEffect(() => {
    if (hospital) setTitle(hospital.name);
  }, [setTitle, hospital]);

  return (
    <SubNavMenuContainer
      menu={
        <SubNavMenu>
          <Menu.Item key={routes.users}>
            <Icon type="user" /> {t('reviewHub:Global.Users.title')}
          </Menu.Item>
          <Menu.Item key={routes.pathways}>
            <Icon component={procedurePathway} /> {t('reviewHub:Global.Pathways.title')}
          </Menu.Item>
          <Menu.Item key={routes.content}>
            <FontAwesomeIcon icon={faFileAlt} /> {t('reviewHub:Global.Content.title')}
          </Menu.Item>
          {/* <Menu.Item key={routes.dataRequests}>
            <FontAwesomeIcon icon={faDatabase} /> {t('reviewHub:Global.DataRequests.title')}
          </Menu.Item> */}
        </SubNavMenu>
      }
    >
      <Switch>
        <Route path={`${match.path}/${routes.users}`} component={Users} />
        {/* <Route path={`${match.path}/${routes.dataRequests}`} component={DataRequests} /> */}
        <Route path={`${match.path}/${routes.content}`} component={Content} />
        <Route path={`${match.path}/${routes.pathways}`} component={Pathways} />
        <Redirect to={`${match.path}/${routes.users}`} />
      </Switch>
    </SubNavMenuContainer>
  );
}

export default HospitalDetails;
