import { takeLatest, takeEvery, put, getContext, call } from 'redux-saga/effects';
import { message } from 'antd';
import { v4 as uuid } from 'uuid';
import slug from 'slug';
import createPipClient, { PIPObject } from '@api/pipClient';
import { appToken } from 'settings';
import { inviteDashboardUser } from '@redux/dashboardUsers/sagas';
import i18n from '../../i18n';

import {
  loadHospitals,
  loadHospitalsSuccess,
  loadHospitalsFailed,
  IUpdateHospital,
  updateHospitalSuccess,
  updateHospitalFailed,
  createHospitalFailed,
  createHospitalSuccess,
  CreateHospital,
  EditHospital,
  editHospitalFailed,
  editHospitalSuccess,
} from './actions';
import {
  IHospital,
  FETCH_HOSPITALS,
  IHospitalInvites,
  CREATE_HOSPITAL,
  UPDATE_HOSPITAL,
  EDIT_HOSPITAL,
} from './types';
import { DuplicateHospitalError, HospitalNotFoundError } from './errors';

export default function* root() {
  yield takeLatest(FETCH_HOSPITALS, doFetchHospitals);
  yield takeEvery(CREATE_HOSPITAL, doCreateHospital);
  yield takeLatest(UPDATE_HOSPITAL, doUpdateHospital);
  yield takeEvery(EDIT_HOSPITAL, doEditHospital);
}

export function* doFetchHospitals() {
  try {
    yield put(loadHospitals());
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const [latest]: PIPObject<{ hospitals: IHospital[] }>[] = yield call(
      pipClient.getObjectsForType,
      `${appToken}-hospitals`,
      'latest',
    );

    // create mock invites to be number 0-100
    const invites = latest.json.hospitals.reduce(
      (acc: IHospitalInvites, hos: IHospital) => ({
        ...acc,
        [hos.slug]: Math.floor(Math.random() * 101),
      }),
      {},
    );

    yield put(loadHospitalsSuccess(latest.json.hospitals, invites));
  } catch (err) {
    console.error(err);
    yield put(loadHospitalsFailed());
  }
}

function* doCreateHospital({ payload: { hospital } }: CreateHospital): any {
  try {
    const history = yield getContext('history');
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const hospitalsObjectType = `${appToken}-hospitals`;
    const hospitalId: string = uuid();
    const hospitalSlug: string = slug(hospital.hospitalName);

    const [hospitalsObject] = yield call(
      pipClient.getObjectsForType,
      hospitalsObjectType,
      'latest',
    );

    const hospitals: IHospital[] = hospitalsObject.json?.hospitals || [];

    if (hospitals.some(({ slug }) => slug === hospitalSlug)) {
      throw new DuplicateHospitalError();
    }

    // invite the hospital admin
    const invitedUser = yield inviteDashboardUser({
      hospitalId: hospitalId,
      hospitalSlug: hospitalSlug,
      hospitalName: hospital.hospitalName,
      language: hospital.language || 'en',
      name: `${hospital.firstName} ${hospital.lastName}`,
      email: hospital.email,
      userType: 'hospital-admin',
      firstName: hospital.firstName,
      lastName: hospital.lastName,
    });

    // create the hospital in PIP
    const newHospital = {
      admin: {
        id: invitedUser.id,
        name: `${hospital.firstName} ${hospital.lastName}`,
        email: hospital.email,
      },
      contactDetails: {},
      created: new Date().toISOString(),
      language: hospital.language || 'en',
      name: hospital.hospitalName,
      pathways: hospital.pathways,
      hospitalPathways: [],
      slug: hospitalSlug,
      socialMedia: [],
      uuid: hospitalId,
      users: [invitedUser],
    };

    yield call(pipClient.createObject, hospitalsObjectType, {
      hospitals: [newHospital, ...hospitals],
    });

    yield call(history.goBack);
    yield put(createHospitalSuccess(newHospital));
    yield call(message.success, i18n.t('hospitals:Wizard.createSuccess'));
  } catch (err) {
    console.error(err);

    if (err instanceof DuplicateHospitalError) {
      yield put(createHospitalFailed());
      yield call(message.error, i18n.t('hospitals:Wizard.duplicateHospitalError'));
      return;
    }

    yield put(createHospitalFailed());
    yield call(message.error, i18n.t('hospitals:Wizard.createFailed'));
  }
}

function* doUpdateHospital({ payload: { hospital: editedHospital } }: IUpdateHospital): any {
  try {
    const history = yield getContext('history');
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const hospitalsObjectType = `${appToken}-hospitals`;

    const [latest]: PIPObject<{ hospitals: IHospital[] }>[] = yield call(
      pipClient.getObjectsForType,
      `${appToken}-hospitals`,
      'latest',
    );

    const newHospitals = latest.json.hospitals.map(hospital => {
      if (hospital.uuid === editedHospital.uuid) return editedHospital;
      return hospital;
    });
    const newJson = { hospitals: newHospitals };

    yield call(pipClient.createObject, hospitalsObjectType, newJson);

    yield put(updateHospitalSuccess(newHospitals));
    yield call(history.goBack);
    yield call(message.success, i18n.t('hospitals:EditHospitalDetails.successMessage'));
  } catch (err) {
    console.error(err);
    yield put(updateHospitalFailed());
    yield call(message.error, i18n.t('hospitals:EditHospitalDetails.failedMessage'));
  }
}

export function* doEditHospital({ payload: { id, hospital, editOnly } }: EditHospital) {
  try {
    const history = yield getContext('history');
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const hospitalsObjectType = `${appToken}-hospitals`;

    let [hospitalsObject]: { json: { hospitals: IHospital[] } }[] = yield call(
      pipClient.getObjectsForType,
      hospitalsObjectType,
      'latest',
    );

    // temp code to remove all entries from hospital users as it is probably no longer needed
    hospitalsObject.json.hospitals.forEach(hospital => {
      hospital.users = [];
    });

    const existingHospital = hospitalsObject.json.hospitals.find(({ uuid }) => uuid === id);

    if (existingHospital) {
      if (hospital.hospitalName) existingHospital.name = hospital.hospitalName;
      if (hospital.pathways) existingHospital.pathways = hospital.pathways;

      if (hospital.hospitalPathways) {
        existingHospital.hospitalPathways = hospital.hospitalPathways;
      }

      if (hospital.content) {
        existingHospital.content = hospital.content;
      }

      yield call(pipClient.createObject, hospitalsObjectType, hospitalsObject.json);

      yield put(editHospitalSuccess(hospitalsObject.json.hospitals));

      // TODO: this needs to be reworked so navigation and success and error messaging is done from the UI code
      if (!editOnly) {
        yield call(history.goBack);
        yield call(message.success, i18n.t('hospitals:Wizard.editSuccess'));
      }
      return;
    }

    throw new HospitalNotFoundError();
  } catch (err) {
    console.error(err);
    yield put(editHospitalFailed());
    yield call(message.error, i18n.t('hospitals:Wizard.editFailed'));
  }
}
