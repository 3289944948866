import { select } from 'redux-saga/effects';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { appToken } from 'settings';

export const BASE_MESSAGES_OBJECT_TYPE = `${appToken}-messages`;
export const GLOBAL_MESSAGES_OBJECT_TYPE = `${BASE_MESSAGES_OBJECT_TYPE}-global`;

export function* getBaseObjectTypeForMessages() {
  const currentDashboardUser = yield select(selectCurrentDashboardUser);

  if (currentDashboardUser.profile.hospitalId) {
    return `${BASE_MESSAGES_OBJECT_TYPE}-${currentDashboardUser.profile.hospitalId}`;
  }

  // TODO: add handling for country and pathway admins when needed

  return GLOBAL_MESSAGES_OBJECT_TYPE;
}
