import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ColumnGroup, Column } from '@cards/Card';
import { usePageActions } from '@hooks';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospital } from '@redux/hospitals/reducers';

import HospitalDetailForm from '@cards/Hospitals/HospitalDetailForm';

const confirmExit = (t, history) => {
  Modal.confirm({
    title: t('hospitals:EditHospitalDetails.exitTitle'),
    content: t('hospitals:EditHospitalDetails.exitContent'),
    okText: t('hospitals:EditHospitalDetails.okText'),
    cancelText: t('hospitals:EditHospitalDetails.cancelText'),
    onOk: history.goBack,
  });
};

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const dashboardUserProfile = useSelector(state => state.login.user.profile);

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const [, hospital] = useSelector(selectHospital(dashboardUserProfile.hospitalId));

  const { setTitle } = usePageActions({
    title: '',
    actions: [],
    showClose: true,
    onClose: () => confirmExit(t, history),
  });

  useEffect(() => {
    const name = (hospital && hospital.name) || '';
    setTitle(name);
    document.title = name;
  }, [hospital, setTitle]);

  return (
    <ColumnGroup>
      <Column>
        <HospitalDetailForm id={dashboardUserProfile.hospitalId} />
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};
