import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form, Empty } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { fetchCannedMessages } from '@redux/messages/actions';
import { selectCannedMessage } from '@redux/messages/reducers';
import { Card } from '@cards/Card';
import { fetchDocuments } from '@redux/documents/actions';
import { fetchForms } from '@redux/forms/actions';
import DetailRow from '@components/DetailRow';
import ContentIcon from '@components/ContentIcon';
import LanguageIcon from '@components/LanguageIcon';
import SourceIcon from '@components/SourceIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { messageTypeToText, NOTIFICATION_ONLY, OPEN_CONTENT, OPEN_LINK } from '@constants';
import './style.less';

const MessageDetailCard = ({ messageId, onChange, title, messageTranslationKey, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCannedMessages());
    dispatch(fetchDocuments());
    dispatch(fetchForms());
  }, [dispatch]);

  const [loading, message] = useSelector(selectCannedMessage(messageId));

  return (
    <Card.Half className="message-details" noPadding loading={loading} title={t(title)} {...props}>
      {message === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <Form
          labelCol={{ span: 8 }}
          layout="vertical"
          wrapperCol={{ span: 16 }}
          className="detail-form"
        >
          <DetailRow
            label={t(`common:contentType.${message.metadata.type}`)}
            value={<ContentIcon contentType={message.metadata.type} showText />}
          />
          <DetailRow label={t('cards:MessageDetail.labels.title')} value={message.title} />
          <DetailRow label={t('cards:MessageDetail.labels.description')} value={message.body} />
          <DetailRow label={t('cards:MessageDetail.labels.version')} value={message.version} />
          <DetailRow
            label={t('cards:MessageDetail.labels.status')}
            value={<PublishedStatusIcon published={message.published} showText />}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.created')}
            value={moment(message.created).format('LL')}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.source')}
            value={<SourceIcon source={message.source} showText />}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.language')}
            value={<LanguageIcon language={message.language} showText />}
          />
          <DetailRow
            label={t('cards:MessageDetail.labels.actions')}
            value={t(messageTypeToText[message.payload?.action || NOTIFICATION_ONLY])}
          />
          {message?.payload?.action === OPEN_LINK && message?.payload?.data ? (
            <DetailRow label={t('cards:MessageDetail.labels.url')} value={message.payload?.data} />
          ) : null}
          {message?.payload?.action === OPEN_CONTENT ? (
            <>
              <DetailRow
                label={t('cards:SentMessageDetail.contentType')}
                value={<ContentIcon contentType={message.payload?.data?.metadata.type} showText />}
              />
              <DetailRow
                label={t('cards:SentMessageDetail.contentTitle')}
                value={
                  <Link
                    to={`/content/${message.payload?.data?.metadata.type.toLowerCase()}s/${message
                      .payload.data.id ?? message.payload.data.uuid}`}
                  >
                    {message?.payload?.data.title || message?.payload?.data.name}
                  </Link>
                }
              />
            </>
          ) : null}
        </Form>
      )}
    </Card.Half>
  );
};

export default MessageDetailCard;
