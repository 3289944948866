import { combineReducers } from 'redux';
import {
  ADD_PATHWAY_TO_APP_USERS_SUCCESS,
  APP_USER_JOURNEYS_LOADED,
  APP_USERS_PATHWAYS_LOADED,
  APP_USER_PATHWAY_STAGE_UPDATED,
  SET_APP_USER_PATHWAY_STAGE,
  INVITE_APP_USER_WITH_PATHWAYS_FAILED,
  INVITE_APP_USER_WITH_PATHWAYS_SUCCESS,
  SET_APP_USER_PATHWAY_ARCHIVED_STATUS_SUCCESS,
  EDIT_APP_USER_JOURNEY,
  EDIT_APP_USER_JOURNEY_FAILED,
  EDIT_APP_USER_JOURNEY_SUCCESS,
  TRIGGER_ADHOC_RULE,
  TRIGGER_ADHOC_RULE_FAILED,
  TRIGGER_ADHOC_RULE_SUCCESS,
} from './types';
import { selectPathwayWithRules } from '../pathways/reducers';

const creating = (state = false, action) => {
  switch (action.type) {
    case 'INVITE_APP_USER_SUCCESS':
      return true;
    case INVITE_APP_USER_WITH_PATHWAYS_FAILED:
    case INVITE_APP_USER_WITH_PATHWAYS_SUCCESS:
      return false;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case SET_APP_USER_PATHWAY_STAGE:
      return true;
    case APP_USER_PATHWAY_STAGE_UPDATED:
      return false;
    default:
      return state;
  }
};

const triggeringRule = (state = false, action) => {
  switch (action.type) {
    case TRIGGER_ADHOC_RULE:
      return true;
    case TRIGGER_ADHOC_RULE_FAILED:
    case TRIGGER_ADHOC_RULE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const updating = (state = false, action) => {
  switch (action.type) {
    case EDIT_APP_USER_JOURNEY:
      return true;
    case EDIT_APP_USER_JOURNEY_FAILED:
    case EDIT_APP_USER_JOURNEY_SUCCESS:
      return false;
    default:
      return state;
  }
};

const pathways = (state = {}, action) => {
  switch (action.type) {
    case INVITE_APP_USER_WITH_PATHWAYS_SUCCESS:
      return { ...state, [action.payload.appUserId]: action.payload.appUserPathways };
    case ADD_PATHWAY_TO_APP_USERS_SUCCESS:
      return Object.entries(action.payload.appUserPathways).reduce(
        (prev, [appUserId, newPathway]) => {
          const currentStateForAppUser = state[appUserId] || [];
          return {
            ...prev,
            [appUserId]: [...currentStateForAppUser, newPathway],
          };
        },
        state,
      );
    case APP_USERS_PATHWAYS_LOADED:
      return {
        ...state,
        ...action.payload.appUsers.reduce((acc, appUser) => {
          return {
            ...acc,
            [appUser.identity_id]: appUser.pathways,
          };
        }, {}),
      };
    case APP_USER_PATHWAY_STAGE_UPDATED:
      return {
        ...state,
        [action.payload.appUserPathwaysId]: state[action.payload.appUserPathwaysId].map(pathway =>
          pathway.id === action.payload.appUserPathwayId
            ? {
                ...pathway,
                currentStageSlug: action.payload.stageSlug,
              }
            : pathway,
        ),
      };
    case SET_APP_USER_PATHWAY_ARCHIVED_STATUS_SUCCESS:
      return {
        ...state,
        [action.payload.appUserId]: state[action.payload.appUserId].map(pathway =>
          pathway.id === action.payload.appUserPathwayId
            ? { ...pathway, isActive: action.payload.isActive }
            : pathway,
        ),
      };
    default:
      return state;
  }
};

const journeys = (state = {}, action) => {
  switch (action.type) {
    case APP_USER_JOURNEYS_LOADED:
      return {
        ...state,
        [action.payload.appUserId]: action.payload.journeys.map(journey => ({
          id: journey.id,
          url: journey.url,
          started: journey.start_date,
          ended: journey.end_date,
          created: journey.created_on,
          indexEvents: journey.indexEvents || [],
          entries: journey.entries || [],
        })),
      };
    default:
      return state;
  }
};

export default combineReducers({
  creating,
  loading,
  pathways,
  journeys,
  triggeringRule,
  updating,
});

export const selectAppUsersPathwaysCreating = state => state.appUserPathways.creating;
export const selectAppUsersPathwaysLoading = state => state.appUserPathways.loading;
export const selectAppUserPathwayRuleTriggering = state => state.appUserPathways.triggeringRule;
export const selectAppUserPathwaysUpdating = state => state.appUserPathways.updating;

export const selectAppUsersWithPathway = pathwayId => state => [
  state.appUserPathways.loading,
  Object.entries(state.appUserPathways.pathways).reduce((prev, [appUserId, pathways]) => {
    return pathways.some(({ originalPathway }) => originalPathway === Number(pathwayId))
      ? [...prev, appUserId]
      : prev;
  }, []),
];

export const selectJourneysForAppUser = appUser => state => [
  state.appUserPathways.loading,
  state.appUserPathways.journeys[appUser?.ids?.pathways],
];

export const selectPathwaysForAppUser = appUser => state => [
  state.appUserPathways.loading,
  state.appUserPathways.pathways[appUser?.ids?.pathways],
];

export const selectPathwaysForAppUserWithOriginals = appUser => state => [
  state.appUserPathways.loading,
  state.appUserPathways.pathways[appUser?.ids?.pathways]
    ?.map(appUserPathway => ({
      ...appUserPathway,
      originalPathway: selectPathwayWithRules(appUserPathway.originalPathway)(state)[1],
    }))
    // remove pathways that hospital no longer has access to post-content restrictions
    // can probably be removed once temp pre-release data has been cleared
    .filter(pathway => !!pathway.originalPathway),
];
