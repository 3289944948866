import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Table, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import SelectPathwayModal from '@components/SelectPathwayModal';
import LanguageIcon from '@components/LanguageIcon';
import IconButton from '@components/IconButton';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectSourceIcon, selectSourceLegend } from '@utils/supportedLanguages';

function HospitalPathways() {
  const { t } = useTranslation();
  const { currentStepData, setCurrentStepData } = useWizard();
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const [loading, pathwayTemplates] = useSelector(selectPathways);

  const pathways = currentStepData.pathways || [];
  const selectablePathways = useMemo(() => {
    const selectedPathwayIds = currentStepData.pathways?.map(({ id }) => id) || [];

    return pathwayTemplates.filter(({ id }) => !selectedPathwayIds.includes(id));
  }, [currentStepData, pathwayTemplates]);

  const addPathways = useCallback(
    selectedPathwayIds => {
      const selectedPathways = pathwayTemplates.filter(({ id }) => selectedPathwayIds.includes(id));
      setCurrentStepData({ pathways: [...pathways, ...selectedPathways] });
      setIsVisible(false);
    },
    [pathways, pathwayTemplates, setCurrentStepData],
  );

  const removePathway = useCallback(
    pathwayId => {
      setCurrentStepData({ pathways: pathways.filter(({ id }) => pathwayId !== id) });
    },
    [pathways, setCurrentStepData],
  );

  useEffect(() => {
    dispatch(fetchPathways());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        title: t('hospitals:Wizard.pathways.columns.language'),
        dataIndex: ['metadata', 'language'],
        key: 'language',
        render: language => <LanguageIcon language={language} />,
        // width: 150,
      },
      {
        title: t('hospitals:Wizard.pathways.columns.source'),
        dataIndex: 'source',
        key: 'source',
        render: source => (
          <Tooltip placement="bottom" title={selectSourceLegend(source, t)}>
            <FontAwesomeIcon size="lg" icon={selectSourceIcon(source)} />
          </Tooltip>
        ),
        // width: 130,
      },
      { dataIndex: 'name', title: t('hospitals:Wizard.pathways.columns.name') },
      {
        title: t('hospitals:Wizard.pathways.columns.active'),
        dataIndex: 'isActive',
        key: 'isActive',
        render: isActive => <PublishedStatusIcon published={isActive} />,
        // width: 150,
      },
      {
        title: t('hospitals:Wizard.pathways.columns.delete'),
        render: record => (
          <IconButton
            tooltip={t('common:buttons.delete')}
            icon={faTrashAlt}
            onClick={() => removePathway(record.id)}
          />
        ),
      },
    ],
    [t, removePathway],
  );

  return (
    <>
      <Card className="hospital-wizard-card" title={t('hospitals:Wizard.pathways.title')}>
        <Table
          columns={columns}
          dataSource={pathways}
          locale={{ emptyText: ' ' }}
          pagination={false}
          rowKey="id"
        />
        <Button
          className="add-pathway-button"
          icon="plus"
          type="dashed"
          onClick={() => setIsVisible(true)}
        >
          {t('hospitals:Wizard.pathways.add')}
        </Button>
      </Card>
      <SelectPathwayModal
        loading={loading}
        pathways={selectablePathways}
        visible={isVisible}
        onSubmit={addPathways}
        onCancel={() => setIsVisible(false)}
        clearOnClose
      />
    </>
  );
}

export default HospitalPathways;
