import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import Overview from '@reviewHub/cards/Overview';
import UserMetrics from '@reviewHub/cards/UserMetrics';
import DataRefresh from '@reviewHub/cards/DataRefresh';
import { selectPathwayMetrics } from '@reviewHub/redux/selectors';
import { fetchPathwayMetrics } from '@reviewHub/redux/actions';

function Users({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathwayId } = match.params;
  const [loading, metrics] = useSelector(selectPathwayMetrics(pathwayId));

  useEffect(() => {
    dispatch(fetchPathwayMetrics());
  }, [dispatch]);

  const overview = [
    {
      key: 'total-patients',
      metric: t('reviewHub:Pathways.Users.data.totalPatients'),
      value: metrics?.overview.totalPatients,
    },
    {
      key: 'total-content',
      metric: t('reviewHub:Global.Users.Overview.totalContent'),
      value: metrics?.overview.totalContent,
    },
  ];

  const userData = useMemo(
    () => [
      {
        key: 'total-patients',
        metric: t('reviewHub:Pathways.Overview.totalPatients'),
        value: metrics?.overview.totalPatients,
      },
      ...Object.values(metrics?.users || {})
        .filter(stage => stage.number !== 99 && !stage.isDeleted)
        .map(stage => ({
          metric: t('reviewHub:Pathways.Users.data.inStage', {
            stageNumber: stage.number,
            stageName: stage.name,
          }),
          value: stage.numAppUsers,
          key: stage.number,
        })),
    ],
    [metrics, t],
  );

  return (
    <ColumnGroup>
      <Column>
        <Overview data={overview} />
        <UserMetrics
          data={userData}
          loading={loading}
          title={t('reviewHub:Pathways.Users.title')}
        />
        <DataRefresh data={{ created: metrics?.created }} loading={loading} />
      </Column>
    </ColumnGroup>
  );
}

export default Users;
