import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './style.less';
import { logoGreen } from '@assets/index';

const OnboardingContainer = ({ children, className }) => {
  const { t } = useTranslation();
  return (
    <div className={`onboarding-container ${className}`}>
      <header>
        <img src={logoGreen} alt="" />
        <h1>{t('common:poweredBy')}</h1>
      </header>
      <main>
        <div className="content">{children}</div>
      </main>
    </div>
  );
};

OnboardingContainer.propTypes = {
  className: PropTypes.string,
};

OnboardingContainer.defaultProps = {
  className: 'login-container',
};

export default OnboardingContainer;
