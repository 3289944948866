import {
  faGlobe,
  faFilePdf,
  faCommentAltLines,
  faFileEdit,
  faFileAlt,
  faFileVideo,
  faQuestionCircle,
  faTasks,
  faExclamationTriangle,
  faBell,
} from '@fortawesome/pro-regular-svg-icons';

import { ReactComponent as addDocument } from './add-document.svg';
import { ReactComponent as addMessage } from './add-message.svg';
import { ReactComponent as addProcedure } from './add-procedure.svg';
import { ReactComponent as addAppUser } from './add-user.svg';
import { ReactComponent as germany } from './208-germany.svg';
import { ReactComponent as spain } from './230-spain.svg';
import { ReactComponent as unitedKingdom } from './110-united kingdom.svg';
import { ReactComponent as czech } from './202-czech republic.svg';
import { ReactComponent as japan } from './241-japan.svg';
import { ReactComponent as slovakia } from './218-slovakia.svg';
import { ReactComponent as campaignPathway } from './arrow-megaphone.svg';
import { ReactComponent as campaignRule } from './list-megaphone.svg';
import { ReactComponent as pathway } from './arrow-two.svg';
import { ReactComponent as procedurePathway } from './arrow-stethoscope.svg';
import { ReactComponent as procedureRule } from './list-stethoscope.svg';
import { ReactComponent as newAppUser } from './user-plus-regular-ls.svg';
import { ReactComponent as hospital } from './hospital.svg';
import { ReactComponent as hospitalAdmin } from './hospital-admin.svg';
import { ReactComponent as hospitalUser } from './Professional-User.svg';
import { ReactComponent as superAdmin } from './super-admin.svg';
import { ReactComponent as supportAdmin } from './support-admin.svg';
import { ReactComponent as systemAdmin } from './system-admin.svg';

const selectContentTypeIcon = type => {
  switch (type) {
    case 'DOC':
    case 'documents':
      return faFileAlt;
    case 'VIDEO':
    case 'videos':
      return faFileVideo;
    case 'PDF':
      return faFilePdf;
    case 'MESSAGE':
      return faCommentAltLines;
    case 'FORM':
      return faFileEdit;
    case 'PROM': // old value
    case 'QUESTIONNAIRE':
      return faQuestionCircle;
    case 'TASK':
      return faTasks;
    case 'ALERT':
      return faExclamationTriangle;
    case 'REMINDER':
      return faBell;
    default:
      return faGlobe;
  }
};

// using string literals here to prevent circular dependancy with constants file
export const selectDashboardUserIcon = userType => {
  switch (userType) {
    case 'hospital-admin':
      return hospitalAdmin;
    case 'hospital-user':
      return hospitalUser;
    case 'super-admin':
      return superAdmin;
    case 'support-admin':
      return supportAdmin;
    case 'system-admin':
      return systemAdmin;
    default:
      return null;
  }
};

export {
  selectContentTypeIcon,
  addDocument,
  addMessage,
  addProcedure,
  addAppUser,
  germany,
  spain,
  unitedKingdom,
  czech,
  japan,
  slovakia,
  campaignPathway,
  campaignRule,
  pathway,
  procedurePathway,
  procedureRule,
  newAppUser,
  hospital,
  hospitalAdmin,
  hospitalUser,
  superAdmin,
  supportAdmin,
  systemAdmin,
};
