import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Input } from 'antd';
import { Mod37_36 } from '@konfirm/iso7064';
import './style.less';

function UCIDInput({ record, callback }) {
  const { t } = useTranslation();
  // const [isInvalid, setIsInvalid] = useState(false);
  const [shouldDisplayModal, setShouldDisplayModal] = useState(false);
  return (
    <>
      <Input
        allowClear={true}
        defaultValue={record.externalId}
        onBlur={e => {
          if (e.target.value) {
            const validationResult = Mod37_36.validate(e.target.value);
            // setIsInvalid(!validationResult);
            setShouldDisplayModal(!validationResult);
            if (validationResult) {
              callback(e.target.value);
            }
          } else {
            // setIsInvalid(false);
            setShouldDisplayModal(false);
            callback('');
          }
        }}
      />
      {/* {isInvalid ? <span className="pathway-ucid-error">{t('patients:NewPatient.basicDetails.admin.ucidField.required')}</span> : null } */}
      <Modal
        title="UCID"
        visible={shouldDisplayModal}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              setShouldDisplayModal(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        <span>{t('patients:NewPatient.basicDetails.admin.ucidField.required')}</span>
      </Modal>
    </>
  );
}

UCIDInput.propTypes = {
  record: Object,
  callback: Function,
};

UCIDInput.defaultProps = {
  record: {},
  callback: () => {},
};

export default UCIDInput;
