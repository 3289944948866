import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { selectSourceIcon, selectSourceLegend } from '@utils/supportedLanguages';

function ReviewHospitalPathways() {
  const { t } = useTranslation();
  const { formData } = useWizard();

  const pathways = useMemo(() => formData.pathways?.pathways || [], []); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = useMemo(
    () => [
      {
        title: t('hospitals:Wizard.pathways.columns.language'),
        dataIndex: ['metadata', 'language'],
        key: 'language',
        render: language => <LanguageIcon language={language} />,
      },
      {
        title: t('hospitals:Wizard.pathways.columns.source'),
        dataIndex: 'source',
        key: 'source',
        render: source => (
          <Tooltip placement="bottom" title={selectSourceLegend(source, t)}>
            <FontAwesomeIcon size="lg" icon={selectSourceIcon(source)} />
          </Tooltip>
        ),
      },
      { dataIndex: 'name', title: t('hospitals:Wizard.pathways.columns.name') },
      {
        title: t('hospitals:Wizard.pathways.columns.active'),
        dataIndex: 'isActive',
        key: 'isActive',
        render: isActive => <PublishedStatusIcon published={isActive} />,
      },
    ],
    [t],
  );

  return (
    <Card className="hospital-wizard-card" title={t('hospitals:Wizard.pathways.title')}>
      <Table
        columns={columns}
        dataSource={pathways}
        locale={{ emptyText: ' ' }}
        pagination={false}
        rowKey="id"
      />
    </Card>
  );
}

export default ReviewHospitalPathways;
