import React from 'react';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { ColumnGroup, Column } from '@cards/Card';
import HospitalListCard from '@cards/Hospitals/HospitalList';
import DataRefresh from '@reviewHub/cards/DataRefresh';

function HospitalList({ history, match }) {
  const { t } = useTranslation();
  usePageActions({ title: t('reviewHub:Hospital.title') });

  return (
    <ColumnGroup>
      <Column>
        <HospitalListCard onRowClick={id => history.push(`${match.path}/${id}`)} />
        <DataRefresh />
      </Column>
    </ColumnGroup>
  );
}

export default HospitalList;
