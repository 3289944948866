import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { submitFormAsAppUser } from '@redux/appUsers/actions';
import { fetchForms } from '@redux/forms/actions';
import { selectAppUserFormSubmitting } from '@redux/appUsers/reducers';

import SelectContentModal from '@components/SelectContentModal';
import { FormPreviewModalById } from '@components/FormPreviewModal';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';

const VISIBLE_MODAL = {
  SELECT: 'select',
  SUBMIT: 'submit',
};

const SubmitFormModals = ({
  appUserId,
  formType,
  visibleModal,
  setVisibleModal,
  selectedForm,
  setSelectedForm,
  type,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitting = useSelector(selectAppUserFormSubmitting);

  useEffect(() => {
    dispatch(fetchForms());
  }, [dispatch, appUserId]);

  const contentTypes = useMemo(() => [formType], [formType]);

  const store = useStore();

  return (
    <>
      {visibleModal === VISIBLE_MODAL.SELECT ? (
        <SelectContentModal
          visible
          onCancel={() => {
            setSelectedForm(undefined);
            setVisibleModal(undefined);
          }}
          onSubmit={(uuid, [form]) => {
            setSelectedForm(form);
            setVisibleModal(VISIBLE_MODAL.SUBMIT);
          }}
          contentTypes={contentTypes}
        />
      ) : null}
      {visibleModal === VISIBLE_MODAL.SUBMIT ? (
        <FormPreviewModalById
          hideCloseFooter
          formId={selectedForm.uuid}
          name={selectedForm?.name}
          visible
          handleCloseModal={() => {
            setSelectedForm(undefined);
            setVisibleModal(undefined);
          }}
          submitting={submitting}
          onSubmit={async form => {
            const formData = form.formData;
            dispatch(submitFormAsAppUser(selectedForm.uuid, formData, appUserId, type));
            await waitForStoreUpdate(store, selectAppUserFormSubmitting);
            setVisibleModal(undefined);
          }}
        >
          <Button type="primary" htmlType="submit">
            {t('cards:PatientFormList.submitButton')}
          </Button>
        </FormPreviewModalById>
      ) : null}
    </>
  );
};

export { SubmitFormModals, VISIBLE_MODAL };
