import React, { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { determineSourceTypeForNewContent } from '@utils';
import { useTranslation } from 'react-i18next';
import { OPEN_CONTENT, OPEN_LINK } from '@constants';
import { createContentMessage } from '@redux/messages/actions';
import { selectCannedMessagesCreating } from '@redux/messages/reducers';
import MessageWizard from './MessageWizard';

function getPayloadOptionsData(content) {
  switch (content.action) {
    case OPEN_CONTENT:
      return content.contentData;
    case OPEN_LINK:
      return content.websiteLink;
    default:
      return null;
  }
}

export default ({ type, messageTranslationKey, match: { path } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissionsForUser);

  const onCompleted = useCallback(
    messageData => {
      const { details, content } = messageData;

      const newMessage = {
        title: details.title,
        published: details.status === 'publish' ? true : false,
        version: 1,
        id: uuid(),
        language: details.language,
        body: content.content,
        source: determineSourceTypeForNewContent(permissions),
        created: new Date().toISOString(),
        payload: {
          action: content.action,
          data: getPayloadOptionsData(content),
        },
        metadata: {
          type,
        },
      };

      dispatch(createContentMessage(newMessage, type, messageTranslationKey, path));
    },
    [dispatch, messageTranslationKey, type, path, permissions],
  );

  const creating = useSelector(selectCannedMessagesCreating);

  return (
    <MessageWizard
      title={t(`${messageTranslationKey}:NewContentMessageWizard.title`)}
      exitMessage={t(`${messageTranslationKey}:NewContentMessageWizard.exitConfirmation`)}
      type={type}
      onCompleted={onCompleted}
      loading={false}
      submitting={creating}
      messageTranslationKey={messageTranslationKey}
    />
  );
};
