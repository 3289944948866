import {
  HOSPITAL_USER,
  HOSPITAL_ADMIN,
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
  APP_USER,
} from '@constants';
import { RequestAssignee, RequestStatus } from '@redux/requests/types';
import { appToken } from 'settings';

export function determineGDPRRequestAssigneeBasedOnUserRole(
  userRole: string,
  hospitalId?: string,
): RequestAssignee {
  switch (userRole) {
    case APP_USER:
      return { type: 'hospital', id: hospitalId };
    case HOSPITAL_USER:
      return { type: 'hospital', id: hospitalId };
    case HOSPITAL_ADMIN:
    case SUPER_ADMIN:
      return { type: 'super-admin' };
    case SUPPORT_ADMIN:
    case SYSTEM_ADMIN:
      return { type: 'support-admin' };
    default:
      throw new Error(`User role ${userRole} for GDPR Request not supported`);
  }
}

export function determineGDPRRequestStatusBasedOnUserRole(userRole: string): RequestStatus {
  switch (userRole) {
    case HOSPITAL_ADMIN:
      return 'processing';
    case SUPER_ADMIN:
      return 'completed';
    default:
      throw new Error(`User role ${userRole} for GDPR Request not supported`);
  }
}

export function determinePIPKeyForGDPRContacts(hospitalId: string) {
  const basePIPKey = `${appToken}-contacts`;

  if (hospitalId) {
    return `${basePIPKey}-hospital-${hospitalId}`;
  }

  return `${basePIPKey}-super-admin`;
}

export function extractAppUserIdFromUrl(url: string) {
  const urlRegex = /[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}/;
  const result = urlRegex.exec(url);

  return result ? result[0] : '';
}
