import MessagingClient from '@liquid-state/messaging-client';
import { messagingApiRoot, envName, appToken } from 'settings';
import createPipClient from './pipClient';

let API_KEY = undefined;

async function createMessagingClient(tokens) {
  try {
    if (!API_KEY) {
      const pip = await createPipClient(tokens);
      const object = (await pip.getObjectsForType(`${appToken}-ubiquity-key-${envName}`))
        .results[0];
      API_KEY = object.json.apiKey;
    }

    const client = await new MessagingClient({ apiKey: API_KEY }, { baseUrl: messagingApiRoot });

    return client;
  } catch (err) {
    console.error(err);
  }
}

export default createMessagingClient;
