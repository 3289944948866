import { Permissions } from '@authorisation/constants';
import { selectPermissionsForUser } from '@authorisation/selectors';
import {
  FEATURE_DOCUMENT,
  FEATURE_FORM,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  FEATURE_VIDEO,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
} from '@constants';
import { selectHospital } from '@redux/hospitals/reducers';
import { doFetchHospitals } from '@redux/hospitals/sagas';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { MESSAGE } from '@utils/contentTypes';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { metricsUrlRoot } from 'settings';
import takeFirst from '../../redux/takeFirst';
import {
  loadMetrics,
  loadMetricsFailed,
  globalMetricsLoaded,
  IFetchCountryMetrics,
  countryMetricsLoaded,
  IFetchHospitalMetrics,
  hospitalMetricsLoaded,
  pathwayMetricsLoaded,
} from './actions';
import { IMetrics, IPathwayMetricsState, IRawPathwayMetrics } from './types';

export default function* root() {
  yield takeLatest('metrics/fetch-country', doFetchCountryMetrics);
  yield takeFirst('metrics/fetch-global', doFetchGlobalMetrics);
  yield takeLatest('metrics/fetch-hospital', doFetchHospitalMetrics);
  yield takeFirst('metrics/fetch-pathway', doFetchPathwayMetrics);
}

function* doFetchGlobalMetrics(): any {
  try {
    yield put(loadMetrics());
    const latestUrl = `${metricsUrlRoot}reports/latest.json`;
    const latestReportResponse = yield call(fetch, latestUrl);
    const latestReportData = yield call(latestReportResponse.json.bind(latestReportResponse));

    const appUserMetricsUrl = `${metricsUrlRoot}reports/${latestReportData.date}/global/app_users.json`;
    const dashboardMetricsUrl = `${metricsUrlRoot}reports/${latestReportData.date}/global/dashboard_users.json`;
    const contentMetricsUrl = `${metricsUrlRoot}reports/${latestReportData.date}/global/content.json`;

    const [
      appUserMetricsResponse,
      dashboardUserMetricsResponse,
      contentMetricsResponse,
    ] = yield call(Promise.all.bind(Promise), [
      fetch(appUserMetricsUrl),
      fetch(dashboardMetricsUrl),
      fetch(contentMetricsUrl),
    ]);
    const appUserMetricsData = yield call(appUserMetricsResponse.json.bind(appUserMetricsResponse));
    const dashboardUserMetricsData = yield call(
      dashboardUserMetricsResponse.json.bind(dashboardUserMetricsResponse),
    );
    const contentMetricsData = yield call(contentMetricsResponse.json.bind(contentMetricsResponse));

    // @ts-ignore
    const { hospitalAdmins, professionalUsers } = Object.entries(
      dashboardUserMetricsData.grouped_by_role,
    ).reduce(
      // @ts-ignore
      (userCounts, [role, count]) => {
        if (role.includes('hospital-admin')) return { ...userCounts, hospitalAdmins: count };
        if (role.includes('hospital-user')) return { ...userCounts, professionalUsers: count };
        return userCounts;
      },
      { hospitalAdmins: 0, professionalUsers: 0 },
    );

    const globalMetrics = {
      content: {
        alerts: contentMetricsData.message_counts.alert,
        documents: contentMetricsData.document_counts.document,
        forms: contentMetricsData.form_counts.form,
        messages: contentMetricsData.message_counts.message,
        questionnaires: contentMetricsData.form_counts.questionnaire,
        reminders: contentMetricsData.message_counts.reminder,
        tasks: contentMetricsData.form_counts.task,
        videos: contentMetricsData.document_counts.video,
      },
      created: latestReportData.end_datetime,
      invitations: {
        completed: dashboardUserMetricsData.registered_count,
        pending: dashboardUserMetricsData.total_count - dashboardUserMetricsData.registered_count,
        sent: dashboardUserMetricsData.total_count,
        percentage: Math.round(
          (dashboardUserMetricsData.registered_count / dashboardUserMetricsData.total_count) * 100,
        ),
      },
      overview: {
        appUsers: appUserMetricsData.total_count,
        dashboardUsers: (hospitalAdmins || 0) + (professionalUsers || 0),
        totalContent:
          contentMetricsData.document_counts.document +
          contentMetricsData.document_counts.video +
          contentMetricsData.form_counts.form +
          contentMetricsData.form_counts.questionnaire +
          contentMetricsData.form_counts.task +
          contentMetricsData.message_counts.message +
          contentMetricsData.message_counts.alert +
          contentMetricsData.message_counts.reminder,
        // TODO: replace hardcoded numbers with real values when available
        dataRequests: 34,
      },
      pathways: { total: contentMetricsData.pathways_count },
      users: {
        // TODO: remove hardcoded fallback value when concept of countries has been introduced to the solution
        countries: dashboardUserMetricsData.countries_count || 3,
        hospitals: dashboardUserMetricsData.hospitals_count,
        hospitalAdmins,
        patients: appUserMetricsData.total_count,
        professionalUsers,
      },
    };

    yield put(globalMetricsLoaded(globalMetrics));
  } catch (err) {
    console.error(err);
    yield put(loadMetricsFailed());
  }
}

function* doFetchCountryMetrics({ payload: { countryId } }: IFetchCountryMetrics) {
  try {
    yield put(loadMetrics());

    const { default: data } = yield import('./mockData/country.json');

    yield put(countryMetricsLoaded(countryId, data));
  } catch (err) {
    console.error(err);
    yield put(loadMetricsFailed());
  }
}

function* doFetchHospitalMetrics({ payload: { hospitalId } }: IFetchHospitalMetrics): any {
  try {
    yield put(loadMetrics());
    let [, hospital] = yield select(selectHospital(hospitalId));

    if (!hospital) {
      yield call(doFetchHospitals);
      [, hospital] = yield select(selectHospital(hospitalId));

      if (!hospital) throw new Error('Hospital not found');
    }

    const latestUrl = `${metricsUrlRoot}reports/latest.json`;
    const latestReportResponse = yield call(fetch, latestUrl);
    const latestReportData = yield call(latestReportResponse.json.bind(latestReportResponse));

    const appUserMetricsUrl = `${metricsUrlRoot}reports/${latestReportData.date}/organisations/${hospital.slug}/app_users.json`;
    const dashboardMetricsUrl = `${metricsUrlRoot}reports/${latestReportData.date}/organisations/${hospital.slug}/dashboard_users.json`;
    const contentMetricsUrl = `${metricsUrlRoot}reports/${latestReportData.date}/organisations/${hospital.slug}/content.json`;

    const [
      appUserMetricsResponse,
      dashboardUserMetricsResponse,
      contentMetricsResponse,
    ] = yield call(Promise.all.bind(Promise), [
      fetch(appUserMetricsUrl),
      fetch(dashboardMetricsUrl),
      fetch(contentMetricsUrl),
    ]);
    const appUserMetricsData = yield call(appUserMetricsResponse.json.bind(appUserMetricsResponse));
    const dashboardUserMetricsData = yield call(
      dashboardUserMetricsResponse.json.bind(dashboardUserMetricsResponse),
    );
    const contentMetricsData = yield call(contentMetricsResponse.json.bind(contentMetricsResponse));

    const pendingRegistrations =
      appUserMetricsData.total_count - appUserMetricsData.registered_count;
    let percentageCompleted = Math.round(
      (appUserMetricsData.registered_count / appUserMetricsData.total_count) * 100,
    );

    let dashboardPercentageCompleted = Math.round(
      (dashboardUserMetricsData.registered_count / dashboardUserMetricsData.total_count) * 100,
    );

    if (Number.isNaN(percentageCompleted)) percentageCompleted = 0;

    // @ts-ignore
    const { admin, professional } = Object.entries(dashboardUserMetricsData.grouped_by_role).reduce(
      // @ts-ignore
      (userCounts, [role, count]) => {
        if (role.includes('hospital-admin')) return { ...userCounts, admin: count };
        if (role.includes('hospital-user')) return { ...userCounts, professional: count };
        return userCounts;
      },
      { admin: 0, professional: 0 },
    );

    const hospitalMetrics = {
      created: latestReportData.end_datetime,
      content: {
        alerts: contentMetricsData.message_counts.alert,
        documents: contentMetricsData.document_counts.document,
        forms: contentMetricsData.form_counts.form,
        messages: contentMetricsData.message_counts.message,
        questionnaires: contentMetricsData.form_counts.questionnaire,
        reminders: contentMetricsData.message_counts.reminder,
        scheduledAdhocMessages: contentMetricsData.adhoc_message_counts.scheduled,
        sentAdhocMessages: contentMetricsData.adhoc_message_counts.sent,
        tasks: contentMetricsData.form_counts.task,
        videos: contentMetricsData.document_counts.video,
      },
      dashboardUsers: {
        admin,
        professional,
        total: dashboardUserMetricsData.total_count,
        completedRegistration: dashboardUserMetricsData.registered_count,
        invitationsSent: dashboardUserMetricsData.total_count,
        pendingRegistrations:
          dashboardUserMetricsData.total_count - dashboardUserMetricsData.registered_count,
        percentageCompleted: dashboardPercentageCompleted,
      },
      overview: {
        hospitalAdmins: admin,
        professionalUsers: professional,
        pendingRegistrations,
        signupPercentage: percentageCompleted,
        totalRegistrations: appUserMetricsData.registered_count,
      },
      pathways: {
        total: contentMetricsData.pathways_count,
        rules: contentMetricsData.rules_count,
      },
      users: {
        completedRegistration: appUserMetricsData.registered_count,
        invitationsSent: appUserMetricsData.total_count,
        pendingRegistrations,
        percentageCompleted,
      },
    };

    yield put(hospitalMetricsLoaded(hospitalId, hospitalMetrics));
  } catch (err) {
    console.error(err);
    yield put(loadMetricsFailed());
  }
}

function* doFetchPathwayMetrics(): any {
  try {
    yield put(loadMetrics());

    const permissions = yield select(selectPermissionsForUser);

    const latestUrl = `${metricsUrlRoot}reports/latest.json`;
    const latestReportResponse = yield call(fetch, latestUrl);
    const latestReportData = yield call(latestReportResponse.json.bind(latestReportResponse));

    let pathwayMetricsData: IRawPathwayMetrics;

    if (permissions.includes(Permissions.ViewHospitalMetrics)) {
      const dashboardUser = yield select(selectCurrentDashboardUser);
      const pathwayMetricsUrl = `${metricsUrlRoot}reports/${latestReportData.date}/organisations/${dashboardUser.profile.hospitalSlug}/pathways.json`;
      const pathwayMetricsResponse = yield call(fetch.bind(window), pathwayMetricsUrl);
      pathwayMetricsData = yield call(pathwayMetricsResponse.json.bind(pathwayMetricsResponse));
    } else {
      const pathwayMetricsUrl = `${metricsUrlRoot}reports/${latestReportData.date}/global/pathways.json`;
      const pathwayMetricsResponse = yield call(fetch.bind(window), pathwayMetricsUrl);
      pathwayMetricsData = yield call(pathwayMetricsResponse.json.bind(pathwayMetricsResponse));
    }

    const { byId, list } = pathwayMetricsData.pathways.reduce(
      (pathways, pathway) => {
        const totalPatients = Object.values(pathway.metrics.stages_app_user_counts).reduce(
          (total: number, stage: IMetrics) => total + (stage.num_app_users || 0),
          0,
        );

        const totalContent =
          (pathway.metrics.content_counts[MESSAGE_ALERT] || 0) +
          (pathway.metrics.content_counts[FEATURE_DOCUMENT] || 0) +
          (pathway.metrics.content_counts[FEATURE_FORM] || 0) +
          (pathway.metrics.index_events_counts.total || 0) +
          (pathway.metrics.content_counts[MESSAGE] || 0) +
          (pathway.metrics.content_counts[FEATURE_QUESTIONNAIRE] || 0) +
          (pathway.metrics.content_counts[MESSAGE_REMINDER] || 0) +
          (pathway.metrics.content_counts[FEATURE_TASK] || 0) +
          (pathway.metrics.content_counts[FEATURE_VIDEO] || 0);

        return {
          byId: {
            ...pathways.byId,
            [`${pathway.pathway_id}`]: {
              metadata: pathway.metadata,
              name: pathway.name,
              organisationId: pathway.organisation_id,
              ownerId: pathway.owner_id,
              ownerName: pathway.owner_name,
              pathwayId: pathway.pathway_id,
              isActive: pathway.is_active,
              content: {
                alerts: pathway.metrics.content_counts[MESSAGE_ALERT] || 0,
                documents: pathway.metrics.content_counts[FEATURE_DOCUMENT] || 0,
                forms: pathway.metrics.content_counts[FEATURE_FORM] || 0,
                indexEvents: pathway.metrics.index_events_counts.total || 0,
                messages: pathway.metrics.content_counts[MESSAGE] || 0,
                questionnaires: pathway.metrics.content_counts[FEATURE_QUESTIONNAIRE] || 0,
                reminders: pathway.metrics.content_counts[MESSAGE_REMINDER] || 0,
                tasks: pathway.metrics.content_counts[FEATURE_TASK] || 0,
                videos: pathway.metrics.content_counts[FEATURE_VIDEO] || 0,
              },
              overview: {
                totalPatients,
                totalContent,
              },
              users: {
                ...Object.entries(pathway.metrics.stages_app_user_counts).reduce(
                  (stageMetrics, [stageSlug, metrics]) => {
                    return {
                      ...stageMetrics,
                      [stageSlug]: {
                        isAdhoc: metrics.is_adhoc,
                        isDeleted: metrics.is_deleted,
                        name: metrics.name,
                        numAppUsers: metrics.num_app_users,
                        number: metrics.number,
                      },
                    };
                  },
                  {} as { [stageSlug: string]: { [key: string]: any } },
                ),
              },
            },
          },
          list: [...pathways.list, pathway.pathway_id],
        };
      },
      { byId: {}, list: [] } as { byId: { [key: string]: IMetrics }; list: number[] },
    );

    const metrics: IPathwayMetricsState = {
      byId,
      created: latestReportData.end_datetime,
      list,
    };

    yield put(pathwayMetricsLoaded(metrics));
  } catch (err) {
    console.error(err);
    yield put(loadMetricsFailed());
  }
}
