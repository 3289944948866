import React from 'react';
import { bool, string } from 'prop-types';
import { Icon, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLanguage } from '@redux/core/reducers';
import './style.less';

function LanguageIcon({ language, showText }) {
  const languageDetails = useSelector(selectLanguage(language.toLowerCase()));
  const { t } = useTranslation();

  if (!language || !languageDetails) return null;
  const { name, icon } = languageDetails;

  return (
    <Tooltip placement="bottom" title={t(name)}>
      <div className="language-icon-wrapper">
        <Icon
          className="language-icon"
          component={
            icon ? icon : () => <span className="missing-language-icon">{languageDetails.key}</span>
          }
        />
        {showText && <span className="language-icon-text">{t(name)}</span>}
      </div>
    </Tooltip>
  );
}

LanguageIcon.propTypes = {
  language: string,
  showText: bool,
};

LanguageIcon.defaultProps = {
  language: 'en',
  showText: false,
};

export default LanguageIcon;
