import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Errors from './Errors';
import Login from './Login';
// import Onboarding from './Onboarding';
import Registration from './Registration';
import PasswordRecovery from './PasswordRecovery';

import './style.less';

export default () => (
  <Switch>
    <Route path="/auth" exact render={() => <Redirect to="/auth/login" />} />
    <Route path="/auth/error" component={Errors} />
    <Route path="/auth/login" component={Login} />
    {/* <Route path="/auth/onboarding" component={Onboarding} /> */}
    <Route path="/auth/registration" component={Registration} />
    <Route path="/auth/recovery" component={PasswordRecovery} />
  </Switch>
);
