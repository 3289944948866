import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPathwayWithRules } from '@pathways/redux/pathways/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { fetchRules } from '@pathways/redux/rules/actions';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import Card from './Card';
import './style.less';

function IndexEventTriggeredRules({ pathwayId }) {
  const dispatch = useDispatch();
  const [loading, pathway] = useSelector(selectPathwayWithRules(pathwayId));

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchIndexEvents());
    dispatch(fetchRules());
  }, [dispatch]);

  return <Card loading={loading} pathway={pathway} />;
}

export default IndexEventTriggeredRules;
