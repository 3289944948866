import { IState } from '@redux/reducer';

export const selectGlobalMetrics = (state: IState) => [
  state.reviewHub.loading,
  state.reviewHub.global,
];

export const selectCountryMetrics = (id: number) => (state: IState) => [
  state.reviewHub.loading,
  state.reviewHub.country[`${id}`],
];

export const selectHospitalMetrics = (id: number) => (state: IState) => [
  state.reviewHub.loading,
  state.reviewHub.hospital[`${id}`],
];

export const selectPathwayMetrics = (id: number) => (state: IState) => [
  state.reviewHub.loading,
  state.reviewHub.pathways.byId[`${id}`]
    ? { ...state.reviewHub.pathways.byId[`${id}`], created: state.reviewHub.pathways.created }
    : undefined,
];

export const selectAllPathwayMetrics = (state: IState) => [
  state.reviewHub.loading,
  state.reviewHub.pathways.list.map(id => state.reviewHub.pathways.byId[id]),
];
