import React from 'react';
import { arrayOf, bool, shape } from 'prop-types';
import { Table } from 'antd';
import './style.less';

function PathwayStagesTableCard({ columns, dataSource, loading }) {
  return (
    <Table
      bordered
      className="pathway-stages-table"
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      pagination={false}
      rowKey={record => record.id || record.key}
      scroll={{ x: true }}
    />
  );
}

PathwayStagesTableCard.propTypes = {
  columns: arrayOf(shape({})),
  dataSource: arrayOf(shape({})),
  loading: bool,
};

PathwayStagesTableCard.defaultProps = {
  columns: [],
  dataSource: [],
  loading: false,
};

export default PathwayStagesTableCard;
