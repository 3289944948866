import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HeaderButton from '@components/HeaderButton';
import { Column } from '@cards/Card';
import { usePageActions, useTitleUpdate } from '@hooks';
import HospitalListCard from '@cards/Hospitals/HospitalList';
import { setCurrentScreen, screens } from '../../../analytics';

export default ({ history, match }) => {
  useEffect(() => {
    setCurrentScreen(screens.ADMINISTRATION_HOSPITALS_LIST);
  }, []);

  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t(`hospitals:HospitalList.header`),
    actions: [
      <HeaderButton
        tooltip={t('hospitals:HospitalList.new')}
        onClick={() => history.push(`${match.path}/new`)}
      >
        {t('hospitals:HospitalList.new')}
      </HeaderButton>,
    ],
  });

  useTitleUpdate(setTitle, `hospitals:HospitalList.header`);
  document.title = t(`hospitals:HospitalList.header`);

  return (
    <Column>
      <HospitalListCard match={match} />
    </Column>
  );
};
