import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Card, Button, Alert } from 'antd';
import OnboardingContainer from '@components/OnboardingContainer';
import './style.less';

const InvalidProfile = () => {
  const { t } = useTranslation();
  let history = useHistory();
  const ref = window.location.search.replace('?ref=', '');

  return (
    <OnboardingContainer>
      <Card
        title={<h1>{t('login:title')}</h1>}
        actions={[
          <div className="custom-alignment">
            <Button type="primary" onClick={() => history.push('/auth/login')}>
              {t('common:buttons.login')}
            </Button>
          </div>,
        ]}
        className="login-card three-btn"
      >
        <Alert
          message={t('login:InvalidProfile.alert.message')}
          description={t('login:InvalidProfile.alert.description', { ref })}
          type="error"
          showIcon
        />
      </Card>
    </OnboardingContainer>
  );
};

InvalidProfile.propTypes = {
  form: PropTypes.object.isRequired,
};

export { InvalidProfile };
export default InvalidProfile;
