import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import {
  createLanguageFilters,
  selectSourceIcon,
  selectSourceLegend,
} from '@utils/supportedLanguages';

export default t => [
  {
    title: t('cards:ProcedurePathwayList.columns.language.title'),
    dataIndex: 'metadata',
    key: 'language',
    render: ({ language }) => (language ? <LanguageIcon language={language} /> : null),
    width: 15,
    sorter: (a, b) =>
      a.metadata.language && b.metadata.language
        ? a.metadata.language.localeCompare(b.metadata.language)
        : -1,
    filters: createLanguageFilters(t),
    onFilter: (value, record) => {
      return record.metadata.language === value;
    },
  },
  {
    title: t('cards:ProcedurePathwayList.columns.source.title'),
    dataIndex: 'source',
    key: 'source',
    render: source => (
      <Tooltip placement="bottom" title={selectSourceLegend(source, t)}>
        <FontAwesomeIcon size="lg" icon={selectSourceIcon(source)} />
      </Tooltip>
    ),
    width: 15,
    sorter: (a, b) => {
      if (a.source > b.source) return -1;
      if (a.source < b.source) return 1;
      return 0;
    },
    filters: [
      {
        text: t('common:source.global'),
        value: 'global',
      },
      {
        text: t('common:source.country'),
        value: 'country',
      },
      {
        text: t('common:source.hospital'),
        value: 'hospital',
      },
    ],
    onFilter: (value, record) => record.source === value,
  },
  {
    title: t('cards:ProcedurePathwayList.columns.title'),
    dataIndex: 'name',
    key: 'title',
    width: 50,
    ellipsis: true,
    sorter: (a, b) => {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    },
  },
  // {
  //   title: t('cards:ProcedurePathwayList.columns.description'),
  //   dataIndex: 'description',
  //   key: 'description',
  //   ellipsis: true,
  //   sorter: (a, b) => {
  //     if (a.description > b.description) return -1;
  //     if (a.description < b.description) return 1;
  //     return 0;
  //   },
  // },
  {
    title: t('cards:ProcedurePathwayList.columns.published.title'),
    dataIndex: 'isActive',
    fixed: 'right',
    key: 'isActive',
    render: isActive => <PublishedStatusIcon published={isActive} />,
    width: 20,
    sorter: ({ isActive: a }, { isActive: b }) => {
      if (a === b) return 0;
      if (a < b) return 1;
      if (a > b) return -1;
    },
    filters: [
      {
        text: t('cards:ProcedurePathwayList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:ProcedurePathwayList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.isActive === value,
  },
];
