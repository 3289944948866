import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { fetchHospitals, updateHospital } from '@redux/hospitals/actions';
import { selectHospital, selectHospitalUpdating } from '@redux/hospitals/reducers';
import { Card } from '@cards/Card';
import i18n from '../../../i18n';

import './style.less';

const detailsLayout = {
  labelCol: {
    span: 6,
  },
  layout: 'vertical',
  wrapperCol: {
    span: 18,
  },
};

const validateUrl = (_, value, callback) => {
  if (value && !value.startsWith('http://') && !value.startsWith('https://')) {
    callback(i18n.t('cards:HospitalDetailForm.socialForm.linkFormatError'));
  }
  callback();
};

const HospitalDetailForm = ({ id, form: { getFieldDecorator, validateFields } }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const [loading, hospital] = useSelector(selectHospital(id));
  const updating = useSelector(selectHospitalUpdating);

  const initialValues = useMemo(() => {
    if (hospital) {
      return {
        country: hospital.country,
        contactDetails: {
          name: hospital.contactDetails.name,
          email: hospital.contactDetails.email,
          openHours: hospital.contactDetails.openHours,
          phone: hospital.contactDetails.phone,
          address: hospital.contactDetails.address,
          addressSecondLine: hospital.contactDetails.addressSecondLine,
          type: hospital.contactDetails.type,
        },
        socialMedia:
          hospital.socialMedia && Array.isArray(hospital.socialMedia)
            ? hospital.socialMedia.map(social => ({ ...social }))
            : [],
      };
    }
    return { country: undefined, contactDetails: {}, socialMedia: [] };
  }, [hospital]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          const socialMedia = [
            ...(values['first-social-type'] && values['first-social-link']
              ? [
                  {
                    type: values['first-social-type'],
                    link: values['first-social-link'],
                  },
                ]
              : []),
            ...(values['second-social-type'] && values['second-social-link']
              ? [
                  {
                    type: values['second-social-type'],
                    link: values['second-social-link'],
                  },
                ]
              : []),
            ...(values['third-social-type'] && values['third-social-link']
              ? [
                  {
                    type: values['third-social-type'],
                    link: values['third-social-link'],
                  },
                ]
              : []),
          ];

          const editedHospital = {
            ...hospital,
            country: values.country,
            contactDetails: {
              ...hospital.contactDetails,
              openHours: values.hours,
              phone: values.phone,
              address: values.address,
              addressSecondLine: values.secondLineAddress,
              type: values.type,
            },
            socialMedia,
          };

          dispatch(updateHospital(editedHospital));
        }
      });
    },
    [validateFields, hospital, dispatch],
  );

  return (
    <>
      <Card.Half
        className="hospital-details-form-card"
        loading={loading}
        title={t('cards:HospitalDetailForm.detailsTitle')}
        onSubmit={onSubmit}
      >
        <Form {...detailsLayout}>
          <Form.Item label={t('cards:HospitalDetailForm.detailsForm.labels.openHours')}>
            {getFieldDecorator('hours', {
              rules: [
                {
                  required: true,
                  message: t('cards:HospitalDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.openHours,
            })(
              <Input
                placeholder={t('cards:HospitalDetailForm.detailsForm.placeholders.openHours')}
              />,
            )}
          </Form.Item>
          <Form.Item label={t('cards:HospitalDetailForm.detailsForm.labels.phone')}>
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: t('cards:HospitalDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.phone,
            })(
              <Input placeholder={t('cards:HospitalDetailForm.detailsForm.placeholders.phone')} />,
            )}
          </Form.Item>
          <Form.Item label={t('cards:HospitalDetailForm.detailsForm.labels.address')}>
            {getFieldDecorator('address', {
              rules: [
                {
                  required: true,
                  message: t('cards:HospitalDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.address,
            })(
              <Input
                placeholder={t('cards:HospitalDetailForm.detailsForm.placeholders.address')}
              />,
            )}
          </Form.Item>
          <Form.Item label={t('cards:HospitalDetailForm.detailsForm.labels.secondLineAddress')}>
            {getFieldDecorator('secondLineAddress', {
              rules: [
                {
                  required: true,
                  message: t('cards:HospitalDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.addressSecondLine,
            })(
              <Input
                placeholder={t(
                  'cards:HospitalDetailForm.detailsForm.placeholders.secondLineAddress',
                )}
              />,
            )}
          </Form.Item>
          {/* <Form.Item label={t('cards:HospitalDetailForm.detailsForm.labels.country')}>
            {getFieldDecorator('country', {
              rules: [
                {
                  required: true,
                  message: t('cards:HospitalDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.country,
            })(
              <Select placeholder={t('cards:HospitalDetailForm.detailsForm.placeholders.country')}>
                <Select.Option value="germany">
                  {t('cards:HospitalDetailForm.detailsForm.countries.germany')}
                </Select.Option>
                <Select.Option value="spain">
                  {t('cards:HospitalDetailForm.detailsForm.countries.spain')}
                </Select.Option>
              </Select>,
            )}
          </Form.Item> */}
          <Form.Item label={t('cards:HospitalDetailForm.detailsForm.labels.type')}>
            {getFieldDecorator('type', {
              rules: [
                {
                  required: true,
                  message: t('cards:HospitalDetailForm.detailsForm.required'),
                },
              ],
              initialValue: initialValues.contactDetails.type,
            })(
              <Select placeholder={t('cards:HospitalDetailForm.detailsForm.placeholders.type')}>
                <Select.Option value="public">
                  {t('cards:HospitalDetailForm.detailsForm.hospitalTypes.public')}
                </Select.Option>
                <Select.Option value="private">
                  {t('cards:HospitalDetailForm.detailsForm.hospitalTypes.private')}
                </Select.Option>
              </Select>,
            )}
          </Form.Item>
          <h3>{t('cards:HospitalDetailForm.socialTitle')}</h3>
          <div className="form-column-labels">
            <Form.Item label={t('cards:HospitalDetailForm.socialForm.order')}>
              <div className="form-field-labels">
                <div>{t('cards:HospitalDetailForm.socialForm.type')}</div>
                <div>{t('cards:HospitalDetailForm.socialForm.link')}</div>
              </div>
            </Form.Item>
          </div>

          <Form.Item label="1">
            <div className="social-field">
              <Form.Item>
                {getFieldDecorator('first-social-type', {
                  rules: [],
                  initialValue: initialValues.socialMedia[0]
                    ? initialValues.socialMedia[0].type
                    : undefined,
                })(
                  <Select
                    placeholder={t('cards:HospitalDetailForm.socialForm.typePlaceholder')}
                    allowClear
                  >
                    <Select.Option value="website">
                      {t('cards:HospitalDetailForm.socialForm.website')}
                    </Select.Option>
                    <Select.Option value="facebook">
                      {t('cards:HospitalDetailForm.socialForm.facebook')}
                    </Select.Option>
                    <Select.Option value="twitter">
                      {t('cards:HospitalDetailForm.socialForm.twitter')}
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('first-social-link', {
                  rules: [{ validator: validateUrl }],
                  initialValue: initialValues.socialMedia[0]
                    ? initialValues.socialMedia[0].link
                    : undefined,
                })(
                  <Input placeholder={t('cards:HospitalDetailForm.socialForm.linkPlaceholder')} />,
                )}
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item label="2">
            <div className="social-field">
              <Form.Item>
                {getFieldDecorator('second-social-type', {
                  rules: [],
                  initialValue: initialValues.socialMedia[1]
                    ? initialValues.socialMedia[1].type
                    : undefined,
                })(
                  <Select
                    placeholder={t('cards:HospitalDetailForm.socialForm.typePlaceholder')}
                    allowClear
                  >
                    <Select.Option value="website">
                      {t('cards:HospitalDetailForm.socialForm.website')}
                    </Select.Option>
                    <Select.Option value="facebook">
                      {t('cards:HospitalDetailForm.socialForm.facebook')}
                    </Select.Option>
                    <Select.Option value="twitter">
                      {t('cards:HospitalDetailForm.socialForm.twitter')}
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('second-social-link', {
                  rules: [{ validator: validateUrl }],
                  initialValue: initialValues.socialMedia[1]
                    ? initialValues.socialMedia[1].link
                    : undefined,
                })(
                  <Input placeholder={t('cards:HospitalDetailForm.socialForm.linkPlaceholder')} />,
                )}
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item label="3">
            <div className="social-field">
              <Form.Item>
                {getFieldDecorator('third-social-type', {
                  rules: [],
                  initialValue: initialValues.socialMedia[2]
                    ? initialValues.socialMedia[2].type
                    : undefined,
                })(
                  <Select
                    placeholder={t('cards:HospitalDetailForm.socialForm.typePlaceholder')}
                    allowClear
                  >
                    <Select.Option value="website">
                      {t('cards:HospitalDetailForm.socialForm.website')}
                    </Select.Option>
                    <Select.Option value="facebook">
                      {t('cards:HospitalDetailForm.socialForm.facebook')}
                    </Select.Option>
                    <Select.Option value="twitter">
                      {t('cards:HospitalDetailForm.socialForm.twitter')}
                    </Select.Option>
                  </Select>,
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator('third-social-link', {
                  rules: [{ validator: validateUrl }],
                  initialValue: initialValues.socialMedia[2]
                    ? initialValues.socialMedia[2].link
                    : undefined,
                })(
                  <Input placeholder={t('cards:HospitalDetailForm.socialForm.linkPlaceholder')} />,
                )}
              </Form.Item>
            </div>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={updating}>
              {t('common:buttons.save')}
            </Button>
          </Form.Item>
        </Form>
      </Card.Half>
    </>
  );
};

const WrappedHospitalDetailForm = Form.create()(HospitalDetailForm);

export default WrappedHospitalDetailForm;
