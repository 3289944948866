import { put, call, takeEvery, takeLatest, getContext, select } from 'redux-saga/effects';
import { message } from 'antd';
import { IMessagingClient } from '@liquid-state/messaging-client';
import createPipClient, { PIPObject } from '@api/pipClient';
import { appToken } from 'settings';
import i18n from '../../i18n';
import {
  deleteScheduledMessagesSuccess,
  deleteScheduledMessagesFailed,
  loadCannedMessages,
  cannedMessagesLoaded,
  ICreateContentMessage,
  IUpdateContentMessage,
  createContentMessageSuccess,
  createContentMessageFailed,
  updateContentMessageSuccess,
  updateContentMessageFailed,
  deleteContentMessagesSuccess,
  deleteContentMessagesFailed,
  loadMessages,
  messagesLoaded,
  loadMessagesFailed,
  createScheduledMessageFailed,
  createScheduledMessageSuccess,
  IDeleteScheduledMessages,
  IDeleteScheduledMessage,
  ICreateScheduledMessage,
  IDeleteContentMessages,
  publishContentMessageFailed,
  publishContentMessageSuccess,
  unpublishContentMessageFailed,
  unpublishContentMessageSuccess,
  IPublishContentMessage,
  IUnpublishContentMessage,
} from './actions';
import {
  FETCH_CANNED_MESSAGES,
  LOAD_CANNED_MESSAGES_FAILED,
  CREATE_CONTENT_MESSAGE,
  UPDATE_CONTENT_MESSAGE,
  DELETE_CONTENT_MESSAGES,
  FETCH_MESSAGES,
  DELETE_SCHEDULED_MESSAGES,
  DELETE_SCHEDULED_MESSAGE,
  CREATE_SCHEDULED_MESSAGE,
  IMessage,
  IContentMessage,
} from './types';
import takeFirst from '../takeFirst';
import doCreateMessagingClient from '../doCreateMessagingClient';
import { getBaseObjectTypeForMessages, BASE_MESSAGES_OBJECT_TYPE } from './utils';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { trackDataEvent } from '../../analytics';
import { doDetermineOwnerForContent } from '@redux/doDetermineOwnerIdForContent';

export default function* root() {
  yield takeFirst(FETCH_CANNED_MESSAGES, doFetchCannedMessages);
  yield takeFirst(FETCH_MESSAGES, doFetchMessages);
  yield takeLatest(LOAD_CANNED_MESSAGES_FAILED, doLoadFailed);
  yield takeLatest(CREATE_CONTENT_MESSAGE, doCreateContentMessage);
  yield takeLatest(UPDATE_CONTENT_MESSAGE, doUpdateContentMessage);
  yield takeLatest(DELETE_CONTENT_MESSAGES, doDeleteContentMessages);
  yield takeEvery(DELETE_SCHEDULED_MESSAGES, doDeleteScheduledMessages);
  yield takeEvery(DELETE_SCHEDULED_MESSAGE, doDeleteScheduledMessage);
  yield takeEvery(CREATE_SCHEDULED_MESSAGE, doCreateScheduledMessage);
  yield takeEvery('content-messages/publish', doPublishContentMessage);
  yield takeEvery('content-messages/unpublish', doUnpublishContentMessage);
}

function* doFetchCannedMessages(): any {
  yield put(loadCannedMessages());
  const tokens = yield getContext('tokens');
  const client = yield call(createPipClient, tokens);
  const objectType = yield call(getBaseObjectTypeForMessages);
  const [usersLatestMessages] = yield call(client.getObjectsForType, objectType, 'latest');

  // migrate old master forms list to list based on users role (e.g. hospital or global for super admins)
  if (usersLatestMessages === undefined) {
    const currentDashboardUser = yield select(selectCurrentDashboardUser);
    const [globalLatestForms] = yield call(
      client.getObjectsForType,
      BASE_MESSAGES_OBJECT_TYPE,
      'latest',
    );

    const usersMessages = globalLatestForms.json.filter((message: IContentMessage) =>
      currentDashboardUser.profile.hospitalId
        ? message.metadata?.hospitalId === currentDashboardUser.profile.hospitalId
        : message.source === 'global',
    );

    yield call(client.createObjectType, objectType, appToken);
    yield call(client.createObject, objectType, usersMessages);
    yield put(cannedMessagesLoaded(usersMessages));
    return;
  }

  if (usersLatestMessages && usersLatestMessages.json && Array.isArray(usersLatestMessages.json)) {
    yield put(cannedMessagesLoaded(usersLatestMessages.json));

    return;
  }

  yield put(cannedMessagesLoaded([]));
}

function* doLoadFailed() {
  yield call(message.warning, 'Failed to load messages');
}

function* doCreateContentMessage({
  payload: { message: newMessage, type, messageTranslationKey, path },
}: ICreateContentMessage) {
  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForMessages);

    const [latest]: PIPObject<IContentMessage[]>[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );

    if (latest === undefined) {
      yield call(pipClient.createObjectType, objectType, appToken);
    }

    const newMessages = [newMessage, ...(latest?.json || [])];

    const response = yield call(pipClient.createObject, objectType, newMessages);
    const createdMessage = response.json[0];

    yield put(cannedMessagesLoaded(response.json));

    const history = yield getContext('history');
    const newPath = path.replace('new', createdMessage.id);

    yield call(history.replace, newPath);

    yield call(
      message.success,
      i18n.t(`${messageTranslationKey}:NewContentMessageWizard.successMessage`),
    );
    trackDataEvent('Content Message Created', {
      messageId: createdMessage.id,
      messageTitle: newMessage.title,
      messageType: type,
    });
    yield put(createContentMessageSuccess());
  } catch (err) {
    console.error(err);
    yield call(
      message.warning,
      i18n.t(`${messageTranslationKey}:NewContentMessageWizard.failedMessage`),
    );
    yield put(createContentMessageFailed());
  }
}

function* doUpdateContentMessage({
  payload: { message: updatedMessage, type, messageTranslationKey, id },
}: IUpdateContentMessage) {
  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForMessages);

    const [latest]: PIPObject<IContentMessage[]>[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );

    const updatedMessages = latest.json.map(msg =>
      msg.id === id ? { ...msg, ...updatedMessage } : msg,
    );
    const response = yield call(pipClient.createObject, objectType, updatedMessages);

    yield put(cannedMessagesLoaded(response.json));

    const history = yield getContext('history');
    yield call(history.goBack);

    yield call(
      message.success,
      i18n.t(`${messageTranslationKey}:EditContentMessageWizard.successMessage`),
    );
    trackDataEvent('Content Message Updated', {
      messageId: updatedMessage.id,
      messageTitle: updatedMessage.title,
      messageType: type,
    });
    yield put(updateContentMessageSuccess());
  } catch (err) {
    console.error(err);
    yield call(
      message.warning,
      i18n.t(`${messageTranslationKey}:EditContentMessageWizard.failedMessage`),
    );
    yield put(updateContentMessageFailed());
  }
}

function* doDeleteContentMessages({
  payload: { messageIds, messageTranslationKey },
}: IDeleteContentMessages) {
  try {
    const tokens = yield getContext('tokens');
    const pipClient = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForMessages);

    const [latest]: PIPObject<IContentMessage[]>[] = yield call(
      pipClient.getObjectsForType,
      objectType,
      'latest',
    );
    const updatedMessages = latest.json.filter(msg => !messageIds.includes(msg.id));

    const response = yield call(pipClient.createObject, objectType, updatedMessages);

    yield put(deleteContentMessagesSuccess(response.json));

    yield call(
      message.success,
      i18n.t(`${messageTranslationKey}:DeleteMessage.deleteSuccess`, { count: messageIds.length }),
    );
  } catch (err) {
    console.error(err);
    yield call(
      message.warning,
      i18n.t(`${messageTranslationKey}:DeleteMessage.deleteFailed`, { count: messageIds.length }),
    );
    yield put(deleteContentMessagesFailed());
  }
}

function ensureMessageData(message: IMessage) {
  return {
    ...message,
    title: message.title || '',
    metadata: { language: 'en', type: 'message', ...(message.metadata || {}) },
  };
}

function* doFetchMessages(): any {
  yield put(loadMessages());
  try {
    const client: IMessagingClient = yield call(doCreateMessagingClient);
    const ownerId = yield call(doDetermineOwnerForContent);
    let messages: IMessage[] = [];
    let page = 1;

    while (true) {
      const { nextPage, result } = yield call(client.listMessages, page, ownerId);
      messages = [...messages, ...result];
      if (!nextPage) {
        break;
      }
      page = nextPage;
    }

    // backend ISO date string has T omitted
    const nowISO = new Date().toISOString().replace('T', ' ');

    const { scheduled, sent } = messages.reduce(
      (
        prev: { scheduled: IMessage[]; sent: IMessage[] },
        message: IMessage,
      ): { scheduled: IMessage[]; sent: IMessage[] } => {
        if (message.isDeleted) return prev;

        if (message.lastSentDatetime) {
          return {
            scheduled: [
              ...prev.scheduled,
              ...(message.nextSendDatetime > nowISO ? [ensureMessageData(message)] : []),
            ],
            sent: [...prev.sent, ensureMessageData(message)],
          };
        }

        return { ...prev, scheduled: [...prev.scheduled, ensureMessageData(message)] };
      },
      { scheduled: [], sent: [] },
    );

    yield put(messagesLoaded(sent, scheduled));
  } catch (err) {
    console.error(err);
    yield put(loadMessagesFailed());
    yield call(message.error, i18n.t('messages:MessagesList.loadMessagesNetworkError'));
  }
}

function* doDeleteScheduledMessages({ payload: { messageIds } }: IDeleteScheduledMessages) {
  try {
    const client = yield call(doCreateMessagingClient);

    for (const id of messageIds) {
      yield call(client.deleteMessage, id);
    }

    yield put(deleteScheduledMessagesSuccess(messageIds));
    yield call(message.success, i18n.t('messages:Scheduled.deleteSuccess'));
  } catch (err) {
    console.error(err);
    yield put(deleteScheduledMessagesFailed());
    yield call(message.error, i18n.t('messages:Scheduled.deleteFailure'));
  }
}

function* doDeleteScheduledMessage({ payload: { messageId } }: IDeleteScheduledMessage) {
  const history = yield getContext('history');
  try {
    const client = yield call(doCreateMessagingClient);

    yield call(client.deleteMessage, messageId);

    yield put(deleteScheduledMessagesSuccess([messageId]));
    yield call(history.goBack);
    yield call(message.success, i18n.t('messages:Scheduled.deleteSuccess'));
  } catch (err) {
    console.error(err);
    yield put(deleteScheduledMessagesFailed());
    yield call(message.error, i18n.t('messages:Scheduled.deleteFailure'));
  }
}

function* doCreateScheduledMessage({ payload: { messageData } }: ICreateScheduledMessage): any {
  const history = yield getContext('history');

  try {
    const client = yield call(doCreateMessagingClient);
    const ownerId = yield call(doDetermineOwnerForContent);
    const newMessage = yield call(client.createMessage, { ...messageData, ownerId });

    yield put(createScheduledMessageSuccess(newMessage));
    yield call(history.push, '/messages/scheduled');
    yield call(message.success, i18n.t('messages:Scheduled.createSuccess'));
  } catch (err) {
    console.error(err);
    yield put(createScheduledMessageFailed());
    yield call(message.error, i18n.t('messages:Scheduled.createFailure'));
  }
}

function* doPublishContentMessage({
  payload: { id, translationKey },
}: IPublishContentMessage): any {
  try {
    const tokens = yield getContext('tokens');
    const client = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForMessages);
    const [usersLatestMessages]: PIPObject<IContentMessage[]>[] = yield call(
      client.getObjectsForType,
      objectType,
      'latest',
    );

    const response = yield call(
      client.createObject,
      objectType,
      usersLatestMessages.json.map(message =>
        message.id === id ? { ...message, published: true } : message,
      ),
    );

    yield put(cannedMessagesLoaded(response.json));
    yield put(publishContentMessageSuccess());
  } catch (err) {
    console.error(err);
    yield put(publishContentMessageFailed());
  }
}

function* doUnpublishContentMessage({
  payload: { id, translationKey },
}: IUnpublishContentMessage): any {
  try {
    const tokens = yield getContext('tokens');
    const client = yield call(createPipClient, tokens);
    const objectType = yield call(getBaseObjectTypeForMessages);
    const [usersLatestMessages]: PIPObject<IContentMessage[]>[] = yield call(
      client.getObjectsForType,
      objectType,
      'latest',
    );

    const response = yield call(
      client.createObject,
      objectType,
      usersLatestMessages.json.map(message =>
        message.id === id ? { ...message, published: false } : message,
      ),
    );

    yield put(cannedMessagesLoaded(response.json));
    yield put(unpublishContentMessageSuccess());
  } catch (err) {
    console.error(err);
    yield put(unpublishContentMessageFailed());
  }
}
