import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import {
  checkingSession,
  hasValidSession,
  hasValidProfile,
  selectCurrentDashboardUser,
} from '@redux/login/reducer';
import { logoGreen as logo } from '@assets';
import { logout } from '@redux/login/actions';

export default ({ children }) => {
  const loading = useSelector(checkingSession);
  const authenticated = useSelector(hasValidSession);
  const validProfile = useSelector(hasValidProfile);
  const currentUser = useSelector(selectCurrentDashboardUser);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  if (loading) {
    return (
      <div className="loader">
        <img src={logo} alt="Loading" />
      </div>
    );
  }

  if (!validProfile) {
    const to = `/auth/error/invalid-profile?ref=${currentUser.id}`;
    dispatch(logout());
    return <Redirect to={to} />;
  }

  // Authenticated users go to /
  if (authenticated && pathname.startsWith('/auth')) {
    return <Redirect to="/" />;
  }
  // Unauthenticated users can only see /auth
  if (authenticated || pathname.startsWith('/auth')) {
    return children;
  }

  // Unauthenticated users go to /auth
  return <Redirect to="/auth" />;
};
