import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import { Column } from '@cards/Card';
import AppUserListCard from '@cards/AppUsers/List';
import { usePageActions, useTitleUpdate } from '@hooks';
import HeaderButton from '@components/HeaderButton';

import { setCurrentScreen, screens, trackAndPushHistory } from '../../analytics';

export default ({ history }) => {
  useEffect(() => {
    setCurrentScreen(screens.APP_USERS_LIST);
  }, []);
  const permissions = useHasPermissions();
  const { t } = useTranslation();
  const { setTitle } = usePageActions({
    title: t('patients:IndividualList.header'),
    actions: [
      permissions[Permissions.ManagePatients] ? (
        <HeaderButton
          key="new-patient-button"
          tooltip={t('patients:IndividualList.newPatientTooltip')}
          onClick={() => trackAndPushHistory(history, '/patients/individuals/new', 'New Patient')}
        >
          {t('patients:IndividualList.newPatient')}
        </HeaderButton>
      ) : null,
    ],
  });
  useTitleUpdate(setTitle, 'patients:IndividualList.header');

  document.title = t('patients:IndividualList.header');

  return (
    <Column>
      <AppUserListCard />
    </Column>
  );
};
