import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Tooltip, Button } from 'antd';
import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardUserTypeIcon from '@components/DashboardUserTypeIcon';
import { userTypesWithLabel, APP_USER } from '@constants';
import { selectHospital } from '@redux/hospitals/reducers';

function HospitalName({ item, hospitals }) {
  const dashboardUser = useSelector(state =>
    Object.values(state.dashboardUsers.byId).find(user => {
      if (user.uuid === item.userId) return true;
      return false;
    }),
  );
  const [, dashboardUserHospital] = useSelector(selectHospital(dashboardUser?.hospitalId));

  if (dashboardUserHospital) {
    return dashboardUserHospital.name;
  }

  // determine hospital for app users
  const hospitalHistoryItem = item.history
    ? item.history.find(
        h => h.data && h.data.previousAssignee.type === 'hospital' && h.data.previousAssignee.id,
      )
    : undefined;

  if (hospitalHistoryItem) {
    return hospitals.find(h => h.uuid === hospitalHistoryItem.data.previousAssignee.id).name;
  }

  return '';
}

export default (t, action, appUsers, dashboardUsers, currentUsersHospital, hospitals) => [
  {
    title: t('cards:RequestList.columns.date'),
    dataIndex: 'created',
    key: 'created',
    sorter: (a, b) => a.created.localeCompare(b.created),
    render: created => moment(created).format('L'),
    width: 160,
    defaultSortOrder: 'descend',
  },
  {
    title: t('cards:RequestList.columns.userType'),
    key: 'userType',
    render: item => {
      if (item.userType === APP_USER) {
        return (
          <Tooltip title={t('cards:RequestList.userTypes.appUser')}>
            <FontAwesomeIcon icon={faUser} />
          </Tooltip>
        );
      }

      return <DashboardUserTypeIcon userType={item.userType} tooltip label={false} />;
    },
    filters: userTypesWithLabel.map(u => ({
      text: t(u.label),
      value: u.value,
    })),
    onFilter: (value, record) => {
      if (appUsers.find(user => record.userId === user.ids.pip)) {
        return value === 'app-user';
      }
      const dashboardUser = dashboardUsers.find(user => user.uuid === record.userId);
      return value === dashboardUser.userType;
    },
    width: 100,
  },
  {
    title: t('cards:RequestList.columns.requestId'),
    dataIndex: 'uuid',
    key: 'uuid',
    width: 120,
  },
  {
    title: t('cards:RequestList.columns.hospitalName'),
    key: 'name',
    render: item => <HospitalName item={item} hospitals={hospitals} />,
    width: 200,
  },
  {
    title: t('cards:RequestList.columns.hospitalDate'),
    key: 'hospital-date',
    render: item => {
      const hospitalHistoryItem = item.history
        ? item.history.find(
            h =>
              h.data && h.data.previousAssignee.type === 'hospital' && h.data.previousAssignee.id,
          )
        : undefined;

      if (hospitalHistoryItem) {
        return moment(hospitalHistoryItem.data.previousAssignee.timestamp).format('L');
      }

      /* requests for hospital admins and above are being sent directly to super admins
         so defaulting this date to when the request was created */
      return moment(item.created).format('L');
    },
    width: 200,
  },
  ...(action
    ? [
        {
          className: 'action-column',
          title: t('cards:RequestList.columns.action2'),
          key: 'action',
          render: item => {
            const disabled =
              item.history &&
              item.history.find(
                h =>
                  h.action === 'transition' && h.data.previousAssignee.id === currentUsersHospital,
              );

            return (
              <Button
                type="primary"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  action(item);
                }}
                disabled={disabled}
              >
                {t('cards:RequestList.actionButtonSuperAdmin')}
              </Button>
            );
          },
          width: 230,
        },
      ]
    : []),
];
