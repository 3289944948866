import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, arrayOf, string } from 'prop-types';
import { Button, Modal, Input, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchDocuments } from '@redux/documents/actions';
import { fetchForms } from '@redux/forms/actions';
import { useSearch } from '@hooks';
import createColumns from './createColumns';
import { selectContent, filterFunc } from './';

function SelectContentModal({ visible, onCancel, onSubmit, contentTypes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, allContent] = useSelector(selectContent);
  const content = useMemo(() => allContent.filter(c => contentTypes.includes(c.metadata.type)), [
    allContent,
    contentTypes,
  ]);

  const columns = useMemo(() => {
    return createColumns(t, contentTypes);
  }, [t, contentTypes]);

  useEffect(() => {
    if (contentTypes.includes('DOCUMENT') || contentTypes.includes('VIDEO')) {
      dispatch(fetchDocuments());
    }
    if (
      contentTypes.includes('FORM') ||
      contentTypes.includes('QUESTIONNAIRE') ||
      contentTypes.includes('TASK')
    ) {
      dispatch(fetchForms());
    }
  }, [dispatch, contentTypes]);

  // selection modal stuff below
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchResults, searchProps] = useSearch(content, filterFunc);

  const updateRowSelections = useCallback(
    (keys, records) => {
      setSelectedRowKeys(keys);
      setSelectedRows(records);
    },
    [setSelectedRowKeys],
  );

  return (
    <Modal
      afterClose={() => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
      }}
      title={t('common:modals.SelectContent.title')}
      visible={visible}
      onCancel={() => {
        onCancel();
      }}
      width={800}
      footer={[
        <div className="footer-left" key="footer-button-select-all"></div>,
        <div className="footer-right" key="footer-button-add">
          <Button
            disabled={!selectedRowKeys || !selectedRowKeys.length}
            type="primary"
            onClick={() => {
              onSubmit(selectedRowKeys, selectedRows); // preserve order plz
            }}
          >
            {t('common:selectionModal.add')}
          </Button>
        </div>,
      ]}
      className="selection-modal"
    >
      <div className="modal-search">
        <div className="row-key-count">{selectedRowKeys ? selectedRowKeys.length : 0}</div>
        <Input.Search placeholder={t('common:search')} {...searchProps}></Input.Search>
      </div>
      <Table
        columns={columns}
        dataSource={searchResults}
        rowKey={record => record.id || record.uuid}
        loading={loading}
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: updateRowSelections,
        }}
        pagination={{ position: 'bottom' }}
      />
    </Modal>
  );
}

SelectContentModal.propTypes = {
  visible: bool.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  contentTypes: arrayOf(string).isRequired,
};

export default SelectContentModal;
