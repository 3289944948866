import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useHasPermissions } from '@authorisation/hooks';
import NewAppUser from './NewAppUser';
import AppUserList from './AppUserList';
import AppUserDetail from './AppUserDetail';
import { Permissions } from '@authorisation/constants';

import './style.less';

export default ({ match }) => {
  const perms = useHasPermissions();
  return (
    <Switch>
      <Route exact path={match.path} component={AppUserList} />
      {perms[Permissions.ManagePatients] ? (
        <Route exact path={`${match.path}/new`} component={NewAppUser} />
      ) : null}
      <Route path={`${match.path}/:id`} component={AppUserDetail} />
    </Switch>
  );
};
