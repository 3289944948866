import React from 'react';
import { createLanguageFilters } from '@utils/supportedLanguages';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { trimTextToApproximateLength } from '@utils';

export default (t, messageTranslationKey) => [
  {
    className: 'language-column',
    title: t('cards:MessagesList.columns.language.title'),
    dataIndex: 'language',
    key: 'language',
    render: language => <LanguageIcon language={language} />,
    sorter: (a, b) => a.language.localeCompare(b.language),
    filters: createLanguageFilters(t),
    onFilter: (value, record) => record.language === value,
  },
  {
    title: t(`cards:MessagesList.columns.${messageTranslationKey}.title`),
    dataIndex: 'title',
    key: 'title',
    render: title => trimTextToApproximateLength(title, 20),
    sorter: (a, b) => a.title > b.title,
  },
  {
    title: t('cards:MessagesList.columns.description'),
    dataIndex: 'body',
    key: 'body',
    render: description => trimTextToApproximateLength(description),
    sorter: (a, b) => (a.body || '').localeCompare(b.body),
  },
  {
    className: 'published-column',
    title: t('cards:MessagesList.columns.published.title'),
    dataIndex: 'published',
    key: 'published',
    render: published => <PublishedStatusIcon published={published} />,
    sorter: ({ published: a }, { published: b }) => a - b,
    filters: [
      {
        text: t('cards:MessagesList.columns.published.published'),
        value: true,
      },
      {
        text: t('cards:MessagesList.columns.published.unpublished'),
        value: false,
      },
    ],
    onFilter: (value, record) => record.published === value,
  },
];
