import React, { useMemo } from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import useWizard from '@components/Wizard/useWizard';

function ReviewHospitalDetails({ editing }) {
  const { t } = useTranslation();
  const { formData } = useWizard();

  // hacky fix for hospital name changing to previous unedited name.
  // Wizard initialData prop isn't just initial anymore and there are some issues with it overriding things that have been edited.
  const hospitalName = useMemo(() => formData.details.hospitalName, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className="hospital-wizard-card" title={t('hospitals:Wizard.details.titles.details')}>
      <DetailForm>
        <DetailRow label={t('hospitals:Wizard.details.labels.name')} value={hospitalName} />
      </DetailForm>
      {editing ? null : (
        <>
          <h3>{t('hospitals:Wizard.details.titles.admin')}</h3>
          <DetailForm>
            <DetailRow
              label={t('hospitals:Wizard.details.labels.firstName')}
              value={formData.details.firstName}
            />
            <DetailRow
              label={t('hospitals:Wizard.details.labels.lastName')}
              value={formData.details.lastName}
            />
            <DetailRow
              label={t('hospitals:Wizard.details.labels.email')}
              value={formData.details.email}
            />
          </DetailForm>
        </>
      )}
    </Card>
  );
}

ReviewHospitalDetails.propTypes = {
  editing: bool,
};

ReviewHospitalDetails.defaultProps = {
  editing: false,
};

export default ReviewHospitalDetails;
