import cards from '../cards/locales';
import common from '../components/locales/index';
import en from './en.json';
import es from './es.json';
import de from './de.json';
import cs from './cs.json';
import sk from './sk.json';
import ja from './ja.json';

export default {
  en: {
    ...cards.en,
    ...common.en,
    ...en,
  },
  es: {
    ...cards.es,
    ...common.es,
    ...es,
  },
  de: {
    ...cards.de,
    ...common.de,
    ...de,
  },
  cs: {
    ...cards.cs,
    ...common.cs,
    ...cs,
  },
  sk: {
    ...cards.sk,
    ...common.sk,
    ...sk,
  },
  ja: {
    ...cards.ja,
    ...common.ja,
    ...ja,
  },
};
