import React from 'react';
import { bool } from 'prop-types';
import { selectDocuments } from '@redux/documents/reducers';
import { selectForms } from '@redux/forms/reducers';
import { languageFromMetadata, sourceFromMetadata } from '@utils/contentMetadata';
import { documentTypes, formTypes } from '@utils/contentTypes';
import searchDocuments from '@utils/searchDocuments';
import SingleSelect from './SingleSelect';
import MultipleSelect from './MutlipleSelect';
import './style.less';

const defaultContentTypes = [...documentTypes, ...formTypes];

export const filterFunc = (value, comparator, searchTerm) => {
  if (!searchTerm || searchTerm.length < 3) {
    return true;
  }
  if (value.metadata?.type === 'DOCUMENT' || value.metadata?.type === 'VIDEO') {
    return searchDocuments(value, comparator, searchTerm);
  }

  return comparator(value.title || value.name);
};

export function selectContent(state) {
  const selectLanguage = languageFromMetadata(state);
  const selectSource = sourceFromMetadata(state);
  const [documentsLoading, documents] = selectDocuments(state);
  const [formsLoading, forms] = selectForms(state);

  return [
    documentsLoading || formsLoading,
    [
      ...documents.map(document => ({
        ...document,
        metadata: {
          type: document.metadata?.tags?.find(t => t.term === 'VIDEO') ? 'VIDEO' : 'DOCUMENT',
          ...(document.metadata || {}),
          language: selectLanguage(document),
          source: selectSource(document),
        },
      })),
      ...forms.map(form => ({
        ...form,
        id: form.uuid,
        title: form.name,
        metadata: {
          published: form.published,
          ...(form.metadata || {}),
          language: selectLanguage(form),
          source: selectSource(form),
        },
      })),
    ],
  ];
}

function SelectContentModal({ multiSelect, ...props }) {
  if (multiSelect) {
    return <MultipleSelect {...props} />;
  }

  return <SingleSelect {...props} />;
}

SelectContentModal.propTypes = {
  multiSelect: bool,
};

SelectContentModal.defaultProps = {
  multiSelect: false,
  contentTypes: defaultContentTypes,
};

export default SelectContentModal;
