import UISAdminClient from '@liquid-state/uis-client/dist/';
import { call, getContext } from 'redux-saga/effects';
import { appToken, uisApiRoot } from 'settings';

function* doCreateUISAdminClient() {
  const tokenPool = yield getContext('tokens');
  const jwt = yield call(tokenPool.get, 'uis');
  return new UISAdminClient(appToken, jwt, { baseUrl: uisApiRoot });
}

export default doCreateUISAdminClient;
