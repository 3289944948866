import { call, getContext } from 'redux-saga/effects';
import createPathwaysClient from '@api/pathwaysClient';

function* doCreatePathwaysClient() {
  const tokens = yield getContext('tokens');
  const jwt = yield call(tokens.get, 'pathways');
  const pathwaysClient = yield call(createPathwaysClient, jwt);

  return pathwaysClient;
}

export default doCreatePathwaysClient;
