import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { fetchAppUsersPathways } from '@pathways/redux/appUserPathways/actions';
import { selectAppUsers } from '@redux/appUsers/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import createColumns from './createColumns';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import SearchableTable from '@components/SearchableTable';
import './styles.less';

const filterFunc = (value, comparator) => {
  return comparator(`${value.firstName} ${value.lastName}`) || comparator(value.email);
};

const AppUserListCard = ({ showTitle }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchPathways());
    dispatch(fetchAppUsersPathways());
  }, [dispatch]);

  const [loading, appUsers] = useSelector(selectAppUsers);

  const { [Permissions.ViewPatients]: canViewPII } = useHasPermissions(Permissions.ViewPatients);
  const columns = useMemo(() => createColumns(t, appUsers, canViewPII), [t, appUsers, canViewPII]);

  return (
    <SearchableTable
      className="app-user-list-card"
      columns={columns}
      dataSource={appUsers}
      filterFunc={filterFunc}
      hasRoutedPagination
      loading={loading}
      title={showTitle ? <h3>{t('cards:DocumentList.title')}</h3> : null}
      onRowClick={({ id }) => history.push(`/patients/individuals/${id}`)}
    />
  );
};

export default AppUserListCard;
