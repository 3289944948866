import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Tag, Upload, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import UploadButton from './UploadButton';

const supportedExtensions = ['application/pdf'];

function Content() {
  const { t } = useTranslation();
  const { currentStepData, form, setCurrentStepData } = useWizard();
  const [documentList, setDocumentList] = useState(currentStepData.documents || []);
  const [error, setError] = useState(null);

  const validatePDFSelected = useCallback(
    (rule, value, callback) => {
      if (currentStepData?.documents?.length) {
        setError('');
        return callback();
      }

      setError(rule.message);
      return callback(rule.message);
    },
    [currentStepData],
  );

  const onChange = useCallback(
    ({ file, fileList, event }) => {
      setError(null);
      form.resetFields(['file-required']);

      if (supportedExtensions.includes(file.type)) {
        setDocumentList(fileList);
        setCurrentStepData({ ...currentStepData, documents: fileList });
        return;
      } else {
        message.error(t('documents:Wizard.content.unsupportedFileType'));
      }
    },
    [currentStepData, form, setCurrentStepData, t],
  );

  const onClose = useCallback(
    id => {
      setDocumentList(documentList.filter(({ uid }) => uid !== id));
    },
    [documentList],
  );

  return (
    <Card className="document-wizard-content" title={t('documents:Wizard.content.title')}>
      <h4 className="title-help-text">{t('documents:Wizard.content.help')}</h4>
      <Upload
        accept=".pdf"
        disabled={documentList.length > 0}
        fileList={documentList}
        name="avatar"
        listType="picture-card"
        className="document-uploader"
        showUploadList={false}
        onChange={onChange}
      >
        <UploadButton disabled={documentList.length > 0} />
      </Upload>
      {form.getFieldDecorator('file-required', {
        rules: [
          { message: t('documents:Wizard.content.required'), validator: validatePDFSelected },
        ],
      })(<span />)}
      <p className="message-wizard-error">{error}</p>
      <div className="document-upload-list">
        {documentList.map(doc => (
          <Tag closable key={doc.uid} onClose={() => onClose(doc.uid)}>
            <span>
              <FontAwesomeIcon icon={faPaperclip} />
              {doc.name}
            </span>
          </Tag>
        ))}
      </div>
      <div className="upload-help-text">
        <h4>{t('documents:Wizard.content.unlocked')}</h4>
        <h5>{t('documents:Wizard.content.templates.question')}</h5>
        <h5>{t('documents:Wizard.content.templates.download')}</h5>
      </div>
      <a
        download
        href="https://forpatientapp-assets.s3.eu-central-1.amazonaws.com/common/Health+factsheet.docx"
      >
        <Button className="download-tempaltes-btn" icon="download">
          {t('documents:Wizard.content.templates.button')}
        </Button>
      </a>
    </Card>
  );
}

export default Content;
