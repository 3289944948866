import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchHospitalMetrics } from '@reviewHub/redux/actions';
import { selectHospitalMetrics } from '@reviewHub/redux/selectors';
import { ColumnGroup, Column } from '@cards/Card';
import DataRefresh from '@reviewHub/cards/DataRefresh';
import MetricTableCard from '@reviewHub/cards/MetricTable';

function Pathways({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hospitalId } = match.params;
  const [loading, metrics] = useSelector(selectHospitalMetrics(hospitalId));
  const isLoaded = !!metrics;

  useEffect(() => {
    if (!isLoaded) dispatch(fetchHospitalMetrics(hospitalId));
  }, [dispatch, hospitalId, isLoaded]);

  const pathways = [
    {
      metric: t('reviewHub:Global.Pathways.title'),
      value: metrics?.pathways.total,
    },
  ];
  return (
    <ColumnGroup>
      <Column>
        <MetricTableCard
          data={pathways}
          loading={loading}
          title={t('reviewHub:Global.Pathways.title')}
        />
        <DataRefresh data={{ created: metrics?.created }} loading={loading} />
      </Column>
    </ColumnGroup>
  );
}

export default Pathways;
