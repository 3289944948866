import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks/usePageActions';
import { editRule, fetchRules } from '@pathways/redux/rules/actions';
import { selectRule, selectRuleEditing } from '@pathways/redux/rules/reducers';
import { INDEX_EVENT, INDEX_EVENT_DELAY } from '@constants';
import { assignRealWhenType } from '@utils';
import RuleWizard from './RuleWizard';
import './style.less';

function NewRule({ match }) {
  const [initialData, setInitialData] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isEditing = useSelector(selectRuleEditing);
  const [loading, existingRule] = useSelector(selectRule(match.params.id));

  const { setTitle } = usePageActions({
    title: ' ',
  });

  const onCompleted = useCallback(
    formData => {
      const ruleData = Object.entries(formData).reduce((prev, [key, data]) => {
        if (key === 'timing') {
          const wd = data.whenDetail || {};
          return {
            ...prev,
            when: assignRealWhenType(data.when),
            whenDetail: data.days ? { ...wd, days: data.days } : wd,
          };
        }

        if (key === 'details') {
          return {
            ...prev,
            ...data,
            metadata: { language: data.language, source: 'global' },
          };
        }

        return { ...prev, ...data };
      }, {});

      dispatch(editRule(match.params.id, ruleData));
    },
    [dispatch, match],
  );

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  useEffect(() => {
    if (existingRule) {
      setTitle(`${t('common:buttons.edit')} ${existingRule.name}`);
      const {
        name,
        description,
        metadata: { language },
        what,
        whatDetail,
        who,
        whoDetail,
        when,
        whenDetail,
      } = existingRule;
      setInitialData({
        details: {
          name,
          description,
          language,
        },
        content: {
          what,
          whatDetail,
        },
        audience: {
          who,
          whoDetail,
        },
        timing: {
          when: when === INDEX_EVENT && whenDetail.days ? INDEX_EVENT_DELAY : when,
          whenDetail,
          ...(whenDetail && whenDetail.days ? whenDetail : {}),
        },
      });
    }
  }, [existingRule, setTitle, t]);

  return (
    <RuleWizard
      initialData={initialData}
      loading={loading}
      submitting={isEditing}
      onCompleted={onCompleted}
    />
  );
}

export default NewRule;
