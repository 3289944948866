import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Input, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearch } from '@hooks';
import { selectAppUsersWithPathway } from '@pathways/redux/appUserPathways/reducers';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { fetchAppUsersPathways } from '@pathways/redux/appUserPathways/actions';
import { Card } from '@cards/Card';
import { selectAppUsersById } from '@redux/appUsers/reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import './style.less';
import { useRequiresPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';

const filterFunc = (value, comparator) => comparator(value.firstName) || comparator(value.lastName);

const createColumns = t => [
  {
    title: t('common:name'),
    key: 'firstName',
    render: appUser => `${appUser.firstName} ${appUser.lastName}`,
    sorter: (a, b) => `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`,
  },
  {
    title: t('cards:ProcedurePathwayIndividuals.columns.details'),
    key: 'details',
    render: individual => <FontAwesomeIcon icon={faExternalLink} />,
    width: 100,
    align: 'right',
    fixed: 'right',
  },
];

function Individuals({ pathwayId }) {
  useRequiresPermissions(Permissions.ViewPatients);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchAppUsersPathways());
  }, [dispatch]);

  const selectAppUsers = useCallback(selectAppUsersWithPathway(pathwayId), [pathwayId]);

  const [, pathwaysIdsForUsers] = useSelector(selectAppUsers);
  const selectUsersData = useCallback(selectAppUsersById('pathways', pathwaysIdsForUsers), [
    pathwaysIdsForUsers,
  ]);
  const [loading, individualsWithPathway] = useSelector(selectUsersData);
  const [searchResults, searchProps] = useSearch(individualsWithPathway, filterFunc);
  // const [selectedRows, setSelectedRows] = useState([]);
  // const removeIndividual = useCallback(() => {
  //   Modal.confirm({
  //     title: t('cards:DocumentIndividuals.confirmDelete'),
  //     onOk: () => {
  //       // TODO: remove pathway from user action
  //       // dispatch();
  //     },
  //     onCancel: () => {},
  //   });
  // }, [t, /* dispatch, */ selectedRows]);
  const columns = useMemo(() => createColumns(t), [t]);

  return (
    <Card.Half
      className="procedure-pathways-individuals-card"
      // extra={<IconButton icon={faTrashAlt} tooltip="Delete" onClick={removeIndividual} />}
      loading={loading}
      title={t('cards:ProcedurePathwayIndividuals.title')}
    >
      <Input.Search placeholder={t('common:search')} {...searchProps} />
      <Table
        columns={columns}
        dataSource={searchResults}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        onRow={({ uuid }) => ({
          onClick: () => history.push(`/patients/individuals/${uuid}/overview`),
        })}
      />
    </Card.Half>
  );
}

export default Individuals;
