import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import { Table } from 'antd';
import { Card } from '@cards/Card';
import './style.less';

function UserMetrics({ data, loading, title }) {
  const { t } = useTranslation();

  return (
    <Card
      className="user-metrics-overview-card"
      title={title || t('reviewHub:Global.Users.AllUsersMetrics.title')}
    >
      <Table
        columns={[
          {
            dataIndex: 'metric',
            title: t('reviewHub:columns.metric'),
            width: '50%',
          },
          {
            dataIndex: 'value',
            title: t('reviewHub:columns.value'),
          },
        ]}
        dataSource={data}
        loading={loading}
        pagination={false}
      />
    </Card>
  );
}

UserMetrics.propTypes = {
  data: arrayOf(
    shape({
      metric: string,
      value: oneOfType([string, number]),
    }),
  ),
  loading: bool,
  title: string,
};

UserMetrics.defaultProps = {
  data: [],
  loading: false,
  title: null,
};

export default UserMetrics;
