import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { Card } from '@cards/Card';

import './style.less';

const FormDetailCard = ({ form, title, ...props }) => {
  const { t } = useTranslation();

  return (
    <Card.Half className="review-form-details" title={t(title)} {...props}>
      {form === undefined ? (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        <DetailForm>
          <DetailRow label={t('cards:FormDetail.labels.title')} value={form.name} />
          <DetailRow label={t('cards:FormDetail.labels.description')} value={form.description} />
        </DetailForm>
      )}
    </Card.Half>
  );
};

FormDetailCard.propTypes = {
  title: PropTypes.string,
  form: PropTypes.object,
};

FormDetailCard.defaultProps = {
  title: 'cards:FormDetail.title',
  form: undefined,
};

export default FormDetailCard;
