import { call, put, select, take } from 'redux-saga/effects';
import { message } from 'antd';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { Permissions } from '@authorisation/constants';
import i18n from '../../../../i18n';
import { fetchingRules, loadRules } from '../actions';
import doCreatePathwaysClient from '../../../../redux/doCreatePathwaysClient';
import { fetchPathways } from '../../pathways/actions';
import { selectPathways } from '../../pathways/reducers';
import { LOAD_PATHWAYS, IPathway } from '../../pathways/types';
import { mapRawRule } from '../utils';
import { doDetermineOwnerForPathway } from '../../pathways/sagas/utils';
import { IRawRule, IRule } from '../types';

export function* doFetchRules(): any {
  yield put(fetchingRules());
  let rules: IRule[] = [];
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const permissions = yield select(selectPermissionsForUser);
    const ownerId = yield call(doDetermineOwnerForPathway);

    let page = 1;

    while (true) {
      const { next, results }: { next: string; results: IRawRule[] } = yield call(
        pathwaysClient.listRules,
        page,
        ownerId,
      );
      rules = [...rules, ...results.map(rule => mapRawRule(rule))];

      if (!next) break;
      page += 1;
    }

    if (permissions.includes(Permissions.ManagePatients)) {
      let [loading, pathways] = yield select(selectPathways);

      if (loading || pathways.length === 0) {
        yield put(fetchPathways());
        yield take(LOAD_PATHWAYS);
        [loading, pathways] = yield select(selectPathways);
      }

      const addedRuleIds: { [key: string]: boolean } = rules.reduce(
        (addedIds, rule) => ({ ...addedIds, [rule.id]: true }),
        {},
      );

      pathways.forEach((pathway: IPathway) => {
        pathway.indexEvents.forEach(indexEvent => {
          indexEvent.rules.forEach(rule => {
            if (!addedRuleIds[rule.id]) {
              addedRuleIds[rule.id] = true;
              rules.push(rule);
            }
          });
        });
        pathway.stages.forEach(stage => {
          stage.rules.forEach(rule => {
            if (!addedRuleIds[rule.id]) {
              addedRuleIds[rule.id] = true;
              rules.push(rule);
            }
          });
        });
      });
    }

    yield put(loadRules(rules));
  } catch (err) {
    yield call(message.error, i18n.t('cards:ProcedureRuleList.networkError'));
    yield put(loadRules([]));
  }
}
