import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { oneOfType, number, string } from 'prop-types';
import { fetchDocuments, fetchDocumentPublishedStatus } from '../../../redux/documents/actions';
import { selectDocument, selectDocumentPublishedStatus } from '../../../redux/documents/reducers';
import DocumentDetailsCard from './Card';

const DocumentDetails = ({ documentId, title, documentTranslationKey, ...props }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchDocumentPublishedStatus(documentId));
  }, [dispatch, documentId]);

  const [loading, document] = useSelector(selectDocument(documentId));
  const isPublished = useSelector(selectDocumentPublishedStatus(documentId));

  return (
    <DocumentDetailsCard
      document={loading ? document : { ...document, published: isPublished }}
      documentTranslationKey={documentTranslationKey}
      loading={loading}
      title={title}
      {...props}
    />
  );
};

DocumentDetails.propTypes = {
  title: string,
  documentId: oneOfType([number, string]).isRequired,
  documentTranslationKey: string,
};

DocumentDetails.defaultProps = {
  title: 'cards:DocumentDetail.title',
  documentTranslationKey: 'documents',
};

export default DocumentDetails;
