import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchGlobalMetrics } from '@reviewHub/redux/actions';
import { selectGlobalMetrics } from '@reviewHub/redux/selectors';
import { ColumnGroup, Column } from '@cards/Card';
import Overview from '@reviewHub/cards/Overview';
import DataRefresh from '@reviewHub/cards/DataRefresh';
import MetricTableCard from '@reviewHub/cards/MetricTable';

function Users() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, metrics] = useSelector(selectGlobalMetrics);

  useEffect(() => {
    dispatch(fetchGlobalMetrics());
  }, [dispatch]);

  const overview = [
    {
      metric: t('reviewHub:Global.Users.Overview.dashboardUsers'),
      value: metrics?.overview.dashboardUsers,
    },
    {
      metric: t('reviewHub:Global.Users.Overview.appUsers'),
      value: metrics?.overview.appUsers,
    },
    {
      metric: t('reviewHub:Global.Users.Overview.totalContent'),
      value: metrics?.overview.totalContent,
    },
    // {
    //   metric: t('reviewHub:Global.Users.Overview.dataRequests'),
    //   value: metrics?.overview.dataRequests,
    // },
  ];

  const users = [
    {
      metric: t('reviewHub:Global.Users.AllUsersMetrics.data.hospitals'),
      value: metrics?.users.hospitals,
    },
    {
      metric: t('reviewHub:Global.Users.AllUsersMetrics.data.patients'),
      value: metrics?.users.patients,
    },
    {
      metric: t('reviewHub:Global.Users.AllUsersMetrics.data.hospitalAdmins'),
      value: metrics?.users.hospitalAdmins,
    },
    {
      metric: t('reviewHub:Global.Users.AllUsersMetrics.data.professionalUsers'),
      value: metrics?.users.professionalUsers,
    },
    {
      metric: t('reviewHub:Global.Users.AllUsersMetrics.data.countries'),
      value: metrics?.users.countries,
    },
  ];

  const invitations = [
    {
      metric: t('reviewHub:Global.Users.DashboardUsersMetrics.data.sent'),
      value: metrics?.invitations.sent,
    },
    {
      metric: t('reviewHub:Global.Users.DashboardUsersMetrics.data.pending'),
      value: metrics?.invitations.pending,
    },
    {
      metric: t('reviewHub:Global.Users.DashboardUsersMetrics.data.completed'),
      value: metrics?.invitations.percentage,
    },
  ];

  return (
    <ColumnGroup>
      <Column>
        <Overview data={overview} loading={loading} />
        <MetricTableCard
          data={users}
          loading={loading}
          title={t('reviewHub:Global.Users.AllUsersMetrics.title')}
        />
        <MetricTableCard
          data={invitations}
          loading={loading}
          title={t('reviewHub:Global.Users.DashboardUsersMetrics.title')}
        />
        <DataRefresh data={{ created: metrics?.created }} loading={loading} />
      </Column>
    </ColumnGroup>
  );
}

export default Users;
