import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Icon } from 'antd';

import { ColumnGroup, Column } from '@cards/Card';
import { useHasPermissions } from '@authorisation/hooks';
import { usePageActions, useTitleUpdate } from '@hooks';
import LinkCard from '@cards/Link';
import { addDocument, addMessage, addProcedure, addAppUser } from '@assets/icons';
import HospitalAdminOnboarding from '@cards/Hospitals/AdminOnboarding';
import { Permissions } from '@authorisation/constants';

import { screens, setCurrentScreen } from '../../analytics';

export default () => {
  useEffect(() => {
    setCurrentScreen(screens.HOME_HOME);
  }, []);

  const { t } = useTranslation();
  const history = useHistory();
  const perms = useHasPermissions();

  const { setTitle } = usePageActions({
    title: t('home:header'),
  });
  useTitleUpdate(setTitle, 'home:header');
  document.title = t('home:header');

  return (
    <ColumnGroup>
      <Column>
        {perms[Permissions.AdministerHospital] ? <HospitalAdminOnboarding /> : null}
        {perms[Permissions.ViewPatients] ? (
          <LinkCard
            title={t('home:links.patients.title')}
            description={t('home:links.patients.description')}
            onContentClick={() => history.push('/patients/individuals')}
            showButton={Boolean(perms[Permissions.ManagePatients])}
            buttonIcon={<Icon component={addAppUser} />}
            buttonLabel={t('home:links.patients.buttonLabel')}
            buttonClick={() => history.push('/patients/individuals/new')}
          />
        ) : null}
        <LinkCard
          title={t('home:links.content.title')}
          description={t('home:links.content.description')}
          onContentClick={() => history.push('/content/documents')}
          buttonIcon={<Icon component={addDocument} />}
          buttonLabel={t('home:links.content.buttonLabel')}
          buttonClick={() => history.push('/content/documents/new')}
        />
      </Column>
      <Column>
        {perms[Permissions.ViewMessages] ? (
          <LinkCard
            title={t('home:links.messages.title')}
            description={t('home:links.messages.description')}
            onContentClick={() => history.push('/messages')}
            showButton={Boolean(perms[Permissions.ViewMessages])}
            buttonIcon={<Icon component={addMessage} />}
            buttonLabel={t('home:links.messages.buttonLabel')}
            buttonClick={() => history.push('/messages/new')}
          />
        ) : null}
        <LinkCard
          title={t('home:links.procedurePathways.title')}
          description={t('home:links.procedurePathways.description')}
          onContentClick={() => history.push('/procedure/pathways')}
          showButton={!!perms[Permissions.CreatePathway]}
          buttonIcon={<Icon component={addProcedure} />}
          buttonLabel={t('home:links.procedurePathways.buttonLabel')}
          buttonClick={() => history.push('/procedure/pathways/new')}
        />
      </Column>
    </ColumnGroup>
  );
};
