import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchGlobalMetrics } from '@reviewHub/redux/actions';
import { selectGlobalMetrics } from '@reviewHub/redux/selectors';
import DataRefresh from '@reviewHub/cards/DataRefresh';
import { ColumnGroup, Column } from '@cards/Card';
import MetricTableCard from '@reviewHub/cards/MetricTable';

function Content() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, metrics] = useSelector(selectGlobalMetrics);

  useEffect(() => {
    dispatch(fetchGlobalMetrics());
  }, [dispatch]);

  const contentData = [
    {
      metric: t('common:contentTypes.plural.documents'),
      value: metrics?.content.documents,
    },
    {
      metric: t('common:contentTypes.plural.videos'),
      value: metrics?.content.videos,
    },
    {
      metric: t('common:contentTypes.plural.forms'),
      value: metrics?.content.forms,
    },
    {
      metric: t('common:contentTypes.plural.questionnaires'),
      value: metrics?.content.questionnaires,
    },
    {
      metric: t('common:contentTypes.plural.tasks'),
      value: metrics?.content.tasks,
    },
    {
      metric: t('common:contentTypes.plural.messages'),
      value: metrics?.content.messages,
    },
    {
      metric: t('common:contentTypes.plural.alerts'),
      value: metrics?.content.alerts,
    },
    {
      metric: t('common:contentTypes.plural.reminders'),
      value: metrics?.content.reminders,
    },
  ];

  return (
    <ColumnGroup>
      <Column>
        <MetricTableCard
          data={contentData}
          loading={loading}
          title={t('reviewHub:Global.Content.title')}
        />
        <DataRefresh data={{ created: metrics?.created }} loading={loading} />
      </Column>
    </ColumnGroup>
  );
}

export default Content;
