import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HospitalList from './HospitalList';
import HospitalDetails from './HospitalDetails';
import NewHospital from './New';

export default ({ match }) => (
  <Switch>
    <Route path={`${match.path}/new`} component={NewHospital} />
    <Route path={`${match.path}/:id`} component={HospitalDetails} />
    <Route path={`${match.path}`} component={HospitalList} />
  </Switch>
);
