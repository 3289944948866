import React from 'react';
import { createLanguageFilters } from '@utils/supportedLanguages';
import { trimTextToApproximateLength } from '@utils';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusColumn from './PublishedStatusColumn';

// TODO: to be reviewed. Most documents don't have tags in their metadata.
export default (t, findLanguage, findSource, documentTranslationKey) => [
  {
    className: 'language-column',
    dataIndex: 'metadata',
    filters: createLanguageFilters(t),
    key: 'language',
    title: t('cards:DocumentList.columns.language.title'),
    onFilter: (value, record) => {
      return (findLanguage(record) || '').toLowerCase() === value;
    },
    render: metadata => <LanguageIcon language={findLanguage({ metadata })} />,
    sorter: (a, b) => findLanguage(a)?.localeCompare(findLanguage(b)),
  },
  {
    title: t(`cards:DocumentList.columns.${documentTranslationKey}.title`),
    dataIndex: 'name',
    key: 'title',
    ellipsis: true,
    sorter: (a, b) => a.name.localeCompare(b.title),
  },
  {
    title: t('cards:DocumentList.columns.description'),
    dataIndex: 'description',
    key: 'description',
    render: description => trimTextToApproximateLength(description),
    sorter: (a, b) => a.description > b.description,
  },
  {
    className: 'published-column no-filters',
    key: 'published',
    title: t('cards:DocumentList.columns.published.title'),
    render: record => <PublishedStatusColumn documentId={record.id} />,
  },
];
