import { call, getContext, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import {
  createPathwayFailure,
  createPathwaySuccess,
  editPathwaySuccess,
  editPathwayFailed,
  ICreatePathway,
  IEditPathway,
} from '../actions';
import { selectPathway } from '../reducers';
import i18n from '../../../../i18n';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import {
  generateSlugName,
  mapRawPathway,
  mapRawPathwayIndexEvent,
  mapRawPathwayStage,
} from '../utils';
import { doDetermineOwnerForPathway } from './utils';
import { IPathwayIndexEvent, IStage } from '../types';

const getRandomString = (length = 6) =>
  Math.round(Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))
    .toString(36)
    .slice(1)
    .toUpperCase();

function createUniqueSlug(slug: string, name?: string) {
  const baseSlugName = slug || (name && generateSlugName(name));
  const slugName = `${baseSlugName}-${getRandomString(6)}`;
  return slugName;
}

export function* doCreatePathway({ payload: { pathway } }: ICreatePathway): any {
  const history = yield getContext('history');

  try {
    const ownerId = yield call(doDetermineOwnerForPathway);
    const pathwaysClient = yield call(doCreatePathwaysClient);

    const { description, language, name } = pathway;
    const rawNewPathway = yield call(
      pathwaysClient.createPathway,
      name,
      description || '',
      true,
      {
        language,
      },
      ownerId,
    );
    const newPathway = mapRawPathway(rawNewPathway);

    for (const stage of pathway.stages) {
      const { description, isAdhoc, name, number, rules, slug } = stage;

      const slugName = createUniqueSlug(slug, name);

      const newStage = yield call(pathwaysClient.createPathwayStage, newPathway.id, {
        description: description || '',
        isAdhoc,
        name,
        number,
        rules: rules.map(({ id }) => id),
        slug: slugName,
      });

      const patchedStage = yield call(
        pathwaysClient.patchPathwayStage,
        newPathway.id,
        newStage.id,
        {
          description,
          isAdhoc,
          name,
          number,
          rules: rules.map(({ id }) => id),
          slug: slugName,
        },
      );

      const mappedPathwayStage = mapRawPathwayStage(patchedStage);
      if (mappedPathwayStage) newPathway.stages.push(mappedPathwayStage);
    }

    for (const indexEvent of pathway.indexEvents) {
      const { eventTypeSlug, rules } = indexEvent;
      const newIndexEvent = yield call(
        pathwaysClient.createPathwayIndexEvent,
        newPathway.id,
        eventTypeSlug,
        rules.map(({ id }) => id),
      );

      newPathway.indexEvents.push(mapRawPathwayIndexEvent(newIndexEvent));
    }

    yield put(createPathwaySuccess(newPathway));
    yield call(history.push, '/procedure/pathways');
    yield call(message.success, i18n.t('pathways:ProcedurePathways.createSuccess'));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('pathways:ProcedurePathways.wizard.networkError'));
    yield put(createPathwayFailure());
  }
}

export function* doEditPathway({ payload: { pathwayId, editedPathway } }: IEditPathway): any {
  const history = yield getContext('history');
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const [, existingPathway] = yield select(selectPathway(pathwayId));

    const { description, isActive, isDeleted, indexEvents, language, name, stages } = editedPathway;

    // update stage numbers based on order of edited pathway
    stages.forEach((stage, index) => {
      // ignore non-aligned/adhoc stage
      if (stage.number === 99) return;
      stage.number = index + 1;
    });

    const updatedPathway = yield call(pathwaysClient.patchPathway, pathwayId, {
      description,
      isActive,
      isDeleted,
      metadata: { ...existingPathway.metadata, language },
      name,
    });

    const newStages = [];
    for (const stage of stages) {
      let updatedStage;
      if (stage.id) {
        const existingStage = existingPathway.stages.find(
          (existingStage: IStage) => existingStage.id === stage.id,
        );
        updatedStage = yield call(pathwaysClient.patchPathwayStage, pathwayId, stage.id, {
          ...existingStage,
          ...stage,
          rules: stage.rules.map(({ id }) => id),
        });
      } else {
        const { description, isAdhoc, name, number, rules, slug } = stage;
        const slugName = createUniqueSlug(slug, name);
        updatedStage = yield call(pathwaysClient.createPathwayStage, pathwayId, {
          description: description || '',
          isAdhoc,
          name,
          number,
          rules: rules.map(({ id }) => id),
          slug: slugName,
        });

        const patchedStage = yield call(
          pathwaysClient.patchPathwayStage,
          pathwayId,
          updatedStage.id,
          {
            description: description || '',
            isAdhoc,
            name,
            number,
            rules: rules.map(({ id }) => id),
            slug: slugName,
          },
        );

        updatedStage = { ...updatedStage, ...patchedStage };
      }

      const mappedPathwayStage = mapRawPathwayStage(updatedStage);
      if (mappedPathwayStage) newStages.push(mappedPathwayStage);
    }

    const newStageIds = newStages.map(({ id }) => id);
    const stagesToDelete = existingPathway.stages.filter(
      ({ id }: IStage) => !newStageIds.includes(Number(id)),
    );

    for (const { id: stageId, ...stageData } of stagesToDelete) {
      yield call(pathwaysClient.patchPathwayStage, pathwayId, stageId, {
        ...stageData,
        number: 0,
        isDeleted: true,
      });
    }

    const newIndexEvents = [];

    for (const indexEvent of indexEvents) {
      let updatedIndexEvent;
      if (indexEvent.id) {
        updatedIndexEvent = yield call(
          pathwaysClient.patchPathwayIndexEvent,
          pathwayId,
          indexEvent.id,
          indexEvent.eventTypeSlug,
          indexEvent.rules.map(({ id }) => id),
        );
      } else {
        const { eventTypeSlug, rules } = indexEvent;
        updatedIndexEvent = yield call(
          pathwaysClient.createPathwayIndexEvent,
          pathwayId,
          eventTypeSlug,
          rules.map(({ id }) => id),
        );
      }

      newIndexEvents.push(mapRawPathwayIndexEvent(updatedIndexEvent));
    }
    const newIndexEventIds = newIndexEvents.map(({ id }) => id);

    const indexEventsToDelete = existingPathway.indexEvents
      .filter(({ id }: IPathwayIndexEvent) => !newIndexEventIds.includes(id))
      .map(({ id }: IPathwayIndexEvent) => id);

    for (const indexEventId of indexEventsToDelete) {
      yield call(pathwaysClient.deletePathwayIndexEvent, pathwayId, indexEventId);
    }

    yield put(
      editPathwaySuccess({
        ...updatedPathway,
        id: pathwayId,
        indexEvents: newIndexEvents,
        stages: newStages,
      }),
    );
    yield call(history.push, `/procedure/pathways/${pathwayId}`);
    yield call(message.success, i18n.t('pathways:ProcedurePathways.editSuccess'));
  } catch (err) {
    console.error(err);
    yield put(editPathwayFailed());
    yield call(message.error, i18n.t('pathways:ProcedurePathways.editError'));
  }
}
