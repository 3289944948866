import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';

const MenuItem = ({ icon, active, children, onClick, subMenuItems = null }) => {
  return (
    <>
      <div className={cn('menu-item', { active })} onClick={onClick}>
        <div className="icon">{icon}</div>
        <span className="content">{children}</span>
        {subMenuItems && subMenuItems.length && (
          <span className="caret">
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        )}
      </div>
      {active && subMenuItems
        ? subMenuItems.map(option => (
            <div
              className={cn('sub-menu-item', { active: option.active })}
              key={option.label}
              onClick={option.onClick}
            >
              {option.label}
            </div>
          ))
        : null}
    </>
  );
};

MenuItem.propTypes = {
  icon: PropTypes.node.isRequired,
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MenuItem;
