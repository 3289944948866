import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Icon, Menu } from 'antd';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePageActions } from '@hooks';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import { selectPathway } from '@pathways/redux/pathways/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import Content from './Content';
import Users from './Users';

const routes = {
  content: 'content',
  users: 'users',
};

function PathwayDetails({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, pathway] = useSelector(selectPathway(match.params.pathwayId));
  const { setTitle } = usePageActions({
    showBack: true,
    title: pathway ? pathway.name : t('reviewHub:Pathways.title'),
  });

  useEffect(() => {
    dispatch(fetchPathways());
  }, [dispatch]);

  useEffect(() => {
    if (pathway) setTitle(pathway.name);
  }, [setTitle, pathway]);

  return (
    <SubNavMenuContainer
      menu={
        <SubNavMenu>
          <Menu.Item key={routes.users}>
            <Icon type="user" /> {t('reviewHub:Global.Users.title')}
          </Menu.Item>
          <Menu.Item key={routes.content}>
            <FontAwesomeIcon icon={faFileAlt} /> {t('reviewHub:Global.Content.title')}
          </Menu.Item>
        </SubNavMenu>
      }
    >
      <Switch>
        <Route path={`${match.path}/${routes.users}`} component={Users} />
        <Route path={`${match.path}/${routes.content}`} component={Content} />
        <Redirect to={`${match.path}/${routes.users}`} />
      </Switch>
    </SubNavMenuContainer>
  );
}

export default PathwayDetails;
