import { useContext, useMemo, useCallback } from 'react';
import { WizardContext } from '.';

function useWizard() {
  const { currentStepName, form, formData, setFormData } = useContext(WizardContext);
  const currentStepData = useMemo(() => formData[currentStepName] || {}, [
    currentStepName,
    formData,
  ]);

  const setCurrentStepData = useCallback(
    data => setFormData({ ...formData, [currentStepName]: data }),
    [currentStepName, formData, setFormData],
  );

  return { currentStepData, setCurrentStepData, form, formData, setFormData };
}

export default useWizard;
