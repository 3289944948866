import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bool, func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import { Column, ColumnGroup } from '@cards/Card';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { fetchRules } from '@pathways/redux/rules/actions';
import Details from './Details';
import Stages from './Stages';
import StagedRules from './StagedRules';
import IndexEvents from './IndexEvents';
import IndexEventRules from './IndexEventRules';
import Review from './Review';
import './style.less';

function PathwayWizard({ initialData, loading, isPathwayInUse, submitting, onCompleted }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchIndexEvents());
    dispatch(fetchRules());
  }, [dispatch]);

  return (
    <Wizard
      initialData={initialData}
      loading={loading}
      submitting={submitting}
      onCompleted={onCompleted}
    >
      <WizardItem stepKey="details" title={t('pathways:ProcedurePathways.wizard.details.stepName')}>
        <Details />
      </WizardItem>
      <WizardItem stepKey="stages" title={t('pathways:ProcedurePathways.wizard.stages.stepName')}>
        <Column>
          <Stages isPathwayInUse={isPathwayInUse} />
        </Column>
      </WizardItem>
      <WizardItem
        stepKey="stagedRules"
        title={t('pathways:ProcedurePathways.wizard.stagedRules.stepName')}
      >
        <Column>
          <StagedRules />
        </Column>
      </WizardItem>
      <WizardItem
        stepKey="indexEvents"
        title={t('pathways:ProcedurePathways.wizard.indexEvents.stepName')}
      >
        <ColumnGroup>
          <Column>
            <IndexEvents />
          </Column>
          <Column></Column>
        </ColumnGroup>
      </WizardItem>
      <WizardItem
        stepKey="indexEventRules"
        title={t('pathways:ProcedurePathways.wizard.indexEventRules.stepName')}
      >
        <Column>
          <IndexEventRules />
        </Column>
      </WizardItem>
      <WizardItem stepKey="review" title={t('pathways:ProcedurePathways.wizard.review.stepName')}>
        <Review />
      </WizardItem>
    </Wizard>
  );
}

PathwayWizard.propTypes = {
  initialData: shape({}),
  isPathwayInUse: bool.isRequired,
  loading: bool,
  submitting: bool,
  onCompleted: func.isRequired,
};

PathwayWizard.defaultProps = {
  initialData: {},
  loading: false,
  submitting: false,
};

export default PathwayWizard;
