import { IReduxAction } from '@redux/types';
import {
  FETCH_FORM,
  FETCH_FORMS,
  LOAD_FORMS,
  FORMS_LOADED,
  FORM_LOADED,
  GRANT_INDIVIDUAL_FORMS_ACCESS,
  REVOKE_INDIVIDUAL_FORMS_ACCESS,
  GRANT_GROUP_FORMS_ACCESS,
  REVOKE_GROUP_FORMS_ACCESS,
  FETCH_FORM_DATA,
  FETCH_FORM_DATA_SUCCESS,
  FETCH_FORM_DATA_FAILED,
  DOWNLOAD_FORMS,
  PUBLISH_FORM,
  PUBLISH_FORM_SUCCESS,
  PUBLISH_FORM_FAILED,
  UNPUBLISH_FORM,
  UNPUBLISH_FORM_SUCCESS,
  UNPUBLISH_FORM_FAILED,
  CREATE_FORM,
  FORM_CREATED,
  UPDATE_FORM,
  FORM_UPDATED,
  IForm,
  IFormData,
  IVersionFormData,
  DELETE_FORMS,
  FORMS_DELETED,
  FETCH_VERSION_FORM_DATA,
  FETCH_VERSION_FORM_DATA_SUCCESS,
  FETCH_VERSION_FORM_DATA_FAILED,
} from './types';

export type IFetchForms = IReduxAction<typeof FETCH_FORMS>;
export const fetchForms = (): IFetchForms => ({
  type: FETCH_FORMS,
  payload: {},
});

export type IFetchForm = IReduxAction<typeof FETCH_FORM, { formId: string }>;
export const fetchForm = (formId: string): IFetchForm => ({
  type: FETCH_FORM,
  payload: { formId },
});

export type ILoadForms = IReduxAction<typeof LOAD_FORMS>;
export const loadForms = (): ILoadForms => ({
  type: LOAD_FORMS,
  payload: {},
});

export type IFormsLoaded = IReduxAction<typeof FORMS_LOADED, { forms: IForm[] }>;
export const formsLoaded = (forms: IForm[]): IFormsLoaded => ({
  type: FORMS_LOADED,
  payload: {
    forms,
  },
});

export type IFormLoaded = IReduxAction<typeof FORM_LOADED, { form: IForm }>;
export const formLoaded = (form: IForm) => ({
  type: FORM_LOADED,
  payload: { form },
});

export type IGrantFormsGroupAccess = IReduxAction<
  typeof GRANT_GROUP_FORMS_ACCESS,
  { formIds: string[]; groupIds: string[] }
>;
export const grantFormsGroupAccess = (
  formIds: string[],
  groupIds: string[],
): IGrantFormsGroupAccess => ({
  type: GRANT_GROUP_FORMS_ACCESS,
  payload: {
    formIds,
    groupIds,
  },
});

export type IRevokeFormGroupAccess = IReduxAction<
  typeof REVOKE_GROUP_FORMS_ACCESS,
  { formIds: string[]; groupIds: string[] }
>;
export const revokeFormGroupAccess = (
  formIds: string[],
  groupIds: string[],
): IRevokeFormGroupAccess => ({
  type: REVOKE_GROUP_FORMS_ACCESS,
  payload: {
    formIds,
    groupIds,
  },
});

export type IFetchFormData = IReduxAction<typeof FETCH_FORM_DATA, { formId: string }>;
export const fetchFormData = (formId: string): IFetchFormData => ({
  type: FETCH_FORM_DATA,
  payload: {
    formId,
  },
});

export type IFetchFormDataSuccess = IReduxAction<
  typeof FETCH_FORM_DATA_SUCCESS,
  { formId: string; data: IFormData }
>;
export const fetchFormDataSuccess = (formId: string, data: IFormData): IFetchFormDataSuccess => ({
  type: FETCH_FORM_DATA_SUCCESS,
  payload: {
    formId,
    data,
  },
});

export type IFetchFormDataFailed = IReduxAction<
  typeof FETCH_FORM_DATA_FAILED,
  { formId: string; error: string }
>;
export const fetchFormDataFailed = (formId: string, error: string): IFetchFormDataFailed => ({
  type: FETCH_FORM_DATA_FAILED,
  payload: {
    formId,
    error,
  },
});

export type IFetchVersionFormData = IReduxAction<
  typeof FETCH_VERSION_FORM_DATA,
  { formId: string; dataVersion: number; schemaVersion: number; appUserUISId: string }
>;
export const fetchVersionFormData = (
  formId: string,
  dataVersion: number,
  schemaVersion: number,
  appUserUISId: string,
): IFetchVersionFormData => ({
  type: FETCH_VERSION_FORM_DATA,
  payload: {
    formId,
    schemaVersion,
    dataVersion,
    appUserUISId,
  },
});

export type IFetchVersionFormDataSuccess = IReduxAction<
  typeof FETCH_VERSION_FORM_DATA_SUCCESS,
  { formId: string; dataVersion: number; data: IVersionFormData }
>;
export const fetchVersionFormDataSuccess = (
  formId: string,
  dataVersion: number,
  data: IVersionFormData,
): IFetchVersionFormDataSuccess => ({
  type: FETCH_VERSION_FORM_DATA_SUCCESS,
  payload: {
    formId,
    dataVersion,
    data,
  },
});

export type IFetchVersionFormDataFailed = IReduxAction<
  typeof FETCH_VERSION_FORM_DATA_FAILED,
  { formId: string; dataVersion: number; error: string }
>;
export const fetchVersionFormDataFailed = (
  formId: string,
  dataVersion: number,
  error: string,
): IFetchVersionFormDataFailed => ({
  type: FETCH_VERSION_FORM_DATA_FAILED,
  payload: {
    formId,
    dataVersion,
    error,
  },
});

export type IGrantFormsIndividualAccess = IReduxAction<
  typeof GRANT_INDIVIDUAL_FORMS_ACCESS,
  { formIds: string[]; appUserIds: string[] }
>;
export const grantFormsIndividualAccess = (
  formIds: string[],
  appUserIds: string[],
): IGrantFormsIndividualAccess => ({
  type: GRANT_INDIVIDUAL_FORMS_ACCESS,
  payload: {
    formIds,
    appUserIds,
  },
});

export type IRevokeFormIndividualAccess = IReduxAction<
  typeof REVOKE_INDIVIDUAL_FORMS_ACCESS,
  { formIds: string[]; appUserIds: string[] }
>;
export const revokeFormIndividualAccess = (
  formIds: string[],
  appUserIds: string[],
): IRevokeFormIndividualAccess => ({
  type: REVOKE_INDIVIDUAL_FORMS_ACCESS,
  payload: {
    formIds,
    appUserIds,
  },
});

export type IDownloadForm = IReduxAction<
  typeof DOWNLOAD_FORMS,
  { fileName: string; formData: object }
>;
export const downloadForm = (fileName: string, formData: object): IDownloadForm => ({
  type: DOWNLOAD_FORMS,
  payload: { fileName, formData },
});

export type IPublishForm = IReduxAction<
  typeof PUBLISH_FORM,
  { formId: string; formTranslationKey: string }
>;
export const publishForm = (formId: string, formTranslationKey: string): IPublishForm => ({
  type: PUBLISH_FORM,
  payload: {
    formId,
    formTranslationKey,
  },
});

export type IPublishFormSuccess = IReduxAction<typeof PUBLISH_FORM_SUCCESS>;
export const publishFormSuccess = (): IPublishFormSuccess => ({
  type: PUBLISH_FORM_SUCCESS,
  payload: {},
});

export type IPublishFormFailed = IReduxAction<typeof PUBLISH_FORM_FAILED>;
export const publishFormFailed = (): IPublishFormFailed => ({
  type: PUBLISH_FORM_FAILED,
  payload: {},
});

export type IUnpublishForm = IReduxAction<
  typeof UNPUBLISH_FORM,
  { formId: string; formTranslationKey: string }
>;
export const unpublishForm = (formId: string, formTranslationKey: string): IUnpublishForm => ({
  type: UNPUBLISH_FORM,
  payload: {
    formId,
    formTranslationKey,
  },
});

export type IUnpublishFormSuccess = IReduxAction<typeof UNPUBLISH_FORM_SUCCESS>;
export const unpublishFormSuccess = (): IUnpublishFormSuccess => ({
  type: UNPUBLISH_FORM_SUCCESS,
  payload: {},
});

export type IUnpublishFormFailed = IReduxAction<typeof UNPUBLISH_FORM_FAILED>;
export const unpublishFormFailed = (): IUnpublishFormFailed => ({
  type: UNPUBLISH_FORM_FAILED,
  payload: {},
});

export type ICreateForm = IReduxAction<
  typeof CREATE_FORM,
  {
    formTranslationKey: string;
    name: string;
    description: string;
    language: string;
    published: boolean;
    formData: { schema: object; uiSchema: object; computations?: object };
    type: string;
    path: string;
    fileName: string;
  }
>;
export const createForm = (
  formTranslationKey: string,
  name: string,
  description: string,
  language: string,
  published: boolean,
  formData: { schema: object; uiSchema: object; computations?: object },
  type: string,
  path: string,
  fileName: string,
): ICreateForm => ({
  type: CREATE_FORM,
  payload: {
    formTranslationKey,
    name,
    description,
    language,
    published,
    formData,
    type,
    path,
    fileName,
  },
});

export type IFormCreated = IReduxAction<typeof FORM_CREATED>;
export const formCreated = (): IFormCreated => ({
  type: FORM_CREATED,
  payload: {},
});

export type IUpdateForm = IReduxAction<
  typeof UPDATE_FORM,
  {
    formTranslationKey: string;
    uuid: string;
    name: string;
    description: string;
    language: string;
    published: boolean;
    formData: { schema: object; uiSchema: object; computations?: object };
    type: string;
    fileName: string;
  }
>;
export const updateForm = (
  formTranslationKey: string,
  uuid: string,
  name: string,
  description: string,
  language: string,
  published: boolean,
  formData: { schema: object; uiSchema: object; computations?: object },
  type: string,
  fileName: string,
): IUpdateForm => ({
  type: UPDATE_FORM,
  payload: {
    formTranslationKey,
    uuid,
    name,
    description,
    language,
    published,
    formData,
    type,
    fileName,
  },
});

export type IFormUpdated = IReduxAction<typeof FORM_UPDATED>;
export const formUpdated = (): IFormUpdated => ({
  type: FORM_UPDATED,
  payload: {},
});

export type IDeleteForms = IReduxAction<
  typeof DELETE_FORMS,
  { ids: string[]; formTranslationKey: string }
>;
export const deleteForms = (ids: string[], formTranslationKey: string): IDeleteForms => ({
  type: DELETE_FORMS,
  payload: { ids, formTranslationKey },
});

export type IFormsDeleted = IReduxAction<typeof FORMS_DELETED, { forms: IForm[] }>;
export const formsDeleted = (forms: IForm[]): IFormsDeleted => ({
  type: FORMS_DELETED,
  payload: { forms },
});

export type IRemoveFormData = ReturnType<typeof removeFormData>;
export const removeFormData = (formId: string) => ({
  type: 'forms/remove-data' as const,
  payload: { formId },
});

export type ITemporaryFormsLoaded = ReturnType<typeof temporaryFormsLoaded>;
export const temporaryFormsLoaded = (forms: IForm[]) => ({
  type: 'forms/temporary-loaded' as const,
  payload: { forms },
});
