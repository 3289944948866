import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchableTable from '@components/SearchableTable';
import { fetchMessages } from '@redux/messages/actions';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectSentMessages } from '@redux/messages/reducers';
import createColumns from './createColumns';
import { selectAppUsers } from '@redux/appUsers/reducers';

const filterFunc = (value, comparator) => {
  return comparator(value.title) || comparator(value.audienceDisplay);
};

function SentList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, messages] = useSelector(selectSentMessages);
  const [appUsersLoading] = useSelector(selectAppUsers);
  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchMessages());
  }, [dispatch]);

  return (
    <SearchableTable
      columns={columns}
      dataSource={messages}
      loading={loading || appUsersLoading}
      hasRoutedPagination
      filterFunc={filterFunc}
    />
  );
}

export default SentList;
