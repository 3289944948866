import React from 'react';
import { func, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import WizardDetails from '@components/Wizard/Details';
// import WizardAudience from '@components/Wizard/Audience';
import Details from './Details';
import Content from './Content';
import Review from './Review';
import './style.less';

function DocumentWizard({ initialData, loading, submitting, onCompleted }) {
  const { t } = useTranslation();

  return (
    <Wizard
      loading={loading}
      submitting={submitting}
      initialData={initialData}
      onCompleted={onCompleted}
    >
      <WizardItem stepKey="details" title={t('common:Wizard.details.stepName')}>
        <WizardDetails>
          <Details documentTranslationKey="documents" />
        </WizardDetails>
      </WizardItem>
      <WizardItem stepKey="content" title={t('documents:Wizard.content.stepName')}>
        <ColumnGroup>
          <Column>
            <Content />
          </Column>
          <Column></Column>
        </ColumnGroup>
      </WizardItem>
      {/* <WizardItem stepKey="audience" title={t('common:Wizard.audience.stepName')}>
        <WizardAudience
          titles={{
            groups: t('documents:Wizard.audience.groupsTitle'),
            individuals: t('documents:Wizard.audience.individualsTitle'),
          }}
        />
      </WizardItem> */}
      <WizardItem stepKey="review" title={t('documents:Wizard.review.stepName')}>
        <Review />
      </WizardItem>
    </Wizard>
  );
}

DocumentWizard.propTypes = {
  initialData: shape({
    content: shape({}),
    details: shape({}),
  }),
  onCompleted: func.isRequired,
};

DocumentWizard.defaultProps = {
  initialData: {},
};

export default DocumentWizard;
