import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Popover, Modal } from 'antd';
import { faInfoCircle, faMinusCircle } from '@fortawesome/pro-regular-svg-icons';
import { ColumnGroup, Column } from '@cards/Card';
import IconButton from '@components/IconButton';
import SearchableTable from '@components/SearchableTable';
import SelectContentModal from '@components/SelectContentModal';
import { editHospital, fetchHospitals } from '@redux/hospitals/actions';
import { selectHospital, selectHospitalEditing } from '@redux/hospitals/reducers';
import {
  documentTypes,
  formTypes,
  DOCUMENT,
  VIDEO,
  FORM,
  QUESTIONNAIRE,
  TASK,
} from '@utils/contentTypes';
import createColumns from './createColumns';
import { setCurrentScreen, screens } from '../../../../analytics';
import './style.less';

const contentTypes = [...documentTypes, ...formTypes];

const filterFunc = (value, comparator) => comparator(value.name);

function Content() {
  useEffect(() => {
    setCurrentScreen(screens.ADMINISTRATION_HOSPITAL_CONTENT);
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [rowsToRemove, setRowsToRemove] = useState([]);
  const dashboardUserProfile = useSelector(state => state.login.user.profile);
  const editing = useSelector(selectHospitalEditing);
  const [loading, hospital] = useSelector(selectHospital(dashboardUserProfile.hospitalId));
  const columns = useMemo(() => createColumns(t, contentTypes), [t]);

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const removeHospitalContent = useCallback(() => {
    const updatedHospitalContent = hospital.content.filter(content => {
      const contentToRemove = rowsToRemove.find(row => {
        if (content.type === row.type && content.id === row.id) {
          return true;
        }
        return false;
      });

      return !contentToRemove;
    });

    dispatch(
      editHospital(dashboardUserProfile.hospitalId, { content: updatedHospitalContent }, true),
    );
  }, [dashboardUserProfile, dispatch, hospital, rowsToRemove]);

  const showDeleteWarningModal = useCallback(() => {
    Modal.confirm({
      title: t('hospitals:Content.delete.title'),
      content: t('hospitals:Content.delete.content'),
      okText: t('hospitals:Content.delete.ok'),
      okType: 'danger',
      cancelText: t('hospitals:Content.delete.cancel'),
      onOk: removeHospitalContent,
      onCancel() {
        return;
      },
    });
  }, [removeHospitalContent, t]);

  const addHospitalContent = useCallback(
    (ids, rows) => {
      const contentToAdd = rows.map(row => {
        return {
          id: row.id || row.uuid,
          name: row.name || row.title,
          type: row.metadata?.type || row.type,
          language: row.metadata?.language || row.language,
          published: row.metadata?.published || row.published,
          productId: row.product_id || undefined,
        };
      });
      dispatch(
        editHospital(
          dashboardUserProfile.hospitalId,
          { content: [...contentToAdd, ...(hospital.content || [])] },
          true,
        ),
      );
      setVisible(false);
    },
    [dashboardUserProfile, dispatch, hospital],
  );

  return (
    <>
      <ColumnGroup>
        <Column>
          <SearchableTable
            columns={columns}
            dataSource={(hospital && hospital.content) || []}
            extra={
              <div className="app-hospital-details-extra">
                <Popover
                  overlayClassName="app-hospital-details-extra-popover"
                  content={<p>{t('hospitals:Content.popover.body')}</p>}
                  placement="bottomRight"
                  title={t('hospitals:Content.popover.title')}
                  trigger="click"
                >
                  <IconButton
                    icon={faInfoCircle}
                    tooltip={t('common:buttons.info')}
                    onClick={() => {}}
                  />
                </Popover>
                <IconButton
                  disabled={rowsToRemove.length === 0 || editing}
                  icon={faMinusCircle}
                  tooltip={t('common:buttons.remove')}
                  onClick={showDeleteWarningModal}
                />
                <Button
                  className="add-btn"
                  disabled={editing}
                  icon="plus"
                  onClick={() => setVisible(true)}
                >
                  {t('common:buttons.add')}
                </Button>
              </div>
            }
            filterFunc={filterFunc}
            loading={loading || editing}
            pagination={false}
            onRowClick={row => {
              switch (row.type) {
                case DOCUMENT:
                  return history.push(`/content/documents/${row.id}`);
                case VIDEO:
                  return history.push(`/content/videos/${row.id}`);
                case FORM:
                  return history.push(`/content/forms/${row.id}`);
                case QUESTIONNAIRE:
                  return history.push(`/content/questionnaires/${row.id}`);
                case TASK:
                  return history.push(`/content/tasks/${row.id}`);
                default:
                  return null;
              }
            }}
            onRowSelectionChange={(ids, rows) => setRowsToRemove(rows)}
          />
        </Column>
      </ColumnGroup>
      <SelectContentModal
        multiSelect
        visible={visible}
        onCancel={() => setVisible(false)}
        onSubmit={addHospitalContent}
        contentTypes={contentTypes}
      />
    </>
  );
}

export default Content;
