import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import {
  CREATE_PATHWAY,
  CREATE_PATHWAY_FAILURE,
  CREATE_PATHWAY_SUCCESS,
  DELETE_PATHWAY,
  DELETE_PATHWAY_FAILURE,
  DELETE_PATHWAY_SUCCESS,
  DELETE_PATHWAYS,
  DELETE_PATHWAYS_FAILURE,
  DELETE_PATHWAYS_SUCCESS,
  DUPLICATE_PATHWAY,
  DUPLICATE_PATHWAY_SUCCESS,
  DUPLICATE_PATHWAYS,
  DUPLICATE_PATHWAYS_SUCCESS,
  EDIT_PATHWAY,
  EDIT_PATHWAY_SUCCESS,
  FETCH_PATHWAYS,
  FETCHING_PATHWAYS,
  FETCH_PATHWAYS_ERROR,
  LOAD_PATHWAYS,
  PUBLISH_PATHWAYS_SUCCESS,
  UNPUBLISH_PATHWAYS_SUCCESS,
  IPathway,
  EDIT_PATHWAY_FAILED,
} from './types';
import { IRule } from '@pathways/redux/rules/types';
import { IState } from '@redux/reducer';
import { selectRule } from '@pathways/redux/rules/reducers';
import {
  ICreatePathwaySuccess,
  IDeletePathwaySuccess,
  IDeletePathwaysSuccess,
  IEditPathwaySuccess,
  IPublishPathwaysSuccess,
  IUnpublishPathwaysSuccess,
  ILoadPathways,
  IFetchPathwaysError,
  IFetchPathways,
  ICreatePathway,
  IDeletePathway,
  IDeletePathways,
  IDuplicatePathway,
  IDuplicatePathways,
  IEditPathway,
  IFetchingPathways,
  ICreatePathwayFailure,
  IDeletePathwayFailure,
  IDeletePathwaysFailure,
  IDuplicatePathwaySuccess,
  IDuplicatePathwaysSuccess,
  IEditPathwayFailed,
} from './actions';

export interface IDataState {
  list: number[];
  byId: { [key: string]: IPathway };
}

function creating(
  state = false,
  action: ICreatePathway | ICreatePathwayFailure | ICreatePathwaySuccess,
) {
  switch (action.type) {
    case CREATE_PATHWAY:
      return true;
    case CREATE_PATHWAY_FAILURE:
    case CREATE_PATHWAY_SUCCESS:
      return false;
    default:
      return state;
  }
}

const initialDataState: IDataState = {
  list: [],
  byId: {},
};
function data(
  state = initialDataState,
  action:
    | ICreatePathwaySuccess
    | IDeletePathwaySuccess
    | IDeletePathwaysSuccess
    | IEditPathwaySuccess
    | ILoadPathways
    | IPublishPathwaysSuccess
    | IUnpublishPathwaysSuccess,
): IDataState {
  switch (action.type) {
    case CREATE_PATHWAY_SUCCESS: {
      const { pathway } = action.payload;
      return {
        list: [pathway.id, ...state.list],
        byId: { ...state.byId, [pathway.id]: pathway },
      };
    }
    case DELETE_PATHWAYS_SUCCESS:
      return {
        list: state.list.filter(id => !action.payload.pathwayIds.includes(id)),
        byId: action.payload.pathwayIds.reduce(
          (prev, id) => ({ ...prev, [id]: { ...state.byId[id], isDeleted: true } }),
          state.byId,
        ),
      };
    case DELETE_PATHWAY_SUCCESS:
      return {
        list: state.list.filter(id => id !== action.payload.pathwayId),
        byId: Object.keys(state.byId).reduce(
          (prev, id) =>
            Number(id) === action.payload.pathwayId
              ? { ...prev, [id]: { ...state.byId[id], isDeleted: true } }
              : { ...prev, [id]: state.byId[id] },
          {},
        ),
      };
    case EDIT_PATHWAY_SUCCESS: {
      const { pathway } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [pathway.id]: {
            ...state.byId[pathway.id],
            ...pathway,
          },
        },
      };
    }
    case PUBLISH_PATHWAYS_SUCCESS:
      return {
        ...state,
        byId: action.payload.pathwayIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...state.byId[id],
              isActive: true,
            },
          }),
          state.byId,
        ),
      };
    case UNPUBLISH_PATHWAYS_SUCCESS:
      return {
        ...state,
        byId: action.payload.pathwayIds.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              ...state.byId[id],
              isActive: false,
            },
          }),
          state.byId,
        ),
      };
    case LOAD_PATHWAYS:
      return action.payload.pathways.reduce(
        (acc, pathway) => ({
          list: [...acc.list, pathway.id],
          byId: {
            ...acc.byId,
            [pathway.id]: {
              ...pathway,
            },
          },
        }),
        initialDataState,
      );
    default:
      return state;
  }
}

function editing(state = false, action: IEditPathway | IEditPathwaySuccess | IEditPathwayFailed) {
  switch (action.type) {
    case EDIT_PATHWAY:
      return true;
    case EDIT_PATHWAY_FAILED:
    case EDIT_PATHWAY_SUCCESS:
      return false;
    default:
      return state;
  }
}

function error(state = false, action: IFetchPathwaysError | IFetchPathways) {
  switch (action.type) {
    case FETCH_PATHWAYS_ERROR:
      return action.payload.error;
    case FETCH_PATHWAYS:
      return false;
    default:
      return state;
  }
}

function duplicating(
  state = false,
  action:
    | IDuplicatePathway
    | IDuplicatePathways
    | IDuplicatePathwaySuccess
    | IDuplicatePathwaysSuccess,
) {
  switch (action.type) {
    case DUPLICATE_PATHWAY:
    case DUPLICATE_PATHWAYS:
      return true;
    case DUPLICATE_PATHWAY_SUCCESS:
    case DUPLICATE_PATHWAYS_SUCCESS:
      return false;
    default:
      return state;
  }
}

function loading(
  state = false,
  action:
    | IDeletePathway
    | IDeletePathways
    | IFetchingPathways
    | IDeletePathwayFailure
    | IDeletePathwaySuccess
    | IDeletePathwaysFailure
    | IDeletePathwaysSuccess
    | IFetchPathwaysError
    | ILoadPathways,
) {
  switch (action.type) {
    case DELETE_PATHWAY:
    case DELETE_PATHWAYS:
    case FETCHING_PATHWAYS:
      return true;
    case DELETE_PATHWAY_FAILURE:
    case DELETE_PATHWAY_SUCCESS:
    case DELETE_PATHWAYS_FAILURE:
    case DELETE_PATHWAYS_SUCCESS:
    case FETCH_PATHWAYS_ERROR:
    case LOAD_PATHWAYS:
      return false;
    default:
      return state;
  }
}

export default combineReducers({ creating, data, duplicating, editing, error, loading });

export const selectPathwayCreating = (state: IState) => state.pathways.creating;
export const selectPathwayEditing = (state: IState) => state.pathways.editing;
export const selectPathwaysLoading = (state: IState) => state.pathways.loading;

export const selectPathways = createSelector(
  (state: IState): [boolean, IPathway[]] => [
    state.pathways.loading,
    state.pathways.data.list.reduce((acc, id) => {
      if (state.pathways.data.byId[id].isDeleted) return acc;

      return [...acc, state.pathways.data.byId[id]];
    }, [] as IPathway[]),
  ],
  pathways => pathways,
);

export const selectPathwaysById = (ids: number[] = []) => (state: IState) => [
  state.pathways.loading,
  ids.map(id => state.pathways.data.byId[id]).filter(pathway => !!pathway),
];

export const selectPathwaysByRules = (filter: (rule: IRule | undefined) => boolean) => (
  state: IState,
): [boolean, IPathway[]] => {
  const [loading, pathways] = selectPathways(state);

  const result = pathways.filter(
    pathway =>
      pathway.stages.flatMap(stage => stage.rules.filter(filter)).some(Boolean) ||
      pathway.indexEvents.flatMap(indexEvent => indexEvent.rules.filter(filter)).some(Boolean),
  );

  return [loading, result];
};

export const selectPathway = (id: number) => (state: IState): [boolean, IPathway] => [
  state.pathways.loading,
  state.pathways.data.byId[id],
];

export const selectAppUserMainPathway = (ids: number[]) => (state: IState) => {
  const appUserPathways = ids.map(id => state.pathways.data.byId[id]).filter(Boolean);
  return [state.pathways.loading, appUserPathways[0]];
};

export const selectPathwayWithRules = (id: number) => (
  state: IState,
): [boolean, IPathway | undefined] => [
  state.pathways.loading,
  state.pathways.data.byId[id]
    ? {
        ...state.pathways.data.byId[id],
        indexEvents: state.pathways.data.byId[id].indexEvents.map(indexEvent => ({
          ...indexEvent,
          rules: indexEvent.rules.map(indexEventRule => {
            const [, rule] = selectRule(indexEventRule.id)(state);
            return rule || indexEventRule;
          }),
        })),
        stages: state.pathways.data.byId[id].stages.map(stage => ({
          ...stage,
          rules: stage.rules.map(stageRule => {
            const [, rule] = selectRule(stageRule.id)(state);
            return rule || stageRule;
          }),
        })),
      }
    : undefined,
];
