import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'antd';
import { selectPathwayWithRules } from '@pathways/redux/pathways/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import PathwayStagesTable from '@components/PathwayStagesTable';
import { fetchDocuments } from '@redux/documents/actions';
import { selectDocuments } from '@redux/documents/reducers';
import { fetchForms } from '@redux/forms/actions';
import { selectForms } from '@redux/forms/reducers';
import { fetchCannedMessages } from '@redux/messages/actions';
import { selectCannedMessages } from '@redux/messages/reducers';
import { Card } from '../../../../cards/Card';
import createColumns from './createColumns';
import createDataSource from './createDataSource';
import './style.less';

function PathwayStagesTableCard({
  currentStageSlug,
  pathwayId,
  triggeredRules,
  transitionPathwayToStage,
  triggerAdhocRule,
  loading: loadingProp,
  ...props
}) {
  const dispatch = useDispatch();
  const [pathwaysLoading, pathway] = useSelector(selectPathwayWithRules(pathwayId));
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchForms());
    dispatch(fetchCannedMessages());
    dispatch(fetchPathways());
  }, [dispatch]);

  const [documentsLoading] = useSelector(selectDocuments);
  const [formsLoading] = useSelector(selectForms);
  const [cannedMessagesLoading] = useSelector(selectCannedMessages);

  const [columns, dataSource] = useMemo(
    () => [
      createColumns(t, pathway?.stages, currentStageSlug, triggeredRules, triggerAdhocRule),
      createDataSource(pathway?.stages),
    ],
    [currentStageSlug, pathway, t, triggeredRules, triggerAdhocRule],
  );

  const { currentStageIndex, transitionDisabled } = useMemo(() => {
    const currentStageIndex =
      pathway?.stages?.findIndex(({ slug }) => slug === currentStageSlug) || 0;

    return {
      currentStageIndex,
      // - 2 is because all pathways have one stage that is for ad-hoc rules
      transitionDisabled: currentStageIndex >= pathway?.stages?.length - 2,
    };
  }, [currentStageSlug, pathway]);

  const transitionStage = useCallback(() => {
    const nextStage = pathway.stages[currentStageIndex + 1];
    Modal.confirm({
      okText: t('cards:PathwayStagesTable.buttons.transition'),
      title: t('cards:PathwayStagesTable.modals.transition', { stageName: nextStage.name }),
      onOk: () => {
        transitionPathwayToStage(nextStage.slug);
      },
    });
  }, [currentStageIndex, pathway, t, transitionPathwayToStage]);

  const loading =
    documentsLoading ||
    formsLoading ||
    cannedMessagesLoading ||
    pathwaysLoading ||
    loadingProp ||
    !pathway;

  return (
    <Card.Full
      className="pathway-stages-table-card"
      extra={
        transitionPathwayToStage ? (
          <Button.Group>
            <Button disabled={transitionDisabled} onClick={transitionStage}>
              {t('cards:PathwayStagesTable.buttons.transition')} <Icon type="right" />
            </Button>
          </Button.Group>
        ) : null
      }
      title={t('cards:PathwayStagesTable.title')}
      {...props}
    >
      <PathwayStagesTable columns={columns} dataSource={dataSource} loading={loading} />
    </Card.Full>
  );
}

PathwayStagesTableCard.propTypes = {
  currentStageSlug: string,
  transitionPathwayToStage: func,
  triggerAdhocRule: func,
};

export default PathwayStagesTableCard;
