import { IRawRule, IRule } from '@pathways/redux/rules/types';

export const CREATE_PATHWAY = 'CREATE_PATHWAY';
export const CREATE_PATHWAY_FAILURE = 'CREATE_PATHWAY_FAILURE';
export const CREATE_PATHWAY_SUCCESS = 'CREATE_PATHWAY_SUCCESS';
export const DELETE_PATHWAY = 'DELETE_PATHWAY';
export const DELETE_PATHWAY_FAILURE = 'DELETE_PATHWAY_FAILURE';
export const DELETE_PATHWAY_SUCCESS = 'DELETE_PATHWAY_SUCCESS';
export const DELETE_PATHWAYS = 'DELETE_PATHWAYS';
export const DELETE_PATHWAYS_FAILURE = 'DELETE_PATHWAYS_FAILURE';
export const DELETE_PATHWAYS_SUCCESS = 'DELETE_PATHWAYS_SUCCESS';
export const DUPLICATE_PATHWAY = 'DUPLICATE_PATHWAY';
export const DUPLICATE_PATHWAY_SUCCESS = 'DUPLICATE_PATHWAY_SUCCESS';
export const DUPLICATE_PATHWAYS = 'DUPLICATE_PATHWAYS';
export const DUPLICATE_PATHWAYS_SUCCESS = 'DUPLICATE_PATHWAYS_SUCCESS';
export const EDIT_PATHWAY = 'EDIT_PATHWAY';
export const EDIT_PATHWAY_SUCCESS = 'EDIT_PATHWAY_SUCCESS';
export const EDIT_PATHWAY_FAILED = 'EDIT_PATHWAY_FAILED';
export const FETCH_PATHWAYS = 'FETCH_PATHWAYS';
export const FETCHING_PATHWAYS = 'FETCHING_PATHWAYS';
export const FETCH_PATHWAYS_ERROR = 'FETCH_PATHWAYS_ERROR';
export const LOAD_PATHWAYS = 'LOAD_PATHWAYS';
export const PUBLISH_PATHWAYS = 'PUBLISH_PATHWAYS';
export const PUBLISH_PATHWAYS_SUCCESS = 'PUBLISH_PATHWAYS_SUCCESS';
export const UNPUBLISH_PATHWAYS = 'UNPUBLISH_PATHWAYS';
export const UNPUBLISH_PATHWAYS_SUCCESS = 'UNPUBLISH_PATHWAYS_SUCCESS';
export const PROCESS_APPUSER_PATHWAY_NOW = 'PROCESS_APPUSER_PATHWAY_NOW';

export interface IPathwayIndexEvent {
  id: number;
  url: string;
  rules: Array<IRule>;
  eventTypeSlug: string;
}

export interface IStage {
  id: number;
  url: string;
  number: number;
  name: string;
  slug: string;
  description: string;
  rules: IRule[];
  isAdhoc: boolean;
}

export interface IPathwayData {
  name: string;
  description: string;
  stages: IStage[];
  metadata: { [key: string]: any };
  source: string;
  indexEvents: Array<IPathwayIndexEvent>;
  isActive: boolean;
  isDeleted: boolean;
  language: string;
}

export interface IPathway extends IPathwayData {
  id: number;
  url: string;
  numAppUserPathways: number;
  ownerId: string;
}

export interface IRawStage {
  id: number;
  url: string;
  number: number;
  name: string;
  slug: string;
  description: string;
  rules: Array<IRawRule>;
  is_adhoc: boolean;
  is_deleted: boolean;
}

export interface IRawPathwayIndexEvent {
  id: number;
  url: string;
  rules: Array<IRawRule>;
  event_type_slug: string;
}

export interface IRawPathway {
  id: number;
  url: string;
  name: string;
  description: string;
  stages: IRawStage[];
  metadata: { [key: string]: any };
  source: string;
  index_events: Array<IRawPathwayIndexEvent>;
  is_active: boolean;
  is_deleted: boolean;
  language: string;
  num_app_user_pathways: number;
  owner_id: string;
}
