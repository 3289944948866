import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { Icon, Menu, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMobile } from '@fortawesome/pro-regular-svg-icons';
import { pathway } from '@assets/icons';
import EditButton from '@components/EditButton';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import { usePageActions } from '@hooks';
import { fetchAppUsers } from '@redux/appUsers/actions';
import {
  fetchAppUsersPathways,
  fetchAppUserJourneys,
} from '@pathways/redux/appUserPathways/actions';
import { selectAppUser } from '@redux/appUsers/reducers';
import { selectPathwaysForAppUserWithOriginals } from '@pathways/redux/appUserPathways/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import AppUserPathways from '@pathways/pages/AppUsers';
import { contentTypes, FORM, QUESTIONNAIRE, TASK } from '@utils/contentTypes';
import AppUserOverviewTab from './AppUserOverviewTab';
import AppUserFormListTab from './AppUserFormListTab';
import AppUserEdit from './AppUserEdit';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Evereg from './Evereg';
import AppUserAppAccessTab from './AppUserAppAccessTab';
import { getMatchTypeWithParams } from '../../propTypes';
import { trackAndPushHistory } from '../../analytics';

const routes = {
  edit: 'edit',
  overview: 'overview',
  forms: 'forms',
  questionnaires: 'questionnaires',
  tasks: 'tasks',
  journey: 'journey',
  access: 'access',
  termsAndConditions: 'terms-and-conditions',
  privacyPolicy: 'privacy-policy',
  evereg: 'evereg',
};

function AppUserDetails({ history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { id },
  } = match;

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchAppUsersPathways());
    dispatch(fetchPathways());
    dispatch(fetchAppUserJourneys(id));
  }, [dispatch, id]);

  const [, appUser] = useSelector(selectAppUser(id));
  const [appUserPathwaysLoading, pathways] = useSelector(
    selectPathwaysForAppUserWithOriginals(appUser),
  );
  const [pathwaysLoading] = useSelector(selectPathways);
  const { setTitle } = usePageActions({
    actions: [
      <EditButton
        key="edit-btn"
        onClick={() => trackAndPushHistory(history, `${match.url}/${routes.edit}`, 'Edit')}
      />,
    ],
    showBack: true,
  });

  useEffect(() => {
    setTitle(appUser && `${appUser.firstName} ${appUser.lastName}`);
  }, [appUser, setTitle]);

  return (
    <Switch>
      <Route path={`${match.path}/${routes.edit}`} component={AppUserEdit} />
      <SubNavMenuContainer
        menu={
          <SubNavMenu>
            <Menu.Item key={routes.overview}>
              <FontAwesomeIcon icon={faUser} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.overview')}
            </Menu.Item>
            <Menu.Item key={routes.forms}>
              <FontAwesomeIcon icon={contentTypes.FORM.icon} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.forms')}
            </Menu.Item>
            <Menu.Item key={routes.questionnaires}>
              <FontAwesomeIcon icon={contentTypes.QUESTIONNAIRE.icon} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.questionnaires')}
            </Menu.Item>
            <Menu.Item key={routes.tasks}>
              <FontAwesomeIcon icon={contentTypes.TASK.icon} className="tab-item-icon" />
              {t('patients:IndividualDetail.tabs.tasks')}
            </Menu.Item>
            <Menu.SubMenu
              key={routes.journey}
              title={
                <>
                  <Icon component={pathway} className="tab-item-icon" />
                  {t('patients:IndividualDetail.tabs.journeys')}
                </>
              }
              onTitleClick={() =>
                history.location.pathname === `${match.url}/${routes.journey}`
                  ? null
                  : history.push(`${match.url}/${routes.journey}`)
              }
            >
              {appUserPathwaysLoading || pathwaysLoading ? (
                <Spin style={{ width: '100%' }} />
              ) : (
                pathways?.map(pathway => (
                  <Menu.Item key={`${routes.journey}/pathway/${pathway.id}`}>
                    {pathway.originalPathway.name}
                  </Menu.Item>
                ))
              )}
            </Menu.SubMenu>
            <Menu.ItemGroup
              key="app-details-group"
              title={
                <span>
                  <FontAwesomeIcon icon={faMobile} className="tab-item-icon" />
                  <span>{t('patients:IndividualDetail.tabs.appDetails')}</span>
                </span>
              }
            >
              <Menu.Item key={routes.access}>
                {t('patients:IndividualDetail.tabs.appAccess')}
              </Menu.Item>
              <Menu.Item key={routes.termsAndConditions}>
                {t('patients:IndividualDetail.tabs.terms')}
              </Menu.Item>
              <Menu.Item key={routes.privacyPolicy}>
                {t('patients:IndividualDetail.tabs.privacyPolicy')}
              </Menu.Item>
            </Menu.ItemGroup>
            {appUser?.language === 'es' ? (
              <Menu.Item key={routes.evereg}>
                <>
                  <span className="app-user-tabs-item-alphabet-icon">E</span>
                  {t('patients:IndividualDetail.tabs.evereg')}
                </>
              </Menu.Item>
            ) : null}
          </SubNavMenu>
        }
      >
        <Switch>
          <Route path={`${match.path}/${routes.overview}`} component={AppUserOverviewTab} />
          <Route
            path={`${match.path}/${routes.forms}`}
            render={props => (
              <AppUserFormListTab formTranslationKey="form" type={FORM} {...props} />
            )}
          />
          <Route
            path={`${match.path}/${routes.questionnaires}`}
            render={props => (
              <AppUserFormListTab
                formTranslationKey="questionnaire"
                type={QUESTIONNAIRE}
                {...props}
              />
            )}
          />
          <Route
            path={`${match.path}/${routes.tasks}`}
            render={props => (
              <AppUserFormListTab formTranslationKey="task" type={TASK} {...props} />
            )}
          />
          <Route path={`${match.path}/${routes.journey}`} component={AppUserPathways} />
          <Route path={`${match.path}/${routes.access}`} component={AppUserAppAccessTab} />
          <Route
            path={`${match.path}/${routes.termsAndConditions}`}
            component={TermsAndConditions}
          />
          <Route path={`${match.path}/${routes.privacyPolicy}`} component={PrivacyPolicy} />
          {appUser?.language === 'es' ? (
            <Route path={`${match.path}/${routes.evereg}`} component={Evereg} />
          ) : null}
          <Route path={match.path} render={() => <Redirect to={`${match.url}/overview`} />} />
        </Switch>
      </SubNavMenuContainer>
    </Switch>
  );
}

AppUserDetails.propTypes = {
  match: getMatchTypeWithParams({
    id: string.isRequired,
    activeTab: string,
    detailId: string,
  }).isRequired,
};

AppUserDetails.defaultProps = {
  activeTab: null,
  detailId: null,
};

export default AppUserDetails;
