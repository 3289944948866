import React from 'react';
import { Button } from 'antd';
import RuleTag, { IndexEventTriggeredRuleIcon } from '@components/RuleTag';

// assumes stage can't be adhoc AND be the current stage
function generateColumnClassName(isCurrentStage, isAdhocStage, isAppUserPathway) {
  if (isCurrentStage) return 'current-stage';
  if (isAdhocStage && isAppUserPathway) return 'manual-rules-column';

  return '';
}

export default (t, stages, currentStageSlug, triggeredRules, triggerAdhocRule) => {
  const adhocStage = (stages || []).find(({ isAdhoc }) => isAdhoc);

  return stages
    ? [
        {
          className: 'icon-column',
          fixed: 'left',
          key: 'icon',
          dataIndex: 'what',
          title: '',
          width: '60px',
          render: what => <IndexEventTriggeredRuleIcon what={what} />,
        },
        ...Object.values(stages)
          .map(stage => ({
            className: generateColumnClassName(
              stage.slug === currentStageSlug,
              stage.isAdhoc,
              !!triggeredRules,
            ),
            dataIndex: stage.number,
            key: `stage-${stage.number}`,
            title: `${
              stage.isAdhoc
                ? ''
                : `${t('cards:PathwayStagesTable.columns.stage', {
                    stageNumber: stage.number,
                  })}: `
            }${stage.name}`,
            ...(stage.isAdhoc ? { fixed: 'right', width: '200px' } : {}),
            render: (rules = []) =>
              rules.map(rule => (
                <RuleTag
                  rule={rule}
                  triggered={(triggeredRules || []).includes(rule.id)}
                  key={rule.id}
                />
              )),
          }))
          .sort((a, b) => {
            if (a.dataIndex > b.dataIndex) return 1;
            if (a.dataIndex < b.dataIndex) return -1;
            return 0;
          }),
        ...(triggeredRules
          ? [
              {
                className: 'manual-rules-column',
                dataIndex: adhocStage.number,
                fixed: 'right',
                key: 'action',
                title: t('cards:PathwayStagesTable.columns.action'),
                width: '100px',
                render: (rules = []) =>
                  rules.map(rule => (
                    <Button type="link" onClick={() => triggerAdhocRule(rule.id)}>
                      {t('cards:PathwayStagesTable.buttons.trigger')}
                    </Button>
                  )),
              },
            ]
          : []),
      ]
    : [];
};
