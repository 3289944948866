import { takeEvery, call, put, select, getContext, take } from 'redux-saga/effects';
import {
  AdminClient as PIPClient,
  Client as AppUserPIPClient,
  Acceptable as PIPAcceptable,
} from '@liquid-state/pip-client';
import { PIPAdminAcceptable } from '@liquid-state/pip-client/dist/acceptable';
import { AcceptableVersion, AcceptableContent } from '@liquid-state/pip-client/dist/types';
import { appToken, pipApiRoot } from 'settings';
import {
  IRefreshTermsOfUse,
  termsOfUseRefreshed,
  IAcceptTermsOfUse,
  IFetchUserAcceptances,
  userAcceptancesFetched,
  IRefreshAppUserTermsOfUse,
  appUserTermsOfUseRefreshed,
  IFetchAppUserAcceptances,
  fetchUserAcceptances,
} from './actions';
import PIPAdminClient from '@liquid-state/pip-client/dist/admin-client';
import { APP_USERS_LOADED } from '@redux/appUsers/types';
import i18n from '../../i18n';

export default function*() {
  yield takeEvery('termsOfUse/refreshTermsOfUse', doRefreshTermsOfUse);
  yield takeEvery('termsOfUse/refreshAppUserTermsOfUse', doRefreshAppUsersTermsOfUse);
  yield takeEvery('termsOfUse/acceptTermsOfUse', doAcceptTermsOfUse);
  yield takeEvery('termsOfUse/fetchAppUserAcceptances', doFetchAppUserAcceptances);
  yield takeEvery('termsOfUse/fetchUserAcceptances', doFetchUserAcceptances);
}

function* doRefreshTermsOfUse({
  payload: { jwtToken, hospitalId, languages, userIsAppUser },
}: IRefreshTermsOfUse) {
  try {
    const client = userIsAppUser
      ? new AppUserPIPClient({ apiRoot: pipApiRoot })
      : new PIPClient({ jwt: jwtToken }, { apiRoot: pipApiRoot });
    const termsOfUseIds = [`${appToken}-dashboard-terms-of-use-global`];
    const privacyPolicyIds = [`${appToken}-dashboard-privacy-policy-global`];
    if (hospitalId) {
      termsOfUseIds.push(`${appToken}-dashboard-terms-of-use-${hospitalId}`);
      privacyPolicyIds.push(`${appToken}-dashboard-privacy-policy-${hospitalId}`);
    }
    const terms = [];
    const privacy = [];

    for (const touid of termsOfUseIds) {
      const result = yield call(loadAcceptable, touid, languages, client, jwtToken);
      if (result) {
        terms.push(result);
      }
    }
    for (const privacyId of privacyPolicyIds) {
      const result = yield call(loadAcceptable, privacyId, languages, client, jwtToken);
      if (result) {
        privacy.push(result);
      }
    }

    yield put(termsOfUseRefreshed(terms, privacy));
  } catch (err) {
    console.error(err);
  }
}

function* loadAcceptable(
  id: string,
  languages: string[],
  client: PIPAdminClient | AppUserPIPClient,
  jwt: string,
) {
  if (client instanceof AppUserPIPClient) {
    const acceptable = new PIPAcceptable(id, client, jwt);
    const result = yield call(acceptable.acceptable.bind(acceptable), languages);
    // Error
    if (result.detail) {
      return undefined;
    }
    return result;
  }
  const acceptable = new PIPAdminAcceptable(id, client);
  const acceptableVersion: AcceptableVersion = yield call(acceptable.acceptable.bind(acceptable));
  if (!acceptableVersion) {
    return;
  }
  const content: AcceptableContent = yield call(acceptable.content.bind(acceptable), languages);
  return {
    slug: id,
    name: id,
    latest_version: {
      id: acceptableVersion.uuid,
      number: acceptableVersion.number,
      version_content: {
        id: content.uuid,
        language_code: content.language_code,
        display_name: content.display_name,
        content: content.content,
        data: content.data,
      },
    },
    latest_acceptance: null,
  };
}

function* doRefreshAppUsersTermsOfUse({ payload: { appUserId } }: IRefreshAppUserTermsOfUse) {
  let appUser = yield select(state => state.appUsers.data.byId[appUserId]);
  if (!appUser) {
    yield take(APP_USERS_LOADED);
    appUser = yield select(state => state.appUsers.data.byId[appUserId]);
  }
  const termsOfUseIds = [
    `${appToken}-app-terms-of-use-global`,
    `${appToken}-app-terms-of-use-${appUser.hospitalId}`,
  ];
  const privacyPolicyIds = [
    `${appToken}-app-privacy-policy-global`,
    `${appToken}-app-privacy-policy-${appUser.hospitalId}`,
  ];
  const tokens = yield getContext('tokens');
  const token = yield call(tokens.get, 'pip');
  const client = new PIPClient({ jwt: token }, { apiRoot: pipApiRoot });
  const terms = [];
  const privacy = [];

  for (const touid of termsOfUseIds) {
    const result = yield call(
      loadAcceptable,
      touid,
      [i18n.language, ...navigator.languages],
      client,
      token,
    );
    if (result) {
      terms.push(result);
    }
  }
  for (const privacyId of privacyPolicyIds) {
    const result = yield call(loadAcceptable, privacyId, [...navigator.languages], client, token);
    if (result) {
      privacy.push(result);
    }
  }

  yield put(appUserTermsOfUseRefreshed(appUserId, terms, privacy));
}

function* doAcceptTermsOfUse({ payload: { id } }: IAcceptTermsOfUse) {
  const tokens = yield getContext('tokens');
  const jwt = yield call(tokens.get, 'pip');
  const client = new PIPClient({ jwt }, { apiRoot: pipApiRoot });
  const tou: any = yield select(state => {
    const tou = state.termsOfUse.currentUser.tou.find((t: any) => t.slug === id);
    if (tou) {
      return tou;
    }
    return state.termsOfUse.currentUser.privacy.find((t: any) => t.slug === id);
  });
  yield call(
    client.sendAcceptance.bind(client) as any,
    { uuid: tou.latest_version.id },
    { uuid: tou.latest_version.version_content.id },
  );
}

function* doFetchUserAcceptances({
  payload: { id, hospitalId, acceptanceType, pipId },
}: IFetchUserAcceptances) {
  const tokens = yield getContext('tokens');
  const jwt = yield call(tokens.get, 'pip');
  const client = new PIPClient({ jwt }, { apiRoot: pipApiRoot });
  const acceptableIds = [
    `${appToken}-${acceptanceType}-terms-of-use-global`,
    `${appToken}-${acceptanceType}-privacy-policy-global`,
  ];
  if (hospitalId) {
    acceptableIds.push(`${appToken}-${acceptanceType}-privacy-policy-${hospitalId}`);
    acceptableIds.push(`${appToken}-${acceptanceType}-terms-of-use-${hospitalId}`);
  }

  const results = {} as { [key: string]: any };
  for (const acceptableId of acceptableIds) {
    const forId = yield call(client.listUserAcceptances.bind(client), acceptableId, pipId || id);
    results[acceptableId] = forId;
  }

  yield put(userAcceptancesFetched(id, results));
}

function* doFetchAppUserAcceptances({ payload: { id } }: IFetchAppUserAcceptances) {
  let appUser = yield select(state => state.appUsers.data.byId[id]);
  if (!appUser) {
    yield take(APP_USERS_LOADED);
    appUser = yield select(state => state.appUsers.data.byId[id]);
  }
  yield put(fetchUserAcceptances(id, appUser.hospitalId, 'app', appUser.ids.pip));
}
