import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import './style.less';
import moment from 'moment';

function DataRefresh({ data, loading }) {
  const { t } = useTranslation();

  return (
    <Card
      className="data-refresh-card"
      loading={loading}
      title={t('reviewHub:Global.Users.DataRefresh.title')}
    >
      <p>
        <span>{t('reviewHub:Global.Users.DataRefresh.last')}</span>
        {moment(data?.created).format('LT - L')}
      </p>
      <p>
        <span>{t('reviewHub:Global.Users.DataRefresh.description')}</span>
      </p>
    </Card>
  );
}

export default DataRefresh;
