import React from 'react';
import { bool, string } from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { selectOwner } from '@redux/core/reducers';
import './style.less';

function SourceIcon({ customName, source, showText }) {
  const { t } = useTranslation();
  const sourceData = useSelector(selectOwner(source));

  if (!source) return t('common:unknown');

  const { name, icon } = sourceData;

  if (customName) {
    return (
      <Tooltip placement="bottom" title={t(name)}>
        <span className="source-icon-with-text">
          <FontAwesomeIcon icon={icon} size="lg" />
          {customName || t(name)}
        </span>
      </Tooltip>
    );
  }
  if (showText) {
    return (
      <Tooltip placement="bottom" title={t(name)}>
        <span className="source-icon-with-text">
          <FontAwesomeIcon icon={icon} size="lg" />
          {t(name)}
        </span>
      </Tooltip>
    );
  }
  return (
    <Tooltip placement="bottom" title={t(name)}>
      <FontAwesomeIcon icon={icon} size="lg" />
    </Tooltip>
  );
}

SourceIcon.propTypes = {
  customName: string,
  source: string,
  showText: bool,
};

SourceIcon.defaultProps = {
  customName: null,
  source: null,
  showText: false,
};

export default SourceIcon;
