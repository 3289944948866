export const FETCH_CANNED_MESSAGES = 'FETCH_CANNED_MESSAGES';
export const LOAD_CANNED_MESSAGES = 'LOAD_CANNED_MESSAGES';
export const CANNED_MESSAGES_LOADED = 'CANNED_MESSAGES_LOADED';
export const LOAD_CANNED_MESSAGES_FAILED = 'LOAD_CANNED_MESSAGES_FAILED';
// export const DELETE_CANNED_MESSAGES = 'DELETE_CANNED_MESSAGES';

export const CREATE_CONTENT_MESSAGE = 'CREATE_CONTENT_MESSAGE';
export const CREATE_CONTENT_MESSAGE_SUCCESS = 'CREATE_CONTENT_MESSAGE_SUCCESS';
export const CREATE_CONTENT_MESSAGE_FAILED = 'CREATE_CONTENT_MESSAGE_FAILED';

export const UPDATE_CONTENT_MESSAGE = 'UPDATE_CONTENT_MESSAGE';
export const UPDATE_CONTENT_MESSAGE_SUCCESS = 'UPDATE_CONTENT_MESSAGE_SUCCESS';
export const UPDATE_CONTENT_MESSAGE_FAILED = 'UPDATE_CONTENT_MESSAGE_FAILED';

export const DELETE_CONTENT_MESSAGES = 'DELETE_CONTENT_MESSAGES';
export const DELETE_CONTENT_MESSAGES_SUCCESS = 'DELETE_CONTENT_MESSAGES_SUCCESS';
export const DELETE_CONTENT_MESSAGES_FAILED = 'DELETE_CONTENT_MESSAGES_FAILED';
// export const PUBLISH_CANNED_MESSAGE = 'PUBLISH_CANNED_MESSAGE';
// export const UNPUBLISH_CANNED_MESSAGE = 'UNPUBLISH_CANNED_MESSAGE';

export const FETCH_MESSAGES = 'FETCH_MESSAGES';
export const MESSAGES_LOADED = 'MESSAGES_LOADED';
export const LOAD_MESSAGES = 'LOAD_MESSAGES';
export const LOAD_MESSAGES_FAILED = 'LOAD_MESSAGES_FAILED';
export const DELETE_SCHEDULED_MESSAGE = 'DELETE_SCHEDULED_MESSAGE';
export const DELETE_SCHEDULED_MESSAGES = 'DELETE_SCHEDULED_MESSAGES';
export const DELETE_SCHEDULED_MESSAGES_SUCCESS = 'DELETE_SCHEDULED_MESSAGES_SUCCESS';
export const DELETE_SCHEDULED_MESSAGES_FAILED = 'DELETE_SCHEDULED_MESSAGES_FAILED';
export const CREATE_SCHEDULED_MESSAGE = 'CREATE_SCHEDULED_MESSAGE';
export const CREATE_SCHEDULED_MESSAGE_SUCCESS = 'CREATE_SCHEDULED_MESSAGE_SUCCESS';
export const CREATE_SCHEDULED_MESSAGE_FAILED = 'CREATE_SCHEDULED_MESSAGE_FAILED';

export interface ICannedMessage {
  id: string;
  metadata: {
    published: boolean;
    [key: string]: any;
  };
}

export interface IContentMessage {
  title: string;
  published: boolean;
  version: number;
  id: string;
  language: string;
  body: string;
  source: string;
  created: string;
  payload: object;
  metadata: {
    type: string;
    [key: string]: any;
  };
}

export interface IMessage {
  id: number;
  isDeleted: boolean;
  deleted: boolean;
  app: number;
  isSent: boolean;
  title: string;
  content: string;
  audienceType: string;
  audienceDisplay: string;
  scheduleType: string;
  scheduledDatetime: string;
  nextSendDatetime: string;
  lastSentDatetime: string;
  isRecurring: boolean;
  recurringOffset: number;
  recurringUnit: string;
  shouldIncreaseBadge: boolean;
  payloadOptions: { [key: string]: any };
  ownerId: string;
  users: string[];
  groups: string[];
  metadata: {
    type: string;
    [key: string]: any;
  };
}
