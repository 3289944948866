import React, { Children, cloneElement, useCallback } from 'react';
import { arrayOf, bool, element, string } from 'prop-types';
import { Menu } from 'antd';
import { useHistory, useRouteMatch } from 'react-router-dom';
import './style.less';

/**
 * Each Menu.Item must have a key that is identical to the intended route name.
 * Default route transition is replace. Add a push prop to Menu.Items you want
 * to use a push transition.
 *
 * Set isHandlingMenuItemProps to false to override the default handling of onClick and className props.
 * If set to false, you will need to roll your own handling for setting the active
 * class and onClick methods
 */
function SubNavMenu({ className, children, isHandlingMenuItemProps }) {
  const history = useHistory();
  const match = useRouteMatch();

  const getMenuItemClassName = useCallback(
    path => {
      if (history.location.pathname.includes(path)) {
        return 'active';
      }
      return '';
    },
    [history.location.pathname],
  );

  const createMenuItemWithProps = useCallback(
    menuItem =>
      cloneElement(menuItem, {
        className: `${menuItem.props.className} ${getMenuItemClassName(menuItem.key)}`,
        onClick: () =>
          menuItem.props.push
            ? history.push(`${match.url}/${menuItem.key}`)
            : history.replace(`${match.url}/${menuItem.key}`),
      }),
    [getMenuItemClassName, match, history],
  );

  return (
    <Menu className={`${className} sub-navigation-menu`} mode="inline">
      {isHandlingMenuItemProps
        ? Children.map(children, menuItem => {
            if (!menuItem) return null;

            if (menuItem.type.isMenuItem) {
              return createMenuItemWithProps(menuItem);
            }

            if (menuItem.type.isMenuItemGroup || menuItem.type.isSubMenu) {
              return cloneElement(menuItem, {
                className: `${menuItem.props.className} ${getMenuItemClassName(menuItem.key)}`,
                children: Children.map(menuItem.props.children, nestedMenuItem =>
                  nestedMenuItem.type.isMenuItem
                    ? createMenuItemWithProps(nestedMenuItem)
                    : nestedMenuItem,
                ),
              });
            }

            return menuItem;
          })
        : children}
    </Menu>
  );
}

SubNavMenu.propTypes = {
  className: string,
  isHandlingMenuItemProps: bool,
  menuItems: arrayOf(element),
};

SubNavMenu.defaultProps = {
  className: '',
  isHandlingMenuItemProps: true,
};

export default SubNavMenu;
