import React from 'react';
import { createLanguageFilters } from '@utils/supportedLanguages';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusColumn from '@cards/Documents/List/PublishedStatusColumn';

export default (t, defaultFilteredValue, findLanguage, findSource, contentTypeTranslationKey) => [
  {
    title: t('cards:DocumentList.columns.language.title'),
    dataIndex: 'metadata',
    defaultFilteredValue: defaultFilteredValue.language || [],
    key: 'language',
    render: metadata => <LanguageIcon language={findLanguage({ metadata })} />,
    width: 150,
    // sorter will have to be modified
    sorter: (a, b) => (findLanguage(a) || '').localeCompare(findLanguage(b) || ''),
    filters: createLanguageFilters(t),
    onFilter: (value, record) => {
      return (findLanguage(record) || '').toLowerCase() === value;
    },
  },
  {
    title: t(`cards:DocumentList.columns.${contentTypeTranslationKey}.title`),
    dataIndex: 'name',
    key: 'name',
    width: 250,
    ellipsis: true,
    sorter: (a, b) => a.name > b.name,
  },
  {
    key: 'published',
    render: record => <PublishedStatusColumn documentId={record.id} />,
    title: t('cards:DocumentList.columns.published.title'),
    width: 150,
  },
];
