import { combineReducers } from 'redux';
import {
  LOAD_FORMS,
  FORMS_LOADED,
  DELETE_FORMS,
  FORMS_DELETED,
  GRANT_GROUP_FORMS_ACCESS,
  GRANT_INDIVIDUAL_FORMS_ACCESS,
  REVOKE_GROUP_FORMS_ACCESS,
  REVOKE_INDIVIDUAL_FORMS_ACCESS,
  PUBLISH_FORM,
  PUBLISH_FORM_SUCCESS,
  PUBLISH_FORM_FAILED,
  UNPUBLISH_FORM,
  UNPUBLISH_FORM_SUCCESS,
  UNPUBLISH_FORM_FAILED,
  FETCH_FORM_DATA_SUCCESS,
  FETCH_FORM_DATA,
  FETCH_FORM_DATA_FAILED,
  IForm,
  IFormData,
  CREATE_FORM,
  FORM_CREATED,
  UPDATE_FORM,
  FORM_UPDATED,
  FETCH_VERSION_FORM_DATA,
  FETCH_VERSION_FORM_DATA_SUCCESS,
  FETCH_VERSION_FORM_DATA_FAILED,
  IVersionFormData,
  FORM_LOADED,
} from './types';
import {
  ILoadForms,
  IFormsLoaded,
  IDeleteForms,
  IFormsDeleted,
  IPublishForm,
  IPublishFormSuccess,
  IPublishFormFailed,
  IUnpublishForm,
  IUnpublishFormSuccess,
  IUnpublishFormFailed,
  IFetchFormData,
  IFetchFormDataFailed,
  IFetchFormDataSuccess,
  IFetchVersionFormData,
  IFetchVersionFormDataSuccess,
  IFetchVersionFormDataFailed,
  IGrantFormsGroupAccess,
  IRevokeFormGroupAccess,
  IGrantFormsIndividualAccess,
  IRevokeFormIndividualAccess,
  ICreateForm,
  IFormCreated,
  IUpdateForm,
  IFormUpdated,
  IRemoveFormData,
  ITemporaryFormsLoaded,
  IFormLoaded,
} from './actions';
import { createUniqueArray } from '@utils';
import { FORM, QUESTIONNAIRE, TASK } from '@utils/contentTypes';
import { IState } from '../reducer';
import { IClearFormsForAppUserResults } from '@redux/appUsers/actions';

const creating = (state: boolean = false, action: ICreateForm | IFormCreated): boolean => {
  switch (action.type) {
    case CREATE_FORM:
      return true;
    case FORM_CREATED:
      return false;
    default:
      return state;
  }
};

const deleting = (state: boolean = false, action: IDeleteForms | IFormsDeleted): boolean => {
  switch (action.type) {
    case DELETE_FORMS:
      return true;
    case FORMS_DELETED:
      return false;
    default:
      return state;
  }
};

const loading = (state: boolean = false, action: ILoadForms | IFormsLoaded): boolean => {
  switch (action.type) {
    case LOAD_FORMS:
      return true;
    case FORMS_LOADED:
      return false;
    default:
      return state;
  }
};

const publishing = (
  state = false,
  action:
    | IPublishForm
    | IPublishFormSuccess
    | IPublishFormFailed
    | IUnpublishForm
    | IUnpublishFormSuccess
    | IUnpublishFormFailed,
) => {
  switch (action.type) {
    case PUBLISH_FORM:
    case UNPUBLISH_FORM:
      return true;
    case PUBLISH_FORM_SUCCESS:
    case PUBLISH_FORM_FAILED:
    case UNPUBLISH_FORM_SUCCESS:
    case UNPUBLISH_FORM_FAILED:
      return false;
    default:
      return state;
  }
};

const updating = (state: boolean = false, action: IUpdateForm | IFormUpdated): boolean => {
  switch (action.type) {
    case UPDATE_FORM:
      return true;
    case FORM_UPDATED:
      return false;
    default:
      return state;
  }
};

const byId = (
  state: { [key: string]: IForm } = {},
  action:
    | IFormsLoaded
    | IFormsDeleted
    | ITemporaryFormsLoaded
    | IClearFormsForAppUserResults
    | IFormLoaded,
): { [key: string]: IForm } => {
  switch (action.type) {
    case FORMS_LOADED:
    case FORMS_DELETED:
    case 'forms/temporary-loaded':
      return action.payload.forms.reduce(
        (formsById, form) => ({ ...formsById, [form.uuid]: form }),
        state,
      );
    case 'appUsers/clear-app-user-forms-for-results':
      return Object.entries(state).reduce(
        (newState, [key, form]) =>
          action.payload.formIdList.includes(key) ? { ...newState, [key]: form } : newState,
        {},
      );
    case FORM_LOADED:
      return { ...state, [action.payload.form.uuid]: action.payload.form };
    default:
      return state;
  }
};

const list = (state: string[] = [], action: IFormsLoaded | IFormsDeleted): string[] => {
  switch (action.type) {
    case FORMS_LOADED:
    case FORMS_DELETED:
      return action.payload.forms.map(({ uuid }) => uuid);
    default:
      return state;
  }
};

export interface IFormDataState {
  [key: string]: {
    loading: boolean;
    data: IFormData | {};
  };
}
const formData = (
  state: IFormDataState = {},
  action: IFetchFormData | IFetchFormDataSuccess | IFetchFormDataFailed | IRemoveFormData,
): IFormDataState => {
  switch (action.type) {
    case FETCH_FORM_DATA:
      return {
        ...state,
        [action.payload.formId]: {
          loading: true,
          data: {},
        },
      };
    case FETCH_FORM_DATA_SUCCESS:
      return {
        ...state,
        [action.payload.formId]: {
          loading: false,
          data: action.payload.data,
        },
      };
    case FETCH_FORM_DATA_FAILED:
      return {
        ...state,
        [action.payload.formId]: {
          loading: false,
          data: {},
        },
      };
    case 'forms/remove-data':
      const { [action.payload.formId]: toRemove, ...newState } = state;
      return newState;
    default:
      return state;
  }
};

export interface IVersionFormDataState {
  [key: string]: {
    loading: boolean;
    data: IVersionFormData | {};
  };
}
const versionFormData = (
  state: IVersionFormDataState = {},
  action: IFetchVersionFormData | IFetchVersionFormDataSuccess | IFetchVersionFormDataFailed,
): IVersionFormDataState => {
  switch (action.type) {
    case FETCH_VERSION_FORM_DATA:
      return {
        ...state,
        [`${action.payload.formId}_${action.payload.dataVersion}`]: {
          loading: true,
          data: {},
        },
      };
    case FETCH_VERSION_FORM_DATA_SUCCESS:
      return {
        ...state,
        [`${action.payload.formId}_${action.payload.dataVersion}`]: {
          loading: false,
          data: action.payload.data,
        },
      };
    case FETCH_VERSION_FORM_DATA_FAILED:
      return {
        ...state,
        [`${action.payload.formId}_${action.payload.dataVersion}`]: {
          loading: false,
          data: {},
        },
      };

    default:
      return state;
  }
};

export interface IPermissionsState {
  groupAccess: {
    [key: string]: string[];
  };
  individualAccess: {
    [key: string]: string[];
  };
}
const permissions = (
  state: IPermissionsState = {
    groupAccess: {},
    individualAccess: {},
  },
  action:
    | IGrantFormsGroupAccess
    | IGrantFormsIndividualAccess
    | IRevokeFormGroupAccess
    | IRevokeFormIndividualAccess,
): IPermissionsState => {
  switch (action.type) {
    case GRANT_GROUP_FORMS_ACCESS:
      return {
        ...state,
        groupAccess: action.payload.formIds.reduce(
          (acc, formId) => ({
            ...acc,
            [formId]: createUniqueArray([
              ...(state.groupAccess[formId] || []),
              ...action.payload.groupIds,
            ]),
          }),
          state.groupAccess,
        ),
      };
    case GRANT_INDIVIDUAL_FORMS_ACCESS:
      return {
        ...state,
        individualAccess: action.payload.formIds.reduce(
          (acc, formId) => ({
            ...acc,
            [formId]: createUniqueArray([
              ...(state.individualAccess[formId] || []),
              ...action.payload.appUserIds,
            ]),
          }),
          state.individualAccess,
        ),
      };

    case REVOKE_GROUP_FORMS_ACCESS:
      return {
        ...state,
        groupAccess: Object.keys(state.groupAccess).reduce(
          (acc, formId) =>
            action.payload.formIds.includes(formId)
              ? {
                  ...acc,
                  [formId]: state.groupAccess[formId].filter(
                    groupId => !action.payload.groupIds.includes(groupId),
                  ),
                }
              : acc,
          state.groupAccess,
        ),
      };
    case REVOKE_INDIVIDUAL_FORMS_ACCESS:
      return {
        ...state,
        individualAccess: Object.keys(state.individualAccess).reduce(
          (acc, formId) =>
            action.payload.formIds.includes(formId)
              ? {
                  ...acc,
                  [formId]: state.individualAccess[formId].filter(
                    individualId => !action.payload.appUserIds.includes(individualId),
                  ),
                }
              : acc,
          state.individualAccess,
        ),
      };
    default:
      return state;
  }
};

export default combineReducers({
  byId,
  creating,
  deleting,
  formData,
  list,
  loading,
  permissions,
  publishing,
  updating,
  versionFormData,
});

// selects all forms regardless of type
const selectForms = (state: IState): [boolean, IForm[]] => [
  state.forms.loading,
  state.forms.list.map(id => state.forms.byId[id]),
];

const selectForm = (formId: string) => (state: IState): [boolean, IForm?] => [
  state.forms.loading,
  state.forms.byId[formId],
];

const selectFormData = (formId: string) => (state: IState) => [
  state.forms.formData[formId]?.loading ?? false,
  state.forms.formData[formId]?.data ?? {},
];

const selectVersionFormData = (formId: string, dataVersion: number) => (state: IState) => [
  state.forms.versionFormData[`${formId}_${dataVersion}`]?.loading ?? false,
  state.forms.versionFormData[`${formId}_${dataVersion}`]?.data ?? undefined,
];

const selectFormPermissions = (formId: string) => (state: IState): [string[], string[]] => [
  state.forms.permissions.groupAccess[formId],
  state.forms.permissions.individualAccess[formId],
];

const selectContentForms = (state: IState): [boolean, IForm[]] => [
  state.forms.loading,
  state.forms.list.map(id => state.forms.byId[id]).filter(form => form.metadata.type === FORM),
];

const selectContentQuestionnaires = (state: IState): [boolean, IForm[]] => [
  state.forms.loading,
  state.forms.list.map(id => state.forms.byId[id]).filter(qa => qa.metadata.type === QUESTIONNAIRE),
];

const selectContentTasks = (state: IState): [boolean, IForm[]] => [
  state.forms.loading,
  state.forms.list.map(id => state.forms.byId[id]).filter(task => task.metadata.type === TASK),
];

export const selectFormsByType = (type: typeof FORM | typeof QUESTIONNAIRE | typeof TASK) => (
  state: IState,
) => [
  state.forms.loading,
  Object.values(state.forms.byId).filter(form => form.metadata.type === type),
];

export const selectFormIdList = (state: IState) => state.forms.list;

const selectFormCreating = (state: IState): boolean => state.forms.creating;

const selectFormUpdating = (state: IState): boolean => state.forms.updating;

const selectFormPublishing = (state: IState): boolean => state.forms.publishing;

const selectFormDeleting = (state: IState): boolean => state.forms.deleting;

export {
  selectForms,
  selectForm,
  selectFormPermissions,
  selectFormData,
  selectVersionFormData,
  selectContentQuestionnaires,
  selectContentForms,
  selectFormCreating,
  selectContentTasks,
  selectFormUpdating,
  selectFormPublishing,
  selectFormDeleting,
};
