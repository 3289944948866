import { PathwaysAdminClient } from '@liquid-state/pathways-client';
import { appToken, pathwaysApiRoot } from 'settings';

async function createPathwaysClient(jwt) {
  try {
    const client = await new PathwaysAdminClient(appToken, jwt, { baseUrl: pathwaysApiRoot });

    return client;
  } catch (err) {
    console.error(err);
  }
}

export default createPathwaysClient;
