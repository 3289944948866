export const Permissions = {
  AdministerAllHospitals: 'administer-all-hospitals',
  AdministerHospital: 'administer-hospital',
  ViewPatients: 'view-patients',
  ViewDashboardUsers: 'view-dashboard-users',
  ViewDataRequests: 'view-data-requests',
  ManagePatients: 'manage-patients',
  ManageSystemAdmin: 'manage-system-admin',
  ManageSupportAdmin: 'manage-support-admin',
  ManageSuperAdmin: 'manage-super-admin',
  ManageHospitalAdmin: 'manage-hospital-admin',
  ManageHospitalUser: 'manage-hospital-user',
  ViewMessages: 'view-messages',
  CreateRule: 'create-rule',
  CreatePathway: 'create-pathway',
  InviteSystemAdmin: 'invite-system-admin',
  InviteSupportAdmin: 'invite-support-admin',
  InviteSuperAdmin: 'invite-super-admin',
  InviteHospitalAdmin: 'invite-hospital-admin',
  InviteHospitalUser: 'invite-hospital-user',
  ViewAdministation: 'view-administration',
  AdministerIndexEvents: 'administer-index-events',
  PerformPathwayActionsInListView: 'perform-pathway-actions-in-list-view',
  ViewHospitalMetrics: 'view-hospital-metrics',
};

export const permissionsMap = {
  'system-admin': [
    Permissions.InviteSuperAdmin,
    Permissions.InviteSupportAdmin,
    Permissions.InviteSystemAdmin,
    Permissions.ManageSystemAdmin,
    Permissions.ManageSupportAdmin,
    Permissions.ManageSuperAdmin,
    Permissions.ViewDashboardUsers,
    Permissions.ViewAdministation,
    Permissions.AdministerIndexEvents,
  ],
  'support-admin': [
    Permissions.AdministerAllHospitals,
    Permissions.CreatePathway,
    Permissions.CreateRule,
    Permissions.InviteSupportAdmin,
    Permissions.InviteSuperAdmin,
    Permissions.ManageSupportAdmin,
    Permissions.ManageSuperAdmin,
    Permissions.ManageHospitalAdmin,
    Permissions.ManageHospitalUser,
    Permissions.ViewDashboardUsers,
    Permissions.ViewAdministation,
    Permissions.AdministerIndexEvents,
    Permissions.PerformPathwayActionsInListView,
  ],
  'super-admin': [
    Permissions.AdministerAllHospitals,
    Permissions.CreatePathway,
    Permissions.CreateRule,
    Permissions.InviteSuperAdmin,
    Permissions.ManageSuperAdmin,
    Permissions.ManageHospitalAdmin,
    Permissions.ManageHospitalUser,
    Permissions.ViewDashboardUsers,
    Permissions.ViewDataRequests,
    Permissions.ViewAdministation,
    Permissions.AdministerIndexEvents,
    Permissions.PerformPathwayActionsInListView,
  ],
  'hospital-admin': [
    Permissions.AdministerHospital,
    Permissions.ViewMessages,
    Permissions.ViewPatients,
    Permissions.ManagePatients,
    Permissions.InviteHospitalAdmin,
    Permissions.InviteHospitalUser,
    Permissions.ManageHospitalAdmin,
    Permissions.ManageHospitalUser,
    Permissions.ViewDashboardUsers,
    Permissions.ViewDataRequests,
    Permissions.ViewAdministation,
    Permissions.ViewHospitalMetrics,
  ],
  'hospital-user': [
    Permissions.ViewPatients,
    Permissions.ManagePatients,
    Permissions.ViewMessages,
    Permissions.ViewHospitalMetrics,
  ],
};
