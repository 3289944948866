import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks/usePageActions';
import { editPathway, fetchPathways } from '@pathways/redux/pathways/actions';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { fetchRules } from '@pathways/redux/rules/actions';
import { selectPathwayEditing, selectPathwayWithRules } from '@pathways/redux/pathways/reducers';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { extractSlugFromTypeSlug } from '@pathways/redux/indexEvents/utils';
import { matchType } from '../../../propTypes';
import PathwayWizard from './PathwayWizard';
import './style.less';

function EditPathway({ match }) {
  const [initialData, setInitialData] = useState({
    details: {},
    stages: {},
    stagedRules: {},
    indexEvents: {},
    indexEventRules: {
      indexEvents: [],
    },
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const editing = useSelector(selectPathwayEditing);
  const selector = useCallback(selectPathwayWithRules(match.params.id), [match.params.id]);
  const [loadingPathway, existingPathway] = useSelector(selector);
  const [loadingIndexEvents, indexEventsList] = useSelector(selectIndexEvents);

  const loading = loadingPathway || loadingIndexEvents;

  const { setTitle } = usePageActions({
    title: '',
  });

  const onCompleted = useCallback(
    formData => {
      const pathwayData = {
        ...formData.details,
        ...formData.stagedRules,
        ...formData.indexEvents,
        ...formData.indexEventRules,
        isActive: existingPathway.isActive,
        isDeleted: existingPathway.isDeleted,
      };

      dispatch(editPathway(match.params.id, pathwayData));
    },
    [dispatch, existingPathway, match],
  );

  useEffect(() => {
    dispatch(fetchPathways());
    dispatch(fetchIndexEvents());
    dispatch(fetchRules());
  }, [dispatch]);

  useEffect(() => {
    if (existingPathway) {
      const { name, description, metadata, indexEvents, isActive } = existingPathway;

      const indexEventTypes = indexEvents.reduce((acc, { eventTypeSlug }) => {
        const event = indexEventsList.find(
          ({ slug }) => extractSlugFromTypeSlug(eventTypeSlug) === slug,
        );
        return event ? [...acc, event] : acc;
      }, []);

      setTitle(`${t('common:buttons.edit')} ${name}`);
      document.title = `${t('common:buttons.edit')} ${name}`;
      setInitialData({
        details: {
          name,
          description,
          language: metadata.language,
          isActive: isActive,
        },
        stages: {
          ...existingPathway.stages.reduce((prev, stage) => {
            if (stage.isAdhoc) return prev;
            const stageNumber = `0${stage.number}`.slice(-2);
            return {
              ...prev,
              [`stage-${stageNumber}--name`]: stage.name,
              [`stage-${stageNumber}--description`]: stage.description,
            };
          }, {}),
        },
        stagedRules: {
          stages: existingPathway.stages.map(stage => ({
            ...stage,
            stageKey: `stage-${`0${stage.number}`.slice(-2)}`,
          })),
        },
        indexEvents: { indexEventTypes },
        indexEventRules: {
          indexEvents: indexEvents.map(ie => ({
            ...indexEventsList.find(({ slug }) => ie.eventTypeSlug === slug),
            ...ie,
          })),
        },
      });
    }
    // only recompute if loading or id changes
  }, [loading, match.params.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PathwayWizard
      initialData={initialData}
      isPathwayInUse={existingPathway?.numAppUserPathways > 0}
      loading={loading}
      submitting={editing}
      onCompleted={onCompleted}
    />
  );
}

EditPathway.propTypes = {
  match: matchType.isRequired,
};

export default EditPathway;
