import { call, getContext } from 'redux-saga/effects';
import { IMessagingClient } from '@liquid-state/messaging-client/dist/types';
import createMessagingClient from '@api/messagingClient';

function* doCreateMessagingClient() {
  const tokens = yield getContext('tokens');
  const client: IMessagingClient = yield call(createMessagingClient, tokens);

  return client;
}

export default doCreateMessagingClient;
