import {
  CREATE_PATHWAY,
  CREATE_PATHWAY_FAILURE,
  CREATE_PATHWAY_SUCCESS,
  DELETE_PATHWAY,
  DELETE_PATHWAY_FAILURE,
  DELETE_PATHWAY_SUCCESS,
  DELETE_PATHWAYS,
  DELETE_PATHWAYS_FAILURE,
  DELETE_PATHWAYS_SUCCESS,
  DUPLICATE_PATHWAY,
  DUPLICATE_PATHWAY_SUCCESS,
  DUPLICATE_PATHWAYS,
  DUPLICATE_PATHWAYS_SUCCESS,
  EDIT_PATHWAY,
  EDIT_PATHWAY_FAILED,
  EDIT_PATHWAY_SUCCESS,
  FETCH_PATHWAYS,
  FETCH_PATHWAYS_ERROR,
  FETCHING_PATHWAYS,
  LOAD_PATHWAYS,
  PUBLISH_PATHWAYS,
  PUBLISH_PATHWAYS_SUCCESS,
  UNPUBLISH_PATHWAYS,
  UNPUBLISH_PATHWAYS_SUCCESS,
  IPathwayData,
  IPathway,
} from './types';

import { IReduxAction } from '@redux/types';

// TODO - requires pathway type from pathways-client
export interface ICreatePathway
  extends IReduxAction<typeof CREATE_PATHWAY, { pathway: IPathwayData }> {}
export const createPathway = (pathway: IPathwayData) => ({
  type: CREATE_PATHWAY,
  payload: { pathway },
});

export interface ICreatePathwayFailure extends IReduxAction<typeof CREATE_PATHWAY_FAILURE> {}
export const createPathwayFailure = () => ({
  type: CREATE_PATHWAY_FAILURE,
  payload: {},
});

// TODO - requires pathway type from pathways-client
export interface ICreatePathwaySuccess
  extends IReduxAction<typeof CREATE_PATHWAY_SUCCESS, { pathway: IPathway }> {}
export const createPathwaySuccess = (pathway: IPathway) => ({
  type: CREATE_PATHWAY_SUCCESS,
  payload: { pathway },
});

export interface IDuplicatePathway
  extends IReduxAction<typeof DUPLICATE_PATHWAY, { pathwayId: number }> {}
export const duplicatePathway = (pathwayId: number) => ({
  type: DUPLICATE_PATHWAY,
  payload: { pathwayId },
});

export interface IDuplicatePathwaySuccess extends IReduxAction<typeof DUPLICATE_PATHWAY_SUCCESS> {}
export const duplicatePathwaySuccess = () => ({
  type: DUPLICATE_PATHWAY_SUCCESS,
  payload: {},
});

export interface IDuplicatePathways extends IReduxAction<typeof DUPLICATE_PATHWAYS> {}
export const duplicatePathways = (pathwayIds: Array<number>) => ({
  type: DUPLICATE_PATHWAYS,
  payload: { pathwayIds },
});

export interface IDuplicatePathwaysSuccess
  extends IReduxAction<typeof DUPLICATE_PATHWAYS_SUCCESS> {}
export const duplicatePathwaysSuccess = () => ({
  type: DUPLICATE_PATHWAYS_SUCCESS,
  payload: {},
});

export interface IDeletePathway
  extends IReduxAction<typeof DELETE_PATHWAY, { pathwayId: number }> {}
export const deletePathway = (pathwayId: number) => ({
  type: DELETE_PATHWAY,
  payload: { pathwayId },
});

export interface IDeletePathwayFailure
  extends IReduxAction<typeof DELETE_PATHWAY_FAILURE, { pathwayId: number }> {}
export const deletePathwayFailure = () => ({
  type: DELETE_PATHWAY_FAILURE,
  payload: {},
});

export interface IDeletePathwaySuccess
  extends IReduxAction<typeof DELETE_PATHWAY_SUCCESS, { pathwayId: number }> {}
export const deletePathwaySuccess = (pathwayId: number) => ({
  type: DELETE_PATHWAY_SUCCESS,
  payload: { pathwayId },
});

export interface IDeletePathways
  extends IReduxAction<typeof DELETE_PATHWAYS, { pathwayIds: Array<number> }> {}
export const deletePathways = (pathwayIds: Array<number>) => ({
  type: DELETE_PATHWAYS,
  payload: { pathwayIds },
});

export interface IDeletePathwaysFailure extends IReduxAction<typeof DELETE_PATHWAYS_FAILURE> {}
export const deletePathwaysFailure = () => ({
  type: DELETE_PATHWAYS_FAILURE,
  payload: {},
});

export interface IDeletePathwaysSuccess
  extends IReduxAction<typeof DELETE_PATHWAYS_SUCCESS, { pathwayIds: Array<number> }> {}
export const deletePathwaysSuccess = (pathwayIds: Array<number>) => ({
  type: DELETE_PATHWAYS_SUCCESS,
  payload: { pathwayIds },
});

// TODO
export interface IEditPathway
  extends IReduxAction<typeof EDIT_PATHWAY, { pathwayId: number; editedPathway: IPathway }> {}
export const editPathway = (pathwayId: number, editedPathway: any) => ({
  type: EDIT_PATHWAY,
  payload: { pathwayId, editedPathway },
});

// TODO - requires pathway type from pathways-client
export interface IEditPathwaySuccess
  extends IReduxAction<typeof EDIT_PATHWAY_SUCCESS, { pathway: IPathway }> {}
export const editPathwaySuccess = (pathway: IPathway) => ({
  type: EDIT_PATHWAY_SUCCESS,
  payload: { pathway },
});

export interface IEditPathwayFailed extends IReduxAction<typeof EDIT_PATHWAY_FAILED> {}
export const editPathwayFailed = () => ({
  type: EDIT_PATHWAY_FAILED,
  payload: {},
});

export interface IFetchPathways extends IReduxAction<typeof FETCH_PATHWAYS> {}
export const fetchPathways = (): IFetchPathways => ({
  type: FETCH_PATHWAYS,
  payload: {},
});

export interface IFetchingPathways extends IReduxAction<typeof FETCHING_PATHWAYS> {}
export const fetchingPathways = (): IFetchingPathways => ({
  type: FETCHING_PATHWAYS,
  payload: {},
});

export interface IFetchPathwaysError
  extends IReduxAction<typeof FETCH_PATHWAYS_ERROR, { error: string }> {}
export const fetchPathwaysError = (error: string): IFetchPathwaysError => ({
  type: FETCH_PATHWAYS_ERROR,
  payload: { error },
});

export interface ILoadPathways
  extends IReduxAction<typeof LOAD_PATHWAYS, { pathways: IPathway[] }> {}
export const loadPathways = (pathways: IPathway[]): ILoadPathways => ({
  type: LOAD_PATHWAYS,
  payload: { pathways },
});

export interface IPublishPathways
  extends IReduxAction<typeof PUBLISH_PATHWAYS, { pathwayIds: Array<number> }> {}
export const publishPathways = (pathwayIds: Array<number>): IPublishPathways => ({
  type: PUBLISH_PATHWAYS,
  payload: { pathwayIds },
});

export interface IPublishPathwaysSuccess
  extends IReduxAction<typeof PUBLISH_PATHWAYS_SUCCESS, { pathwayIds: Array<number> }> {}
export const publishPathwaysSuccess = (pathwayIds: Array<number>): IPublishPathwaysSuccess => ({
  type: PUBLISH_PATHWAYS_SUCCESS,
  payload: { pathwayIds },
});

export interface IUnpublishPathways
  extends IReduxAction<typeof UNPUBLISH_PATHWAYS, { pathwayIds: Array<number> }> {}
export const unpublishPathways = (pathwayIds: Array<number>): IUnpublishPathways => ({
  type: UNPUBLISH_PATHWAYS,
  payload: { pathwayIds },
});

export interface IUnpublishPathwaysSuccess
  extends IReduxAction<typeof UNPUBLISH_PATHWAYS_SUCCESS, { pathwayIds: Array<number> }> {}
export const unpublishPathwaysSuccess = (pathwayIds: Array<number>) => ({
  type: UNPUBLISH_PATHWAYS_SUCCESS,
  payload: { pathwayIds },
});
