import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createLanguageFilters } from '@utils/supportedLanguages';
import { contentTypes, documentTypes } from '@utils/contentTypes';
import DocumentPublishedStatusColumn from '@cards/Documents/List/PublishedStatusColumn';
import PublishedStatusIcon from '../PublishedStatusIcon';
import LanguageIcon from '../LanguageIcon';

const createColumns = (t, dataSourceContentTypes, defaultFilteredValue) => [
  {
    title: t('cards:DocumentList.columns.language.title'),
    defaultFilteredValue: defaultFilteredValue?.language || [],
    key: 'language',
    render: data => <LanguageIcon language={data.metadata.language || data.language} />,
    width: 150,
    sorter: (a, b) => (a.metadata.language || a.language) > (b.metadata.language || b.language),
    filters: createLanguageFilters(t),
    onFilter: (value, record) => {
      const lang = record.metadata.language || record.language || '';
      return lang.toLowerCase() === value;
    },
    fixed: 'left',
  },
  ...(dataSourceContentTypes.length > 1
    ? [
        {
          title: t('common:modals.SelectContent.columns.contentType'),
          dataIndex: 'metadata',
          key: 'type',
          render: ({ type }) => (
            <span className="fa-icon-with-text">
              <FontAwesomeIcon icon={contentTypes[type]?.icon} />
              {t(contentTypes[type]?.label)}
            </span>
          ),
          width: 150,
          sorter: (a, b) => a.metadata.type > b.metadata.type,
          filters: dataSourceContentTypes.map(type => ({
            text: t(contentTypes[type]?.label),
            value: type,
          })),
          onFilter: (value, record) => record.metadata.type === value,
        },
      ]
    : []),
  {
    title: t('common:modals.SelectContent.columns.title'),
    dataIndex: 'name',
    key: 'title',
    width: 150,
    sorter: (a, b) => a.name.localeCompare(b.name),
  },
  {
    title: t('common:modals.SelectContent.columns.published'),
    key: 'published',
    render: ({ id, metadata: { published, type } }) => {
      if (documentTypes.includes(type)) {
        return <DocumentPublishedStatusColumn documentId={id} />;
      }
      return <PublishedStatusIcon published={published} />;
    },
    width: 150,
    sorter: (a, b) => a.metadata.published > b.metadata.published,
  },
];

export default createColumns;
