import React from 'react';
import LanguageIcon from '@components/LanguageIcon';
import { spain, unitedKingdom, germany, czech, japan, slovakia } from '../assets/icons';
import { faGlobe, faFlagAlt, faHospital } from '@fortawesome/pro-regular-svg-icons';
import i18n from '../i18n';

export const selectSourceLegend = source => {
  switch (source) {
    case 'global':
    case 'GLOBAL':
      return i18n.t('common:sources.global');
    case 'country':
    case 'COUNTRY':
      return i18n.t('common:sources.country');
    case 'hospital':
    case 'HOSPITAL':
      return i18n.t('common:sources.hospital');
    default:
      return i18n.t('common:sources.global');
  }
};

export const selectSourceIcon = source => {
  switch (source) {
    case 'global':
    case 'GLOBAL':
      return faGlobe;
    case 'country':
    case 'COUNTRY':
      return faFlagAlt;
    case 'hospital':
    case 'HOSPITAL':
      return faHospital;
    default:
      return faGlobe;
  }
};

const supportedLanguages = {
  en: {
    key: 'en',
    name: 'common:languages.english',
    icon: unitedKingdom,
  },
  es: {
    key: 'es',
    name: 'common:languages.spanish',
    icon: spain,
  },
  de: {
    key: 'de',
    name: 'common:languages.german',
    icon: germany,
  },
  cs: {
    key: 'cs',
    name: 'common:languages.czech',
    icon: czech,
  },
  ja: {
    key: 'ja',
    name: 'common:languages.japanese',
    icon: japan,
  },
  sk: {
    key: 'sk',
    name: 'common:languages.slovak',
    icon: slovakia,
  },
};

export const createLanguageFilters = t =>
  Object.values(supportedLanguages).map(language => ({
    text: (
      <span className="table-language-filters-label">
        <LanguageIcon language={language.key} /> <span>{t(language.name)}</span>
      </span>
    ),
    value: language.key,
  }));

export default supportedLanguages;
