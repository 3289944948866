export const FETCH_FORMS = 'FETCH_FORMS';
export const FETCH_FORM = 'FETCH_FORM';
export const LOAD_FORMS = 'LOAD_FORMS';
export const FORMS_LOADED = 'FORMS_LOADED';
export const FORM_LOADED = 'FORM_LOADED';
export const LOAD_FORMS_FAILED = 'LOAD_FORMS_FAILED';

export const GRANT_INDIVIDUAL_FORMS_ACCESS = 'GRANT_INDIVIDUAL_FORMS_ACCESS';
export const REVOKE_INDIVIDUAL_FORMS_ACCESS = 'REVOKE_INDIVIDUAL_FORMS_ACCESS';
export const GRANT_GROUP_FORMS_ACCESS = 'GRANT_GROUP_FORMS_ACCESS';
export const REVOKE_GROUP_FORMS_ACCESS = 'REVOKE_GROUP_FORMS_ACCESS';

export const FETCH_FORM_DATA = 'FETCH_FORM_DATA';
export const FETCH_FORM_DATA_SUCCESS = 'FETCH_FORM_DATA_SUCCESS';
export const FETCH_FORM_DATA_FAILED = 'FETCH_FORM_DATA_FAILED';

export const FETCH_VERSION_FORM_DATA = 'FETCH_VERSION_FORM_DATA';
export const FETCH_VERSION_FORM_DATA_SUCCESS = 'FETCH_VERSION_FORM_DATA_SUCCESS';
export const FETCH_VERSION_FORM_DATA_FAILED = 'FETCH_VERSION_FORM_DATA_FAILED';

export const DOWNLOAD_FORMS = 'DOWNLOAD_FORMS';
export const PUBLISH_FORM = 'PUBLISH_FORM';
export const PUBLISH_FORM_SUCCESS = 'PUBLISH_FORM_SUCCESS';
export const PUBLISH_FORM_FAILED = 'PUBLISH_FORM_FAILED';
export const UNPUBLISH_FORM = 'UNPUBLISH_FORM';
export const UNPUBLISH_FORM_SUCCESS = 'UNPUBLISH_FORM_SUCCESS';
export const UNPUBLISH_FORM_FAILED = 'UNPUBLISH_FORM_FAILED';

export const CREATE_FORM = 'CREATE_FORM';
export const FORM_CREATED = 'FORM_CREATED';

export const UPDATE_FORM = 'UPDATE_FORM';
export const FORM_UPDATED = 'FORM_UPDATED';
export const DELETE_FORMS = 'DELETE_FORMS';
export const FORMS_DELETED = 'FORMS_DELETED';

export interface IForm {
  type: 'FORM';
  created: string;
  description: string;
  language: string;
  metadata: {
    fileName: string;
    type: string;
    [key: string]: any;
  };
  name: string;
  published: boolean;
  owner: string;
  uuid: string;
  version: number;
}

export interface IFormData {
  uiSchema: { [key: string]: any };
  schema: { [key: string]: any };
}

export interface IVersionFormData extends IFormData {
  formData: object;
}
