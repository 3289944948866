import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PathwayList from './List';
import PathwayDetails from './Details';

function Pathways({ match }) {
  return (
    <Switch>
      <Route path={`${match.path}/:pathwayId`} component={PathwayDetails} />
      <Route path={match.path} component={PathwayList} />
    </Switch>
  );
}

export default Pathways;
