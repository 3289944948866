import React from 'react';
import { Switch, Route } from 'react-router-dom';
import InvalidProfile from './InvalidProfile';

const ErrorsIndex = () => {
  return (
    <Switch>
      <Route exact path="/auth/error/invalid-profile" component={InvalidProfile} />
    </Switch>
  );
};

export default ErrorsIndex;
