import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { channel } from 'redux-saga';
import { put, call, take, spawn, takeLatest, getContext, cps, select } from 'redux-saga/effects';
import { userPoolId, userPoolAppClient } from 'settings';
import { mapRoleURIToUserType } from '@constants';
import { doFetchHospitals } from '@redux/hospitals/sagas';
import { selectHospital } from '@redux/hospitals/reducers';
import { languageCodeToName, roleSlugToName } from '@hooks/useDashboardUserAnalyticsProperties';
import {
  LOGIN_SUBMITTED,
  authenticationRequired,
  authenticationFailed,
  AUTHENTICATION_SUBMITTED,
  loginRequired,
  loginFailed,
  sessionEstablished,
  LOGOUT,
  logoutSuccesful,
} from './actions';
import i18n from '../../i18n';
import {
  setIdentity,
  setSuperProperties,
  track,
  trackApplicationEvent,
  reset as analyticsReset,
} from '../../analytics';

export default function* loginSaga() {
  yield spawn(doCheckSession);
  yield takeLatest(LOGIN_SUBMITTED, onLoginSubmitted);
  yield takeLatest(LOGOUT, doLogout);
}

export const getUserPool = () => {
  return new CognitoUserPool({
    UserPoolId: userPoolId,
    ClientId: userPoolAppClient,
  });
};

export function* establishSession(user) {
  const tokens = yield getContext('tokens');
  yield call(tokens.updateUser, user);
  const hospitalData = {};

  if (!tokens.loginProfileData.hospitalSlug) {
    yield call(doFetchHospitals);
    const [, hospital] = yield select(selectHospital(tokens.loginProfileData.hospitalId));

    if (hospital) {
      const { name: hospitalName, slug: hospitalSlug } = hospital;
      hospitalData.hospitalName = hospitalName;
      hospitalData.hospitalSlug = hospitalSlug;
    }
  }

  const attributes = yield cps(user.getUserAttributes.bind(user));
  const id = attributes.find(({ Name }) => Name === 'sub').Value;

  const firstName = tokens.loginProfileData.firstName || '';
  const lastName = tokens.loginProfileData.lastName || '';

  const storedLanguagePreference = localStorage.getItem(`${tokens.email}-language`);
  if (storedLanguagePreference) {
    i18n.changeLanguage(storedLanguagePreference);
  }

  const applicationRole = mapRoleURIToUserType[tokens.role];

  trackApplicationEvent('Login success');
  const analyticsProperties = {
    role: tokens.role,
    application_role: applicationRole,
    ...hospitalData,
    ...tokens.loginProfileData,
  };

  const userProfile = {
    name: `${firstName} ${lastName}`,
    role: applicationRole,
    email: tokens.email,
    profile: {
      ...hospitalData,
      ...tokens.loginProfileData,
    },
  };

  const analtyicsUserProperties = {
    language: languageCodeToName[userProfile.profile.language],
    ...(analyticsProperties.hospitalName ? { hospital: analyticsProperties.hospitalName } : {}),
    role: roleSlugToName[userProfile.role],
  };

  setIdentity(id);
  setSuperProperties(analyticsProperties);
  trackApplicationEvent('Session started', analtyicsUserProperties);

  yield put(sessionEstablished(id, userProfile));
}

function* doCheckSession() {
  const userPool = getUserPool();
  const user = userPool.getCurrentUser();
  if (!user) {
    yield put(loginRequired());
    return;
  }
  try {
    yield cps(user.getSession.bind(user));
    yield call(establishSession, user);
  } catch (err) {
    // Invalid session
    console.error(err);
    yield put(loginRequired());
    return;
  }
}

export function* doCognitoLogin(user, email, password) {
  const callbackChan = channel();
  yield call(
    user.authenticateUser.bind(user),
    new AuthenticationDetails({
      Username: email,
      Password: password,
    }),
    {
      onFailure: () => callbackChan.put({ status: 'FAILURE' }),
      onSuccess: () => callbackChan.put({ status: 'SUCCESS' }),
      newPasswordRequired: () => callbackChan.put({ status: 'NEW_PASSWORD_REQUIRED' }),
      mfaRequired: (_, { CODE_DELIVERY_DESTINATION: phoneNumber }) =>
        callbackChan.put({ status: 'MFA', phoneNumber }),
    },
  );
  const result = yield take(callbackChan);

  return result;
}

function* onLoginSubmitted({ payload: { email, password } }) {
  const userPool = getUserPool();
  const user = new CognitoUser({ Username: email, Pool: userPool });
  const result = yield call(doCognitoLogin, user, email, password);

  // eslint-disable-next-line default-case
  switch (result.status) {
    case 'SUCCESS':
      yield spawn(establishSession, user);
      return;
    // case "NEW_PASSWORD_REQUIRED":
    //   yield put(changePasswordRequired(user));
    //   return user;
    case 'FAILURE':
      trackApplicationEvent('Login failure');
      yield put(loginFailed());
      return;
    case 'MFA':
      // It is likely that the phone number that the message was sent to
      // Is likely in the challengeParameters returned from mfaRequired()
      // But the documentation isn't great we'll likely need to look at the
      // Call in the debugger.
      trackApplicationEvent('MFA required');
      yield put(authenticationRequired(result.phoneNumber));
      yield call(doMFAValidation, user);
      return;
  }
}

function* doMFAValidation(user) {
  const history = yield getContext('history');
  yield call(history.replace, '/auth/login/verify');

  while (true) {
    const {
      payload: { code },
    } = yield take(AUTHENTICATION_SUBMITTED);
    const chan = channel();
    track('MFA submitted');
    yield call(user.sendMFACode.bind(user), code, {
      onSuccess: () => chan.put('SUCCESS'),
      onFailure: () => chan.put('FAILURE'),
    });
    const result = yield take(chan);
    if (result === 'SUCCESS') {
      track('Login success');
      yield call(establishSession, user);
      break;
    } else {
      track('Login failure');
      yield put(authenticationFailed());
    }
  }
}

function* doLogout() {
  const tokens = yield getContext('tokens');
  const pool = getUserPool();
  const user = yield call(pool.getCurrentUser.bind(pool));
  if (user) {
    yield call(() => Promise.all([tokens.logout(), user.signOut()]));
  }
  trackApplicationEvent('Logout');
  analyticsReset();
  window.localStorage.clear();
  yield put(logoutSuccesful());
  window.location.reload();
}
