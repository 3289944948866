import createUbiquityClient from '@api/ubiquityClient';
import { call, getContext } from 'redux-saga/effects';

function* doCreateUbiquityClient() {
  const tokens = yield getContext('tokens');
  const client = yield call(createUbiquityClient, tokens);

  return client;
}

export default doCreateUbiquityClient;
