import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';
import DetailForm from '@components/DetailForm';
import { Card } from '@cards/Card';
import { fetchGDPRContacts, saveGDPRContact } from '@redux/requests/actions';
import {
  selectContacts,
  selectContactsLanguage,
  isRequestsContactsSubmitting,
} from '@redux/requests/reducer';
import { antFormType } from '../../../propTypes';
import LanguageSelect from '@components/LanguageSelect';

function GDPR({ form }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, contacts] = useSelector(selectContacts);
  const [, contactsLanguage] = useSelector(selectContactsLanguage);
  const isSubmitting = useSelector(isRequestsContactsSubmitting);
  const contact = contacts[0];

  useEffect(() => {
    dispatch(fetchGDPRContacts());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({ email: contact });
  }, [contact]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = event => {
    event.preventDefault();
    form.validateFields((err, data) => {
      if (err) return;
      dispatch(saveGDPRContact(data.email, data.language));
    });
  };

  return (
    <Card className="gdpr-contact" loading={loading} title={t('dataRequests:GDPRCard.title')}>
      <p>{t('dataRequests:GDPRCard.explain')}</p>
      {contact ? (
        <>
          <p>
            {t('dataRequests:GDPRCard.email')} <strong>{contact}</strong>
          </p>
        </>
      ) : null}
      <DetailForm formProps={{ hideRequiredMark: true, onSubmit }}>
        <Form.Item label={t('common:email')}>
          {form.getFieldDecorator('email', {
            initalValue: contacts[0] || '',
            rules: [
              {
                required: true,
                message: t('common:validation.Email.emptyError'),
              },
              {
                type: 'email',
                message: t('common:validation.Email.invalidError'),
              },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={t('common:Wizard.details.language.label')}>
          {form.getFieldDecorator('language', {
            initialValue: contactsLanguage || 'en',
            rules: [
              {
                required: true,
                message: t('common:Wizard.details.language.required'),
              },
            ],
          })(<LanguageSelect />)}
        </Form.Item>
        <Button htmlType="submit" loading={isSubmitting} type="primary">
          {t('common:buttons.save')}
        </Button>
      </DetailForm>
    </Card>
  );
}

GDPR.propTypes = {
  form: antFormType.isRequired,
};

export default Form.create()(GDPR);
