import { takeEvery } from 'redux-saga/effects';
import takeFirst from '@redux/takeFirst';
import {
  CREATE_RULE,
  DELETE_RULE,
  DELETE_RULES,
  DUPLICATE_RULE,
  DUPLICATE_RULES,
  EDIT_RULE,
  FETCH_RULES,
} from '../types';
import { doDeleteRule, doDeleteRules } from './delete';
import { doDuplicateRule, doDuplicateRules } from './duplicate';
import { doFetchRules } from './fetch';
import { doCreateRule, doEditRule } from './save';

export default function* root() {
  yield takeEvery(CREATE_RULE, doCreateRule);
  yield takeEvery(DELETE_RULE, doDeleteRule);
  yield takeEvery(DELETE_RULES, doDeleteRules);
  yield takeEvery(DUPLICATE_RULE, doDuplicateRule);
  yield takeEvery(DUPLICATE_RULES, doDuplicateRules);
  yield takeEvery(EDIT_RULE, doEditRule);
  yield takeFirst(FETCH_RULES, doFetchRules);
}
