import { put, select, take } from 'redux-saga/effects';
import { fetchHospitals } from './actions';
import { selectHospital } from './reducers';
import { IHospital, LOAD_HOSPITALS_SUCCESS } from './types';

export function* selectAndLoadHospital(hospitalId: string) {
  let [, hospital]: [boolean, IHospital | undefined] = yield select(selectHospital(hospitalId));
  if (!hospital) {
    yield put(fetchHospitals());
    yield take(LOAD_HOSPITALS_SUCCESS);
    [, hospital] = yield select(selectHospital(hospitalId));
  }
  return hospital;
}
