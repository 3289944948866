import { useState, useCallback, useEffect } from 'react';

const comparator = searchTerm => value =>
  value && value.toLowerCase().includes(searchTerm.toLowerCase());

export default (initialData, filter, minLength = 0, initialSearchTerm = '') => {
  const [searchTerm, setTerm] = useState(initialSearchTerm);
  const [results, setResults] = useState(initialData);
  const clearSearch = useCallback(() => setTerm(''), [setTerm]);

  useEffect(() => {
    if (!searchTerm || searchTerm.length < minLength) {
      setResults(initialData);
      return;
    }
    const compareFunc = comparator(searchTerm);
    setResults(initialData.filter(value => filter(value, compareFunc, searchTerm)));
  }, [searchTerm, initialData, filter, minLength]);

  return [
    results,
    {
      value: searchTerm,
      onChange: event => {
        const value = event.target.value;
        setTerm(value);
      },
      onSearch: setTerm,
    },
    clearSearch,
  ];
};
