import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Container from '../components/Container';
import Authentication from './Authentication';
import AuthValidator from './Authentication/AuthValidator';
import AppUserPages from './AppUsers';
import Documents from './Documents/';
import ProcedurePathways from '@pathways/pages/Pathways';
import { selectVideoDocuments, selectNonVideoDocuments } from '../redux/documents/reducers';
import {
  selectContentAlerts,
  selectContentMessages,
  selectContentReminders,
} from '../redux/messages/reducers';
import {
  selectContentForms,
  selectContentQuestionnaires,
  selectContentTasks,
} from '../redux/forms/reducers';
import ReviewHub from '@reviewHub/pages';
import MyProfilePage from './MyProfile';
import FormPages from './Forms';
import CannedMessages from './CannedMessages';
import Messages from './Messages';
import HomePage from './Home';
import Administration from './Administration';
import NewVideo from './Documents/VideoWizard/New';
import EditVideo from './Documents/VideoWizard/Edit';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';

const App = () => {
  const permissions = useHasPermissions(Permissions.ViewPatients);
  return (
    <AuthValidator>
      <Switch>
        <Route path="/auth" component={Authentication} />
        <Container>
          {permissions[Permissions.ViewPatients] ? (
            <Route path="/patients/individuals" component={AppUserPages} />
          ) : null}
          <Route
            path="/content/documents"
            render={props => (
              <Documents
                {...props}
                documentTranslationKey="documents"
                selector={selectNonVideoDocuments}
              />
            )}
          />
          <Route
            path="/content/videos"
            render={props => (
              <Documents {...props} documentTranslationKey="videos" selector={selectVideoDocuments}>
                <Route path={'/content/videos/new'} component={NewVideo} />
                <Route path={'/content/videos/:id/edit'} component={EditVideo} />
              </Documents>
            )}
          />
          <Route
            path="/content/forms"
            render={props => (
              <FormPages
                {...props}
                type="FORM"
                formTranslationKey="forms"
                selector={selectContentForms}
              />
            )}
          />
          <Route
            path="/content/questionnaires"
            render={props => (
              <FormPages
                {...props}
                type="QUESTIONNAIRE"
                formTranslationKey="questionnaire"
                selector={selectContentQuestionnaires}
              />
            )}
          />
          <Route
            path="/content/tasks"
            render={props => (
              <FormPages
                {...props}
                type="TASK"
                formTranslationKey="tasks"
                selector={selectContentTasks}
              />
            )}
          />
          <Route
            path="/content/messages"
            render={props => (
              <CannedMessages
                {...props}
                type="MESSAGE"
                messageTranslationKey="messages"
                selector={selectContentMessages}
              />
            )}
          />
          <Route
            path="/content/alerts"
            render={props => (
              <CannedMessages
                {...props}
                type="ALERT"
                messageTranslationKey="alerts"
                selector={selectContentAlerts}
              />
            )}
          />
          <Route
            path="/content/reminders"
            render={props => (
              <CannedMessages
                {...props}
                type="REMINDER"
                messageTranslationKey="reminders"
                selector={selectContentReminders}
              />
            )}
          />
          <Route path="/procedure" component={ProcedurePathways} />
          <Route path="/messages" component={Messages} />
          <Route path="/administration" component={Administration} />
          <Route path="/my-profile" component={MyProfilePage} />
          <Route path="/review-hub" component={ReviewHub} />
          <Route exact path="/" component={HomePage} />
        </Container>
      </Switch>
    </AuthValidator>
  );
};

export default withTranslation()(App);
