import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { extractSlugFromTypeSlug } from '@pathways/redux/indexEvents/utils';
import { Card } from '@cards/Card';
import RuleTable from './RuleTable';
import './style.less';

function IndexEventTriggeredRulesCard({ loading, pathway }) {
  const { i18n, t } = useTranslation();
  const [ieLoading, indexEventTypes] = useSelector(selectIndexEvents);

  const indexEvents = useMemo(
    () =>
      indexEventTypes.length && pathway
        ? indexEventTypes
            .map(indexEventType => {
              const ies = pathway.indexEvents || [];
              const indexEvent = ies.find(
                ie => extractSlugFromTypeSlug(ie.eventTypeSlug) === indexEventType.slug,
              );
              return indexEvent ? { ...indexEvent, ...indexEventType } : undefined;
            })
            .filter(ie => !!ie)
        : [],
    [indexEventTypes, pathway],
  );

  const rulesByTiming = useMemo(() => {
    return indexEvents.reduce(
      (ruleTimings, indexEvent) => ({
        ...ruleTimings,
        [indexEvent.slug]: indexEvent.rules.reduce((rulesByTimings, rule) => {
          const ruleTiming = rule.whenDetail.days || '0';
          return {
            ...rulesByTimings,
            [ruleTiming]: [...(rulesByTimings[ruleTiming] || []), rule],
          };
        }, {}),
      }),
      {},
    );
  }, [indexEvents]);

  if (!pathway || pathway.indexEvents.length === 0) return null;
  return (
    <Card.Half
      className="index-event-triggered-rules-card"
      loading={loading || ieLoading}
      title={t('cards:ProcedurePathwayIndexEvents.title')}
    >
      <Tabs>
        {indexEvents.map(indexEvent => {
          return (
            <Tabs.TabPane
              tab={indexEvent?.translatedNames[i18n.language.slice(0, 2)] || indexEvent.name}
              key={indexEvent.slug}
            >
              <RuleTable
                indexEventsByDate={{ '0': [indexEvent] }}
                rulesByDate={rulesByTiming[indexEvent.slug]}
              />
            </Tabs.TabPane>
          );
        })}
      </Tabs>
    </Card.Half>
  );
}

export default IndexEventTriggeredRulesCard;
