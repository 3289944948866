import React from 'react';
import { useSelector } from 'react-redux';
import { selectPermissionsForUser } from '@authorisation/selectors';
import useWizard from '@components/Wizard/useWizard';
import { ColumnGroup, Column } from '@cards/Card';
import Details from '@cards/Messages/Details';
import AttachedContent from '@cards/Messages/AttachedContent';
import { determineSourceTypeForNewContent } from '@utils';
import { OPEN_CONTENT } from '@constants';

import './styles.less';

const MessageReviewStep = ({ formTranslationKey, type }) => {
  const { formData } = useWizard();
  const permissions = useSelector(selectPermissionsForUser);

  const message = {
    metadata: {
      type,
      language: formData.details?.language || 'en',
    },
    title: formData.details.title,
    content: formData.content.content,
    published: formData.details.status === 'publish',
    source: determineSourceTypeForNewContent(permissions),
    payloadOptions: {
      data:
        formData.content.action === OPEN_CONTENT
          ? formData.content.contentData
          : formData.content.websiteLink,
      action: formData.content.action,
    },
  };

  return (
    <ColumnGroup>
      <Column>
        <Details message={message} />
        {message.payloadOptions.action === 'open-content' ? (
          <AttachedContent content={formData.content.contentData} />
        ) : null}
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};

export default MessageReviewStep;
