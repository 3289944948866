import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useHasPermissions } from '@authorisation/hooks';
import PathwayList from './PathwayList';
import RuleList from './RuleList';
import EditPathway from './EditPathway';
import NewPathway from './NewPathway';
import NewRule from './NewRule';
import EditRule from './EditRule';
import RuleDetails from './RuleDetails';
import PathwayDetails from './PathwayDetails';
import { Permissions } from '@authorisation/constants';

export default ({ match }) => {
  const perms = useHasPermissions();

  return (
    <Switch>
      {perms[Permissions.CreateRule] && (
        <Route path={`${match.path}/rules/new`} component={NewRule} />
      )}
      <Route path={`${match.path}/rules/:id/edit`} component={EditRule} />
      <Route path={`${match.path}/rules/:id`} component={RuleDetails} />
      <Route path={`${match.path}/rules`} component={RuleList} />

      {perms[Permissions.CreatePathway] && (
        <Route path={`${match.path}/pathways/new`} component={NewPathway} />
      )}
      <Route path={`${match.path}/pathways/:id/edit`} component={EditPathway} />
      <Route path={`${match.path}/pathways/:id`} component={PathwayDetails} />
      <Route path={`${match.path}/pathways`} component={PathwayList} />
      <Route path={match.path} render={() => <Redirect to={`${match.path}/pathways`} />} />
    </Switch>
  );
};
