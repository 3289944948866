import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Input, Modal, Table } from 'antd';
import { selectRulesFilteredByWhen } from '@pathways/redux/rules/reducers';
import { fetchRules } from '@pathways/redux/rules/actions';
import { useSearch } from '@hooks';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => comparator(value.name);

function SelectRulesModal({ defaultFilteredValue, filters, selectedRules, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [{ selectedRowKeys }, setSelected] = useState({
    selectedRowKeys: [],
  });
  const selector = useCallback(selectRulesFilteredByWhen(filters), [filters]);

  const [loading, rules] = useSelector(selector);
  const columns = useMemo(() => createColumns(t, defaultFilteredValue), [defaultFilteredValue, t]);
  const onOkClick = useCallback(() => {
    onOk(rules.filter(rule => selectedRowKeys.includes(rule.id)));
    onCancel();
  }, [rules, selectedRowKeys, onCancel, onOk]);

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  const [searchResults, searchProps] = useSearch(rules, filterFunc);
  const selectedRuleIds = selectedRules.map(sr => sr.id);
  const dataSource = searchResults.filter(result => !selectedRuleIds.includes(result.id));

  return (
    <Modal
      className="choose-content-modal"
      destroyOnClose
      title={t('pathways:ProcedurePathways.wizard.stagedRules.modalTitle')}
      visible
      onCancel={onCancel}
      onOk={onOkClick}
    >
      <Input.Search placeholder={t('common:search')} {...searchProps} />
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: selectedRowKeys => setSelected({ selectedRowKeys }),
        }}
      />
    </Modal>
  );
}

SelectRulesModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectRulesModal;
