import React, { useCallback, useEffect, useState } from 'react';
import useWizard from '@components/Wizard/useWizard';
import i18n from 'i18next';
import AddRulesToStage from './AddRulesToStage';
import {
  DELAY,
  INDEX_EVENT,
  STAGE_TRANSITION,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
} from '@constants';
import './style.less';

const nonAlignedStage = 'stage-99';
const extractStageKeys = /(stage-\d\d)--(.*)/;

function getExistingStageData(stages, key) {
  if (!stages) return {};
  const stage = stages.find(({ stageKey }) => key === stageKey);

  return stage || {};
}

function StagedRules() {
  const [stages, setStages] = useState([]);
  const { currentStepData, form, formData, setFormData } = useWizard();

  const updateStageData = useCallback(
    data => {
      setStages(data);
      form.setFieldsValue({ stages: data });
    },
    [form],
  );

  const pathwayLanguage = i18n.getFixedT(formData.details.language);

  useEffect(() => {
    if (formData.stages.stages) {
      updateStageData(
        formData.stages.stages.map(stage => ({
          ...stage,
          ...(stage.isAdhoc
            ? {}
            : {
                name: formData.stages[`${stage.stageKey}--name`],
                description: formData.stages[`${stage.stageKey}--description`],
              }),
        })),
      );
      setFormData({
        ...formData,
        stages: {
          ...formData.stages,
          stages: null,
        },
      });
    } else {
      const existingStageData = Object.values(
        Object.entries(formData.stages)
          .filter(([key]) => key !== 'stages')
          .reduce((prev, [key, data], index) => {
            const [, stageKey, dataKey] = extractStageKeys.exec(key);

            const existingDataForStage =
              getExistingStageData(currentStepData.stages, stageKey) || {};

            return {
              ...prev,
              [stageKey]: {
                ...existingDataForStage,
                ...prev[stageKey],
                isAdhoc: false,
                // / 2 because there are two entries from the previous step for each stage
                number: Math.round((index + 1) / 2),
                stageKey,
                rules: existingDataForStage.rules || [],
                [dataKey]: data,
              },
            };
          }, {}),
      );

      const existingAdhocStage = getExistingStageData(currentStepData.stages, 'stage-99');

      updateStageData(
        [
          ...existingStageData,
          {
            ...existingAdhocStage,
            isAdhoc: true,
            name: pathwayLanguage('pathways:ProcedurePathways.wizard.stagedRules.notAligned'),
            number: 99,
            stageKey: nonAlignedStage,
            rules: getExistingStageData(currentStepData.stages, nonAlignedStage).rules || [],
          },
        ].sort((a, b) => {
          if (a.stageKey > b.stageKey) return 1;
          if (a.stageKey < b.stageKey) return -1;
          return 0;
        }),
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addRules = useCallback(
    stageIndex => rules =>
      updateStageData([
        ...stages.slice(0, stageIndex),
        {
          ...stages[stageIndex],
          rules: [...stages[stageIndex].rules, ...rules],
        },
        ...stages.slice(stageIndex + 1),
      ]),
    [stages, updateStageData],
  );

  const removeRule = useCallback(
    stageIndex => ruleId =>
      updateStageData([
        ...stages.slice(0, stageIndex),
        {
          ...stages[stageIndex],
          rules: stages[stageIndex].rules.filter(({ id }) => id !== ruleId),
        },
        ...stages.slice(stageIndex + 1),
      ]),
    [stages, updateStageData],
  );

  return (
    <>
      {form.getFieldDecorator('stages')(<span />)}
      {stages.map(({ rules, stageKey, name }, index) => (
        <AddRulesToStage
          defaultFilteredValue={{ language: [formData.details.language] }}
          filters={
            stageKey === nonAlignedStage
              ? [
                  DELAY,
                  INDEX_EVENT,
                  STAGE_TRANSITION,
                  FORM_SUBMITTED,
                  QUESTIONNAIRE_SUBMITTED,
                  TASK_SUBMITTED,
                ]
              : [DELAY, STAGE_TRANSITION, FORM_SUBMITTED, QUESTIONNAIRE_SUBMITTED, TASK_SUBMITTED]
          }
          key={stageKey}
          selectedRules={rules}
          title={
            stageKey === nonAlignedStage
              ? pathwayLanguage('pathways:ProcedurePathways.wizard.stagedRules.notAligned')
              : `${index + 1}. ${name}`
          }
          addRules={addRules(index)}
          removeRule={removeRule(index)}
        />
      ))}
    </>
  );
}

export default StagedRules;
