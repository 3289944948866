import { selectCurrentDashboardUser } from '@redux/login/reducer';
import { useSelector } from 'react-redux';

export const languageCodeToName = {
  cs: 'Czech',
  de: 'German',
  en: 'English',
  es: 'Spanish',
};

export const roleSlugToName = {
  'hospital-admin': 'Hospital Admin',
  'hospital-user': 'Professional User',
  'super-admin': 'Super Admin',
};

function useDashboardUserAnalyticsProperties() {
  const currentDashboardUser = useSelector(selectCurrentDashboardUser);

  if (currentDashboardUser) {
    return {
      language: languageCodeToName[currentDashboardUser.profile.language || 'en'],
      hospital: currentDashboardUser.profile.hospitalName,
      role: roleSlugToName[currentDashboardUser.role],
    };
  }

  return {};
}

export default useDashboardUserAnalyticsProperties;
