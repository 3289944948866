import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Input, Table } from 'antd';
import { useSearch } from '../../hooks';
import './style.less';

function SingleSelectModal({
  filterFunc,
  title,
  visible,
  onCancel,
  loading,
  width,
  primaryKey,
  columns,
  dataSource,
  onSubmit,
}) {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dataSourceWithKeys = useMemo(
    () => dataSource.map(data => ({ key: data[primaryKey], ...data })),
    [dataSource, primaryKey],
  );
  const [searchResults, searchProps] = useSearch(dataSourceWithKeys, filterFunc);

  const rowSelection = useMemo(
    () => ({
      type: 'radio',
      selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
      },
    }),
    [selectedRowKeys],
  );

  return (
    <Modal
      className="single-selection-modal"
      footer={[
        <div className="footer-left" key="footer-button-select-all" />,
        <div className="footer-right" key="footer-button-add">
          <Button
            disabled={!selectedRowKeys.length}
            type="primary"
            onClick={() => onSubmit(selectedRowKeys, selectedRows)}
          >
            {t('common:selectionModal.add')}
          </Button>
        </div>,
      ]}
      title={title}
      visible={visible}
      width={width}
      onCancel={onCancel}
    >
      <div className="modal-search">
        <Input.Search placeholder={t('common:search')} {...searchProps} />
      </div>
      <Table
        columns={columns}
        dataSource={searchResults}
        loading={loading}
        pagination={{ position: 'bottom' }}
        rowSelection={rowSelection}
      />
    </Modal>
  );
}

SingleSelectModal.defaultProps = {
  width: 700,
};

export default SingleSelectModal;
