import en from './en.json';
import es from './es.json';
import de from './de.json';
import cs from './cs.json';
import sk from './sk.json';
import ja from './ja.json';

export default {
  en,
  es,
  de,
  cs,
  sk,
  ja,
};
