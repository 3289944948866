import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { selectHospital } from '@redux/hospitals/reducers';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import { ColumnGroup, Column, Card } from '@cards/Card';
import { setCurrentScreen, screens } from '../../../analytics';

const HospitalDetails = ({ history, match }) => {
  useEffect(() => {
    setCurrentScreen(screens.ADMINISTRATION_HOSPITAL_DETAILS);
  }, []);

  const { t } = useTranslation();
  const dashboardUserProfile = useSelector(state => state.login.user.profile);
  const [loading, hospital] = useSelector(selectHospital(dashboardUserProfile.hospitalId));

  return (
    <ColumnGroup>
      <Column>
        <Card
          className="hospital-details-card"
          loading={loading}
          title={t('hospitals:HospitalDetails.contact-details.card-title')}
        >
          {hospital === undefined ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <DetailForm>
              <DetailRow
                key="open-hours"
                label={t('hospitals:HospitalDetails.contact-details.open-hours')}
                value={hospital.contactDetails.openHours}
              ></DetailRow>
              <DetailRow
                key="phone"
                label={t('hospitals:HospitalDetails.contact-details.phone')}
                value={hospital.contactDetails.phone}
              ></DetailRow>
              <DetailRow
                key="address"
                label={t('hospitals:HospitalDetails.contact-details.address')}
                value={hospital.contactDetails.address}
              ></DetailRow>
              <DetailRow
                key="second-line-address"
                label={t('hospitals:HospitalDetails.contact-details.second-line-address')}
                value={hospital.contactDetails.addressSecondLine}
              ></DetailRow>
              <DetailRow
                key="type"
                label={t('hospitals:HospitalDetails.contact-details.type')}
                value={
                  hospital.contactDetails.type
                    ? t(
                        `hospitals:HospitalDetails.contact-details.type-${hospital.contactDetails.type}`,
                      )
                    : ''
                }
              ></DetailRow>
            </DetailForm>
          )}
        </Card>
        <Card
          className="hospital-social-details-card"
          loading={loading}
          title={t('hospitals:HospitalDetails.social-media.card-title')}
        >
          {hospital === undefined || !Array.isArray(hospital.socialMedia) ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <DetailForm className="detail-form">
              {Array.isArray(hospital.socialMedia) &&
                hospital.socialMedia.map((sm, index) => (
                  <DetailRow
                    key={index}
                    label={t(`hospitals:HospitalDetails.social-media.${sm.type}`)}
                    value={
                      <a href={sm.link} target="_blank" rel="noopener noreferrer">
                        {sm.link}
                      </a>
                    }
                  />
                ))}
            </DetailForm>
          )}
        </Card>
      </Column>
      <Column></Column>
    </ColumnGroup>
  );
};

export default HospitalDetails;
