import React, { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PathwayStagesTable from '@components/PathwayStagesTable';
import PathwayDetailsCard from '@pathways/cards/Pathways/PathwayDetails/Card';
import IndexEventTriggeredRulesCard from '@pathways/cards/Pathways/IndexEventTriggeredRules/Card';
import { Card, ColumnGroup, Column } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import { useDispatch } from 'react-redux';
import { fetchDocuments } from '@redux/documents/actions';
import { fetchForms } from '@redux/forms/actions';
import { fetchCannedMessages } from '@redux/messages/actions';
import createColumns from './createColumns';
import createDataSource from './createDataSource';

function Review() {
  const { formData } = useWizard();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocuments());
    dispatch(fetchForms());
    dispatch(fetchCannedMessages());
  }, [dispatch]);

  const [columns, dataSource] = useMemo(
    () => [
      createColumns(formData.stagedRules.stages, t),
      createDataSource(formData.stagedRules.stages),
    ],
    [formData, t],
  );

  const pathwayData = useMemo(
    () => ({
      ...formData.details,
      ...formData.stagedRules,
      ...formData.indexEvents,
      ...formData.indexEventRules,
    }),
    [formData],
  );

  return (
    <>
      <ColumnGroup>
        <Column>
          <Card
            className="pathway-stages-table-card"
            title={t('pathways:ProcedurePathways.wizard.stages.stepName')}
          >
            <PathwayStagesTable columns={columns} dataSource={dataSource} />
          </Card>
        </Column>
      </ColumnGroup>
      <ColumnGroup>
        <Column>
          <PathwayDetailsCard pathway={pathwayData} />
        </Column>
        <Column></Column>
      </ColumnGroup>
      <ColumnGroup>
        <Column>
          <IndexEventTriggeredRulesCard pathway={pathwayData} />
        </Column>
      </ColumnGroup>
    </>
  );
}

export default Review;
