import React, { useMemo } from 'react';
import { AppUserDetailsCard } from '@cards/AppUsers/Details';
import useWizard from '@components/Wizard/useWizard';

export default () => {
  const { formData } = useWizard();
  const appUser = useMemo(
    () => ({
      firstName: formData.details.firstName,
      lastName: formData.details.lastName,
      email: formData.details.email,
      address: formData.details.address,
      city: formData.details.city,
      postcode: formData.details.postcode,
      sex: formData.details.sex,
      title: formData.details.title,
      phone: formData.details.phone,
      dateOfBirth: formData.details.dateOfBirth,
    }),
    [formData],
  );

  return <AppUserDetailsCard appUser={appUser} size="half" />;
};
