import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect, Route } from 'react-router-dom';
import { Button, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faUserAltSlash, faServer } from '@fortawesome/pro-regular-svg-icons';
import { usePageActions } from '@hooks';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import { ColumnGroup, Column } from '@cards/Card';
import { actionSupportRequest } from '@redux/requests/actions';
import {
  selectDownloadRequestsForHospital,
  selectDeleteRequestsForHospital,
  selectDownloadRequestsForSuperAdmin,
  selectDeleteRequestsForSuperAdmin,
} from '@redux/requests/reducer';
import { HOSPITAL_ADMIN, SUPER_ADMIN } from '@constants';
import RequestListCard from './RequestListCard';
import { selectUserRole } from '../../../authorisation/selectors';
import { setCurrentScreen, screens } from '../../../analytics';
import GDPR from './GDPR';
import './style.less';

const routes = {
  download: 'download',
  delete: 'delete',
  gdpr: 'gdpr',
};

const HospitalDetails = ({ history, match }) => {
  useEffect(() => {
    setCurrentScreen(screens.ADMINISTRATION_REQUESTS_LIST);
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userRole = useSelector(selectUserRole);

  usePageActions({
    title: t('dataRequests:title'),
    actions: [],
    showBack: false,
  });

  const [
    selectDownloadRequests,
    selectDeletionRequests,
    action,
    downloadDescription,
    deletionDescription,
  ] = useMemo(() => {
    if (userRole === HOSPITAL_ADMIN)
      return [
        selectDownloadRequestsForHospital,
        selectDeleteRequestsForHospital,
        'transition',
        <p>{t('dataRequests:DownloadCard.description.hospitalAdmin')}</p>,
        <p>{t('dataRequests:DeleteCard.description.hospitalAdmin')}</p>,
      ];
    if (userRole === SUPER_ADMIN)
      return [
        selectDownloadRequestsForSuperAdmin,
        selectDeleteRequestsForSuperAdmin,
        'completed',
        <>
          <p>{t('dataRequests:DownloadCard.description.superAdmin')}</p>
          <div className="data-request-description">
            <div>
              <b>{t('dataRequests:List.ticketSubheader')}</b>
            </div>
            <Button
              type="primary"
              onClick={e => {
                window.open(
                  'https://liquidstate.atlassian.net/servicedesk/customer/portal/1/group/13',
                );
              }}
            >
              {t('dataRequests:List.ticketButton')}
            </Button>
          </div>
        </>,
        <>
          <p>{t('dataRequests:DeleteCard.description.superAdmin')}</p>
          <div className="data-request-description">
            <div>
              <b>{t('dataRequests:List.ticketSubheader')}</b>
            </div>
            <Button
              type="primary"
              onClick={e => {
                window.open(
                  'https://liquidstate.atlassian.net/servicedesk/customer/portal/1/group/13',
                );
              }}
            >
              {t('dataRequests:List.ticketButton')}
            </Button>
          </div>
        </>,
      ];
  }, [t, userRole]);

  return (
    <SubNavMenuContainer
      menu={
        <SubNavMenu>
          <Menu.Item key={routes.download}>
            <FontAwesomeIcon icon={faDatabase} />
            {t('dataRequests:submenu.download')}
          </Menu.Item>
          <Menu.Item key={routes.delete}>
            <FontAwesomeIcon icon={faUserAltSlash} />
            {t('dataRequests:submenu.delete')}
          </Menu.Item>
          <Menu.Item key={routes.gdpr}>
            <FontAwesomeIcon icon={faServer} />
            {t('dataRequests:submenu.gdpr')}
          </Menu.Item>
        </SubNavMenu>
      }
    >
      <Switch>
        <Route
          path={`${match.path}/${routes.download}`}
          render={() => (
            <ColumnGroup>
              <Column>
                <RequestListCard
                  title={t('dataRequests:DownloadCard.title')}
                  extra={
                    <Button onClick={() => history.push(`/administration/create-data-request`)}>
                      {t('dataRequests:DownloadCard.create')}
                    </Button>
                  }
                  description={downloadDescription}
                  action={item => {
                    dispatch(actionSupportRequest(item, action));
                  }}
                  requestSelector={selectDownloadRequests}
                />
              </Column>
            </ColumnGroup>
          )}
        />
        <Route
          path={`${match.path}/${routes.delete}`}
          render={() => (
            <ColumnGroup>
              <Column>
                <RequestListCard
                  title={t('dataRequests:DeleteCard.title')}
                  extra={
                    <Button onClick={() => history.push(`/administration/create-deletion-request`)}>
                      {t('dataRequests:DeleteCard.create')}
                    </Button>
                  }
                  description={deletionDescription}
                  action={item => {
                    dispatch(actionSupportRequest(item, action));
                  }}
                  requestSelector={selectDeletionRequests}
                />
              </Column>
            </ColumnGroup>
          )}
        />
        <Route
          path={`${match.path}/${routes.gdpr}`}
          render={() => (
            <ColumnGroup>
              <Column>
                <GDPR />
              </Column>
              <Column />
            </ColumnGroup>
          )}
        />
        <Route
          path={match.path}
          render={() => <Redirect to={`${match.path}/${routes.download}`} />}
        />
      </Switch>
    </SubNavMenuContainer>
  );
};

export default HospitalDetails;
