import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';
import { Card } from '@cards/Card';
import useWizard from '@components/Wizard/useWizard';
import LanguageSelect from '@components/LanguageSelect';

function HospitalDetails({ editing }) {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <Card className="hospital-wizard-card" title={t('hospitals:Wizard.details.titles.details')}>
      <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
        <Form.Item label={t('hospitals:Wizard.details.labels.name')}>
          {form.getFieldDecorator('hospitalName', {
            initialValue: currentStepData.hospitalName,
            rules: [{ required: true, message: t('hospitals:Wizard.details.errors.hospitalName') }],
          })(<Input placeholder={t('hospitals:Wizard.details.placeholders.hospitalName')} />)}
        </Form.Item>
      </Form>

      {editing ? null : (
        <>
          <h3>{t('hospitals:Wizard.details.titles.admin')}</h3>
          <Form labelCol={{ span: 8 }} layout="vertical" wrapperCol={{ span: 16 }}>
            <Form.Item label={t('hospitals:Wizard.details.labels.firstName')}>
              {form.getFieldDecorator('firstName', {
                initialValue: currentStepData.firstName,
                rules: [
                  { required: true, message: t('hospitals:Wizard.details.errors.contactName') },
                ],
              })(<Input placeholder={t('hospitals:Wizard.details.placeholders.contactName')} />)}
            </Form.Item>
            <Form.Item label={t('hospitals:Wizard.details.labels.lastName')}>
              {form.getFieldDecorator('lastName', {
                initialValue: currentStepData.lastName,
                rules: [
                  { required: true, message: t('hospitals:Wizard.details.errors.contactName') },
                ],
              })(<Input placeholder={t('hospitals:Wizard.details.placeholders.contactName')} />)}
            </Form.Item>
            <Form.Item label={t('hospitals:Wizard.details.labels.email')}>
              {form.getFieldDecorator('email', {
                initialValue: currentStepData.email,
                rules: [
                  {
                    type: 'email',
                    required: true,
                    message: t('hospitals:Wizard.details.errors.email'),
                  },
                ],
              })(<Input placeholder={t('hospitals:Wizard.details.placeholders.email')} />)}
            </Form.Item>
            <Form.Item label={t('common:Wizard.details.language.label')}>
              {form.getFieldDecorator('language', {
                initialValue: currentStepData.language || 'en',
                rules: [
                  {
                    required: true,
                    message: t('common:Wizard.details.language.required'),
                  },
                ],
              })(<LanguageSelect />)}
            </Form.Item>
          </Form>
        </>
      )}
    </Card>
  );
}

HospitalDetails.propTypes = {
  editing: bool,
};

HospitalDetails.defaultProps = {
  editing: false,
};

export default HospitalDetails;
