import { combineReducers } from 'redux';
import {
  IHospital,
  IHospitalInvites,
  LOAD_HOSPITALS,
  LOAD_HOSPITALS_SUCCESS,
  LOAD_HOSPITALS_FAILED,
  CREATE_HOSPITAL,
  CREATE_HOSPITAL_FAILED,
  CREATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL,
  UPDATE_HOSPITAL_SUCCESS,
  UPDATE_HOSPITAL_FAILED,
  EDIT_HOSPITAL,
  EDIT_HOSPITAL_FAILED,
  EDIT_HOSPITAL_SUCCESS,
} from './types';
import {
  ILoadHospitals,
  ILoadHospitalsSuccess,
  ILoadHospitalsFailed,
  CreateHospital,
  CreateHospitalFailed,
  CreateHospitalSuccess,
  IUpdateHospital,
  IUpdateHospitalSuccess,
  IUpdateHospitalFailed,
  EditHospital,
  EditHospitalFailed,
  EditHospitalSuccess,
} from './actions';

function creating(
  state = false,
  action: CreateHospital | CreateHospitalFailed | CreateHospitalSuccess,
) {
  switch (action.type) {
    case CREATE_HOSPITAL:
      return true;
    case CREATE_HOSPITAL_FAILED:
    case CREATE_HOSPITAL_SUCCESS:
      return false;
    default:
      return state;
  }
}

function editing(state = false, action: EditHospital | EditHospitalFailed | EditHospitalSuccess) {
  switch (action.type) {
    case EDIT_HOSPITAL:
      return true;
    case EDIT_HOSPITAL_FAILED:
    case EDIT_HOSPITAL_SUCCESS:
      return false;
    default:
      return state;
  }
}

const loading = (
  state: boolean = false,
  action: ILoadHospitals | ILoadHospitalsSuccess | ILoadHospitalsFailed,
): boolean => {
  switch (action.type) {
    case LOAD_HOSPITALS:
      return true;
    case LOAD_HOSPITALS_SUCCESS:
    case LOAD_HOSPITALS_FAILED:
      return false;
    default:
      return state;
  }
};

const updating = (
  state: boolean = false,
  action: IUpdateHospital | IUpdateHospitalSuccess | IUpdateHospitalFailed,
): boolean => {
  switch (action.type) {
    case UPDATE_HOSPITAL:
      return true;
    case UPDATE_HOSPITAL_SUCCESS:
    case UPDATE_HOSPITAL_FAILED:
      return false;
    default:
      return state;
  }
};

const hospitals = (
  state: IHospital[] = [],
  action: ILoadHospitalsSuccess | IUpdateHospitalSuccess | EditHospitalSuccess,
): IHospital[] => {
  switch (action.type) {
    case LOAD_HOSPITALS_SUCCESS:
    case UPDATE_HOSPITAL_SUCCESS:
    case EDIT_HOSPITAL_SUCCESS:
      return action.payload.hospitals;
    default:
      return state;
  }
};

const invites = (state: IHospitalInvites = {}, action: ILoadHospitalsSuccess): IHospitalInvites => {
  switch (action.type) {
    case LOAD_HOSPITALS_SUCCESS:
      return action.payload.invites;
    default:
      return state;
  }
};

export interface IHospitalState {
  hospitals: {
    creating: boolean;
    editing: boolean;
    hospitals: IHospital[];
    invites: IHospitalInvites;
    loading: boolean;
    updating: boolean;
  };
}

export default combineReducers({
  creating,
  editing,
  hospitals,
  invites,
  loading,
  updating,
});

export const selectHospital = (id: string) => (
  state: IHospitalState,
): [boolean, IHospital | undefined] => [
  state.hospitals.loading,
  state.hospitals.hospitals.find(hos => hos.uuid === id),
];

export const selectHospitals = (state: IHospitalState): [boolean, IHospital[]] => [
  state.hospitals.loading,
  state.hospitals.hospitals,
];

export const selectHospitalsInAlphabeticalOrder = (
  state: IHospitalState,
): [boolean, IHospital[]] => [
  state.hospitals.loading,
  state.hospitals.hospitals.sort((a, b) => (a.name || '').localeCompare(b.name || '')),
];

export const selectHospitalsById = (
  state: IHospitalState,
): [boolean, { [key: string]: IHospital }] => [
  state.hospitals.loading,
  state.hospitals.hospitals.reduce(
    (hospitalsById, hospital) => ({ ...hospitalsById, [hospital.uuid]: hospital }),
    {},
  ),
];

export const selectHospitalInvites = (state: IHospitalState): [boolean, IHospitalInvites] => [
  state.hospitals.loading,
  state.hospitals.invites,
];

export const selectHospitalCreating = (state: IHospitalState): boolean => state.hospitals.creating;
export const selectHospitalUpdating = (state: IHospitalState): boolean => state.hospitals.updating;
export const selectHospitalEditing = (state: IHospitalState): boolean => state.hospitals.editing;
