import React, { useCallback, useState } from 'react';
import { Icon, Popover, List, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import { logoGreen } from '@assets';
import {
  faUser,
  faFileAlt,
  faMailbox,
  faHandshakeAlt,
  faGlobe,
  faKey,
  faCog,
  faDatabase,
  faUserSlash,
} from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { procedurePathway } from '@assets/icons';
import { logout } from '@redux/login/actions';
import { hasValidSession, selectCurrentDashboardUser } from '@redux/login/reducer';
import MenuItem from './MenuItem';
import Divider from './Divider';
import LanguageSelector from './LanguageSelectorPopup';
import { Permissions } from '@authorisation/constants';
import { useHasPermissions } from '@authorisation/hooks';
import { trackMainMenu } from '../../analytics';

const { confirm } = Modal;

export default () => {
  const currentDashboardUser = useSelector(selectCurrentDashboardUser);
  const permissions = useHasPermissions();
  const dispatch = useDispatch();
  const store = useStore();
  const { pathname } = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = visible => {
    setVisible(visible);
  };

  const showLogOutConfirm = useCallback(() => {
    confirm({
      title: t('logout:title'),
      icon: <FontAwesomeIcon className="log-out-icon" icon={faExclamationCircle} />,
      okText: t('logout:ok'),
      onOk() {
        dispatch(logout());
        return waitForStoreUpdate(store, state => [hasValidSession(state)]);
      },
    });
  }, [dispatch, store, t]);

  function determineMetricsMenuItems() {
    if (permissions[Permissions.ViewHospitalMetrics]) {
      return [
        {
          label: t('common:Menu.reviewHub.hospital'),
          onClick: () =>
            trackMainMenu(
              history,
              `/review-hub/hospital/${currentDashboardUser.profile.hospitalId}`,
              'Hospital',
            ),
          active: pathname.startsWith('/review-hub/hospital'),
        },
        {
          label: t('common:Menu.reviewHub.pathways'),
          onClick: () => trackMainMenu(history, '/review-hub/pathway-data', 'Pathways'),
          active: pathname.startsWith('/review-hub/pathway-data'),
        },
      ];
    }

    return [
      {
        label: t('common:Menu.reviewHub.global'),
        onClick: () => trackMainMenu(history, '/review-hub/global', 'Global'),
        active: pathname.startsWith('/review-hub/global'),
      },
      // {
      //   label: t('common:Menu.reviewHub.country'),
      //   onClick: () => trackMainMenu(history, '/review-hub/country', 'Country'),
      //   active: pathname.startsWith('/review-hub/country'),
      // },
      {
        label: t('common:Menu.reviewHub.pathways'),
        onClick: () => trackMainMenu(history, '/review-hub/pathway-data', 'Pathways'),
        active: pathname.startsWith('/review-hub/pathway-data'),
      },
      {
        label: t('common:Menu.reviewHub.hospital'),
        onClick: () => trackMainMenu(history, '/review-hub/hospital', 'Hospital'),
        active: pathname.startsWith('/review-hub/hospital'),
      },
    ];
  }

  return (
    <div className="menu">
      <div className="logo">
        <img src={logoGreen} alt="" />
      </div>
      <Divider />
      <div className="menu-items">
        <MenuItem
          icon={<Icon type="bank" />}
          active={pathname === '/'}
          onClick={() => trackMainMenu(history, '/', 'Home')}
        >
          {t('common:Menu.home')}
        </MenuItem>
        {permissions[Permissions.ViewPatients] ? (
          <>
            <Divider />
            <MenuItem
              icon={<FontAwesomeIcon icon={faUser} />}
              active={pathname.startsWith('/patients/individuals')}
              onClick={() => trackMainMenu(history, '/patients/individuals', 'App Users')}
            >
              {t('common:Menu.patients.title')}
            </MenuItem>
          </>
        ) : null}
        {permissions[Permissions.ViewMessages] ? (
          <>
            <Divider />
            <MenuItem
              icon={<FontAwesomeIcon icon={faMailbox} />}
              active={pathname.startsWith('/messages')}
              onClick={() => trackMainMenu(history, '/messages/sent', 'Ad-Hoc Messages')}
              subMenuItems={[
                {
                  label: t('common:Menu.messages.sent'),
                  onClick: () => trackMainMenu(history, '/messages/sent', 'Ad-Hoc Messages Sent'),
                  active: pathname.startsWith('/messages/sent'),
                },
                {
                  label: t('common:Menu.messages.scheduled'),
                  onClick: () =>
                    trackMainMenu(history, '/messages/scheduled', 'Ad-Hoc Messages Scheduled'),
                  active: pathname.startsWith('/messages/scheduled'),
                },
              ]}
            >
              {t('common:Menu.messages.title')}
            </MenuItem>
          </>
        ) : null}
        <Divider />
        <MenuItem
          icon={<FontAwesomeIcon icon={faFileAlt} />}
          active={pathname.startsWith('/content')}
          onClick={() => trackMainMenu(history, '/content/documents', 'Content')}
          subMenuItems={[
            {
              label: t('common:Menu.content.documents'),
              onClick: () => trackMainMenu(history, '/content/documents', 'Documents'),
              active: pathname.startsWith('/content/documents'),
            },
            {
              label: t('common:Menu.content.videos'),
              onClick: () => trackMainMenu(history, '/content/videos', 'Videos'),
              active: pathname.startsWith('/content/videos'),
            },
            {
              label: t('common:Menu.content.forms'),
              onClick: () => trackMainMenu(history, '/content/forms', 'Forms'),
              active: pathname.startsWith('/content/forms'),
            },
            {
              label: t('common:Menu.content.questionnaires'),
              onClick: () => trackMainMenu(history, '/content/questionnaires', 'Questionnaires'),
              active: pathname.startsWith('/content/questionnaires'),
            },
            {
              label: t('common:Menu.content.tasks'),
              onClick: () => trackMainMenu(history, '/content/tasks', 'Tasks'),
              active: pathname.startsWith('/content/tasks'),
            },
            {
              label: t('common:Menu.content.messages'),
              onClick: () => trackMainMenu(history, '/content/messages', 'Messages'),
              active: pathname.startsWith('/content/messages'),
            },
            {
              label: t('common:Menu.content.alerts'),
              onClick: () => trackMainMenu(history, '/content/alerts', 'Alerts'),
              active: pathname.startsWith('/content/alerts'),
            },
            {
              label: t('common:Menu.content.reminders'),
              onClick: () => trackMainMenu(history, '/content/reminders', 'Reminders'),
              active: pathname.startsWith('/content/reminders'),
            },
          ]}
        >
          {t('common:Menu.content.title')}
        </MenuItem>
        <Divider />
        <MenuItem
          icon={<Icon component={procedurePathway} />}
          active={pathname.startsWith('/procedure')}
          onClick={() => trackMainMenu(history, '/procedure/pathways', 'Pathways heading')}
          subMenuItems={[
            {
              label: t('common:Menu.pathway.pathways'),
              onClick: () => trackMainMenu(history, '/procedure/pathways', 'Pathways'),
              active: pathname.startsWith('/procedure/pathways'),
            },
            {
              label: t('common:Menu.pathway.rules'),
              onClick: () => trackMainMenu(history, '/procedure/rules', 'Rules'),
              active: pathname.startsWith('/procedure/rules'),
            },
          ]}
        >
          {t('common:Menu.pathway.title')}
        </MenuItem>
        <Divider />
        <MenuItem
          icon={<Icon type="line-chart" />}
          active={pathname.startsWith('/review-hub')}
          onClick={() => trackMainMenu(history, '/review-hub', 'Review Hub heading')}
          subMenuItems={determineMetricsMenuItems()}
        >
          {t('common:Menu.reviewHub.title')}
        </MenuItem>
        <Divider />
        {permissions[Permissions.ViewAdministation] && (
          <MenuItem
            icon={<FontAwesomeIcon icon={faCog} />}
            active={pathname.startsWith('/administration')}
            onClick={() => trackMainMenu(history, '/administration', 'Administration heading')}
            subMenuItems={[
              ...(permissions[Permissions.ViewDashboardUsers]
                ? [
                    {
                      label: t('common:Menu.administration.dashboard-users'),
                      onClick: () =>
                        trackMainMenu(
                          history,
                          '/administration/dashboard-users',
                          'Dashboard Users',
                        ),
                      active: pathname.startsWith('/administration/dashboard-users'),
                    },
                  ]
                : []),
              ...(permissions[Permissions.ViewDataRequests]
                ? [
                    {
                      label: t('common:Menu.administration.dataRequests'),
                      onClick: () =>
                        trackMainMenu(history, '/administration/data-requests', 'Data Requests'),
                      active: pathname.startsWith('/administration/data-requests'),
                    },
                  ]
                : []),
              ...(permissions[Permissions.AdministerIndexEvents]
                ? [
                    {
                      label: t('common:Menu.administration.index'),
                      onClick: () =>
                        trackMainMenu(history, '/administration/index-events', 'Index Events'),
                      active: pathname.startsWith('/administration/index-events'),
                    },
                  ]
                : []),
              ...(permissions[Permissions.AdministerAllHospitals]
                ? [
                    {
                      label: t('common:Menu.administration.hospitals'),
                      onClick: () =>
                        trackMainMenu(history, '/administration/hospitals', 'Hospitals'),
                      active: pathname.startsWith('/administration/hospitals'),
                    },
                  ]
                : []),
              ...(permissions[Permissions.AdministerHospital]
                ? [
                    {
                      label: t('common:Menu.administration.hospital'),
                      onClick: () => trackMainMenu(history, '/administration/hospital', 'Hospital'),
                      active: pathname.startsWith('/administration/hospital'),
                    },
                  ]
                : []),
            ]}
          >
            {t('common:Menu.administration.title')}
          </MenuItem>
        )}
      </div>
      <Popover
        placement="rightBottom"
        title={t('common:Menu.popover.title')}
        trigger="hover"
        content={
          <List
            className="user-details-popover-list"
            size="small"
            dataSource={[
              {
                icon: <Icon type="lock" />,
                title: t('common:Menu.popover.logout'),
                onClick: () => showLogOutConfirm(),
              },
              {
                icon: <FontAwesomeIcon icon={faKey} />,
                title: t('common:Menu.popover.password'),
                onClick: () => history.push('/my-profile/change-password'),
              },
              {
                icon: <FontAwesomeIcon icon={faGlobe} />,
                title: t('common:Menu.popover.language'),
                rightIcon: <Icon type="right" className="change-language-menu" />,
                key: 'languages',
              },
              {
                icon: <FontAwesomeIcon icon={faHandshakeAlt} />,
                title: t('common:Menu.popover.termsnCondition'),
                onClick: () => history.push('/my-profile/terms-and-conditions'),
              },
              {
                icon: <FontAwesomeIcon icon={faFileAlt} />,
                title: t('common:Menu.popover.privacyPolicy'),
                onClick: () => history.push('/my-profile/privacy-policy'),
              },
              {
                icon: <FontAwesomeIcon icon={faDatabase} className="tab-item-icon" />,
                title: t('myProfile:Tabs.dataDownload'),
                onClick: () => history.push('/my-profile/gdpr-request-data'),
              },
              {
                icon: <FontAwesomeIcon icon={faUserSlash} className="tab-item-icon" />,
                title: t('myProfile:Tabs.dataDeletion'),
                onClick: () => history.push('/my-profile/gdpr-delete-data'),
              },
            ]}
            bordered={false}
            split={false}
            renderItem={item => {
              return item.key ? (
                <Popover
                  overlayClassName="change-languages"
                  content={<LanguageSelector />}
                  placement="rightBottom"
                  trigger="hover"
                  visible={visible}
                  onVisibleChange={handleVisibleChange}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <List.Item>
                    {item.icon} {item.title} {item.rightIcon}
                  </List.Item>
                </Popover>
              ) : (
                <List.Item onClick={item.onClick}>
                  {item.icon} {item.title} {item.rightIcon}
                </List.Item>
              );
            }}
          />
        }
      >
        <div className="user-details">
          <Icon type="lock" />
          <span className="name">{currentDashboardUser?.name}</span>
          <Icon type="right" />
        </div>
      </Popover>
    </div>
  );
};
