import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathway } from '@pathways/redux/pathways/reducers';
import Card from './Card';
import './style.less';

function PathwayDetails({ pathwayData, pathwayId, externalId, ...props }) {
  const dispatch = useDispatch();
  const [loading, pathway] = useSelector(selectPathway(pathwayId));

  useEffect(() => {
    dispatch(fetchPathways());
  }, [dispatch]);

  return <Card loading={loading} pathway={pathway} externalId={externalId} {...props} />;
}

export default PathwayDetails;
