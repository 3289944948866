import React, { createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { ColumnGroup, Column } from '@cards/Card';
import FormDetailsCard from '@cards/Forms/FormDetails';
import FormPreviewCard from '@cards/Forms/FormPreview';
import FormScoresCard from '@cards/Forms/FormScores';
import IconButton from '@components/IconButton';
import { useTranslation } from 'react-i18next';
import { SubmitFormModals, VISIBLE_MODAL } from '@cards/AppUsers/SubmitForm';
import { selectForm } from '@redux/forms/reducers';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { matchType } from '../../propTypes';

const FormSubmissionDataContext = createContext();

export function useFormSubmissionData() {
  const formSubmission = useContext(FormSubmissionDataContext);

  return formSubmission;
}

const AppUserFormDetail = ({ match, type }) => {
  const { t } = useTranslation();
  const { id: appUserId, formId, submissionId } = match.params;
  const [, form] = useSelector(selectForm(formId));
  const [visibleModal, setVisibleModal] = useState();
  const [formSubmissionIndex, setFormSubmissionIndex] = useState(0);
  const [objectVersion, setObjectVersion] = useState(null);
  const [displayNames, setDisplayNames] = useState({});
  const [improvements, setImprovements] = useState({});
  const [ordering, setOrdering] = useState([]);

  return (
    <>
      <FormSubmissionDataContext.Provider
        value={{
          displayNames,
          improvements,
          index: formSubmissionIndex,
          objectVersion,
          ordering,
          setDisplayNames,
          setImprovements,
          setIndex: setFormSubmissionIndex,
          setOrdering,
          setObjectVersion,
        }}
      >
        <ColumnGroup>
          <Column>
            <FormDetailsCard
              formId={formId}
              extra={[
                <IconButton
                  key="add-form-submission-button"
                  tooltip={t('cards:PatientFormList.submitTooltip')}
                  icon={faPlus}
                  onClick={() => setVisibleModal(VISIBLE_MODAL.SUBMIT)}
                  tooltipPlacement="right"
                />,
              ]}
            />
            <FormScoresCard appUserId={appUserId} formId={formId} />
          </Column>
          <Column>
            <FormPreviewCard formId={formId} appUserId={appUserId} submissionId={submissionId} />
          </Column>
        </ColumnGroup>
      </FormSubmissionDataContext.Provider>
      <SubmitFormModals
        appUserId={appUserId}
        formType={type}
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        selectedForm={form}
        setSelectedForm={() => {}}
        type={type}
      />
    </>
  );
};

AppUserFormDetail.propTypes = {
  match: matchType.isRequired,
  type: string.isRequired,
};

export default AppUserFormDetail;
