import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Icon, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAltLines } from '@fortawesome/pro-regular-svg-icons';
import { pathway } from '@assets/icons';
import { fetchMessages, fetchCannedMessages } from '@redux/messages/actions';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectCannedMessageHistory } from '@redux/messages/reducers';
import { selectAppUsers } from '@redux/appUsers/reducers';
import { useRequiresPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import DateFilter from '@components/DateFilter';
import { Card } from '../../Card';
import './style.less';

const pageSize = 10;

const createColumns = t => [
  {
    title: t('cards:MessageHistory.columns.date.title'),
    dataIndex: 'date',
    key: 'date',
    sorter: (a, b) => {
      if (a.date === b.date) return 0;
      return a.date < b.date ? -1 : 1;
    },
    filterDropdown: DateFilter.filterDropdown,
    onFilter: (value, record) => DateFilter.onFilter(value, record.date),
  },
  {
    title: t('cards:MessageHistory.columns.time.title'),
    dataIndex: 'time',
    key: 'time',
    sorter: (a, b) => {
      if (a.time === b.time) return 0;
      return a.time < b.time ? -1 : 1;
    },
  },
  {
    title: t('cards:MessageHistory.columns.version.title'),
    dataIndex: 'version',
    key: 'version',
    sorter: (a, b) => {
      if (a.version === b.version) return 0;
      return a.version < b.version ? -1 : 1;
    },
  },
  {
    title: t('cards:MessageHistory.columns.audience.title'),
    dataIndex: 'appUser',
    key: 'audience',
    render: appUser => {
      const [profile] = appUser;
      return `${profile.firstName} ${profile.lastName}`;
    },
    sorter: (a, b) => {
      const stringA = `${a.appUser[0].firstName} ${a.appUser[0].lastName}`;
      const stringB = `${b.appUser[0].firstName} ${b.appUser[0].lastName}`;
      return stringA.localeCompare(stringB);
    },
  },
  {
    title: t('cards:MessageHistory.columns.delivery.title'),
    dataIndex: 'delivery',
    key: 'delivery',
    render: delivery => {
      if (delivery === 'Pathway') {
        return <Icon component={pathway} />;
      }
      return <FontAwesomeIcon icon={faCommentAltLines} />;
    },
    sorter: (a, b) => a.delivery.localeCompare(b.delivery),
    filters: [
      {
        text: t('cards:MessageHistory.columns.delivery.adHoc'),
        value: 'Ad-hoc',
      },
      {
        text: t('cards:MessageHistory.columns.delivery.pathway'),
        value: 'Pathway',
      },
    ],
    onFilter: (value, record) => record.delivery === value,
  },
];

const MessageHistoryCard = ({ messageId, ...props }) => {
  useRequiresPermissions(Permissions.ViewPatients);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMessages());
    dispatch(fetchCannedMessages());
    dispatch(fetchAppUsers());
  }, [dispatch]);

  const [loading, appUsers] = useSelector(selectAppUsers);
  const [historyLoading, messageHistory] = useSelector(selectCannedMessageHistory(messageId));

  //assuming only one user per message delivered
  const deliveredAppUsers = useMemo(
    () =>
      messageHistory.reduce((acc, message) => {
        const appUser = appUsers.find(appUser => appUser.id === message.users[0]);
        if (!appUser) return acc;

        const time = moment(message.sendAt).format('HH:mm A');

        return [
          ...acc,
          {
            delivery: message.metadata.delivery,
            version: message.metadata.original.version,
            date: moment(message.sendAt).format('DD-MM-YYYY'),
            appUser,
            time,
            id: message.id,
          },
        ];
      }, []),
    [messageHistory, appUsers],
  );

  const columns = useMemo(() => createColumns(t), [t]);

  return (
    <>
      <Card.Half
        className="message-history-card"
        title={t('cards:MessageHistory.title')}
        noPadding
        loading={loading || historyLoading}
        {...props}
      >
        {appUsers.length === 0 || messageHistory.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Table
            columns={columns}
            dataSource={deliveredAppUsers}
            pagination={{ position: 'bottom', pageSize }}
            rowKey="id"
          />
        )}
      </Card.Half>
    </>
  );
};

export default MessageHistoryCard;
