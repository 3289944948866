import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector /* useStore */ } from 'react-redux';
import { useTranslation } from 'react-i18next';
// import { Modal } from 'antd';
// import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import SearchableTable from '@components/SearchableTable';
// import IconButton from '@components/IconButton';
// import { waitForStoreUpdate } from '@utils';
import {
  /* deleteIndexEvents, */ switchIndexEventOrder,
  fetchIndexEvents,
} from '@pathways/redux/indexEvents/actions';
import {
  selectIndexEvents /* selectIndexEventDeleting */,
  selectIndexEventEditing,
} from '@pathways/redux/indexEvents/reducers';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => {
  return comparator(value.name);
};

function IndexEventsList() {
  const { i18n, t } = useTranslation();
  // const store = useStore();
  const dispatch = useDispatch();
  const [loading, indexEvents] = useSelector(selectIndexEvents);

  const editing = useSelector(selectIndexEventEditing);
  // const [, /* selectedIndexEventIds */ setSelectedIndexEventIds] = useState([]);
  const columns = useMemo(
    () =>
      createColumns(
        t,
        i18n.language,
        editedIndexEvent =>
          dispatch(switchIndexEventOrder(editedIndexEvent.id, editedIndexEvent.order)),
        {
          down: indexEvents[indexEvents.length - 1] && indexEvents[indexEvents.length - 1].id,
          up: indexEvents[0] && indexEvents[0].id,
        },
      ),
    [dispatch, t, i18n, indexEvents],
  );
  // const onRowSelectionChange = useCallback(
  //   selectedRows => setSelectedIndexEventIds(selectedRows),
  //   [],
  // );

  // const onDelete = useCallback(
  //   () =>
  //     Modal.confirm({
  //       title: t('indexEvents:List.delete.confirm'),
  //       onOk: () => {
  //         dispatch(deleteIndexEvents(selectedIndexEventIds));
  //         return waitForStoreUpdate(store, selectIndexEventDeleting);
  //       },
  //       onCancel: () => {},
  //     }),
  //   [dispatch, selectedIndexEventIds, store, t],
  // );

  useEffect(() => {
    dispatch(fetchIndexEvents());
  }, [dispatch]);

  return (
    <SearchableTable
      columns={columns}
      dataSource={indexEvents}
      // extra={
      //   <IconButton
      //     disabled={selectedIndexEventIds.length === 0}
      //     icon={faTrashAlt}
      //     tooltip={t('common:buttons.delete')}
      //     onClick={onDelete}
      //   />
      // }
      loading={editing || loading}
      filterFunc={filterFunc}
      // onRowSelectionChange={onRowSelectionChange}
    />
  );
}

export default IndexEventsList;
