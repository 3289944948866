import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectPermissionsForUser } from './selectors';
import { AccessDenied } from './index';

export const useRequiresPermissions = (...permissions) => {
  const userPermissions = useSelector(selectPermissionsForUser);
  const ok = permissions.every(p => userPermissions.includes(p));
  if (!ok) {
    throw new AccessDenied();
  }
};

export const useHasPermissions = (...permissions) => {
  const userId = useSelector(state => state.login.user?.id);
  const userPermissions = useSelector(selectPermissionsForUser);

  return useMemo(() => {
    if (permissions.length === 0) {
      return userPermissions.reduce((acc, next) => ({ ...acc, [next]: true }), {});
    }
    return permissions.reduce(
      (acc, next) => ({
        ...acc,
        [next]: userPermissions.includes(next),
      }),
      {},
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
};
