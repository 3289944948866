export const ADD_PATHWAY_TO_APP_USERS = 'ADD_PATHWAY_TO_APP_USERS';
export const ADD_PATHWAY_TO_APP_USERS_SUCCESS = 'ADD_PATHWAY_TO_APP_USERS_SUCCESS';
export const APP_USER_JOURNEYS_LOADED = 'APP_USER_JOURNEYS_LOADED';
export const APP_USER_PATHWAY_STAGE_UPDATED = 'APP_USER_PATHWAY_STAGE_UPDATED';
export const APP_USERS_PATHWAYS_LOADED = 'APP_USERS_PATHWAYS_LOADED';
export const FETCH_APP_USERS_JOURNEYS = 'FETCH_APP_USERS_JOURNEYS';
export const FETCH_APP_USERS_PATHWAYS = 'FETCH_APP_USERS_PATHWAYS';
export const PROCESS_APP_USER_PATHWAY_NOW = 'PROCESS_APP_USER_PATHWAY_NOW';
export const SET_APP_USER_PATHWAY_STAGE = 'SET_APP_USER_PATHWAY_STAGE';
export const INVITE_APP_USER_WITH_PATHWAYS_SUCCESS = 'INVITE_APP_USER_WITH_PATHWAYS_SUCCESS';
export const INVITE_APP_USER_WITH_PATHWAYS_FAILED = 'INVITE_APP_USER_WITH_PATHWAYS_FAILED';
export const SET_APP_USER_PATHWAY_ARCHIVED_STATUS = 'SET_APP_USER_PATHWAY_ARCHIVED_STATUS';
export const SET_APP_USER_PATHWAY_ARCHIVED_STATUS_SUCCESS =
  'SET_APP_USER_PATHWAY_ARCHIVED_STATUS_SUCCESS';
export const SET_APP_USER_PATHWAY_ARCHIVED_STATUS_FAILED =
  'SET_APP_USER_PATHWAY_ARCHIVED_STATUS_FAILED';

export const EDIT_APP_USER_JOURNEY = 'EDIT_APP_USER_JOURNEY';
export const EDIT_APP_USER_JOURNEY_FAILED = 'EDIT_APP_USER_JOURNEY_FAILED';
export const EDIT_APP_USER_JOURNEY_SUCCESS = 'EDIT_APP_USER_JOURNEY_SUCCESS';

export const TRIGGER_ADHOC_RULE = 'TRIGGER_ADHOC_RULE';
export const TRIGGER_ADHOC_RULE_FAILED = 'TRIGGER_ADHOC_RULE_FAILED';
export const TRIGGER_ADHOC_RULE_SUCCESS = 'TRIGGER_ADHOC_RULE_SUCCESS';
