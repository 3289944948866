import { ICreateMessageInput } from '@liquid-state/messaging-client/dist/types';
import {
  FETCH_CANNED_MESSAGES,
  LOAD_CANNED_MESSAGES,
  LOAD_MESSAGES,
  CANNED_MESSAGES_LOADED,
  // DELETE_CANNED_MESSAGES,
  FETCH_MESSAGES,
  MESSAGES_LOADED,
  CREATE_CONTENT_MESSAGE,
  CREATE_CONTENT_MESSAGE_SUCCESS,
  CREATE_CONTENT_MESSAGE_FAILED,
  UPDATE_CONTENT_MESSAGE,
  UPDATE_CONTENT_MESSAGE_SUCCESS,
  UPDATE_CONTENT_MESSAGE_FAILED,
  DELETE_CONTENT_MESSAGES,
  DELETE_CONTENT_MESSAGES_SUCCESS,
  DELETE_CONTENT_MESSAGES_FAILED,
  // PUBLISH_CANNED_MESSAGE,
  // UNPUBLISH_CANNED_MESSAGE,
  DELETE_SCHEDULED_MESSAGES,
  DELETE_SCHEDULED_MESSAGES_FAILED,
  DELETE_SCHEDULED_MESSAGES_SUCCESS,
  DELETE_SCHEDULED_MESSAGE,
  CREATE_SCHEDULED_MESSAGE,
  CREATE_SCHEDULED_MESSAGE_FAILED,
  CREATE_SCHEDULED_MESSAGE_SUCCESS,
  IContentMessage,
  IMessage,
  LOAD_MESSAGES_FAILED,
} from './types';
import { IReduxAction } from '../types';

export type IFetchCannedMessages = IReduxAction<typeof FETCH_CANNED_MESSAGES>;
export const fetchCannedMessages = (): IFetchCannedMessages => ({
  type: FETCH_CANNED_MESSAGES,
  payload: {},
});

export type ILoadCannedMessages = IReduxAction<typeof LOAD_CANNED_MESSAGES>;
export const loadCannedMessages = (): ILoadCannedMessages => ({
  type: LOAD_CANNED_MESSAGES,
  payload: {},
});

export type ICannedMessagesLoaded = IReduxAction<
  typeof CANNED_MESSAGES_LOADED,
  { messages: IContentMessage[] }
>;
export const cannedMessagesLoaded = (messages: IContentMessage[]): ICannedMessagesLoaded => ({
  type: CANNED_MESSAGES_LOADED,
  payload: {
    messages,
  },
});

export type ICreateContentMessage = IReduxAction<
  typeof CREATE_CONTENT_MESSAGE,
  { message: IContentMessage; type: string; messageTranslationKey: string; path: string }
>;
export const createContentMessage = (
  message: IContentMessage,
  type: string,
  messageTranslationKey: string,
  path: string,
): ICreateContentMessage => ({
  type: CREATE_CONTENT_MESSAGE,
  payload: {
    message,
    type,
    messageTranslationKey,
    path,
  },
});

export type ICreateContentMessageSuccess = IReduxAction<typeof CREATE_CONTENT_MESSAGE_SUCCESS>;
export const createContentMessageSuccess = (): ICreateContentMessageSuccess => ({
  type: CREATE_CONTENT_MESSAGE_SUCCESS,
  payload: {},
});

export type ICreateContentMessageFailed = IReduxAction<typeof CREATE_CONTENT_MESSAGE_FAILED>;
export const createContentMessageFailed = (): ICreateContentMessageFailed => ({
  type: CREATE_CONTENT_MESSAGE_FAILED,
  payload: {},
});

export type IUpdateContentMessage = IReduxAction<
  typeof UPDATE_CONTENT_MESSAGE,
  { message: IContentMessage; type: string; messageTranslationKey: string; id: string }
>;
export const updateContentMessage = (
  message: IContentMessage,
  type: string,
  messageTranslationKey: string,
  id: string,
): IUpdateContentMessage => ({
  type: UPDATE_CONTENT_MESSAGE,
  payload: {
    message,
    type,
    messageTranslationKey,
    id,
  },
});

export type IUpdateContentMessageSuccess = IReduxAction<typeof UPDATE_CONTENT_MESSAGE_SUCCESS>;
export const updateContentMessageSuccess = (): IUpdateContentMessageSuccess => ({
  type: UPDATE_CONTENT_MESSAGE_SUCCESS,
  payload: {},
});

export type IUpdateContentMessageFailed = IReduxAction<typeof UPDATE_CONTENT_MESSAGE_FAILED>;
export const updateContentMessageFailed = (): IUpdateContentMessageFailed => ({
  type: UPDATE_CONTENT_MESSAGE_FAILED,
  payload: {},
});

export type IDeleteContentMessages = IReduxAction<
  typeof DELETE_CONTENT_MESSAGES,
  { messageIds: string[]; messageTranslationKey: string }
>;
export const deleteContentMessages = (
  messageIds: string[],
  messageTranslationKey: string,
): IDeleteContentMessages => ({
  type: DELETE_CONTENT_MESSAGES,
  payload: {
    messageIds,
    messageTranslationKey,
  },
});

export type IDeleteContentMessagesSuccess = IReduxAction<
  typeof DELETE_CONTENT_MESSAGES_SUCCESS,
  { messages: IContentMessage[] }
>;
export const deleteContentMessagesSuccess = (
  messages: IContentMessage[],
): IDeleteContentMessagesSuccess => ({
  type: DELETE_CONTENT_MESSAGES_SUCCESS,
  payload: { messages },
});

export type IDeleteContentMessagesFailed = IReduxAction<typeof DELETE_CONTENT_MESSAGES_FAILED>;
export const deleteContentMessagesFailed = (): IDeleteContentMessagesFailed => ({
  type: DELETE_CONTENT_MESSAGES_FAILED,
  payload: {},
});

// export type IPublishCannedMessage = IReduxAction<
//   typeof PUBLISH_CANNED_MESSAGE,
//   { messageId: string }
// >;
// export const publishCannedMessage = (messageId: string): IPublishCannedMessage => ({
//   type: PUBLISH_CANNED_MESSAGE,
//   payload: {
//     messageId,
//   },
// });

// export type IUnpublishCannedMessage = IReduxAction<
//   typeof UNPUBLISH_CANNED_MESSAGE,
//   { messageId: string }
// >;
// export const unpublishCannedMessage = (messageId: string): IUnpublishCannedMessage => ({
//   type: UNPUBLISH_CANNED_MESSAGE,
//   payload: {
//     messageId,
//   },
// });

export type IFetchMessages = IReduxAction<typeof FETCH_MESSAGES>;
export const fetchMessages = (): IFetchMessages => ({
  type: FETCH_MESSAGES,
  payload: {},
});

export type IMessagesLoaded = IReduxAction<
  typeof MESSAGES_LOADED,
  { scheduled: IMessage[]; sent: IMessage[] }
>;
export const messagesLoaded = (sent: IMessage[], scheduled: IMessage[]): IMessagesLoaded => ({
  type: MESSAGES_LOADED,
  payload: {
    scheduled,
    sent,
  },
});

export type ILoadMessages = IReduxAction<typeof LOAD_MESSAGES>;
export const loadMessages = (): ILoadMessages => ({
  type: LOAD_MESSAGES,
  payload: {},
});

export type ILoadMessagesFailed = IReduxAction<typeof LOAD_MESSAGES_FAILED>;
export const loadMessagesFailed = (): ILoadMessagesFailed => ({
  type: LOAD_MESSAGES_FAILED,
  payload: {},
});

export type IDeleteScheduledMessage = IReduxAction<
  typeof DELETE_SCHEDULED_MESSAGE,
  { messageId: number }
>;
export const deleteScheduledMessage = (messageId: number): IDeleteScheduledMessage => ({
  type: DELETE_SCHEDULED_MESSAGE,
  payload: {
    messageId,
  },
});

export type IDeleteScheduledMessages = IReduxAction<
  typeof DELETE_SCHEDULED_MESSAGES,
  { messageIds: number[] }
>;
export const deleteScheduledMessages = (messageIds: number[]): IDeleteScheduledMessages => ({
  type: DELETE_SCHEDULED_MESSAGES,
  payload: {
    messageIds,
  },
});

export type IDeleteScheduledMessagesSuccess = IReduxAction<
  typeof DELETE_SCHEDULED_MESSAGES_SUCCESS,
  { messageIds: number[] }
>;
export const deleteScheduledMessagesSuccess = (
  messageIds: number[],
): IDeleteScheduledMessagesSuccess => ({
  type: DELETE_SCHEDULED_MESSAGES_SUCCESS,
  payload: {
    messageIds,
  },
});

export type IDeleteScheduledMessagesFailed = IReduxAction<typeof DELETE_SCHEDULED_MESSAGES_FAILED>;
export const deleteScheduledMessagesFailed = (): IDeleteScheduledMessagesFailed => ({
  type: DELETE_SCHEDULED_MESSAGES_FAILED,
  payload: {},
});

export type ICreateScheduledMessage = IReduxAction<
  typeof CREATE_SCHEDULED_MESSAGE,
  { messageData: ICreateMessageInput }
>;
export const createScheduledMessage = (
  messageData: ICreateMessageInput,
): ICreateScheduledMessage => ({
  type: CREATE_SCHEDULED_MESSAGE,
  payload: { messageData },
});

export type ICreateScheduledMessageFailed = IReduxAction<typeof CREATE_SCHEDULED_MESSAGE_FAILED>;
export const createScheduledMessageFailed = (): ICreateScheduledMessageFailed => ({
  type: CREATE_SCHEDULED_MESSAGE_FAILED,
  payload: {},
});

export type ICreateScheduledMessageSuccess = IReduxAction<
  typeof CREATE_SCHEDULED_MESSAGE_SUCCESS,
  { newMessage: IMessage }
>;
export const createScheduledMessageSuccess = (
  newMessage: IMessage,
): ICreateScheduledMessageSuccess => ({
  type: CREATE_SCHEDULED_MESSAGE_SUCCESS,
  payload: { newMessage },
});

export type IPublishContentMessage = ReturnType<typeof publishContentMessage>;
export const publishContentMessage = (id: string, translationKey: string) => ({
  type: 'content-messages/publish' as const,
  payload: { id, translationKey },
});

export type IPublishContentMessageFailed = ReturnType<typeof publishContentMessageFailed>;
export const publishContentMessageFailed = () => ({
  type: 'content-messages/publish-failed' as const,
  payload: {},
});

export type IPublishContentMessageSuccess = ReturnType<typeof publishContentMessageSuccess>;
export const publishContentMessageSuccess = () => ({
  type: 'content-messages/publish-success' as const,
  payload: {},
});

export type IUnpublishContentMessage = ReturnType<typeof unpublishContentMessage>;
export const unpublishContentMessage = (id: string, translationKey: string) => ({
  type: 'content-messages/unpublish' as const,
  payload: { id, translationKey },
});

export type IUnpublishContentMessageFailed = ReturnType<typeof unpublishContentMessageFailed>;
export const unpublishContentMessageFailed = () => ({
  type: 'content-messages/unpublish-failed' as const,
  payload: {},
});

export type IUnpublishContentMessageSuccess = ReturnType<typeof unpublishContentMessageSuccess>;
export const unpublishContentMessageSuccess = () => ({
  type: 'content-messages/unpublish-success' as const,
  payload: {},
});
