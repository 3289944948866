import { call, getContext } from 'redux-saga/effects';
import { IIDMClient } from '@liquid-state/idm-client';
import createIDMClient from '@api/idmClient';

function* doCreateIDMClient() {
  const tokens = yield getContext('tokens');
  const client: IIDMClient = yield call(createIDMClient, tokens.tokenMap.get('idm'));

  return client;
}

export default doCreateIDMClient;
