import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions } from '@hooks';
import { ColumnGroup, Column } from '@cards/Card';
import DataRefresh from '@reviewHub/cards/DataRefresh';
import { fetchPathwayMetrics } from '@reviewHub/redux/actions';
import { selectAllPathwayMetrics } from '@reviewHub/redux/selectors';
import SearchableTable from '@components/SearchableTable';
import LanguageIcon from '@components/LanguageIcon';
import { createLanguageFilters } from '@utils/supportedLanguages';
import SourceIcon from '@components/SourceIcon';
import { extractSourceFromOwnerId } from '@utils';
import PubslishedStatusIcon from '@components/PublishedStatusIcon';

const filterFunc = (value, comparator) => comparator(value.name);

function PathwaysList({ history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  usePageActions({ title: t('reviewHub:Pathways.title') });

  const [loading, pathways] = useSelector(selectAllPathwayMetrics);

  useEffect(() => {
    dispatch(fetchPathwayMetrics());
  }, [dispatch]);

  const columns = useMemo(
    () => [
      {
        className: 'language-column',
        title: t('cards:ProcedurePathwayList.columns.language.title'),
        dataIndex: 'metadata',
        key: 'language',
        render: ({ language }) => (language ? <LanguageIcon language={language} /> : null),
        sorter: (a, b) =>
          a.metadata.language && b.metadata.language
            ? a.metadata.language.localeCompare(b.metadata.language)
            : -1,
        filters: createLanguageFilters(t),
        onFilter: (value, record) => {
          return record.metadata.language === value;
        },
      },
      {
        className: 'source-column',
        title: t('cards:ProcedurePathwayList.columns.source.title'),
        dataIndex: 'ownerId',
        key: 'source',
        render: (ownerId, record) => (
          <SourceIcon
            customName={record.ownerName === 'Global' ? undefined : record.ownerName}
            showText
            source={extractSourceFromOwnerId(ownerId)}
          />
        ),
        sorter: (a, b) => {
          if (a.source > b.source) return -1;
          if (a.source < b.source) return 1;
          return 0;
        },
        filters: [
          {
            text: t('common:source.global'),
            value: 'global',
          },
          {
            text: t('common:source.hospital'),
            value: 'hospital',
          },
        ],
        onFilter: (value, record) => (record.ownerId || '').includes(value),
      },
      {
        title: t('cards:ProcedurePathwayList.columns.title'),
        dataIndex: 'name',
        key: 'title',
        ellipsis: true,
        sorter: (a, b) => {
          if (a.name > b.name) return -1;
          if (a.name < b.name) return 1;
          return 0;
        },
      },
      {
        className: 'published-column',
        title: t('cards:ProcedurePathwayList.columns.published.title'),
        dataIndex: 'isActive',
        fixed: 'right',
        key: 'isActive',
        render: isActive => <PubslishedStatusIcon published={isActive} />,
        sorter: ({ isActive: a }, { isActive: b }) => {
          if (a === b) return 0;
          if (a < b) return 1;
          if (a > b) return -1;
        },
        filters: [
          {
            text: t('cards:ProcedurePathwayList.columns.published.published'),
            value: true,
          },
          {
            text: t('cards:ProcedurePathwayList.columns.published.unpublished'),
            value: false,
          },
        ],
        onFilter: (value, record) => record.isActive === value,
      },
    ],
    [t],
  );

  return (
    <ColumnGroup>
      <Column>
        <SearchableTable
          columns={columns}
          dataSource={pathways}
          loading={loading}
          filterFunc={filterFunc}
          onRowClick={({ pathwayId: id }) => history.push(`${match.path}/${id}`)}
        />
        <DataRefresh />
      </Column>
    </ColumnGroup>
  );
}

export default PathwaysList;
