import React from 'react';
import { bool, shape } from 'prop-types';
import DocumentDetails from '@cards/Documents/Details';
import FormDetails from '@cards/Forms/FormDetails';
import { documentTypes, formTypes } from '@utils/contentTypes';

function AttachedContent({ content, loading }) {
  if (documentTypes.includes(content?.metadata?.type)) {
    const documentTranslationKey = content?.metadata?.type === 'VIDEO' ? 'videos' : 'documents';
    return (
      <DocumentDetails
        documentId={content.id}
        documentTranslationKey={documentTranslationKey}
        title="cards:SentMessageAttachedContent.title"
      />
    );
  }

  if (formTypes.includes(content?.metadata?.type)) {
    return <FormDetails formId={content.id} title="cards:SentMessageAttachedContent.title" />;
  }

  return null;
}

AttachedContent.propTypes = {
  content: shape({}),
  loading: bool,
};

AttachedContent.defaultProps = {
  content: null,
  loading: false,
};

export default AttachedContent;
