import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { faCopy, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import EditButton from '@components/EditButton';
import { selectRule } from '@pathways/redux/rules/reducers';
import { Column } from '@cards/Card';
import { usePageActions } from '@hooks';
import { waitForStoreUpdate } from '@utils';
import { selectMasterRulesMap } from '@pathways/redux/rules/reducers';
import { deleteRule, duplicateRule, fetchRules } from '@pathways/redux/rules/actions';
import ProcedureRuleDetails from '@pathways/cards/Pathways/RuleDetails';
import confirmModal from '@pathways/cards/Pathways/PathwayList/confirmModal';
import confirmDuplicateModal from '@pathways/cards/Pathways/RuleList/confirmDuplicateModal';
import { matchType } from '../../../propTypes';

function RuleDetails({ history, match }) {
  const dispatch = useDispatch();
  const store = useStore();
  const { t } = useTranslation();
  const [, rule] = useSelector(selectRule(match.params.id));
  const masterRules = useSelector(selectMasterRulesMap);

  const { setActions, setTitle } = usePageActions({
    actions: [],
    showBack: true,
  });

  const copyRule = useCallback(() => {
    dispatch(duplicateRule(rule));

    return waitForStoreUpdate(store, selectRule(rule.id));
  }, [dispatch, rule, store]);

  const onDeleteRule = useCallback(() => {
    dispatch(deleteRule(rule.id));
    return waitForStoreUpdate(store, selectRule(rule.id));
  }, [dispatch, rule, store]);

  useEffect(() => {
    dispatch(fetchRules());
  }, [dispatch]);

  useEffect(() => {
    if (rule) {
      setTitle(rule.name);
      setActions([
        <IconButton
          icon={faCopy}
          key="duplicate-rule"
          tooltip={t('common:buttons.copy')}
          onClick={() => confirmDuplicateModal(t, copyRule)}
        />,
        ...(masterRules[rule?.id]
          ? [null]
          : [
              <IconButton
                icon={faTrashAlt}
                key="delete-rule"
                tooltip={t('common:buttons.delete')}
                onClick={() =>
                  confirmModal(t('cards:ProcedureRuleList.modals.confirmDelete'), onDeleteRule)
                }
              />,
              <EditButton
                key="edit-rule"
                onClick={() => history.push(`/procedure/rules/${rule.id}/edit`)}
              />,
            ]),
      ]);
    }
  }, [masterRules, rule, t, copyRule, onDeleteRule, history, setActions, setTitle]);

  return (
    <Column>
      <ProcedureRuleDetails ruleId={match.params.id} />
    </Column>
  );
}

RuleDetails.propTypes = {
  match: matchType.isRequired,
};

export default RuleDetails;
