import { IFormSubmissionRaw, IFormSubmission } from './../types';
import { put, call, getContext, select, take } from 'redux-saga/effects';
import { message } from 'antd';
import { GLOBAL_FORMS_OBJECT_TYPE } from '@redux/forms/utils';
import { IForm } from '@redux/forms/types';
import { temporaryFormsLoaded } from '@redux/forms/actions';
import i18n from '../../../i18n';
import {
  fetchAppUsers,
  fetchAppUsersFailed,
  loadAppUsers,
  appUsersFormsLoaded,
  appUsersLoaded,
  IFetchAppUsersForms,
  loadAppUserForms,
  IFetchFormsForAppUserResults,
} from '../actions';
import { selectAppUser } from '../reducers';
import { IUISUser, APP_USERS_LOADED } from '@redux/appUsers/types';
import doCreateUISAdminClient from '../../doCreateUISAdminClient';
import { mapUISUserToAppUser } from '@redux/appUsers/utils';
import createPipClient, { PIPObject } from '@api/pipClient';
import { appToken } from 'settings';
import { trackDataEvent, timeEvent } from '../../../analytics';

const BATCH_SIZE = 8;
const PAGE_SIZE = 50;

export function* doFetchAppUsers(): any {
  trackDataEvent('App Users Loading');
  timeEvent('App Users Loaded', 'Data');
  timeEvent('App Users Loading Failure', 'Data');
  yield put(loadAppUsers());

  try {
    const client = yield call(doCreateUISAdminClient);
    const appUsers = [];
    let page = 1;

    // temporary batching logic to improve list load speed
    // fetch first page
    const {
      count,
      next: moreThanOnePage,
      results,
    }: { count: number; next: string; results: IUISUser[] } = yield call(
      client.listAppUsersForApp,
      page,
    );
    appUsers.push(...results.map(uisUser => mapUISUserToAppUser(uisUser)));
    // page += 1;

    // if more than 1 page, break pages up in to batches of BATCH_SIZE
    if (moreThanOnePage) {
      const numberOfPages = Math.ceil(count / PAGE_SIZE);
      const pageBatches: number[][] = [];

      for (let i = page; i <= numberOfPages; i += BATCH_SIZE) {
        pageBatches.push([]);
        for (let j = 1; j < BATCH_SIZE + 1; j++) {
          if (i + j <= numberOfPages) {
            const pageBatchesIndex = i > BATCH_SIZE ? Math.floor(i / BATCH_SIZE) : 0;
            pageBatches[pageBatchesIndex] = [...pageBatches[pageBatchesIndex], i + j];
          }
        }
      }

      // iterate over batches of pages to fetch all pages
      for (const batch of pageBatches) {
        const fetchedPages = yield call(
          Promise.all.bind(Promise),
          batch.map(pageNumber => client.listAppUsersForApp(pageNumber)),
        );

        appUsers.push(
          ...fetchedPages.flatMap(
            ({ results }: { count: number; next: string; results: IUISUser[] }) =>
              results.map(uisUser => mapUISUserToAppUser(uisUser)),
          ),
        );
      }
    }
    // end temp batching logic

    // while (true) {
    //   const { next, results }: { next: string; results: IUISUser[] } = yield call(
    //     client.listAppUsersForApp,
    //     page,
    //   );
    //   appUsers.push(...results.map(uisUser => mapUISUserToAppUser(uisUser)));
    //   if (!next) break;
    //   page += 1;
    // }

    trackDataEvent('App Users Loaded');
    yield put(appUsersLoaded(appUsers));
  } catch (err) {
    console.error(err);
    trackDataEvent('App Users Loading Failure');
    yield put(fetchAppUsersFailed());
    yield call(message.error, i18n.t('cards:PatientList.networkError'));
  }
}

export function* doFetchAppUserForms({ payload: { appUserUISId } }: IFetchAppUsersForms): any {
  yield put(loadAppUserForms());

  let [, appUser] = yield select(selectAppUser(appUserUISId));

  if (!appUser) {
    yield put(fetchAppUsers());

    while (true) {
      const {
        payload: { appUsers },
      } = yield take(APP_USERS_LOADED);
      if (appUsers) {
        [, appUser] = yield select(selectAppUser(appUserUISId));
        break;
      }
    }
  }

  const tokens = yield getContext('tokens');
  const pipClient = yield call(createPipClient, tokens);

  let appUserResponse: any = yield call(pipClient.getAppUser, appUser.ids.pip);

  let pipAppUser = null;
  if (appUserResponse.results.length === 0) {
    console.info('User could not be found in PIP, attempting to create it');
    try {
      const appDetails = yield call(pipClient.getApp, appToken);
      pipAppUser = yield call(
        pipClient.createAppUser,
        appDetails['uuid'],
        appUser.ids.pip,
        'app-user',
      );
      if (!pipAppUser) throw Error('Empty response');
    } catch {
      // yield call(message.warning, 'User could not be found');
      console.error('Failed to create User in PIP. Returning empty forms data.');
      yield put(appUsersFormsLoaded(appUserUISId, []));
      return;
    }
  } else {
    pipAppUser = appUserResponse.results[0];
  }

  const [latest]: [
    PIPObject<{
      submissions: IFormSubmissionRaw[];
    }>,
  ] = yield call(
    pipClient.getObjectsForType,
    `${appToken}-form-submissions`,
    'latest',
    pipAppUser.uuid,
  );

  let formSubmissions: IFormSubmission[] = [];
  if (latest && latest.json && latest.json.submissions) {
    formSubmissions = latest.json.submissions.map(sub => {
      return {
        // At least one of these will always be defined. This should be temporary to account for historical data.
        formObjectType: (sub.formObjectType ?? sub['form-object-type'])!,
        created: sub.created,
        versions: sub.versions,
        uuid: sub.uuid,
      };
    });
  }

  yield put(appUsersFormsLoaded(appUserUISId, formSubmissions));
}

export function* doFetchAppUserFormsForResults({
  payload: { formSubmissions },
}: IFetchFormsForAppUserResults): any {
  try {
    const extractedFormIds = formSubmissions.map(submission => {
      const extractedId = /[A-Za-z\d]{6}-form-(.*)$/.exec(submission.formObjectType);

      return extractedId ? extractedId[1] : null;
    });

    const tokens = yield getContext('tokens');
    const client = yield call(createPipClient, tokens);

    const [latest]: PIPObject<IForm[]>[] = yield call(
      client.getObjectsForType,
      GLOBAL_FORMS_OBJECT_TYPE,
      'latest',
    );

    const appUserResultFormDetails = latest.json.filter(form =>
      extractedFormIds.includes(form.uuid),
    );

    yield put(temporaryFormsLoaded(appUserResultFormDetails));
  } catch (err) {
    console.error(err);
  }
}
