import React, { useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
import { ColumnGroup, Column } from '@cards/Card';
import { usePageActions } from '@hooks';
import waitForStoreUpdate from '@utils/waitForStoreUpdate';
import MessagePathwaysCard from '@pathways/cards/MessagePathways';
import MessageDetail from '@cards/CannedMessages/MessagesDetails';
import MessageHistoryCard from '@cards/CannedMessages/MessageHistory';
import AttachedContent from '@cards/Messages/AttachedContent';
import ContentNotFound from '@components/ContentNotFound';
import { selectCannedMessage, selectCannedMessagePublishing } from '@redux/messages/reducers';
import {
  deleteContentMessages,
  fetchMessages,
  publishContentMessage,
  unpublishContentMessage,
} from '@redux/messages/actions';
import { selectCannedMessageDeleting } from '@redux/messages/reducers';
import { selectPathwaysByRules } from '@pathways/redux/pathways/reducers';
import { MESSAGE } from '@constants';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import IconButton from '@components/IconButton';
import EditButton from '@components/EditButton';
import PublishingButton from '@components/PublishingButton';
import { OPEN_CONTENT } from '@constants';

export default ({
  match: {
    params: { id: messageId },
  },
  messageTranslationKey,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const history = useHistory();

  useEffect(() => {
    dispatch(fetchMessages());
  }, [dispatch]);

  const [loading, message] = useSelector(selectCannedMessage(messageId));
  const [, pathways] = useSelector(
    selectPathwaysByRules(rule => rule.what === MESSAGE && rule.whatDetail.id === messageId),
  );
  const isPublishing = useSelector(selectCannedMessagePublishing);

  const { setTitle, setActions } = usePageActions({
    showBack: true,
  });

  useEffect(() => {
    document.title = message && message.title;
    setTitle(message ? message.title : undefined);
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setActions([
      <PublishingButton
        key="toggle-published-status"
        isPublished={message?.published}
        isPublishing={isPublishing}
        publish={() => dispatch(publishContentMessage(messageId, messageTranslationKey))}
        unpublish={() => dispatch(unpublishContentMessage(messageId, messageTranslationKey))}
      />,
      <IconButton
        key="delete-button"
        tooltip={t('common:buttons.delete')}
        icon={faTrashAlt}
        size="large"
        onClick={() => {
          Modal.confirm({
            content: pathways.length
              ? t(`${messageTranslationKey}:MessageDetail.confirmDeleteWarning`, {
                  count: pathways.length,
                })
              : null,
            title: t(`${messageTranslationKey}:MessageDetail.confirmDelete`),
            onOk: async () => {
              dispatch(deleteContentMessages([messageId], messageTranslationKey));
              await waitForStoreUpdate(store, selectCannedMessageDeleting); // must return for modal to be dismissed
              history.goBack();
            },
            onCancel: () => {},
          });
        }}
      />,
      <EditButton
        key="edit-button"
        tooltip={t(`${messageTranslationKey}:MessageDetail.editButton`)}
        onClick={() => history.push(`edit/${messageId}`)}
      />,
    ]);
  }, [
    dispatch,
    history,
    isPublishing,
    message,
    messageId,
    messageTranslationKey,
    setActions,
    store,
    t,
    pathways.length,
  ]);

  if (!message && !loading) {
    return <ContentNotFound />;
  }

  return (
    <ColumnGroup>
      <Column>
        <MessageDetail
          messageId={messageId}
          title="cards:MessageDetail.title"
          messageTranslationKey={messageTranslationKey}
        />
        <MessagePathwaysCard messageId={messageId} messageTranslationKey={messageTranslationKey} />
      </Column>
      <Column>
        {message && message.payload.action === OPEN_CONTENT && (
          <AttachedContent content={message.payload.data} />
        )}
        <MessageHistoryCard messageId={messageId} />
      </Column>
    </ColumnGroup>
  );
};
