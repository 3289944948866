import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { determineSourceTypeForNewContent } from '@utils';
import { usePageActions } from '@hooks/usePageActions';
import { createVideo } from '@redux/documents/actions';
import { selectDocumentCreating } from '@redux/documents/reducers';
import VideoWizard from './index';

const nowMoment = moment();

function NewVideo() {
  const dispatch = useDispatch();
  const creating = useSelector(selectDocumentCreating);
  const permissions = useSelector(selectPermissionsForUser);
  const { t } = useTranslation();
  usePageActions({ title: t('videos:Wizard.header') });

  const getVideoId = link => {
    const videoId = link.slice(-11);
    return videoId;
  };

  const onCompleted = useCallback(
    formData => {
      if (formData) {
        const { content, details } = formData;

        const newVideo = {
          description: details.description,
          latest_version: { created: nowMoment.toISOString(), name: details.name },
          published: details.status === 'published',
          name: details.name,
          metadata: {
            language: details.language,
            source: determineSourceTypeForNewContent(permissions),
          },
          videoData: {
            videoTitle: content.title,
            videoDescription: content.videoDescription,
            videoId: getVideoId(content.link),
            publishDate: content.publishDate,
            runTime: content.runTime,
          },
        };

        dispatch(createVideo(newVideo));
      }
    },
    [dispatch, permissions],
  );

  return <VideoWizard submitting={creating} onCompleted={onCompleted} />;
}

export default NewVideo;
