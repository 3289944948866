import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
// import { useTranslation } from 'react-i18next';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { determineSourceTypeForNewContent, extractNewDocumentVersion } from '@utils';
import useWizard from '@components/Wizard/useWizard';
import { ColumnGroup, Column } from '@cards/Card';
import DocumentDetails from '@cards/Documents/Details/Card';
// import SearchableTable from '@components/SearchableTable';
// import { groupNameColumn } from '@utils/columns';
import ReviewContent from './Content';

const nowMoment = moment();

function Review() {
  // const { t } = useTranslation();
  const { formData } = useWizard();
  const permissions = useSelector(selectPermissionsForUser);
  const { /* audience, */ content, details } = formData;

  return (
    <ColumnGroup>
      <Column>
        <DocumentDetails
          document={{
            description: details.description,
            latest_version: {
              created: nowMoment.toISOString(),
              name: details.latestVersion ? extractNewDocumentVersion(details.latestVersion) : '1',
            },
            published: details.status === 'published',
            name: details.name,
            metadata: {
              language: details.language,
              source: determineSourceTypeForNewContent(permissions),
            },
          }}
          loading={false}
        />
        {/* {audience.individuals?.length ? (
          <SearchableTable
            columns={[
              {
                title: t('documents:Wizard.review.individuals.column'),
                key: 'name',
                render: appUser => `${appUser.firstName} ${appUser.lastName}`,
                sorter: (a, b) =>
                  `${a.firstName} ${b.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
                onFilter: (value, record) => `${record.firstName} ${record.lastName}` === value,
              },
            ]}
            dataSource={audience.individuals}
            title={t('documents:Wizard.review.individuals.title')}
            filterFunc={individualsFilterFunc}
            onRowClick={() => {}}
          />
        ) : null} */}
      </Column>
      <Column>
        <ReviewContent content={content.documents[0]} />
        {/* {audience.groups?.length ? (
          <SearchableTable
            columns={[groupNameColumn]}
            dataSource={audience.groups}
            title={t('documents:Wizard.review.groups')}
            filterFunc={groupsFilterFunc}
            onRowClick={() => {}}
          />
        ) : null} */}
      </Column>
    </ColumnGroup>
  );
}

export default Review;
