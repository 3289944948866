import { call, put, select, take } from 'redux-saga/effects';
import { message } from 'antd';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import { selectHospital } from '@redux/hospitals/reducers';
import { fetchHospitals } from '@redux/hospitals/actions';
import { LOAD_HOSPITALS_SUCCESS } from '@redux/hospitals/types';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { Permissions } from '@authorisation/constants';
import { loadPathways, fetchingPathways } from '../actions';
import i18n from '../../../../i18n';
import { mapRawPathway } from '../utils';
import { doDetermineOwnerForPathway } from './utils';
import { IPathway, IPathwayIndexEvent, IRawPathway, IStage } from '../types';
import { selectIndexEvents } from '../../indexEvents/reducers';
import { extractSlugFromTypeSlug } from '../../indexEvents/utils';
import { doFetchIndexEvents } from '../../indexEvents/saga';
import { IIndexEvent } from '../../indexEvents/types';
import { setMasterRules } from '../../rules/actions';

const BATCH_SIZE = 10;
const PAGE_SIZE = 20;

export function* doFetchPathways(): any {
  yield put(fetchingPathways());

  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const permissions = yield select(selectPermissionsForUser);
    let pathways: IPathway[] = [];

    const ownerId = yield call(doDetermineOwnerForPathway);
    let page = 1;

    // temporary batching logic to improve list load speed
    // fetch first page
    const {
      count,
      next: moreThanOnePage,
      results,
    }: { count: number; next: string; results: IRawPathway[] } = yield call(
      pathwaysClient.listPathways,
      page,
      true,
      undefined,
      ownerId,
    );
    pathways = [...results.map(pathway => mapRawPathway(pathway))];

    // if more than 1 page, break pages up in to batches of BATCH_SIZE
    if (moreThanOnePage) {
      const numberOfPages = Math.ceil(count / PAGE_SIZE);
      const pageBatches = [];
      for (let i = page; i <= numberOfPages; i += BATCH_SIZE) {
        pageBatches.push([]);

        for (let j = 1; j < BATCH_SIZE + 1; j++) {
          if (i + j <= numberOfPages) {
            const pageBatchesIndex = i > BATCH_SIZE ? Math.floor(i / BATCH_SIZE) : 0;
            pageBatches[pageBatchesIndex] = [...pageBatches[pageBatchesIndex], i + j];
          }
        }
      }

      // iterate over batches of pages to fetch all pages
      for (const batch of pageBatches) {
        const fetchedPages = yield call(
          Promise.all.bind(Promise),
          batch.map(pageNumber =>
            pathwaysClient.listPathways(pageNumber, true, undefined, ownerId),
          ),
        );

        pathways = [
          ...pathways,
          ...fetchedPages.flatMap(({ results }: { results: IRawPathway[] }) =>
            results.map(pathway => mapRawPathway(pathway)),
          ),
        ];
      }
    }
    // end temp batching logic

    // while (true) {
    //   const { next, results }: { next: string; results: IRawPathway[] } = yield call(
    // pathwaysClient.listPathways,
    // page,
    // true,
    // undefined,
    // ownerId,
    //   );
    //   pathways = [...pathways, ...results.map(pathway => mapRawPathway(pathway))];

    //   if (!next) break;
    //   page += 1;
    // }

    if (permissions.includes(Permissions.ManagePatients)) {
      const dashboardUserProfile = yield select(state => state.login.user.profile);
      let [loading, hospital] = yield select(selectHospital(dashboardUserProfile.hospitalId));

      if (loading || hospital === undefined) {
        yield put(fetchHospitals());
        yield take(LOAD_HOSPITALS_SUCCESS);
        [loading, hospital] = yield select(selectHospital(dashboardUserProfile.hospitalId));
      }

      const masterRuleIds: number[] = [];

      const batchedHospitalPathways = hospital.pathways.reduce(
        (batched: number[][], pathwayId: number, index: number) => {
          const batchIndex = Math.floor(index / BATCH_SIZE) || 0;
          batched[batchIndex] = [...(batched[batchIndex] || []), pathwayId];
          return batched;
        },
        [],
      );

      for (const batch of batchedHospitalPathways) {
        const fetchedPathways = yield call(
          Promise.all.bind(Promise),
          batch.map((pathwayId: number) => pathwaysClient.getPathway(pathwayId)),
        );
        for (const pathway of fetchedPathways) {
          pathways.push(mapRawPathway(pathway));

          if (hospital.pathways.includes(pathway.id)) {
            pathway.index_events.forEach((indexEvent: IPathwayIndexEvent) => {
              indexEvent.rules.forEach(rule => masterRuleIds.push(rule.id));
            });
            pathway.stages.forEach((stage: IStage) => {
              stage.rules.forEach(rule => masterRuleIds.push(rule.id));
            });
          }
        }
      }

      yield put(setMasterRules(masterRuleIds));
    }

    // TODO: remove this when backend returns pathway index events in correct order
    let [, indexEvents] = yield select(selectIndexEvents);
    if (!indexEvents.length) {
      yield call(doFetchIndexEvents);
      [, indexEvents] = yield select(selectIndexEvents);
    }
    const indexEventsBySlug = indexEvents.reduce(
      (bySlug: { [key: string]: IIndexEvent }, indexEvent: IIndexEvent) => ({
        ...bySlug,
        [indexEvent.slug]: indexEvent,
      }),
      {},
    );

    pathways = pathways.map(pathway => {
      if (!pathway.isDeleted) {
        pathway.indexEvents.sort((a, b) => {
          const aSlug = extractSlugFromTypeSlug(a.eventTypeSlug);
          const bSlug = extractSlugFromTypeSlug(b.eventTypeSlug);

          if (indexEventsBySlug[aSlug].order > indexEventsBySlug[bSlug].order) {
            return 1;
          }
          if (indexEventsBySlug[aSlug].order < indexEventsBySlug[bSlug].order) {
            return -1;
          }
          return 0;
        });
      }
      return pathway;
    });
    // END TODO

    yield put(loadPathways(pathways));
  } catch (err) {
    console.error(err);
    yield call(message.error, i18n.t('cards:ProcedurePathwayList.networkError'));
    yield put(loadPathways([]));
  }
}
