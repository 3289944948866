import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import PathwayStagesTableCard from '@pathways/cards/Pathways/PathwayStagesTable';
import PathwayDetailsCard from '@pathways/cards/Pathways/PathwayDetails';
import IndexEventTriggeredRulesCard from '@pathways/cards/AppUsers/IndexEventTriggeredRules';
import { Card, Column, ColumnGroup } from '@cards/Card';
import { selectAppUser } from '@redux/appUsers/reducers';
import {
  fetchAppUserJourneys,
  processAppUserPathwayNow,
  setAppUserPathwayStage,
} from '@pathways/redux/appUserPathways/actions';
import {
  selectPathwaysForAppUserWithOriginals,
  selectJourneysForAppUser,
  selectAppUserPathwayRuleTriggering,
} from '@pathways/redux/appUserPathways/reducers';
import { triggerAdhocRule } from '../../redux/appUserPathways/actions';
import { matchType } from '../../../propTypes';
import { setCurrentScreen, screens } from '../../../analytics';

function PathwayDetails({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    params: { id: appUserId, pathwayId },
  } = match;

  useEffect(() => {
    setCurrentScreen(screens.APP_USER_PATHWAY);
  }, []);

  useEffect(() => {
    dispatch(fetchAppUserJourneys(appUserId));
  }, [appUserId, dispatch]);

  const [appUserLoading, appUser] = useSelector(selectAppUser(appUserId));
  const [journeysLoading, journeys] = useSelector(selectJourneysForAppUser(appUser));
  const [loading, appUserPathways] = useSelector(selectPathwaysForAppUserWithOriginals(appUser));
  const ruleTriggering = useSelector(selectAppUserPathwayRuleTriggering);

  const appUserPathway = useMemo(
    () => (appUserPathways || []).find(({ id }) => id === Number(pathwayId)),
    [appUserPathways, pathwayId],
  );

  const triggerRule = useCallback(
    ruleId => {
      dispatch(triggerAdhocRule(appUserId, pathwayId, ruleId));
    },
    [dispatch, appUserId, pathwayId],
  );

  // only supporting one journey for now
  const journey = journeys ? journeys[0] : {};

  const triggeredRuleIds = useMemo(
    () =>
      (journey?.entries || []).reduce(
        (ruleIds, entry) =>
          entry.type === 'rule_execution' ? [...ruleIds, entry.data.original_rule_id] : ruleIds,
        [],
      ),
    [journey],
  );

  const transitionPathwayToStage = useCallback(
    stageSlug => dispatch(setAppUserPathwayStage(appUserId, stageSlug, appUserPathway?.id)),
    [dispatch, appUserId, appUserPathway],
  );

  return (
    <>
      <Column>
        <PathwayStagesTableCard
          currentStageSlug={appUserPathway?.currentStageSlug}
          loading={appUserLoading || journeysLoading || loading || ruleTriggering}
          pathwayId={appUserPathway?.originalPathway?.id}
          triggeredRules={triggeredRuleIds}
          transitionPathwayToStage={transitionPathwayToStage}
          triggerAdhocRule={triggerRule}
        />
        <IndexEventTriggeredRulesCard
          journey={journey}
          pathwayId={appUserPathway?.originalPathway?.id}
        />
        <ColumnGroup>
          <Column>
            <ColumnGroup>
              <Column>
                <PathwayDetailsCard
                  pathwayId={appUserPathway?.originalPathway?.id}
                  externalId={appUserPathway?.externalId}
                />
              </Column>
              <Column>
                <Card title={t('patients:Pathways.processPathway.title')}>
                  <p>{t('patients:Pathways.processPathway.info')}</p>
                  <Button onClick={() => dispatch(processAppUserPathwayNow(appUserId, pathwayId))}>
                    {t('common:buttons.processNow')}
                  </Button>
                </Card>
              </Column>
            </ColumnGroup>
          </Column>
        </ColumnGroup>
      </Column>
    </>
  );
}

PathwayDetails.propTypes = {
  match: matchType.isRequired,
};

export default PathwayDetails;
