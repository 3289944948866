import { whatTypeIcons } from '@constants';

function stageRulesByWhat(stages) {
  if (!stages) return [];

  const contentTypesByStage = Object.values(stages).reduce((prev, stage) => {
    return {
      ...prev,
      [stage.number]: {
        ...stage.rules.reduce(
          (rulesByWhat, rule) => ({
            ...rulesByWhat,
            [rule.what]: [...(rulesByWhat[rule.what] ? rulesByWhat[rule.what] : []), rule],
          }),
          {},
        ),
      },
    };
  }, {});

  const stageRulesByWhat = Object.entries(contentTypesByStage).reduce(
    (prev, [stageNumber, rulesByWhat]) => {
      return {
        ...prev,
        ...Object.entries(rulesByWhat).reduce(
          (stageByWhat, [whatType, rules]) => ({
            ...stageByWhat,
            [whatType]: {
              ...prev[whatType],
              icon: whatTypeIcons[whatType],
              key: `${whatType}-row`,
              [stageNumber]: rules,
            },
          }),
          {},
        ),
      };
    },
    {},
  );

  return Object.values(stageRulesByWhat);
}

export default stageRulesByWhat;
