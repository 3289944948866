import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '@cards/Card';
import { OPEN_CONTENT, OPEN_LINK } from '@constants';
import './styles.less';

function PreviewCard({ action, content, title, websiteLink, contentTitle }) {
  const { t } = useTranslation();

  const linkHref = useMemo(() => {
    if (websiteLink?.toLowerCase().startsWith('http')) {
      return websiteLink;
    }

    return `http://${websiteLink}`;
  }, [websiteLink]);

  const truncTitle = title && title.length >= 75 ? `${title.substring(0, 75)}...` : title;
  const truncContent =
    content && content.length >= 178 ? `${content.substring(0, 178)}...` : content;

  return (
    <Card>
      <div className="notification-preview">
        <div className="notification-message-preview">
          <div className="notification-message-preview-header">
            <span>{t('messages:Wizard.details.preview.forPatientApp')}</span>
            <span>{t('messages:Wizard.details.preview.now')}</span>
          </div>
          <p>
            <b>{truncTitle || t('messages:Wizard.details.preview.titlePlaceholder')}</b>
          </p>
          <p>{truncContent || t('messages:Wizard.details.preview.bodyPlaceholder')}</p>
          {action === OPEN_LINK && websiteLink ? (
            <a href={linkHref} rel="noopener noreferrer" target="_blank">
              {websiteLink}
            </a>
          ) : null}
          {action === OPEN_CONTENT && contentTitle ? <u>{contentTitle}</u> : null}
        </div>
      </div>
      <div className="notification-preview-footer">
        {t('messages:Wizard.details.preview.footer')}
      </div>
    </Card>
  );
}

export default PreviewCard;
