import {
  HOSPITAL_USER,
  HOSPITAL_ADMIN,
  SUPER_ADMIN,
  SUPPORT_ADMIN,
  SYSTEM_ADMIN,
} from '@constants';
import { UserType } from './types';

const userTypes: { [key: string]: UserType } = {
  [HOSPITAL_ADMIN]: HOSPITAL_ADMIN,
  [HOSPITAL_USER]: HOSPITAL_USER,
  [SUPER_ADMIN]: SUPER_ADMIN,
  [SUPPORT_ADMIN]: SUPPORT_ADMIN,
  [SYSTEM_ADMIN]: SYSTEM_ADMIN,
};

export function determineUserTypeFromRole(roleURI: string): UserType {
  const [, userType] = Object.entries(userTypes).find(([key]) => roleURI.includes(key))!;
  // TODO: remove default value when stuff is good;
  return userType || HOSPITAL_USER;
}
