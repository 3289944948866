import React from 'react';
import { Column } from '@cards/Card';

import HospitalProcedurePathwaysCard from './HospitalProcedurePathwaysCard';

import './styles.less';

export default ({
  match: {
    params: { id },
  },
}) => {
  return (
    <Column>
      <HospitalProcedurePathwaysCard hospitalId={id} />
    </Column>
  );
};
