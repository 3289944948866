import React from 'react';
import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Table } from 'antd';
import { Card } from '@cards/Card';
import './style.less';

function MetricTableCard({ data, loading, showHeader, title }) {
  const { t } = useTranslation();

  return (
    <Card className="metric-table-card" title={title}>
      <Table
        columns={[
          {
            dataIndex: 'metric',
            title: t('reviewHub:columns.metric'),
            width: '50%',
          },
          {
            dataIndex: 'value',
            title: t('reviewHub:columns.value'),
          },
        ]}
        dataSource={data}
        loading={loading}
        pagination={false}
        showHeader={showHeader}
      />
    </Card>
  );
}

MetricTableCard.propTypes = {
  data: arrayOf(shape({ metric: string, value: oneOfType([number, string]) })),
  loading: bool,
  showHeader: bool,
  title: string.isRequired,
};

MetricTableCard.defaultProps = {
  loading: false,
  showHeader: true,
};

export default MetricTableCard;
