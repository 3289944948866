import React, { useMemo, useEffect } from 'react';
import { shape } from 'prop-types';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { selectSourceIcon, selectSourceLegend } from '@utils/supportedLanguages';
import SelectionModal from '../SelectionModal/index';
import LanguageIcon from '../LanguageIcon/index';
import { createLanguageFilters } from '@utils/supportedLanguages';
import ActiveIcon from '../ActiveIcon/index';
import { track } from '../../analytics';

function SelectPathwayModal({ defaultFilters, pathways, onSubmit, ...props }) {
  const { t } = useTranslation();

  useEffect(() => {
    track('Select Pathway Modal open');
  }, []);

  const onTableSubmit = (...args) => {
    track('Select Pathway Modal submit');
    onSubmit(...args);
  };

  const columns = useMemo(
    () => [
      {
        title: t('common:SelectPathwayModal.columns.language'),
        dataIndex: 'metadata',
        defaultFilteredValue: defaultFilters.language || [],
        key: 'language',
        render: ({ language }) => <LanguageIcon language={language} />,
        sorter: (a, b) => a.metadata.language.localeCompare(b.metadata.language),
        filters: createLanguageFilters(t),
        onFilter: (value, record) => record.metadata.language === value,
      },
      {
        title: t('common:SelectPathwayModal.columns.source'),
        dataIndex: 'source',
        key: 'source',
        render: source => (
          <Tooltip placement="bottom" title={selectSourceLegend(source, t)}>
            <FontAwesomeIcon size="lg" icon={selectSourceIcon(source)} />
          </Tooltip>
        ),
        sorter: (a, b) => a.source.localeCompare(b.source),
        filters: [
          {
            text: t('common:sources.global'),
            value: 'global',
          },
          {
            text: t('common:sources.country'),
            value: 'country',
          },
          {
            text: t('common:sources.hospital'),
            value: 'hospital',
          },
        ],
        onFilter: (value, record) => record.source === value,
      },
      {
        title: t('common:SelectPathwayModal.columns.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: t('common:SelectPathwayModal.columns.active'),
        dataIndex: 'isActive',
        key: 'isActive',
        render: active => <ActiveIcon active={active} />,
        sorter: (a, b) => a.isActive - b.isActive,
      },
    ],
    [defaultFilters, t],
  );

  return (
    <SelectionModal
      title={t('common:SelectPathwayModal.title')}
      dataSource={pathways}
      columns={columns}
      primaryKey="id"
      displayKeys={['name']}
      onSubmit={onTableSubmit}
      {...props}
    />
  );
}

SelectPathwayModal.propTypes = {
  defaultFilters: shape({}),
};

SelectPathwayModal.defaultProps = {
  defaultFilters: {},
};

export default SelectPathwayModal;
