import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Icon, Menu } from 'antd';
import { /* faDatabase, */ faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePageActions } from '@hooks';
import { procedurePathway } from '@assets/icons';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import Content from './Content';
// import DataRequests from './DataRequests';
import Pathways from './Pathways';
import Users from './Users';

const routes = {
  content: 'content',
  dataRequests: 'data-requests',
  pathways: 'pathways',
  users: 'users',
};

function Global({ match }) {
  const { t } = useTranslation();
  usePageActions({ title: t('reviewHub:Global.title') });

  return (
    <SubNavMenuContainer
      menu={
        <SubNavMenu>
          <Menu.Item key={routes.users}>
            <Icon type="user" /> {t('reviewHub:Global.Users.title')}
          </Menu.Item>
          <Menu.Item key={routes.pathways}>
            <Icon component={procedurePathway} /> {t('reviewHub:Global.Pathways.title')}
          </Menu.Item>
          <Menu.Item key={routes.content}>
            <FontAwesomeIcon icon={faFileAlt} /> {t('reviewHub:Global.Content.title')}
          </Menu.Item>
          {/* <Menu.Item key={routes.dataRequests}>
            <FontAwesomeIcon icon={faDatabase} /> {t('reviewHub:Global.DataRequests.title')}
          </Menu.Item> */}
        </SubNavMenu>
      }
    >
      <Switch>
        <Route path={`${match.path}/${routes.users}`} component={Users} />
        <Route path={`${match.path}/${routes.content}`} component={Content} />
        <Route path={`${match.path}/${routes.pathways}`} component={Pathways} />
        {/* <Route path={`${match.path}/${routes.dataRequests}`} component={DataRequests} /> */}
        <Redirect to={`${match.path}/${routes.users}`} />
      </Switch>
    </SubNavMenuContainer>
  );
}

export default Global;
