import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Icon, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { usePageActions } from '@hooks';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospital } from '@redux/hospitals/reducers';
import SubNavMenuContainer from '@components/SubNavMenuContainer';
import SubNavMenu from '@components/SubNavMenu';
import HeaderButton from '@components/HeaderButton';
import EditHospital from './Edit';
import { hospital as hospitalIcon } from '@assets/icons';
import Details from './Details';
import Content from './Content';
import './style.less';

const routes = {
  edit: 'edit',
  details: 'app-hospital-details',
  content: 'app-hospital-content',
};

const HospitalDetails = ({ history, match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dashboardUserProfile = useSelector(state => state.login.user.profile);

  const { setTitle, setActions } = usePageActions({
    title: '',
    actions: [],
    showBack: false,
  });

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const [, hospital] = useSelector(selectHospital(dashboardUserProfile.hospitalId));

  useEffect(() => {
    const name = (hospital && hospital.name) || '';
    setTitle(name);
    document.title = name;
  }, [hospital, setTitle]);

  useEffect(() => {
    if (history.location.pathname.endsWith(routes.edit)) {
      setActions([]);
    } else {
      setActions([
        <HeaderButton
          key="edit-btn"
          tooltip={t('common:buttons.edit')}
          onClick={() => history.push(`${match.url}/${routes.edit}`)}
        >
          {t('common:buttons.edit')}
        </HeaderButton>,
      ]);
    }
  }, [match, history, t, setActions]);

  return (
    <Switch>
      <Route path={`${match.path}/${routes.edit}`} component={EditHospital} />,
      <SubNavMenuContainer
        menu={
          <SubNavMenu>
            <Menu.Item key={routes.details}>
              <Icon component={hospitalIcon} />
              {t('hospitals:HospitalDetails.menu.appHospitalDetails')}
            </Menu.Item>
            <Menu.Item key={routes.content}>
              <FontAwesomeIcon icon={faFileAlt} />
              {t('hospitals:HospitalDetails.menu.appHospitalContent')}
            </Menu.Item>
          </SubNavMenu>
        }
      >
        <Switch>
          <Route path={`${match.path}/${routes.details}`} component={Details} />
          <Route path={`${match.path}/${routes.content}`} component={Content} />
          <Route
            path={match.path}
            render={() => <Redirect to={`${match.path}/${routes.details}`} />}
          />
        </Switch>
      </SubNavMenuContainer>
    </Switch>
  );
};

export default HospitalDetails;
