import React from 'react';
import { useTranslation } from 'react-i18next';
import { arrayOf, number, oneOfType, shape, string } from 'prop-types';
import { Col, Row, Statistic } from 'antd';
import { Card } from '@cards/Card';
import './style.less';

function Overview({ data, loading }) {
  const { t } = useTranslation();

  return (
    <Card
      className="global-metrics-overview-card"
      loading={loading}
      title={t('reviewHub:Global.Users.Overview.title')}
    >
      <Row justify={data.length >= 4 ? 'space-between' : 'start'} type="flex">
        {data.map(data => (
          <Col className={data.length >= 4 ? '' : 'col-padding'} key={data.key || Math.random()}>
            <Statistic title={data.metric} value={data.value} />
          </Col>
        ))}
      </Row>
    </Card>
  );
}

Overview.propTypes = {
  data: arrayOf(
    shape({
      metric: string,
      value: oneOfType([string, number]),
    }),
  ),
};

Overview.defaultProps = {
  data: [],
};

export default Overview;
