import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { OPEN_CONTENT, OPEN_LINK } from '@constants';
import Wizard from '@components/Wizard';
import WizardItem from '@components/Wizard/Item';
import { usePageActions } from '@hooks/usePageActions';
import MessageDetailStep from './MessageDetailStep';
import MessageContentStep from './MessageContentStep';
import ReviewStep from './ReviewStep';
import './styles.less';

const getPayload = ({ payload }) => {
  switch (payload.action) {
    case OPEN_CONTENT:
      return {
        contentTitle: payload.data.title || payload.data.name,
        contentType: payload.data.metadata.type,
        contentData: payload.data,
      };
    case OPEN_LINK:
      return {
        websiteLink: payload.data,
      };
    default:
      return {};
  }
};

function MessageWizard({
  title,
  onCompleted,
  type,
  initialMessage,
  loading,
  submitting,
  exitMessage,
  messageTranslationKey,
}) {
  const { t } = useTranslation();

  usePageActions({
    title,
  });

  document.title = title;

  const initialWizardData = useMemo(
    () => ({
      details: {
        title: initialMessage.title,
        language: initialMessage.language,
        status: initialMessage.published ? 'publish' : 'notPublish',
      },
      content: {
        content: initialMessage.body,
        action: initialMessage.payload && initialMessage.payload.action,
        ...(initialMessage.payload ? getPayload(initialMessage) : {}),
      },
    }),
    [initialMessage],
  );

  return (
    <Wizard
      onCompleted={onCompleted}
      loading={loading}
      submitting={submitting}
      initialData={initialWizardData}
      exitModalTitle={exitMessage}
    >
      <WizardItem stepKey="details" title={t('messages:ContentMessageWizard.steps.details')}>
        <MessageDetailStep messageTranslationKey={messageTranslationKey} />
      </WizardItem>
      <WizardItem stepKey="content" title={t('messages:ContentMessageWizard.steps.content')}>
        <MessageContentStep />
      </WizardItem>
      <WizardItem stepKey="review" title={t('messages:ContentMessageWizard.steps.review')}>
        <ReviewStep type={type} />
      </WizardItem>
    </Wizard>
  );
}

MessageWizard.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  onCompleted: PropTypes.func.isRequired,
  initialMessage: PropTypes.object,
  type: PropTypes.string.isRequired,
  exitMessage: PropTypes.string.isRequired,
};

MessageWizard.defaultProps = {
  initialMessage: {},
};

export default MessageWizard;
