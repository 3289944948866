import { call, getContext, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';
import { HOSPITAL, GLOBAL } from '@constants';
import i18n from '../../../../i18n';
import { duplicateRulesSuccess, IDuplicateRule, IDuplicateRules } from '../actions';
import { IRule } from '../types';
import doCreatePathwaysClient from '../../../../redux/doCreatePathwaysClient';
import { fixWhatContentType, fixWhenType, mapRawRule } from '../utils';
import { doDetermineOwnerForPathway } from '../../pathways/sagas/utils';
import { selectRule } from '../reducers';

const createDuplicateRuleName = (name: string) =>
  `${name} [${i18n.t('common:buttons.copy').toUpperCase()} ${moment().format('lll')}]`;

function* doDuplicationOfRules(rulesToCopy: IRule[]): any {
  const pathwaysClient = yield call(doCreatePathwaysClient);
  const duplicatedRules = [];
  const ownerId = yield call(doDetermineOwnerForPathway);

  for (const {
    id,
    url,
    name,
    what: ruleWhat,
    whatDetail: ruleWhatDetail,
    when: ruleWhen,
    whenDetail: ruleWhenDetail,
    metadata,
    ...toCopy
  } of rulesToCopy) {
    const [, originalRule] = yield select(selectRule(id));
    const [what, whatDetail] = fixWhatContentType(ruleWhat, ruleWhatDetail);
    const [when, whenDetail] = fixWhenType(ruleWhen, ruleWhenDetail);
    const duplicateRule = yield call(pathwaysClient.createRule, {
      ...toCopy,
      what,
      whatDetail,
      when,
      whenDetail,
      name: createDuplicateRuleName(name),
      metadata: {
        ...originalRule.metadata,
        ...metadata,
        ...(ownerId.includes(HOSPITAL) ? { source: HOSPITAL } : { source: GLOBAL }),
      },
      ownerId,
    });

    duplicatedRules.push(mapRawRule(duplicateRule));
  }

  return duplicatedRules;
}

export function* doDuplicateRule({ payload: { ruleToCopy } }: IDuplicateRule) {
  try {
    const history = yield getContext('history');

    const duplicatedRules = yield call(doDuplicationOfRules, [ruleToCopy]);

    yield put(duplicateRulesSuccess(duplicatedRules));
    yield call(history.push, `/procedure/rules/${duplicatedRules[0].id}`);
    yield call(message.success, i18n.t('pathways:ProcedureRules.duplicateRule.success'));
  } catch (err) {
    yield call(message.error, i18n.t('pathways:ProcedureRules.duplicateRule.error'));
  }
}

export function* doDuplicateRules({ payload: { rulesToCopy } }: IDuplicateRules) {
  try {
    const duplicatedRules = yield call(doDuplicationOfRules, rulesToCopy);

    yield put(duplicateRulesSuccess(duplicatedRules));
    yield call(message.success, i18n.t('pathways:ProcedureRules.duplicateRule.successPlural'));
  } catch (err) {
    yield call(message.error, i18n.t('pathways:ProcedureRules.duplicateRule.errorPlural'));
  }
}
