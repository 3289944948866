import { shape, string, arrayOf, number, bool, func, oneOf, oneOfType } from 'prop-types';
import {
  ALL,
  DELAY,
  FEATURE_DOCUMENT,
  FEATURE_FORM,
  GROUP,
  INDEX_EVENT,
  MANUAL,
  MESSAGE,
  STAGE_TRANSITION,
  FORM_SUBMITTED,
  FEATURE_VIDEO,
  FEATURE_QUESTIONNAIRE,
  FEATURE_TASK,
  MESSAGE_ALERT,
  MESSAGE_REMINDER,
  TRANSITION_NEXT,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
} from './constants';

export const antFormType = shape({
  getFieldDecorator: func,
  getFieldError: func,
  getFieldValue: func,
  getFieldsError: func,
  getFieldsValue: func,
  isSubmitting: func,
  resetField: func,
  setFields: func,
  setFieldsInitialValue: func,
  setFieldsValue: func,
  submit: func,
  validateFields: func,
  validateFieldsAndScroll: func,
});

export const antTableColumnType = shape({});

export const documentType = shape({
  id: string.isRequired,
  title: string.isRequired,
  description: string.isRequired,
  metadata: shape({
    tags: arrayOf(
      shape({
        term: string.isRequired,
        label: string.isRequired,
      }),
    ),
    language: string.isRequired,
    source: string.isRequired,
    published: bool.isRequired,
    version: number.isRequired,
  }),
});

export const historyType = shape({
  location: shape({ pathname: string, search: string }),
  push: func,
  replace: func,
  goBack: func,
});

export const matchType = shape({
  isExact: bool,
  params: shape({}),
  path: string,
  url: string,
});

export const getMatchTypeWithParams = params =>
  shape({
    isExact: bool,
    params: shape(params),
    path: string,
    url: string,
  });

export const whenType = oneOf([
  DELAY,
  INDEX_EVENT,
  MANUAL,
  STAGE_TRANSITION,
  FORM_SUBMITTED,
  QUESTIONNAIRE_SUBMITTED,
  TASK_SUBMITTED,
]);

export const pathwayRuleType = shape({
  description: string,
  id: number,
  metadata: shape({}),
  name: string,
  url: string,
  what: oneOf([
    FEATURE_DOCUMENT,
    FEATURE_FORM,
    MESSAGE,
    FEATURE_VIDEO,
    FEATURE_QUESTIONNAIRE,
    FEATURE_TASK,
    MESSAGE_ALERT,
    MESSAGE_REMINDER,
    TRANSITION_NEXT,
  ]),
  when: whenType,
  who: oneOf([ALL, GROUP]),
  whatDetail: shape({}),
  whenDetail: shape({}),
  whoDetail: shape({}),
});

export const pathwayType = shape({});

export const appUserPathwayType = shape({});

export const appUserType = shape({
  accessCode: string | undefined,
  invitationCodes: arrayOf(Object),
  address: string,
  age: number,
  city: string,
  dateOfBirth: string,
  doctor: string,
  email: string,
  firstName: string,
  hospital: string,
  id: string,
  ids: shape({
    'document-search': string,
    pathways: string,
    pip: string,
    ubiquity: string,
  }),
  isActive: bool,
  language: string,
  lastName: string,
  pathways: arrayOf(pathwayType),
  postcode: string,
  sex: string,
  title: string,
  url: string,
  uuid: string,
  yearOfBirth: string,
});

export const sentMessageType = shape({
  id: number,
  isDeleted: bool,
  deleted: oneOfType([bool, string]),
  app: number,
  isSent: bool,
  title: string,
  content: string,
  audienceType: string,
  audienceDisplay: string,
  scheduleType: string,
  scheduledDatetime: string,
  nextSendDatetime: string,
  lastSentDatetime: string,
  isRecurring: bool,
  recurringOffset: number,
  recurringUnit: string,
  shouldIncreaseBadge: bool,
  payloadOptions: shape({}),
  ownerId: string,
  users: arrayOf(string),
  groups: arrayOf(string),
  metadata: shape({}),
});

export const groupType = shape({
  id: string,
  description: string,
  membershipMode: oneOf(['manual', 'automatic']),
  ownerId: string,
  name: string,
  users: arrayOf(string),
  language: string,
  source: string,
  ruleset: shape({
    pk: number,
    keys: arrayOf(string),
    operators: arrayOf(shape({ key: string, name: string })),
    rules: arrayOf(
      shape({
        pk: number,
        predicates: arrayOf(
          shape({
            pk: number,
            key: string,
            operator: string,
            value: string,
          }),
        ),
      }),
    ),
  }),
});
