import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { usePageActions, useTitleUpdate } from '@hooks';
import { createRule } from '@pathways/redux/rules/actions';
import { selectRuleCreating } from '@pathways/redux/rules/reducers';
import { assignRealWhenType } from '@utils';
import RuleWizard from './RuleWizard';

function NewRule() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCreating = useSelector(selectRuleCreating);

  const { setTitle } = usePageActions({
    title: t('pathways:ProcedureRules.wizard.header'),
  });
  useTitleUpdate(setTitle, 'pathways:ProcedureRules.wizard.header');

  const onCompleted = useCallback(
    formData => {
      const ruleData = Object.entries(formData).reduce((prev, [key, data]) => {
        if (key === 'timing') {
          const wd = data.whenDetail || {};
          return {
            ...prev,
            when: assignRealWhenType(data.when),
            whenDetail: data.days ? { ...wd, days: data.days } : wd,
          };
        }

        if (key === 'details') {
          return {
            ...prev,
            ...data,
            metadata: { language: data.language, source: 'global' },
          };
        }

        return { ...prev, ...data };
      }, {});

      dispatch(createRule(ruleData));
    },
    [dispatch],
  );

  return <RuleWizard submitting={isCreating} onCompleted={onCompleted} />;
}

export default NewRule;
