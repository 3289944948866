import React from 'react';
import { List } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectLanguages } from '@redux/core/reducers';
import { selectCurrentDashboardUser } from '@redux/login/reducer';
import LanguageIcon from '@components/LanguageIcon';

const LanguagesSelectorPopup = () => {
  const currentDashboardUser = useSelector(selectCurrentDashboardUser);
  const languages = useSelector(selectLanguages);
  const { i18n } = useTranslation();

  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    localStorage.setItem(`${currentDashboardUser.email}-language`, lng);
    window.location.reload();
  };

  return (
    <List
      className="user-change-language-popover-list"
      size="small"
      dataSource={languages}
      bordered={false}
      split={false}
      renderItem={item => (
        <List.Item className="user-change-language-items" onClick={() => changeLanguage(item.key)}>
          <LanguageIcon language={item.key} showText />
          {i18n.language.startsWith(item.key) ? (
            <FontAwesomeIcon icon={faCheck} className="current-language" />
          ) : null}
        </List.Item>
      )}
    />
  );
};

export default LanguagesSelectorPopup;
