import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import OnboardingContainer from '@components/OnboardingContainer';
import /* hasError */ '@redux/registration/reducer';
import { invitationCodeSubmitted } from '@redux/registration/actions';
import { historyType } from '../../../propTypes';
import './style.less';

const inviteTokenRegex = /\?token=([A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12})/;

const Invitation = ({ history }) => {
  // const codeError = useSelector(hasError);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const invitationCode = useMemo(() => {
    const code = inviteTokenRegex.exec(history.location.search);

    if (code) {
      return code[1];
    }

    return null;
  }, [history.location.search]);

  useEffect(() => {
    if (invitationCode) {
      dispatch(invitationCodeSubmitted({ invitationCode }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <OnboardingContainer>
      <Card
        actions={
          !invitationCode && [<Button onClick={history.goBack}>{t('common:buttons.back')}</Button>]
        }
        title={
          invitationCode ? (
            <h1>{t('registration:Invitation:title')}</h1>
          ) : (
            <h1>{t('registration:Invitation.applyForAccess.title')}</h1>
          )
        }
        className="login-card"
      >
        {invitationCode ? (
          <p>{t('registration:Invitation.description')}</p>
        ) : (
          <>
            <p>{t('registration:Invitation.applyForAccess.welcome')}</p>
            <p>{t('registration:Invitation.applyForAccess.description')}</p>
            <p>
              <strong>
                <a href={`mailto:${t('registration:Invitation.applyForAccess.email')}`}>
                  {t('registration:Invitation.applyForAccess.email')}
                </a>
              </strong>
            </p>
          </>
        )}
      </Card>
    </OnboardingContainer>
  );
};

Invitation.propTypes = {
  history: historyType.isRequired,
};

Invitation.defaultProps = {};

export default Invitation;
