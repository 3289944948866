import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnGroup, Column } from '@cards/Card';
import DataRefresh from '@reviewHub/cards/DataRefresh';
import { useTranslation } from 'react-i18next';
import MetricTableCard from '@reviewHub/cards/MetricTable';
import { selectHospitalMetrics } from '@reviewHub/redux/selectors';
import { fetchHospitalMetrics } from '@reviewHub/redux/actions';

function Content({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hospitalId } = match.params;
  const [loading, metrics] = useSelector(selectHospitalMetrics(hospitalId));
  const isLoaded = !!metrics;

  useEffect(() => {
    if (!isLoaded) dispatch(fetchHospitalMetrics(hospitalId));
  }, [dispatch, hospitalId, isLoaded]);

  const contentData = [
    {
      metric: t('reviewHub:Hospital.Content.data.sentMessages'),
      value: metrics?.content.sentAdhocMessages,
    },
    {
      metric: t('reviewHub:Hospital.Content.data.scheduledMessages'),
      value: metrics?.content.scheduledAdhocMessages,
    },
    {
      metric: t('common:contentTypes.plural.documents'),
      value: metrics?.content.documents,
    },
    {
      metric: t('common:contentTypes.plural.videos'),
      value: metrics?.content.videos,
    },
    {
      metric: t('common:contentTypes.plural.forms'),
      value: metrics?.content.forms,
    },
    {
      metric: t('common:contentTypes.plural.questionnaires'),
      value: metrics?.content.questionnaires,
    },
    {
      metric: t('common:contentTypes.plural.tasks'),
      value: metrics?.content.tasks,
    },
    {
      metric: t('common:contentTypes.plural.messages'),
      value: metrics?.content.messages,
    },
    {
      metric: t('common:contentTypes.plural.alerts'),
      value: metrics?.content.alerts,
    },
    {
      metric: t('common:contentTypes.plural.reminders'),
      value: metrics?.content.reminders,
    },
  ];

  return (
    <ColumnGroup>
      <Column>
        <MetricTableCard
          data={contentData}
          loading={loading}
          title={t('reviewHub:Global.Content.title')}
        />
        <DataRefresh data={{ created: metrics?.created }} loading={loading} />
      </Column>
    </ColumnGroup>
  );
}

export default Content;
