import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import SearchableTable from '@components/SearchableTable';
import { fetchMessages, deleteScheduledMessages } from '@redux/messages/actions';
import { fetchAppUsers } from '@redux/appUsers/actions';
import { selectScheduledMessages } from '@redux/messages/reducers';
import IconButton from '@components/IconButton';
import createColumns from './createColumns';

const filterFunc = (value, comparator) => {
  return comparator(value.title) || comparator(value.audienceDisplay);
};

function ScheduledList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, messages] = useSelector(selectScheduledMessages);
  const [selectedMessageIds, setSelectedMessageIds] = useState([]);
  const columns = useMemo(() => createColumns(t), [t]);
  const onRowSelectionChange = useCallback(selectedRows => setSelectedMessageIds(selectedRows), []);
  const onDelete = useCallback(
    () =>
      Modal.confirm({
        title: t('messages:Scheduled.confirmDelete'),
        onOk: () => {
          dispatch(deleteScheduledMessages(selectedMessageIds));
          setSelectedMessageIds([]);
        },
        onCancel: () => {},
      }),
    [dispatch, selectedMessageIds, t],
  );

  useEffect(() => {
    dispatch(fetchAppUsers());
    dispatch(fetchMessages());
  }, [dispatch]);

  return (
    <SearchableTable
      columns={columns}
      dataSource={messages}
      extra={
        <IconButton
          disabled={selectedMessageIds.length === 0}
          icon={faTrashAlt}
          tooltip={t('common:buttons.delete')}
          onClick={onDelete}
        />
      }
      loading={loading}
      filterFunc={filterFunc}
      hasRoutedPagination
      onRowSelectionChange={onRowSelectionChange}
    />
  );
}

export default ScheduledList;
