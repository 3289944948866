import React, { useMemo } from 'react';
import { arrayOf, bool, shape } from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table } from 'antd';
import { Card } from '@cards/Card';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import './style.less';

function ControlledDocumentAcceptanceHistory({ acceptances, loading }) {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        dataIndex: ['version', 'version_content', 'language_code'],
        title: t('cards:ControlledDocumentAcceptanceHistory.columns.language'),
        width: 100,
        render: languageCode => <LanguageIcon language={languageCode} />,
      },
      { dataIndex: 'type', title: t('cards:ControlledDocumentAcceptanceHistory.columns.type') },
      {
        dataIndex: 'created',
        title: t('cards:ControlledDocumentAcceptanceHistory.columns.date'),
        width: 150,
        render: date => moment(date).format('L'),
      },
      {
        dataIndex: 'created',
        key: 'agreed-tick',
        title: '',
        width: 40,
        render: date => <PublishedStatusIcon published={!!date} />,
      },
    ],
    [t],
  );

  return (
    <Card
      className="controlled-document-acceptance-history"
      title={t('cards:ControlledDocumentAcceptanceHistory.title')}
    >
      <Table
        columns={columns}
        dataSource={acceptances}
        loading={loading}
        pagination={false}
        rowKey="uuid"
        scroll={{ x: 500 }}
      />
    </Card>
  );
}

ControlledDocumentAcceptanceHistory.propTypes = {
  acceptances: arrayOf(shape({})),
  loading: bool,
};

ControlledDocumentAcceptanceHistory.defaultProps = {
  acceptances: [],
  loading: false,
};

export default ControlledDocumentAcceptanceHistory;
