import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createForm } from '@redux/forms/actions';
import { useTranslation } from 'react-i18next';
import { selectFormCreating } from '@redux/forms/reducers';
import FormWizard from './FormWizard';

export default ({ type, formTranslationKey, match }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { path } = match;

  const creating = useSelector(selectFormCreating);

  const onCompleted = useCallback(
    formData => {
      dispatch(
        createForm(
          formTranslationKey,
          formData.details.name,
          formData.details.description,
          formData.details.language,
          formData.details.status === 'publish' ? true : false,
          formData.content.json,
          type,
          path,
          formData.content.fileList[0].name,
        ),
      );
    },
    [formTranslationKey, dispatch, type, path],
  );

  return (
    <FormWizard
      title={t(`${formTranslationKey}:NewForm.title`)}
      exitMessage={t(`${formTranslationKey}:NewForm.exitConfirmation`)}
      type={type}
      onCompleted={onCompleted}
      submitting={creating}
      formTranslationKey={formTranslationKey}
    />
  );
};
