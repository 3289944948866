import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Details from './Details';
import Invitation from './InvitationCode';

const RegistrationIndex = () => {
  return (
    <Switch>
      <Route exact path="/auth/registration" component={Invitation} />
      <Route path="/auth/registration/details" component={Details} />
    </Switch>
  );
};

export default RegistrationIndex;
