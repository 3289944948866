import React from 'react';
import { createUniqueArray } from '@utils';
import PathwayProgress from './PathwayProgress';
import PathwayName from './PathwayName';
import './styles.less';

export default (t, appUsers, canViewPII) => [
  {
    title: t('cards:PatientList.columns.name'),
    key: 'name',
    render: appUser => (canViewPII ? `${appUser.firstName} ${appUser.lastName}` : '******'),
    sorter: (a, b) => `${a.firstName} ${b.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
    onFilter: (value, record) => `${record.firstName} ${record.lastName}` === value,
  },
  {
    title: t('cards:PatientList.columns.doctor'),
    key: 'doctor',
    render: appUser => appUser.doctor,
    sorter: (a, b) => a.doctor.localeCompare(b.doctor),
    filters: createUniqueArray(appUsers.map(appUser => appUser.doctor)).map(doctor => ({
      text: doctor,
      value: doctor,
    })),
    onFilter: (value, record) => record.doctor === value,
  },
  {
    title: t('cards:PatientList.columns.pathwayName'),
    key: 'pathwayName',
    render: appUser => <PathwayName appUser={appUser} />, // TODO fix how this column retrieves appUser pathway information
  },
  {
    title: t('cards:PatientList.columns.pathwayProgress'),
    key: 'pathwayProgress',
    render: appUser => {
      return <PathwayProgress appUser={appUser} />;
    },
    fixed: 'right',
    width: 300,
  },
];
