import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from 'antd';
import LanguageIcon from '@components/LanguageIcon';
import SourceIcon from '@components/SourceIcon';
import { pathway } from '@assets/icons';
import {
  DELAY,
  whatLabelTypes,
  whatTypesToContentType,
  whenTypes,
  whoTypes,
  TRANSITION_NEXT,
} from '@constants';
import { contentTypesList, contentTypes, extractSourceFromOwnerId } from '@utils';
import { createLanguageFilters } from '@utils/supportedLanguages';

export default t => [
  {
    className: 'language-column',
    title: t('cards:ProcedureRuleList.columns.language.title'),
    dataIndex: 'metadata',
    key: 'language',
    render: ({ language }) => (language ? <LanguageIcon language={language} /> : null),
    width: 150,
    sorter: (a, b) => a.metadata.language.localeCompare(b.metadata.language),
    filters: createLanguageFilters(t),
    onFilter: (value, record) => record.metadata.language === value,
  },
  {
    className: 'source-column',
    title: t('cards:ProcedureRuleList.columns.source.title'),
    dataIndex: 'ownerId',
    key: 'source',
    render: ownerId => <SourceIcon source={extractSourceFromOwnerId(ownerId)} />,
    width: 130,
    sorter: (a, b) => {
      if (a.source > b.source) return -1;
      if (a.source < b.source) return 1;
      return 0;
    },
    filters: [
      {
        text: t('common:source.global'),
        value: 'global',
      },
      // {
      //   text: t('common:source.country'),
      //   value: 'country',
      // },
      {
        text: t('common:source.hospital'),
        value: 'hospital',
      },
    ],
    onFilter: (value, record) => (record.ownerId || '').includes(value),
  },
  {
    title: t('cards:ProcedureRuleList.columns.name'),
    dataIndex: 'name',
    key: 'name',
    width: 250,
    ellipsis: true,
    sorter: (a, b) => {
      if (a.name > b.name) return -1;
      if (a.name < b.name) return 1;
      return 0;
    },
  },
  {
    title: t('cards:ProcedureRuleList.columns.what'),
    dataIndex: 'what',
    key: 'what',
    ellipsis: true,
    render: what => (
      <>
        {what === TRANSITION_NEXT ? (
          <Icon component={pathway} />
        ) : (
          <FontAwesomeIcon icon={contentTypes[whatTypesToContentType[what]].icon} />
        )}{' '}
        {t(whatLabelTypes[what])}
      </>
    ),
    filters: contentTypesList.map(what => ({
      text: t(contentTypes[what].label),
      value: what,
    })),
    onFilter: (value, record) => whatTypesToContentType[record.what] === value,
    sorter: (a, b) => {
      if (a.what > b.what) return -1;
      if (a.what < b.what) return 1;
      return 0;
    },
  },
  {
    title: t('cards:ProcedureRuleList.columns.who'),
    dataIndex: 'who',
    key: 'who',
    ellipsis: true,
    render: who => t(whoTypes[who]),
    sorter: (a, b) => {
      if (a.who > b.who) return -1;
      if (a.who < b.who) return 1;
      return 0;
    },
  },
  {
    title: t('cards:ProcedureRuleList.columns.when'),
    dataIndex: 'when',
    key: 'when',
    ellipsis: true,
    render: (when, record) => {
      if (when === DELAY) return `${record.whenDetail.days} ${t(whenTypes[when])}`;
      return t(whenTypes[when]);
    },
    sorter: (a, b) => {
      if (a.when > b.when) return -1;
      if (a.when < b.when) return 1;
      return 0;
    },
  },
];
