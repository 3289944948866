import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import IndexEvents from '@pathways/pages/IndexEvents';
import { useHasPermissions } from '@authorisation/hooks';
import { Permissions } from '@authorisation/constants';
import Hospitals from './Hospitals';
import HAHospitalDetails from './Hospital';
import HADataRequestList from './DataRequests';
import CreateDataRequest from './DataRequests/CreateDataRequest';
import CreateDeletionRequest from './DataRequests/CreateDeletionRequest';
import DashboardUsers from './DashboardUsers';
import { matchType } from '../../propTypes';

function Administration({ match }) {
  const permissions = useHasPermissions();

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => <Redirect to={`${match.path}/dashboard-users`} />}
      />
      <Route path={`${match.path}/index-events`} component={IndexEvents} />
      {permissions[Permissions.ViewDashboardUsers] && [
        <Route
          path={`${match.path}/dashboard-users`}
          key="dashboard-users"
          component={DashboardUsers}
        />,
      ]}
      {permissions[Permissions.ViewDataRequests] && [
        <Route
          exact
          path={`${match.path}/create-data-request`}
          key="data-request"
          component={CreateDataRequest}
        />,
        <Route
          exact
          path={`${match.path}/create-deletion-request`}
          key="deletion-request"
          component={CreateDeletionRequest}
        />,
        <Route
          path={`${match.path}/data-requests`}
          key="data-requests-list"
          component={HADataRequestList}
        />,
      ]}
      {permissions[Permissions.AdministerHospital] && [
        <Route path={`${match.path}/hospital`} key="hospital" component={HAHospitalDetails} />,
      ]}
      {permissions[Permissions.AdministerAllHospitals] && (
        <Route path={`${match.path}/hospitals`} component={Hospitals} />
      )}
    </Switch>
  );
}

Administration.propTypes = {
  match: matchType.isRequired,
};

export default Administration;
