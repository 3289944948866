import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import {
  faCheckCircle,
  faCopy,
  faTimesCircle,
  faTrashAlt,
  // faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { Column, ColumnGroup } from '@cards/Card';
import PathwayStagesTableCard from '@pathways/cards/Pathways/PathwayStagesTable';
import confirmDuplicateModal from '@pathways/cards/Pathways/PathwayList/confirmDuplicateModal';
import PathwayDetailsCard from '@pathways/cards/Pathways/PathwayDetails';
import IndexEventTriggeredRulesCard from '@pathways/cards/Pathways/IndexEventTriggeredRules';
import IndividualsCard from '@pathways/cards/Pathways/Individuals';
// import IndividualAccessModal from '@components/IndividualAccessModal';
import IconButton from '@components/IconButton';
import { waitForStoreUpdate } from '@utils';
import { usePageActions } from '@hooks';
import { selectPathway } from '@pathways/redux/pathways/reducers';
import { fetchHospitals } from '@redux/hospitals/actions';
import { selectHospital } from '@redux/hospitals/reducers';
import EditButton from '@components/EditButton';
// import { addPathwayToAppUsers } from '@pathways/redux/appUserPathways/actions';
import {
  deletePathway,
  duplicatePathway,
  publishPathways,
  unpublishPathways,
} from '@pathways/redux/pathways/actions';

function determinePathwayEditable(pathwayId, protectedPathways = [], loading) {
  if (protectedPathways.includes(Number(pathwayId)) || loading) return false;

  return true;
}

function PathwayDetails({ history, match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store = useStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [visibleModalType, setVisibleModalType] = useState(null);
  const [pathwayLoading, pathway] = useSelector(selectPathway(match.params.id));
  const dashboardUserProfile = useSelector(state => state.login.user.profile);
  const [hospitalLoading, hospital] = useSelector(selectHospital(dashboardUserProfile?.hospitalId));
  const isEditable = useMemo(
    () =>
      determinePathwayEditable(
        match.params.id,
        hospital?.pathways,
        pathwayLoading || hospitalLoading,
      ),
    [match, hospital, pathwayLoading, hospitalLoading],
  );
  const openModal = useCallback(modalType => setVisibleModalType(modalType), []);

  const onCancel = useCallback(() => {
    setVisibleModalType(null);
  }, []);

  useEffect(() => {
    dispatch(fetchHospitals());
  }, [dispatch]);

  const modalTypes = useMemo(
    () => ({
      // Commented out as workflow to do this isn't complete and doesn't deal ith Journeys
      // 'add-to-user': {
      //   title: '',
      //   okText: '',
      //   cancelText: t('common:buttons.cancel'),
      //   onOk: appUserIds => {
      //     dispatch(addPathwayToAppUsers(appUserIds, pathway.id));
      //     setIsSubmitting(true);
      //     waitForStoreUpdate(store, selectPathway(match.params.id)).then(() => {
      //       onCancel();
      //       setIsSubmitting(false);
      //     });
      //   },
      //   onCancel,
      // },
      'copy-pathway': {
        title: '',
        okText: '',
        cancelText: t('common:buttons.cancel'),
        onOk: () => {
          dispatch(duplicatePathway(pathway.id));
          setIsSubmitting(true);
          return waitForStoreUpdate(store, state => [state.pathways.duplicating]).then(() => {
            onCancel();
            setIsSubmitting(false);
          });
        },
        onCancel,
      },
      'publish-pathway': {
        title:
          pathway && pathway.isActive
            ? t('pathways:ProcedurePathways.details.modals.confirmUnpublish', {
                pathwayName: pathway.name,
              })
            : t('pathways:ProcedurePathways.details.modals.confirmPublish', {
                pathwayName: pathway ? pathway.name : '',
              }),
        okText:
          pathway && pathway.isActive ? t('common:buttons.unpublish') : t('common:buttons.publish'),
        cancelText: t('common:buttons.cancel'),
        onOk: () => {
          pathway.isActive
            ? dispatch(unpublishPathways([pathway.id]))
            : dispatch(publishPathways([(pathway && pathway.id) || '']));
          onCancel();
          return waitForStoreUpdate(store, selectPathway(match.params.id)).then(() => {
            setIsSubmitting(false);
          });
        },
        onCancel,
      },
      'delete-pathway': {
        title: t('pathways:ProcedurePathways.details.modals.confirmDelete', {
          pathwayName: pathway ? pathway.name : '',
        }),
        okText: t('common:buttons.delete'),
        cancelText: t('common:buttons.cancel'),
        onOk: () => {
          dispatch(deletePathway(pathway.id));
          return waitForStoreUpdate(store, selectPathway(match.params.id));
        },
        onCancel,
      },
    }),
    [dispatch, match, pathway, store, onCancel, t],
  );

  useEffect(() => {
    if (visibleModalType === 'copy-pathway') {
      if (!isSubmitting) {
        const { onCancel, onOk } = modalTypes[visibleModalType];
        confirmDuplicateModal(t, onOk, onCancel);
      }
    } else if (visibleModalType === 'add-to-user') {
      // do nothing here
    } else if (visibleModalType) {
      Modal.confirm(modalTypes[visibleModalType]);
    }
  }, [isSubmitting, modalTypes, t, visibleModalType]);

  const { setActions, setTitle } = usePageActions({
    actions: [],
    showBack: true,
    title: '',
  });

  useEffect(() => {
    setTitle(
      pathway ? (pathway.name.length > 50 ? `${pathway.name.slice(0, 50)}...` : pathway.name) : '',
    );
    setActions([
      // Commented out as workflow to do this isn't complete and doesn't deal ith Journeys
      // <IconButton
      //   icon={faUser}
      //   key="add-to-user"
      //   tooltip={t('common:buttons.applyIndividualAccess')}
      //   onClick={() => openModal('add-to-user')}
      // />,
      <IconButton
        icon={faCopy}
        key="copy-pathway"
        tooltip={t('common:buttons.copy')}
        onClick={() => openModal('copy-pathway')}
      />,
      <IconButton
        disabled={!isEditable}
        icon={pathway && pathway.isActive ? faTimesCircle : faCheckCircle}
        key="publish-pathway"
        tooltip={
          pathway && pathway.isActive ? t('common:buttons.unpublish') : t('common:buttons.publish')
        }
        onClick={() => openModal('publish-pathway')}
      />,
      <IconButton
        disabled={!isEditable}
        icon={faTrashAlt}
        key="delete-pathway"
        tooltip={t('common:buttons.delete')}
        onClick={() => Modal.confirm(modalTypes['delete-pathway'])}
      />,
      <EditButton
        disabled={!isEditable}
        key="edit-pathway"
        onClick={() => history.push(`${match.url}/edit`)}
      />,
    ]);
  }, [pathway, openModal, t, isEditable]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ColumnGroup>
        <Column>
          <PathwayStagesTableCard pathwayId={match.params.id} />
        </Column>
      </ColumnGroup>
      <ColumnGroup>
        <Column>
          <IndexEventTriggeredRulesCard pathwayId={match.params.id} />
        </Column>
      </ColumnGroup>
      <ColumnGroup>
        <Column>
          <PathwayDetailsCard pathwayId={match.params.id} />
        </Column>
        <Column>
          <IndividualsCard pathwayId={match.params.id} />
        </Column>
      </ColumnGroup>

      {/* <IndividualAccessModal
        submitting={isSubmitting}
        visible={visibleModalType === 'add-to-user'}
        onCancel={onCancel}
        onSubmit={modalTypes[visibleModalType] && modalTypes[visibleModalType].onOk}
      /> */}
    </>
  );
}

export default PathwayDetails;
