import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ColumnGroup, Column } from '@cards/Card';
import Overview from '@reviewHub/cards/Overview';
import DataRefresh from '@reviewHub/cards/DataRefresh';
import MetricTableCard from '@reviewHub/cards/MetricTable';
import { selectHospitalMetrics } from '@reviewHub/redux/selectors';
import { fetchHospitalMetrics } from '@reviewHub/redux/actions';

function Users({ match }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hospitalId } = match.params;
  const [loading, metrics] = useSelector(selectHospitalMetrics(hospitalId));
  const isLoaded = !!metrics;

  useEffect(() => {
    if (!isLoaded) dispatch(fetchHospitalMetrics(hospitalId));
  }, [dispatch, hospitalId, isLoaded]);

  const overview = [
    // {
    //   metric: t('reviewHub:Hospital.Overview.data.pendingRegistrations'),
    //   value: metrics?.overview.pendingRegistrations,
    // },
    {
      metric: t('reviewHub:Hospital.AppUsers.data.invitations'),
      value: metrics?.users.invitationsSent,
    },
    {
      metric: t('reviewHub:Hospital.Overview.data.appRegistrations'),
      value: metrics?.overview.totalRegistrations,
    },
    {
      metric: t('reviewHub:Hospital.Overview.data.signUpPercentage'),
      value: metrics?.overview.signupPercentage,
    },
    {
      metric: t('reviewHub:Hospital.Overview.data.hospitalAdmins'),
      value: metrics?.overview.hospitalAdmins,
    },
    {
      metric: t('reviewHub:Hospital.Overview.data.professionalUsers'),
      value: metrics?.overview.professionalUsers,
    },
  ];

  const users = [
    {
      metric: t('reviewHub:Hospital.AppUsers.data.invitations'),
      value: metrics?.users.invitationsSent,
    },
    {
      metric: t('reviewHub:Hospital.AppUsers.data.completed'),
      value: metrics?.users.completedRegistration,
    },
    // {
    //   metric: t('reviewHub:Hospital.AppUsers.data.pending'),
    //   value: metrics?.users.pendingRegistrations,
    // },
    {
      metric: t('reviewHub:Hospital.AppUsers.data.percentageCompleted'),
      value: metrics?.users.percentageCompleted,
    },
  ];

  const dashboardUsers = [
    {
      metric: t('reviewHub:Hospital.AppUsers.data.invitations'),
      value: metrics?.dashboardUsers.total,
    },
    {
      metric: t('reviewHub:Hospital.AppUsers.data.completed'),
      value: metrics?.dashboardUsers.completedRegistration,
    },
    // {
    //   metric: t('reviewHub:Hospital.AppUsers.data.pending'),
    //   value: metrics?.dashboardUsers.pendingRegistrations,
    // },
    {
      metric: t('reviewHub:Hospital.AppUsers.data.percentageCompleted'),
      value: metrics?.dashboardUsers.percentageCompleted,
    },
    {
      metric: t('reviewHub:Hospital.DashboardUsers.data.admin'),
      value: metrics?.dashboardUsers.admin,
    },
    {
      metric: t('reviewHub:Hospital.DashboardUsers.data.professional'),
      value: metrics?.dashboardUsers.professional,
    },
  ];

  return (
    <ColumnGroup>
      <Column>
        <Overview data={overview} loading={loading} />
        <MetricTableCard
          data={users}
          loading={loading}
          title={t('reviewHub:Hospital.AppUsers.title')}
        />
        <MetricTableCard
          data={dashboardUsers}
          loading={loading}
          title={t('reviewHub:Hospital.DashboardUsers.title')}
        />
        <DataRefresh data={{ created: metrics?.created }} loading={loading} />
      </Column>
    </ColumnGroup>
  );
}

export default Users;
