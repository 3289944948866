import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, func, arrayOf, shape, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { fetchDocuments } from '@redux/documents/actions';
import { fetchForms } from '@redux/forms/actions';
import SingleSelectModal from '../SingleSelectModal';
import createColumns from './createColumns';
import { selectContent, filterFunc } from './';

function SelectContentModal({ contentTypes, defaultFilteredValue, visible, onCancel, onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, allContent] = useSelector(selectContent);
  const content = useMemo(() => allContent.filter(c => contentTypes.includes(c.metadata.type)), [
    allContent,
    contentTypes,
  ]);

  const columns = useMemo(() => {
    return createColumns(t, contentTypes, defaultFilteredValue);
  }, [t, contentTypes, defaultFilteredValue]);

  useEffect(() => {
    if (contentTypes.includes('DOCUMENT') || contentTypes.includes('VIDEO')) {
      dispatch(fetchDocuments());
    }
    if (
      contentTypes.includes('FORM') ||
      contentTypes.includes('QUESTIONNAIRE') ||
      contentTypes.includes('TASK')
    ) {
      dispatch(fetchForms());
    }
  }, [dispatch, contentTypes]);

  return (
    <SingleSelectModal
      columns={columns}
      dataSource={content}
      displayKeys={['title']}
      filterFunc={filterFunc}
      loading={loading}
      primaryKey="id"
      title={t('common:modals.SelectContent.title')}
      visible={visible}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

SelectContentModal.propTypes = {
  contentTypes: arrayOf(string).isRequired,
  defaultFilteredValue: shape({}),
  visible: bool.isRequired,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
};

SelectContentModal.defaultProps = {
  defaultFilteredValue: {},
};

export default SelectContentModal;
