import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import useWizard from '@components/Wizard/useWizard';

function Details({ documentTranslationKey }) {
  const { t } = useTranslation();
  const { currentStepData, form } = useWizard();

  return (
    <>
      <Form.Item label={t(`${documentTranslationKey}:Wizard.details.status`)}>
        {form.getFieldDecorator('status', {
          initialValue: currentStepData.status || 'published',
        })(
          <Select>
            <Select.Option value="published">
              {t(`${documentTranslationKey}:Wizard.details.publish`)}
            </Select.Option>
            <Select.Option value="unpublished">
              {t(`${documentTranslationKey}:Wizard.details.unpublish`)}
            </Select.Option>
          </Select>,
        )}
      </Form.Item>
    </>
  );
}

export default Details;
