import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Icon, Table } from 'antd';
import moment from 'moment';
import { procedurePathway } from '@assets/icons';
import DetailForm from '@components/DetailForm';
import DetailRow from '@components/DetailRow';
import LanguageIcon from '@components/LanguageIcon';
import PublishedStatusIcon from '@components/PublishedStatusIcon';
import { Card } from '@cards/Card';
import { languageFromMetadata } from '@utils/contentMetadata';
import { selectAppUser } from '@redux/appUsers/reducers';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathways } from '@pathways/redux/pathways/reducers';
import { fetchIndexEvents } from '@pathways/redux/indexEvents/actions';
import { selectIndexEvents } from '@pathways/redux/indexEvents/reducers';
import { extractSlugFromTypeSlug } from '@pathways/redux/indexEvents/utils';
import { fetchAppUserJourneys, fetchAppUsersPathways } from '../../redux/appUserPathways/actions';
import {
  selectJourneysForAppUser,
  selectPathwaysForAppUserWithOriginals,
} from '../../redux/appUserPathways/reducers';
import './style.less';

function JourneyDetails({ appUserId, journeyId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pathwaysLoading] = useSelector(selectPathways);
  const [appUserLoading, appUser] = useSelector(selectAppUser(appUserId));
  const [journeysLoading, journeys] = useSelector(selectJourneysForAppUser(appUser));
  const [appUserPathwaysLoading, appUserPathways] = useSelector(
    selectPathwaysForAppUserWithOriginals(appUser),
  );
  const [indexEventTypesLoading, indexEventTypes] = useSelector(selectIndexEvents);
  const findLanguage = useSelector(languageFromMetadata);
  const { i18n, t } = useTranslation();

  const loading =
    appUserLoading ||
    journeysLoading ||
    appUserPathwaysLoading ||
    indexEventTypesLoading ||
    pathwaysLoading;

  useEffect(() => {
    dispatch(fetchAppUserJourneys(appUserId));
    dispatch(fetchAppUsersPathways());
    dispatch(fetchIndexEvents());
    dispatch(fetchPathways());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const journey = useMemo(() => (journeys || []).find(({ id }) => id === journeyId), [
    journeys,
    journeyId,
  ]);

  const pathwaysForJourney = useMemo(
    () =>
      appUserPathways?.filter(
        ({ journeyId: pathwayJourneyId }) => pathwayJourneyId === journeyId,
      ) || [],
    [appUserPathways, journeyId],
  );

  /**
   * this is needed to account for Index Events that are part of a journey but
   * don't have a value set currently. The pathways API only allows configuring
   * of Index Events that have a value so the Index Events with no values won't show
   * up here without it.
   */
  const indexEventTypesForJourney = useMemo(() => {
    const indexEventSlugs = new Set();
    pathwaysForJourney.forEach(pathway => {
      if (pathway?.originalPathway?.indexEvents) {
        pathway.originalPathway.indexEvents.forEach(({ eventTypeSlug }) =>
          indexEventSlugs.add(eventTypeSlug),
        );
      }
    });

    return indexEventTypes.filter(({ slug }) => indexEventSlugs.has(slug));
  }, [indexEventTypes, pathwaysForJourney]);

  const indexEventDates = useMemo(
    () =>
      (journey?.indexEvents || []).reduce(
        (prev, indexEvent) => ({
          ...prev,
          [indexEvent.eventTypeSlug]: indexEvent.value,
        }),
        {},
      ),
    [journey],
  );

  const columns = useMemo(
    () => [
      {
        className: 'language-column no-filters',
        dataIndex: 'metadata',
        fixed: 'left',
        key: 'language',
        onFilter: (value, record) => {
          return (findLanguage(record) || '').toLowerCase() === value;
        },
        title: t('cards:JourneyDetails.columns.language'),
        render: metadata => <LanguageIcon language={findLanguage({ metadata })} />,
      },
      {
        title: t('cards:JourneyDetails.columns.type'),
        key: 'pathway-type',
        render: () => (
          <span>
            <Icon component={procedurePathway} /> {t('cards:JourneyDetails.procedure')}
          </span>
        ),
      },
      {
        title: t('cards:JourneyDetails.columns.title'),
        dataIndex: 'name',
        key: 'title',
        render: (pathwayName, { isActive }) =>
          isActive ? pathwayName : t('pathways:archived', { pathwayName }),
      },
      {
        title: 'UCID',
        dataIndex: 'externalId',
        key: 'ucid',
        render: externalId => externalId,
      },
      {
        className: 'publish-column no-filters',
        dataIndex: 'isActive',
        fixed: 'right',
        key: 'isActive',
        render: isActive => <PublishedStatusIcon published={isActive} />,
        title: t('cards:JourneyDetails.columns.published'),
      },
    ],
    [findLanguage, t],
  );

  const languageCode = useMemo(() => i18n.language.replace(/-.*/, ''), [i18n]);

  return (
    <Card
      className="journey-details-card"
      extra={
        <Icon
          type="edit"
          onClick={() => history.push(`/patients/individuals/${appUserId}/journey/edit`)}
        />
      }
      title={t('cards:JourneyDetails.title')}
      loading={loading}
    >
      {indexEventTypesForJourney.length ? (
        <DetailForm>
          <h4>{t('cards:JourneyDetails.indexEvents')}</h4>
          {indexEventTypesForJourney.map(indexEvent => (
            <DetailRow
              key={indexEvent.id}
              label={indexEvent.translatedNames[languageCode] || indexEvent.name}
              value={
                indexEventDates[extractSlugFromTypeSlug(indexEvent.slug)]
                  ? moment(indexEventDates[extractSlugFromTypeSlug(indexEvent.slug)]).format('LL')
                  : t('cards:JourneyDetails.noDate')
              }
            />
          ))}
        </DetailForm>
      ) : null}
      <h4>{t('cards:JourneyDetails.pathways')}</h4>
      <Table
        columns={columns}
        dataSource={pathwaysForJourney.map(({ id, isActive, externalId, originalPathway }) => ({
          ...originalPathway,
          isActive,
          externalId,
          appUserPathwayId: id,
        }))}
        pagination={false}
        rowKey="appUserPathwayId"
        scroll={{ x: 1000 }}
        onRow={record => ({
          onClick: () =>
            history.push(
              `/patients/individuals/${appUserId}/journey/pathway/${record.appUserPathwayId}`,
            ),
        })}
      />
    </Card>
  );
}

export default JourneyDetails;
