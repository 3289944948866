import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OPEN_CONTENT, OPEN_LINK } from '@constants';
import { selectPermissionsForUser } from '@authorisation/selectors';
import { fetchCannedMessages, updateContentMessage } from '@redux/messages/actions';
import { selectCannedMessage, selectCannedMessagesUpdating } from '@redux/messages/reducers';
import { determineSourceTypeForNewContent } from '@utils';
import MessageWizard from './MessageWizard';

function getPayloadOptionsData(content) {
  switch (content.action) {
    case OPEN_CONTENT:
      return content.contentData;
    case OPEN_LINK:
      return content.websiteLink;
    default:
      return null;
  }
}

export default ({
  type,
  messageTranslationKey,
  match: {
    params: { id },
  },
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissionsForUser);

  useEffect(() => {
    dispatch(fetchCannedMessages());
  }, [dispatch]);

  const [loading, message] = useSelector(selectCannedMessage(id));

  const updating = useSelector(selectCannedMessagesUpdating);

  const onCompleted = useCallback(
    messageData => {
      const { details, content } = messageData;
      const updatedMessage = {
        title: details.title,
        published: details.status === 'publish' ? true : false,
        version: message ? message.version + 1 : 1,
        id,
        language: details.language,
        body: content.content,
        source: determineSourceTypeForNewContent(permissions),
        created: message.created,
        payload: {
          action: content.action,
          data: getPayloadOptionsData(content),
        },
        metadata: {
          type,
        },
      };

      dispatch(updateContentMessage(updatedMessage, type, messageTranslationKey, id));
    },
    [dispatch, id, message, messageTranslationKey, type, permissions],
  );

  const wizardLoaading = loading || (!loading && !message);

  return (
    <MessageWizard
      title={t(`${messageTranslationKey}:EditContentMessageWizard.title`)}
      exitMessage={t(`${messageTranslationKey}:EditContentMessageWizard.exitConfirmation`)}
      type={type}
      onCompleted={onCompleted}
      loading={wizardLoaading}
      submitting={updating}
      messageTranslationKey={messageTranslationKey}
      initialMessage={message}
    />
  );
};
