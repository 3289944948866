import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RuleTag from '@components/RuleTag';

export default (stages, t) =>
  stages
    ? [
        {
          className: 'icon-column',
          dataIndex: 'icon',
          fixed: 'left',
          key: 'icon',
          title: '',
          width: '60px',
          render: icon => <FontAwesomeIcon icon={icon} />,
        },
        ...Object.values(stages).map(stage => {
          return {
            dataIndex: stage.number,
            key: `stage-${stage.number}-rules`,
            title: stage.isAdhoc
              ? stage.name
              : `${t('pathways:ProcedurePathways.wizard.review.stage')} ${stage.number}: ${
                  stage.name
                }`,
            ...(stage.isAdhoc ? { fixed: 'right', width: '300px' } : {}),
            render: rules => {
              return rules
                ? rules.map(rule => <RuleTag key={rule.id} rule={rule} disableLink />)
                : null;
            },
          };
        }),
      ]
    : [];
