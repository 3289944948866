import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { selectContentMessages } from '@redux/messages/reducers';
import { fetchCannedMessages } from '@redux/messages/actions';
import createColumns from './createMessageColumns';
import ContentModal from './Modal';
import { MESSAGE, whatTypeToTranslationKey } from '../../../../../../constants';
import './style.less';

const filterFunc = (value, comparator) => {
  return comparator(value.title) || comparator(value.description);
};

function SelectMessageModal({ defaultFilteredValue, onCancel, onOk }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, data] = useSelector(selectContentMessages);
  const contentTypeTranslationKey = whatTypeToTranslationKey(MESSAGE);
  const columns = useMemo(() => createColumns(t, defaultFilteredValue, contentTypeTranslationKey), [
    defaultFilteredValue,
    t,
    contentTypeTranslationKey,
  ]);

  useEffect(() => {
    dispatch(fetchCannedMessages());
  }, [dispatch]);

  return (
    <ContentModal
      columns={columns}
      data={data}
      loading={loading}
      title={t('pathways:ProcedureRules.wizard.content.message')}
      filterFunc={filterFunc}
      onCancel={onCancel}
      onOk={onOk}
    />
  );
}

SelectMessageModal.propTypes = {
  onCancel: func.isRequired,
  onOk: func.isRequired,
};

export default SelectMessageModal;
