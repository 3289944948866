import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchForms, fetchFormData, updateForm } from '@redux/forms/actions';
import { selectForm, selectFormData, selectFormUpdating } from '@redux/forms/reducers';
import FormWizard from './FormWizard';

export default ({
  match: {
    params: { id },
  },
  formTranslationKey,
  type,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchForms());
    dispatch(fetchFormData(id));
  }, [dispatch, id]);

  const [formLoading, form] = useSelector(selectForm(id));
  const [formDataLoading, formData] = useSelector(selectFormData(id));

  const loading = formLoading || formDataLoading;

  const updating = useSelector(selectFormUpdating);

  const onCompleted = useCallback(
    formData => {
      dispatch(
        updateForm(
          formTranslationKey,
          id,
          formData.details.name,
          formData.details.description,
          formData.details.language,
          formData.details.status === 'publish' ? true : false,
          formData.content.json,
          type,
          formData.content.fileList[0].name,
        ),
      );
    },
    [formTranslationKey, dispatch, id, type],
  );

  return (
    <FormWizard
      title={t(`${formTranslationKey}:EditForm.title`)}
      exitMessage={t(`${formTranslationKey}:EditForm.exitConfirmation`)}
      loading={loading}
      submitting={updating}
      type={type}
      onCompleted={onCompleted}
      initialForm={form}
      initialFormData={formData}
      formTranslationKey={formTranslationKey}
    />
  );
};
