import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

type TranslationString = string;
export type Language = {
  key: string;
  name: TranslationString;
  icon: IconDefinition;
};

export type Owner = {
  key: string;
  name: TranslationString;
  icon: IconDefinition;
};

export type ISetLanguages = ReturnType<typeof setLanguages>;
export const setLanguages = (languages: Language[]) => ({
  type: 'core/setLanguages' as const,
  payload: {
    languages,
  },
});

export type ISetOwners = ReturnType<typeof setOwners>;
export const setOwners = (owners: Owner[]) => ({
  type: 'core/setOwners' as const,
  payload: {
    owners,
  },
});
