export const TEST = 'Hello';

export const envName = 'prod';

export const domain = 'forpatientapp.com';

// General App config
export const appToken = 'd1c6fe';
export const appId = '3';
export const ubiquityCompanyId = 4;
// "prod-forpatientapp-staff" Cognito User Pool
export const userPoolId = 'eu-central-1_Fv5YOR394';
export const userPoolAppClient = '63bcqrt1b9jk6rg254kn13btu2';

// IDM
export const idmOrganisation = 3;
export const organisationSlug = 'b-braun';
export const idmApiRoot = `https://idm.${domain}/`;
export const organisationUrl = `${idmApiRoot}api/v1/organisations/${idmOrganisation}/`;
export const idmLogin = `https://idm.${domain}/api/login/`;
export const idmLogout = `https://idm.${domain}/logout/`;
// export const idmTokens = `https://idm.${domain}/api/tokens/`;

// UIS
export const uisApiRoot = `https://uis.${domain}/`;

// PIP
export const pipApiRoot = `https://pip.${domain}`;
export const pipAppUrl = `https://pip.${domain}/api/admin/v1/apps/${appToken}/`;

export const ubiquityApiRoot = `https://ubiquity.${domain}/`;
export const pathwaysApiRoot = `https://pathways.${domain}/v1/apps/{{app_ubiquity_token}}/`;

export const messagingApiRoot = `https://ubiquity.${domain}/api/core/v1/apps/${appId}/`;

export const videoCreationEndpoint = `https://hx4c3lhhdg.execute-api.eu-central-1.amazonaws.com/prod/create`;
export const videoExportStatusEndpoint = `https://hx4c3lhhdg.execute-api.eu-central-1.amazonaws.com/prod/export_status`;
export const videoCEDetails = {
  ce_space_uuid: 'cbde80d8-2265-441c-81bf-bb43b31671db',
  ce_category_slug: 'production-videos',
  template_name: 'fpa-video-LANGUAGE',
};
export const videoUbiquityExportDetails = {
  theme_slug: 'default-style',
  ubiquity_app_id: appId,
  ubiquity_company_id: ubiquityCompanyId,
  ubiquity_category_ids: ['39'],
};

export const mixpanelToken = 'a3612385d39bf0e60afaa8677ac4a4d9';
export const mixpanelOptions = {
  // disable_persistence: true
  // api_host: "https://api-eu.mixpanel.com",
};
export const analyticsHiddenSuperProperties = ['firstName', 'lastName', 'name'];

export const metricsUrlRoot = 'https://fpa-prod-product-metrics.s3.eu-central-1.amazonaws.com/';
