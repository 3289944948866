import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { fetchDocumentPublishedStatus } from '@redux/documents/actions';
import { selectDocumentPublishedStatus } from '@redux/documents/reducers';
import PublishedStatusIcon from '@components/PublishedStatusIcon';

function PublishedStatusColumn({ documentId }) {
  const dispatch = useDispatch();
  const published = useSelector(selectDocumentPublishedStatus(documentId));

  useEffect(() => {
    if (published === undefined) {
      dispatch(fetchDocumentPublishedStatus(documentId));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (published === undefined) return <Spin />;

  return <PublishedStatusIcon published={published} />;
}

export default PublishedStatusColumn;
