import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';

import { MESSAGE, MESSAGE_ALERT, MESSAGE_REMINDER } from '@constants';
import { fetchPathways } from '@pathways/redux/pathways/actions';
import { selectPathwaysByRules } from '@pathways/redux/pathways/reducers';
import { Card } from '@cards/Card';

import './styles.less';

const pageSize = 10;

const translationKeyToMessageType = {
  messages: MESSAGE,
  alerts: MESSAGE_ALERT,
  reminders: MESSAGE_REMINDER,
};

const createColumns = t => [
  {
    title: t('cards:MessagePathways.columns.title'),
    dataIndex: 'name',
    key: 'title',
    sorter: (a, b) => a.name.localeCompare(b.name),
    align: 'left',
  },
  {
    title: t('cards:MessagePathways.columns.status'),
    dataIndex: '',
    key: 'status',
    width: 100,
    render: isActive => (
      <Tooltip
        placement="bottom"
        title={
          isActive ? t('cards:MessagePathways.published') : t('cards:MessagePathways.unpublished')
        }
      >
        {isActive ? (
          <FontAwesomeIcon size="lg" className="published-check" icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon size="lg" className="published-cross" icon={faTimesCircle} />
        )}
      </Tooltip>
    ),
    sorter: ({ isActive: a }, { isActive: b }) => {
      if (a === b) return 0;
      if (a < b) return 1;
      if (a > b) return -1;
    },
  },
];

const MessagePathwaysCard = ({ messageId, messageTranslationKey, ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = useMemo(() => createColumns(t), [t]);

  useEffect(() => {
    dispatch(fetchPathways());
  }, [dispatch]);

  const [loading, pathways] = useSelector(
    selectPathwaysByRules(
      rule =>
        rule.what === translationKeyToMessageType[messageTranslationKey] &&
        rule.whatDetail.id === messageId,
    ),
  );

  return (
    <>
      <Card.Half
        className="message-pathways-card"
        title={t(`cards:MessagePathways.${messageTranslationKey}.title`)}
        noPadding
        loading={loading}
        {...props}
      >
        <Table
          dataSource={pathways}
          columns={columns}
          pagination={{ position: 'bottom', pageSize }}
          rowKey="id"
          onRow={({ id }) => ({
            onClick: () => history.push(`/procedure/pathways/${id}`),
          })}
        />
      </Card.Half>
    </>
  );
};

export default MessagePathwaysCard;
