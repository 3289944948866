import { call, put, getContext, takeEvery, takeLatest, select } from 'redux-saga/effects';
import { message } from 'antd';
import i18n from '../../../i18n';
import {
  loadIndexEvents,
  indexEventsLoaded,
  loadIndexEventsFailed,
  ICreateIndexEvent,
  createIndexEventFailed,
  createIndexEventSuccess,
  IEditIndexEvent,
  editIndexEventFailed,
  editIndexEventSuccess,
  IDeleteIndexEvents,
  deleteIndexEventsFailed,
  deleteIndexEventsSuccess,
  ISwitchIndexEventOrder,
  switchIndexEventOrderSuccess,
  switchIndexEventOrderFailed,
} from './actions';
import { IIndexEvent, IRawIndexEvent } from './types';
import takeFirst from '@redux/takeFirst';
import doCreatePathwaysClient from '@redux/doCreatePathwaysClient';
import { mapSnakeCaseIndexEventToCamelCase } from './utils';
import { selectIndexEvents } from './reducers';

export default function* root() {
  yield takeFirst('indexEvents/fetch', doFetchIndexEvents);
  yield takeEvery('indexEvents/createIndexEvent', doCreateIndexEvent);
  yield takeEvery('indexEvents/editIndexEvent', doEditIndexEvent);
  yield takeEvery('indexEvents/deleteIndexEvents', doDeleteIndexEvents);
  yield takeLatest('indexEvents/switchOrder', doSwitchIndexEventOrder);
}

export function* doFetchIndexEvents() {
  yield put(loadIndexEvents());

  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);

    let page = 1;
    let indexEvents: IIndexEvent[] = [];

    while (true) {
      const { next, results } = yield call(pathwaysClient.listIndexEventTypes, page);
      indexEvents = [
        ...indexEvents,
        ...results.map(
          (indexEvent: IRawIndexEvent): IIndexEvent =>
            mapSnakeCaseIndexEventToCamelCase(indexEvent),
        ),
      ];

      if (!next) break;
      page += 1;
    }

    yield put(indexEventsLoaded(indexEvents.sort((a, b) => a.order - b.order)));
  } catch (err) {
    console.error(err);
    yield put(loadIndexEventsFailed());
    yield call(message.error, i18n.t('cards:IndexEventsList.networkError'));
  }
}

function* doCreateIndexEvent({ payload: { name, slug, translatedNames } }: ICreateIndexEvent) {
  try {
    const history = yield getContext('history');
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const indexEvent: IRawIndexEvent = yield call(
      pathwaysClient.createIndexEventType,
      name,
      slug,
      translatedNames,
    );

    yield put(createIndexEventSuccess(mapSnakeCaseIndexEventToCamelCase(indexEvent)));
    yield call(history.push, '/administration/index-events');
    yield call(message.success, i18n.t('indexEvents:Wizard.create.success'));
  } catch (err) {
    console.error(err);
    if (err.response?.body) {
      const errMessage = yield call(err.response.text.bind(err.response));

      if (errMessage?.includes(`An Index Event Type with slug \\"${slug}\\" already exists`)) {
        yield put(createIndexEventFailed());
        yield call(message.error, i18n.t('indexEvents:Wizard.create.alreadyExists'));
        return;
      }
    }
    yield put(createIndexEventFailed());
    yield call(message.error, i18n.t('indexEvents:Wizard.create.failed'));
  }
}

function* doEditIndexEvent({ payload: { indexEvent } }: IEditIndexEvent) {
  try {
    const history = yield getContext('history');
    const pathwaysClient = yield call(doCreatePathwaysClient);

    for (let [key, value] of Object.entries(indexEvent.translatedNames)) {
      if (!value || !value.length) {
        delete indexEvent.translatedNames[key];
      }
    }
    const editedIndexEvent = yield call(
      pathwaysClient.patchIndexEventType,
      indexEvent.id,
      indexEvent.name,
      indexEvent.slug,
      indexEvent.translatedNames,
      indexEvent.order,
    );

    yield put(editIndexEventSuccess(mapSnakeCaseIndexEventToCamelCase(editedIndexEvent)));
    yield call(history.push, `/administration/index-events/${indexEvent.id}`);
    yield call(message.success, i18n.t('indexEvents:Wizard.edit.success'));
  } catch (err) {
    console.error(err);
    yield put(editIndexEventFailed());
    yield call(message.error, i18n.t('indexEvents:Wizard.edit.failed'));
  }
}

function* doDeleteIndexEvents({ payload: { indexEventIds } }: IDeleteIndexEvents) {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);

    // ensure all passed IDs are numbers
    const indexEventNumberIds = indexEventIds.map(id => Number(id));

    for (const indexEventId of indexEventNumberIds) {
      yield call(pathwaysClient.deleteIndexEventType, indexEventId);
    }

    yield put(deleteIndexEventsSuccess(indexEventNumberIds));
    yield call(message.success, i18n.t('indexEvents:List.delete.success'));
  } catch (err) {
    console.error(err);
    yield put(deleteIndexEventsFailed());
    yield call(message.error, i18n.t('indexEvents:List.delete.failed'));
  }
}

function* doSwitchIndexEventOrder({ payload: { id, order } }: ISwitchIndexEventOrder): any {
  try {
    const pathwaysClient = yield call(doCreatePathwaysClient);
    const [, indexEvents]: [boolean, IIndexEvent[]] = yield select(selectIndexEvents);
    const indexEventIndex = indexEvents.findIndex(({ id: eventId }) => eventId === id);
    const indexEvent = indexEvents[indexEventIndex];

    let indexEventToSwap;

    if (indexEvent.order > order) {
      const previousIndexEvent = indexEvents[indexEventIndex - 1];

      yield call(
        pathwaysClient.patchIndexEventType,
        previousIndexEvent.id,
        undefined,
        undefined,
        undefined,
        previousIndexEvent.order + 1,
      );

      indexEventToSwap = { ...previousIndexEvent, order: previousIndexEvent.order + 1 };
    } else {
      const nextIndexEvent = indexEvents[indexEventIndex + 1];

      if (nextIndexEvent && nextIndexEvent.order > 0) {
        yield call(
          pathwaysClient.patchIndexEventType,
          nextIndexEvent.id,
          undefined,
          undefined,
          undefined,
          nextIndexEvent.order - 1,
        );

        indexEventToSwap = { ...nextIndexEvent, order: nextIndexEvent.order - 1 };
      }
    }

    if (order >= 0) {
      yield call(pathwaysClient.patchIndexEventType, id, undefined, undefined, undefined, order);
    }

    yield put(switchIndexEventOrderSuccess(id, order));
    if (indexEventToSwap) yield put(editIndexEventSuccess(indexEventToSwap));
    yield call(message.success, i18n.t('indexEvents:List.order.success'));
  } catch (err) {
    console.error(err);
    yield put(switchIndexEventOrderFailed());
    yield call(message.error, i18n.t('indexEvents:List.order.failed'));
  }
}
